<template>
  <div class="salla-min-h-screen salla-bg-gray-50 salla-p-6">
    <!-- Page Header -->
    <div class="salla-mb-8">
      <h1 class="salla-text-3xl salla-font-bold salla-text-gray-900">Pre-added Images Library</h1>
      <p class="salla-mt-2 salla-text-gray-600">Upload and manage your pre-added images for video templates</p>
    </div>

    <div class="salla-grid salla-grid-cols-1 xl:salla-grid-cols-2 salla-gap-6">
      <!-- Upload Section -->
      <section class="salla-bg-white salla-rounded-lg salla-shadow-md salla-p-6">
        <h2 class="salla-text-xl salla-font-semibold salla-text-gray-800 salla-mb-6">Upload New Image</h2>
        
        <form @submit.prevent="submitImageData" class="salla-space-y-6">
          <!-- Drag & Drop Image Upload -->
          <div class="salla-space-y-2">
            <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">
              Image File
              <span class="salla-text-red-500">*</span>
            </label>
            <div 
              class="salla-mt-1 salla-flex salla-justify-center salla-px-6 salla-pt-5 salla-pb-6 salla-border-2 salla-border-gray-300 salla-border-dashed salla-rounded-md"
              :class="{'salla-border-primary-500 salla-bg-primary-50': imageFile}"
            >
              <div class="salla-space-y-1 salla-text-center">
                <svg 
                  class="salla-mx-auto salla-h-12 salla-w-12 salla-text-gray-400"
                  :class="{'salla-text-primary-500': imageFile}"
                  stroke="currentColor" 
                  fill="none" 
                  viewBox="0 0 48 48"
                >
                  <path 
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    stroke-width="2" 
                    stroke-linecap="round" 
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="salla-flex salla-text-sm salla-text-gray-600">
                  <label
                    class="salla-relative salla-cursor-pointer salla-rounded-md salla-font-medium salla-text-primary-600 hover:salla-text-primary-500"
                  >
                    <span>Upload an image</span>
                    <input 
                      type="file"
                      @change="onImageFileChange"
                      accept="image/*"
                      class="salla-sr-only"
                      required
                    >
                  </label>
                  <p class="salla-pl-1">or drag and drop</p>
                </div>
                <p class="salla-text-xs salla-text-gray-500">PNG, JPG, GIF up to 10MB</p>
                <p v-if="imageFile" class="salla-text-sm salla-text-primary-600 salla-font-medium">
                  Selected: {{ imageFile.name }}
                </p>
              </div>
            </div>
          </div>

          <!-- Description Field -->
          <div class="salla-space-y-2">
            <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">
              Image Description
              <span class="salla-text-xs salla-text-gray-500 salla-ml-1">(Optional)</span>
            </label>
            <textarea
              v-model="imageDescription"
              rows="3"
              class="salla-block salla-w-full salla-rounded-md salla-border salla-border-gray-300 salla-shadow-sm focus:salla-border-primary-500 focus:salla-ring-primary-500 sm:salla-text-sm"
              placeholder="Enter a description for this image..."
            ></textarea>
          </div>

          <!-- Submit Button -->
          <button
            type="submit"
            :disabled="!imageFile"
            class="salla-w-full salla-flex salla-justify-center salla-py-2 salla-px-4 salla-border salla-border-transparent salla-rounded-md salla-shadow-sm salla-text-sm salla-font-medium salla-text-white salla-bg-primary-600 hover:salla-bg-primary-700 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-offset-2 focus:salla-ring-primary-500 disabled:salla-opacity-50 disabled:salla-cursor-not-allowed"
          >
            Upload Image
          </button>
        </form>
      </section>

      <!-- Image Gallery Section -->
      <section class="salla-bg-white salla-rounded-lg salla-shadow-md salla-p-6">
        <h2 class="salla-text-xl salla-font-semibold salla-text-gray-800 salla-mb-6">Image Gallery</h2>
        
        <div class="salla-overflow-x-auto">
          <table class="salla-min-w-full salla-divide-y salla-divide-gray-200">
            <thead class="salla-bg-gray-50">
              <tr>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">
                  Image
                </th>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">
                  Details
                </th>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-right salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody class="salla-bg-white salla-divide-y salla-divide-gray-200">
              <tr v-for="image in images" :key="image.id" class="hover:salla-bg-gray-50">
                <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap">
                  <div class="salla-flex salla-items-center">
                    <div class="salla-h-20 salla-w-20 salla-flex-shrink-0">
                      <img 
                        :src="image.url" 
                        :alt="image.description || 'Pre-added image'" 
                        class="salla-h-20 salla-w-20 salla-rounded-lg salla-object-cover"
                      />
                    </div>
                  </div>
                </td>
                <td class="salla-px-6 salla-py-4">
                  <div class="salla-text-sm salla-text-gray-900">ID: {{ image.id }}</div>
                  <div class="salla-text-sm salla-text-gray-500">{{ image.description || 'No description' }}</div>
                </td>
                <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap salla-text-right salla-text-sm salla-font-medium">
                  <button 
                    @click="confirmDelete(image)"
                    class="salla-inline-flex salla-items-center salla-px-3 salla-py-2 salla-border salla-border-transparent salla-text-sm salla-font-medium salla-rounded-md salla-text-white salla-bg-red-600 hover:salla-bg-red-700"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="salla-h-4 salla-w-4 salla-mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                    Delete
                  </button>
                </td>
              </tr>
              <!-- Empty State -->
              <tr v-if="!images.length">
                <td colspan="3" class="salla-px-6 salla-py-10 salla-text-center salla-text-gray-500">
                  <svg class="salla-mx-auto salla-h-12 salla-w-12 salla-text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                  <p class="salla-mt-2 salla-text-sm">No images uploaded yet</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="salla-fixed salla-z-10 salla-inset-0 salla-overflow-y-auto">
      <div class="salla-flex salla-items-end salla-justify-center salla-min-h-screen salla-pt-4 salla-px-4 salla-pb-20 salla-text-center sm:salla-block sm:salla-p-0">
        <div class="salla-fixed salla-inset-0 salla-transition-opacity" aria-hidden="true">
          <div class="salla-absolute salla-inset-0 salla-bg-gray-500 salla-opacity-75"></div>
        </div>
        <div class="salla-inline-block salla-align-bottom salla-bg-white salla-rounded-lg salla-text-left salla-overflow-hidden salla-shadow-xl salla-transform salla-transition-all sm:salla-my-8 sm:salla-align-middle sm:salla-max-w-lg sm:salla-w-full">
          <div class="salla-bg-white salla-px-4 salla-pt-5 salla-pb-4 sm:salla-p-6 sm:salla-pb-4">
            <div class="sm:salla-flex sm:salla-items-start">
              <div class="salla-mx-auto salla-flex-shrink-0 salla-flex salla-items-center salla-justify-center salla-h-12 salla-w-12 salla-rounded-full salla-bg-red-100 sm:salla-mx-0 sm:salla-h-10 sm:salla-w-10">
                <svg class="salla-h-6 salla-w-6 salla-text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <div class="salla-mt-3 salla-text-center sm:salla-mt-0 sm:salla-ml-4 sm:salla-text-left">
                <h3 class="salla-text-lg salla-leading-6 salla-font-medium salla-text-gray-900">
                  Delete Image
                </h3>
                <div class="salla-mt-2">
                  <p class="salla-text-sm salla-text-gray-500">
                    Are you sure you want to delete this image? This action cannot be undone.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="salla-bg-gray-50 salla-px-4 salla-py-3 sm:salla-px-6 sm:salla-flex sm:salla-flex-row-reverse">
            <button 
              @click="deleteImage(selectedImage.id)"
              class="salla-w-full salla-inline-flex salla-justify-center salla-rounded-md salla-border salla-border-transparent salla-shadow-sm salla-px-4 salla-py-2 salla-bg-red-600 salla-text-base salla-font-medium salla-text-white hover:salla-bg-red-700 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-offset-2 focus:salla-ring-red-500 sm:salla-ml-3 sm:salla-w-auto sm:salla-text-sm"
            >
              Delete
            </button>
            <button 
              @click="showDeleteModal = false"
              class="salla-mt-3 salla-w-full salla-inline-flex salla-justify-center salla-rounded-md salla-border salla-border-gray-300 salla-shadow-sm salla-px-4 salla-py-2 salla-bg-white salla-text-base salla-fontmedium salla-text-gray-700 hover:salla-bg-gray-50 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-offset-2 focus:salla-ring-primary-500 sm:salla-mt-0 sm:salla-w-auto sm:salla-text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Success/Error Toast Messages -->
    <div 
      v-if="toast.show"
      class="salla-fixed salla-bottom-4 salla-right-4 salla-flex salla-items-center salla-p-4 salla-space-x-4 salla-w-full salla-max-w-xs salla-text-gray-500 salla-bg-white salla-rounded-lg salla-shadow-lg salla-transition-all salla-duration-300"
      :class="{
        'salla-border-l-4 salla-border-green-500': toast.type === 'success',
        'salla-border-l-4 salla-border-red-500': toast.type === 'error'
      }"
      role="alert"
    >
      <div class="salla-inline-flex salla-items-center salla-justify-center salla-flex-shrink-0 salla-w-8 salla-h-8 salla-rounded-lg">
        <template v-if="toast.type === 'success'">
          <svg class="salla-w-5 salla-h-5 salla-text-green-500" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/>
          </svg>
        </template>
        <template v-else>
          <svg class="salla-w-5 salla-h-5 salla-text-red-500" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"/>
          </svg>
        </template>
      </div>
      <div class="salla-pl-4 salla-text-sm salla-font-normal">{{ toast.message }}</div>
    </div>
  </div>
</template>


  
  <script>
  import { API_URL } from '@/config';
  
  export default {
    data() {
      return {
      imageFile: null,
      imageDescription: '',
      images: [],
      showDeleteModal: false,
      selectedImage: null,
      toast: {
        show: false,
        message: '',
        type: 'success'
      }
    };
  },
    mounted() {
      this.fetchImages();
    },
    methods: {
      showToast(message, type = 'success') {
      this.toast = {
        show: true,
        message,
        type
      };
      setTimeout(() => {
        this.toast.show = false;
      }, 3000);
    },
    onImageFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 10 * 1024 * 1024) { // 10MB limit
          this.showToast('Image size should be less than 10MB', 'error');
          return;
        }
        this.imageFile = file;
      }
    },

      confirmDelete(image) {
      this.selectedImage = image;
      this.showDeleteModal = true;
    },

    async submitImageData() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          this.showToast('Authentication required', 'error');
          return;
        }

        const formData = new FormData();
        formData.append('image', this.imageFile);
        formData.append('description', this.imageDescription);

        const response = await fetch(`${API_URL}/api/preadded-images`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          body: formData,
        });

        const data = await response.json();
        
        if (!response.ok) {
          throw new Error(data.message || 'Failed to upload image');
        }

        this.showToast('Image uploaded successfully');
        this.imageFile = null;
        this.imageDescription = '';
        await this.fetchImages();
      } catch (error) {
        console.error('Failed to submit image data:', error);
        this.showToast(error.message || 'Failed to upload image', 'error');
      }
    },
    async fetchImages() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          this.showToast('Authentication required', 'error');
          return;
        }

        const response = await fetch(`${API_URL}/api/preadded-images`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch images');
        }

        const data = await response.json();
        this.images = data;
      } catch (error) {
        console.error('Failed to fetch images:', error);
        this.showToast('Failed to load images', 'error');
      }
    },
    async deleteImage(imageId) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          this.showToast('Authentication required', 'error');
          return;
        }

        const response = await fetch(`${API_URL}/api/preadded-images/${imageId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const data = await response.json();
        
        if (!response.ok) {
          throw new Error(data.message || 'Failed to delete image');
        }

        this.showToast('Image deleted successfully');
        this.showDeleteModal = false;
        this.selectedImage = null;
        await this.fetchImages();
      } catch (error) {
        console.error('Failed to delete image:', error);
        this.showToast(error.message || 'Failed to delete image', 'error');
      }
    }
    }
  };
  </script>
  
  <style scoped>
  .pre-added-images {
    padding: 20px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .form-group input[type="file"],
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-group textarea {
    resize: vertical;
  }
  
  button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .image-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .image-table th,
  .image-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .image-table th {
    background-color: #f4f5f7;
    font-weight: bold;
  }
  
  .thumbnail {
    width: 100px;
    height: auto;
  }
  
  .btn-delete {
    padding: 5px 10px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .btn-delete:hover {
    background-color: #d32f2f;
  }
  </style>
  