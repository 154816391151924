<template>
    <div class="admin-ticketing">
      <div class="main-content">
        <header>
          <h1>Ticketing System</h1>
          <div class="header-actions">
            <button class="btn btn-primary" @click="showTickets" :class="{ active: currentPage === 'tickets' }">Tickets</button>
            <button class="btn btn-primary" @click="showCategories" :class="{ active: currentPage === 'categories' }">Categories</button>
          </div>
        </header>
  
        <div v-if="currentPage === 'tickets'" class="ticket-list">
          <div class="search-bar">
            <input v-model="searchQuery" @input="searchTickets" type="text" placeholder="Search tickets..." class="search-input">
          </div>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Category</th>
                <th>Requester</th>
                <th>Status</th>
                <th>Created</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="ticket in filteredTickets" :key="ticket.id">
                <td>#{{ ticket.id }}</td>
                <td>{{ ticket.category_name }}</td>
                <td>{{ ticket.user_username }}</td>
                <td>
                  <span class="status" :class="ticket.status">{{ ticket.status }}</span>
                </td>
                <td>{{ formatDate(ticket.created_at) }}</td>
                <td>
                  <button class="btn btn-sm btn-secondary" @click="openTicketModal(ticket.id)">View</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <div v-if="currentPage === 'categories'" class="category-list">
          <button class="btn btn-primary" @click="openCategoryModal">Add Category</button>
          <ul>
            <li v-for="category in categories" :key="category.id">
              <span>{{ category.name }}</span>
              <button class="btn btn-sm btn-danger" @click="deleteCategory(category.id)">Delete</button>
            </li>
          </ul>
        </div>
  
        <footer v-if="currentPage === 'tickets'">
          <div class="pagination">
            <button class="btn btn-sm btn-secondary">&lt;</button>
            <span class="page-info">Page 1 of 10</span>
            <button class="btn btn-sm btn-secondary">&gt;</button>
          </div>
        </footer>
  
        <!-- Ticket Modal -->
        <div class="modal" v-if="showTicketModal">
          <div class="modal-content">
            <div class="modal-header">
              <h2>Ticket Details</h2>
              <button class="btn btn-sm btn-close" @click="closeTicketModal">&times;</button>
            </div>
            <div class="modal-body">
              <div class="ticket-details">
                <div class="ticket-info">
                  <p><strong>ID:</strong> #{{ selectedTicket.id }}</p>
                  <p><strong>Category:</strong> {{ selectedTicket.category_name }}</p>
                  <p><strong>Requester:</strong> {{ selectedTicket.user_username }}</p>
                  <p><strong>Status:</strong> {{ selectedTicket.status }}</p>
                  <p><strong>Created:</strong> {{ formatDate(selectedTicket.created_at) }}</p>
                </div>
                <div class="ticket-description">
                  <p>{{ selectedTicket.title }}</p>
                </div>
              </div>
  
              <div class="ticket-replies">
                <h3>Messages</h3>
                <ul>
                  <li v-for="message in selectedTicket.messages" :key="message.id">
                    <div class="reply-header">
                      <span class="reply-author">{{ message.sender_username }}</span>
                      <span class="reply-date">{{ formatDate(message.created_at) }}</span>
                    </div>
                    <div class="reply-content">
                      <p>{{ message.message }}</p>
                    </div>
                  </li>
                </ul>
              </div>
  
              <div class="reply-form">
                <textarea v-model="newReply" placeholder="Write a reply..." class="reply-input"></textarea>
                <button class="btn btn-primary" @click="addAdminReply">Send Reply</button>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" @click="closeTicketModal">Close</button>
              <button class="btn btn-primary" @click="updateTicketStatus(selectedTicket.id, 'resolved')">Resolve Ticket</button>
            </div>
          </div>
        </div>
  
        <!-- Category Modal -->
        <div class="modal" v-if="showCategoryModal">
          <div class="modal-content">
            <div class="modal-header">
              <h2>Add Category</h2>
              <button class="btn btn-sm btn-close" @click="closeCategoryModal">&times;</button>
            </div>
            <div class="modal-body">
              <div class="category-form">
                <input type="text" v-model="newCategory" placeholder="Enter category name" class="category-input">
                <button class="btn btn-primary" @click="createCategory">Add Category</button>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" @click="closeCategoryModal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { API_URL } from "@/config";
  
  export default {
    data() {
      return {
        currentPage: "tickets",
        tickets: [],
        filteredTickets: [],
        searchQuery: "",
        selectedTicket: null,
        showTicketModal: false,
        newReply: "",
        categories: [],
        newCategory: "",
        showCategoryModal: false,
        
      };
    },
    mounted() {
      this.fetchAllTickets();
      this.fetchCategories();
    },
    methods: {
      showTickets() {
        this.currentPage = "tickets";
      },
      showCategories() {
        this.currentPage = "categories";
      },
      async fetchAllTickets() {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("No token found. You must be logged in to view tickets.");
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/admin/tickets`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        this.tickets = data.tickets;

        // Sort the tickets to show open tickets first
        this.tickets.sort((a, b) => {
          if (a.status === 'open' && b.status !== 'open') {
            return -1;
          }
          if (a.status !== 'open' && b.status === 'open') {
            return 1;
          }
          return 0;
        });

        this.filteredTickets = this.tickets;
      } catch (error) {
        console.error("Error fetching tickets:", error);
        alert("Failed to fetch tickets.");
      }
    },
      searchTickets() {
        const query = this.searchQuery.toLowerCase();
        this.filteredTickets = this.tickets.filter(ticket => {
          return (
            ticket.id.toString().includes(query) ||
            ticket.user_username.toLowerCase().includes(query)
          );
        });
      },
      async openTicketModal(ticketId) {
        const token = localStorage.getItem("token");
        if (!token) {
          alert("No token found. You must be logged in to view ticket details.");
          return;
        }
  
        try {
          const response = await fetch(`${API_URL}/api/admin/tickets/${ticketId}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const data = await response.json();
          this.selectedTicket = data.ticket;
          if (this.selectedTicket) {
            this.showTicketModal = true;
          } else {
            alert("Failed to fetch ticket details.");
          }
        } catch (error) {
          console.error("Error fetching ticket details:", error);
          alert("Failed to fetch ticket details.");
        }
      },
      closeTicketModal() {
        this.showTicketModal = false;
        this.selectedTicket = null;
      },
      async updateTicketStatus(ticketId, status) {
        const token = localStorage.getItem("token");
        if (!token) {
          alert("No token found. You must be logged in to update ticket status.");
          return;
        }
  
        try {
          const response = await fetch(`${API_URL}/api/admin/tickets/${ticketId}/status`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ status }),
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          this.fetchAllTickets();
          if (this.selectedTicket && this.selectedTicket.id === ticketId) {
            this.selectedTicket.status = status;
          }
        } catch (error) {
          console.error("Error updating ticket status:", error);
          alert("Failed to update ticket status.");
        }
      },
      async addAdminReply() {
        const token = localStorage.getItem("token");
        if (!token) {
          alert("No token found. You must be logged in to add a reply.");
          return;
        }
  
        try {
          const response = await fetch(`${API_URL}/api/admin/tickets/${this.selectedTicket.id}/messages`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ message: this.newReply }),
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          this.newReply = "";
          this.openTicketModal(this.selectedTicket.id);
        } catch (error) {
          console.error("Error adding admin reply:", error);
          alert("Failed to add admin reply.");
        }
      },
      formatDate(dateString) {
        const options = { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
      },
      async fetchCategories() {
        const token = localStorage.getItem("token");
        if (!token) {
          alert("No token found. You must be logged in to view categories.");
          return;
        }
  
        try {
          const response = await fetch(`${API_URL}/api/ticket-categories`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const data = await response.json();
          this.categories = data.categories;
        } catch (error) {
          console.error("Error fetching categories:", error);
          alert("Failed to fetch categories.");
        }
      },
      async createCategory() {
        const token = localStorage.getItem("token");
        if (!token) {
          alert("No token found. You must be logged in to create a category.");
          return;
        }
  
        try {
          const response = await fetch(`${API_URL}/api/admin/ticket-categories`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ name: this.newCategory }),
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          this.newCategory = "";
          this.fetchCategories();
        } catch (error) {
          console.error("Error creating category:", error);
          alert("Failed to create category.");
        }
      },
      async deleteCategory(categoryId) {
        const token = localStorage.getItem("token");
        if (!token) {
          alert("No token found. You must be logged in to delete a category.");
          return;
        }
  
        try {
          const response = await fetch(`${API_URL}/api/admin/ticket-categories/${categoryId}`, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          this.fetchCategories();
        } catch (error) {
          console.error("Error deleting category:", error);
          alert("Failed to delete category.");
        }
      },
      openCategoryModal() {
        this.showCategoryModal = true;
      },
      closeCategoryModal() {
        this.showCategoryModal = false;
      },
    },
  };
  </script>
  
  
  <style scoped>
  body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
  }
  
  .admin-ticketing {
    display: flex;
    height: 100vh;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  header h1 {
    font-size: 24px;
    color: #333;
  }
  
  .header-actions {
    display: flex;
    gap: 10px;
  }
  
  .search-bar {
    margin-bottom: 20px;
  }
  
  .search-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .ticket-list table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .ticket-list th,
  .ticket-list td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .status {
    display: inline-block;
    padding: 6px 10px;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .status.open {
    background-color: #e2e8f0;
    color: #4a5568;
  }
  
  .status.resolved {
    background-color: #d1fae5;
    color: #047857;
  }
  
  footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .pagination {
    display: flex;
    align-items: center;
  }
  
  .page-info {
    margin: 0 10px;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background-color: #fff;
    width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 4px;
  }
  
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .modal-footer {
    padding: 20px;
    border-top: 1px solid #ddd;
    text-align: right;
  }
  
  .ticket-details {
    margin-bottom: 20px;
  }
  
  .ticket-info p {
    margin-bottom: 10px;
  }
  
  .ticket-description {
    margin-top: 20px;
  }
  
  .ticket-replies {
    margin-bottom: 20px;
  }
  
  .ticket-replies ul {
    list-style-type: none;
    padding: 0;
  }
  
  .ticket-replies li {
    margin-bottom: 10px;
  }
  
  .reply-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .reply-author {
    font-weight: bold;
  }
  
  .reply-date {
    color: #777;
  }
  
  .reply-form {
    margin-top: 20px;
  }
  
  .reply-input {
    width: 100%;
    height: 100px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .category-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .category-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .category-form {
    margin-top: 20px;
  }
  
  .category-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #2563eb;
    color: #fff;
  }
  
  .btn-secondary {
    background-color: #e2e8f0;
    color: #4a5568;
  }
  
  .btn-danger {
    background-color: #dc2626;
    color: #fff;
  }
  
  .btn-close {
    background-color: transparent;
    color: #000;
    font-size: 24px;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
  }
  
  .active {
    background-color: #4a5568;
  }
  </style>
  