<!-- eslint-disable vue/no-parsing-error -->

<template>
  <div class="login-page">
    <header class="header">
      <div class="header-actions">
        <router-link to="/register" class="register-button">تسجيل</router-link>
        <router-link to="/login" class="login-link">تسجيل الدخول</router-link>
      </div>

      <div class="header-icons">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/1909eed33e4c92c0c6a7ae656b371072f6a331d8caa1d3f9aff6a1b1f6fa06f2?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Icon" class="icon" />
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/076ad4d43ff739e5b7165868b496c5cce1c42209ae846a2e33f5034beb9bd8ef?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Logo" class="logo" />
      </div>
    </header>
    
    <main class="main-content">
      <div class="login-container">
        <section class="login-form">
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/f71e6eb12d9eabd08f6c8f796b8ab5e9c2bea6392f77fbb4ba68b721a967cba4?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Logo" class="form-logo" />
          
          <form @submit.prevent="handleSubmit" novalidate>
            <label for="email" class="visually-hidden">Email</label>
            <div class="input-wrapper email-input" :class="{ 'error': errors.email }">
              <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/ba4d293dd96f69d372585e4eb4776fdde2d5c7a363c18a6ebd4f21b761b84c12?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Email icon" class="input-icon" />
              <input 
                type="email" 
                v-model="email" 
                dir="rtl" 
                placeholder="الايميل" 
                @input="clearError('email')"
                :class="{ 'input-error': errors.email }"
              />
            </div>

            <label for="password" class="visually-hidden">Password</label>
            <div class="input-wrapper password-input" :class="{ 'error': errors.password }">
              <input 
                :type="passwordFieldType" 
                v-model="password" 
                dir="rtl" 
                placeholder="كلمة السر" 
                @input="clearError('password')"
                :class="{ 'input-error': errors.password }"
              />
              <img 
                :src="passwordFieldType === 'password' ? eyeIcon : eyeOffIcon" 
                class="toggle-password-icon" 
                @click="togglePasswordVisibility" 
              />
            </div>

            <a href="#" @click.prevent="showResetPasswordPopup = true" class="forgot-password-link">هل نسيت كلمة السر؟</a>

            <transition name="fade">
  <div v-if="errorMessage || errors.email || errors.password" 
       class="bg-red-50 border border-red-100 rounded-lg p-4 shadow-sm">
    <div class="flex items-start gap-3">
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        class="h-5 w-5 text-red-500 flex-shrink-0 mt-0.5"
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor"
        stroke-width="2"
      >
        <circle cx="12" cy="12" r="10" />
        <line x1="12" y1="8" x2="12" y2="12" />
        <circle cx="12" cy="16" r="1" fill="currentColor" />
      </svg>
      <div class="flex-1 space-y-1">
        <p v-if="errorMessage" 
           class="text-sm text-red-700 text-right leading-5">
          {{ errorMessage }}
        </p>
        <p v-if="errors.email" 
           class="text-sm text-red-700 text-right leading-5">
          {{ errors.email }}
        </p>
        <p v-if="errors.password" 
           class="text-sm text-red-700 text-right leading-5">
          {{ errors.password }}
        </p>
      </div>
    </div>
  </div>
</transition>

            <button 
  class="submit-button" 
  type="submit" 
  :disabled="isLoading"
  :class="{ 'loading': isLoading }"
>
  <span v-if="!isLoading">تسجيل الدخول</span>
  <span v-else class="loading-spinner"></span>
</button>
          </form>

          <div class="social-login">
            <button class="google-login" @click="signInWithGoogle" :disabled="isLoading">
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/4d324a6b55750eac2f50c4706865ec2a9d319160330a05d44ac2dbe275002259?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Google icon" class="social-icon" />
    </button>
    <button @click="signInWithFacebook" class="facebook-login" :disabled="isLoading">
      <i class="fab fa-facebook-f"></i> acebook
    </button>
          </div>
        </section>

        <section class="login-image">
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/8650436a78d52786ae81e1de7cd517cb7c328b10e423e20d3fd4c98ba03b9413?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Login illustration" class="illustration" />
        </section>
      </div>

      <transition name="fade">
        <div v-if="showResetPasswordPopup" class="reset-password-popup">
          <div class="popup-content">
            <h2>إعادة تعيين كلمة المرور</h2>
            <form @submit.prevent="resetPassword">
              <label for="resetEmail" class="visually-hidden">Email</label>
              <div class="input-wrapper">
                <input type="email" v-model="resetEmail" id="resetEmail" dir="rtl" placeholder="ادخل الايميل" required />
              </div>
              <button class="submit-button" type="submit" :disabled="isLoading">إعادة تعيين</button>
              <button class="cancel-button" @click="showResetPasswordPopup = false" :disabled="isLoading">إلغاء</button>
            </form>
            <p v-if="resetMessage" :class="{ 'error-message': !resetSuccess, 'success-message': resetSuccess }">
              {{ resetMessage }}
            </p>
          </div>
        </div>
      </transition>
    </main>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/services/authService';
import { API_URL } from '@/config';
import eyeIcon from '@/assets/eye-icon.png';
import eyeOffIcon from '@/assets/eye-off-icon.png';
import { GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebaseConfig';

export default {
  name: 'LoginForm',
  setup() {
    const router = useRouter();
    const email = ref('');
    const password = ref('');
    const errorMessage = ref('');
    const resetEmail = ref('');
    const resetMessage = ref('');
    const resetSuccess = ref(false);
    const showResetPasswordPopup = ref(false);
    const isLoading = inject('isLoading', ref(false)); // Provide default value
    const passwordFieldType = ref('password');
    const errors = ref({
      email: '',
      password: ''
    });

    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const clearError = (field) => {
      if (field) {
        errors.value[field] = '';
      }
      errorMessage.value = '';
    };

    const validateForm = () => {
      let isValid = true;
      errors.value = {
        email: '',
        password: ''
      };
      errorMessage.value = '';

      if (!email.value.trim()) {
        errors.value.email = 'الرجاء إدخال البريد الإلكتروني';
        isValid = false;
      } else if (!validateEmail(email.value.trim())) {
        errors.value.email = 'الرجاء إدخال بريد إلكتروني صحيح';
        isValid = false;
      }

      if (!password.value.trim()) {
        errors.value.password = 'الرجاء إدخال كلمة المرور';
        isValid = false;
      }

      return isValid;
    };

    const handleSubmit = async (e) => {
  e.preventDefault();
  e.stopPropagation();
  
  console.log("Form submission started...");
  clearError();

  if (!validateForm()) {
    console.log("Form validation failed");
    return;
  }

  try {
    console.log("Starting API request...");
    const response = await fetch(`${API_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email.value.trim(),
        password: password.value
      })
    });

    const data = await response.json();
    console.log("API Response:", data);

    if (data.success) {
      console.log("Login successful.");
      if (data.user && data.user.token) {
        localStorage.setItem('token', data.user.token);
        await AuthService.login(data.user);

        // Simplified redirect logic
        const redirectPath = data.redirect || '/dashboard';
        console.log("Redirecting to:", redirectPath);
        await router.push(redirectPath);
      } else {
        errorMessage.value = 'فشل في استرداد رمز المصادقة';
        console.log("Token missing in response");
      }
    } else {
      errorMessage.value = 'البريد الإلكتروني أو كلمة المرور غير صحيحة';
      console.log("Login failed:", errorMessage.value);
      password.value = '';
    }
  } catch (error) {
    console.error('Login error:', error);
    errorMessage.value = 'حدث خطأ. الرجاء المحاولة مرة أخرى';
  } finally {
    isLoading.value = false;
    console.log("Login process completed, isLoading set to false.");
  }
};

const signInWithFacebook = async () => {
      const provider = new FacebookAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        const token = await result.user.getIdToken();
        const response = await fetch(`${API_URL}/api/auth/facebook`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({ token })
        });
        const data = await response.json();
        if (data.success) {
          localStorage.setItem('token', data.user.token);
          AuthService.login(data.user);
          errorMessage.value = '';
          if (data.redirect) {
            router.push(data.redirect);
          }
        } else {
          errorMessage.value = data.message;
        }
      } catch (error) {
        errorMessage.value = 'Facebook sign-in failed. Please try again.';
      }
    };

    const signInWithGoogle = async () => {
      try {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        const token = await result.user.getIdToken();
        
        const response = await fetch(`${API_URL}/api/auth/google`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token })
        });

        const data = await response.json();
        if (data.success) {
          localStorage.setItem('token', data.user.token);
          await AuthService.login(data.user);
          
          if (data.redirect) {
            await router.push(data.redirect);
          }
        } else {
          errorMessage.value = 'فشل تسجيل الدخول باستخدام Google';
        }
      } catch (error) {
        console.error('Google sign-in error:', error);
        errorMessage.value = 'فشل تسجيل الدخول باستخدام Google. الرجاء المحاولة مرة أخرى';
      } finally {
        isLoading.value = false;
      }
    };

    const resetPassword = async () => {
      if (!resetEmail.value || !validateEmail(resetEmail.value)) {
        resetMessage.value = 'الرجاء إدخال بريد إلكتروني صحيح';
        resetSuccess.value = false;
        return;
      }

      isLoading.value = true;
      
      try {
        const response = await fetch(`${API_URL}/reset-password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: resetEmail.value })
        });

        const data = await response.json();
        
        if (data.success) {
          resetSuccess.value = true;
          resetMessage.value = 'تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني';
          setTimeout(() => {
            showResetPasswordPopup.value = false;
            resetMessage.value = '';
            resetEmail.value = '';
          }, 3000);
        } else {
          resetSuccess.value = false;
          resetMessage.value = data.message || 'فشل في إعادة تعيين كلمة المرور';
        }
      } catch (error) {
        resetSuccess.value = false;
        resetMessage.value = 'حدث خطأ. الرجاء المحاولة مرة أخرى';
      } finally {
        isLoading.value = false;
      }
    };

    const togglePasswordVisibility = () => {
      passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
    };

    return {
      email,
      password,
      errorMessage,
      errors,
      handleSubmit,
      resetEmail,
      resetMessage,
      resetSuccess,
      showResetPasswordPopup,
      resetPassword,
      passwordFieldType,
      togglePasswordVisibility,
      eyeIcon,
      eyeOffIcon,
      signInWithGoogle,
      signInWithFacebook,
      isLoading,
      clearError
    };
  }
};
</script>





<style scoped>

.error-container {
  background-color: #fef2f2;
  border: 1px solid rgba(220, 38, 38, 0.2);
  padding: 12px 16px;
  margin: 12px 0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(220, 38, 38, 0.1);
  transform-origin: top center;
  animation: slideIn 0.3s ease-out;
}

/* Error Content Layout */
.error-content {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

/* Error Icon Styles */
.error-icon {
  width: 20px;
  height: 20px;
  color: #dc2626;
  flex-shrink: 0;
  animation: pulse 2s infinite;
}

/* Error Messages Container */
.error-messages {
  flex: 1;
}

/* Individual Error Message */
.error-message {
  color: #dc2626;
  margin: 4px 0;
  font-size: 14px;
  text-align: right;
  line-height: 1.5;
  font-weight: 500;
}

/* Multiple Error Messages Spacing */
.error-message + .error-message {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid rgba(220, 38, 38, 0.1);
}

/* Error Container & Transitions */
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-4px);
}

/* Error Container */
.bg-red-50 {
  background-color: rgb(254 242 242);
}

.border-red-100 {
  border-color: rgb(254 226 226);
}

.text-red-500 {
  color: rgb(239 68 68);
}

.text-red-700 {
  color: rgb(185 28 28);
}

/* Layout utilities */
.flex {
  display: flex;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.items-start {
  align-items: flex-start;
}

.gap-3 {
  gap: 0.75rem;
}

.space-y-1 > * + * {
  margin-top: 0.25rem;
}

.mt-0\.5 {
  margin-top: 0.125rem;
}

.p-4 {
  padding: 1rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-right {
  text-align: right;
}

.leading-5 {
  line-height: 1.25rem;
}

.h-5 {
  height: 1.25rem;
}

.w-5 {
  width: 1.25rem;
}

/* RTL Support */
[dir="rtl"] .flex {
  flex-direction: row-reverse;
}

/* Optional hover effect */
.bg-red-50:hover {
  background-color: rgb(254 226 226);
  transition: background-color 0.2s ease-in-out;
}

/* Mobile optimization */
@media (max-width: 768px) {
  .p-4 {
    padding: 0.75rem;
  }
  
  .text-sm {
    font-size: 0.8125rem;
  }
}

/* Add styles for the popup */
.reset-password-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-out;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  width: 40%;
  animation: scaleUp 0.3s ease-out;
}

.cancel-button {
  margin-top: 10px;
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.login-page {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
}

.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(231, 231, 231, 0.2);
  }

.header-actions {
  display: flex;
  gap: 20px;
  font-size: 16px;
}

.login-link {
  padding: 0.75rem 1.5rem;
    border-radius: 72px;
    background-color: #0098fd;
    color: #fff;
    font: 400 16px / 160% Tajawal, sans-serif;
    text-transform: uppercase;
}

.register-button {
  margin: auto 0;
  color: #0098fd;
  font: 400 16px/150% Tajawal, sans-serif;
  text-align: center;
}

.header-nav {
  display: flex;
  gap: 20px;
  margin: auto 0;
  font-size: 16px;
  color: #222;
  font-weight: 400;
  text-align: center;
  line-height: 150%;
}

.nav-link {
  font: 400 16px/150% Tajawal, sans-serif;
}

.header-icons {
  display: flex;
  gap: 10px;
  margin: auto 0;
}

.icon {
  width: 27px;
  aspect-ratio: 1;
  object-fit: contain;
}

.logo {
  width: 80px;
  aspect-ratio: 6.25;
  object-fit: contain;
  margin: auto 0;
}

.main-content {
  width: 100%;
  max-width: 1273px;
  margin: 154px auto 0;
}

.login-container {
  display: flex;
  gap: 20px;
}

.login-form {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin: auto 0;
  padding: 0 20px;
}

.form-logo {
  width: 119px;
  max-width: 100%;
  aspect-ratio: 1.61;
  object-fit: contain;
  align-self: center;
}

.input-wrapper {
  display: flex;
  align-items: center;
  padding: 0px;
  border: 1px solid rgba(35, 40, 51, 0.1);
  border-radius: 8px;
  background-color: #fff;
  max-width: 350px;
  margin: 10px auto;
  position: relative;
}

.input-wrapper input {
  flex: 1;
  border: none;
  outline: none;
  font: 400 16px / 150% Tajawal, sans-serif;
  padding: 0px;
  margin: 14px;
}

.toggle-password-icon {
  width: 18px;
  aspect-ratio: 1;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 319px;
}

.email-input {
  margin-top: 13px;
}

.password-input {
  margin-top: 24px;
}

.input-icon {
  width: 18px;
  aspect-ratio: 1;
  object-fit: contain;
  margin-left: 10px;
}

.forgot-password-link {
  display: block;
  text-align: right;
  margin-top: 10px;
}

.submit-button {
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #0066ff;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  display: block;
  margin: 20px auto;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0055dd;
}

.login-prompt {
  display: flex;
  gap: 6px;
  margin-top: 12px;
  align-self: center;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 150%;
}

.login-prompt span {
  color: #393939;
  font: 400 14px/150% Tajawal, sans-serif;
}

.login-prompt button {
  color: #0098fd;
  font: 400 14px/150% Tajawal, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
}

.social-login {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}

.google-login,
.facebook-login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 60px;
  border: 1px solid rgba(35, 40, 51, 0.1);
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
}

.social-icon {
  width: 18px;
  aspect-ratio: 1;
  object-fit: contain;
}

.login-image {
  width: 65%;
  margin-left: 20px;
}


.facebook-login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 60px;
  border: 1px solid rgba(35, 40, 51, 0.1);
  border-radius: 8px;
  background-color: #1877f2;
  color: white;
  cursor: pointer;
}

.facebook-login:hover {
  background-color: #166fe5;
}

.facebook-login:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.illustration {
  width: 100%;
  aspect-ratio: 1.41;
  object-fit: contain;
  flex-grow: 1;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 600px) {
  .header {
    padding: 10px 20px;
  }


  .reset-password-popup{
    position: fixed;
    top: 0;
    left: -243px;
    width: 243%;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn-c725587c 0.3s ease-out;

  }
  .header-actions, .header-nav {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .register-button, .google-login, .facebook-login {
    padding: 15px 20px;
    font-size: 14px;
  }

  .login-link, .nav-link {
    font-size: 14px;
  }

  .main-content, .login-container, .login-form {
    margin: 20px auto;
    padding: 0 15px;
    width: 100%;
  }

  .input-wrapper {
    align-items: center;
  }

  .input-icon {
    margin: 0 0 5px;
  }

  input[type="text"], input[type="password"] {
    font-size: 14px;
  }

  .login-image, .illustration {
    display: none;
  }

  .forgot-password-link {
    font-size: 14px;
  }

  .social-login {
    gap: 8px;
  }
}
</style>
