<template>
<div class="plans-main">
  <h2>الخطط المتاحة</h2>
  <div v-if="planProducts.length" class="plans-container">
    <div
      v-for="(planProduct, index) in planProducts"
      :key="index"
      class="plan-card"
      :class="{ 'recommended': index === 1 }"
    >
      <div v-if="planProduct.image_url" class="plan-image">
        <img :src="planProduct.image_url" alt="Plan Image" />
      </div>
      <div class="plan-header">
        <h3>{{ planProduct.name }}</h3>
        <div class="plan-price-container">
          <span class="plan-price">{{ planProduct.price }}</span>
          <span class="price-label">$  / شهر</span>
        </div>
        <span v-if="index === 1" class="recommended-badge">مُوصى به</span>
      </div>
      <p class="plan-description">{{ planProduct.description }}</p>
      <ul class="plan-details">
        <li>عدد الكريديتات: {{ planProduct.credits_amount }}</li>
      </ul>
      <button @click="subscribe(planProduct)" class="plan-button">اشتراك</button>
    </div>
  </div>
  <div v-else>
    <p>لا توجد خطط متاحة</p>
  </div>

    <h2>منتجات الشراء مرة واحدة</h2>
    <div v-if="oneTimePurchases.length" class="product-container">
      <div
        v-for="(product, index) in oneTimePurchases"
        :key="index"
        class="product-card"
      >
        <h3>{{ product.name }}</h3>
        <p>{{ product.description }}</p>
        <p class="price">السعر: {{ product.price }}</p>
        <p>عدد الكريدتات: {{ product.credits_amount }}</p>
        <button @click="buyNow(product)" class="buy-button">شراء الآن</button>
      </div>
    </div>
    <div v-else>
      <p>لا توجد منتجات شراء مرة واحدة</p>
    </div>
  </div>
</template>

<script>
import { API_URL } from '@/config';

export default {
  data() {
    return {
      planProducts: [],
      oneTimePurchases: [],
    };
  },
  created() {
    this.fetchPlanProducts();
    this.fetchOneTimePurchases();
  },
  methods: {
    async subscribe(planProduct) {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token available. User must be logged in to subscribe.');
    return;
  }

  try {
    // Check if the user can subscribe to this plan
    const checkResponse = await fetch(`${API_URL}/check-subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        stripe_product_id: planProduct.stripe_product_id,
      }),
    });

    const checkData = await checkResponse.json();
    if (!checkData.can_subscribe) {
      alert(checkData.message);
      return;
    }

    // Proceed with the subscription process
    const response = await fetch(`${API_URL}/subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        stripe_plan_product_id: planProduct.id,
        stripe_product_id: planProduct.stripe_product_id,
        stripe_plan_id: planProduct.stripe_plan_id,
        price: planProduct.price,
      }),
    });

    const data = await response.json();
    if (response.ok) {
      console.log('Subscription response:', data);
      window.location.href = data.checkout_session_url;
    } else {
      console.error('Error subscribing:', data);
    }
  } catch (error) {
    console.error('Error:', error);
  }
},

    buyNow(product) {
      // Handle the buy now action
      alert(`شراء ${product.name} الآن`);
    },
    async fetchPlanProducts() {
      console.log('Fetching plan products');
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to fetch plan products.');
        return;
      }
      try {
        const response = await fetch(`${API_URL}/stripe_plan_products`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          console.log('Fetched plan products:', data);
          this.planProducts = data;
        } else {
          console.error('Error fetching plan products:', data);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async fetchOneTimePurchases() {
      console.log('Fetching one-time purchases');
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to fetch one-time purchases.');
        return;
      }
      try {
        const response = await fetch(`${API_URL}/one_time_purchases`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          console.log('Fetched one-time purchases:', data);
          this.oneTimePurchases = data;
        } else {
          console.error('Error fetching one-time purchases:', data);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
  },
};
</script>




<style>
.plans-main {
  max-width: 90%;
    margin: 15px auto;
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    width: 100%;
    margin-left: 7px;
    direction: rtl;
}

.plans-main h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 2rem;
  font-weight: 700;
  letter-spacing: 1px;
}

.plans-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  justify-content: center;
  margin-bottom: 48px;
}

.plan-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  text-align: center;
  position: relative;
}

.plan-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.plan-price-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.plan-header {
  margin-bottom: 1.5rem;
}

.plan-header h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.plan-price {
  font-size: 2.5rem;
  color: #333;
  font-weight: 700;
}

.price-label {
  font-size: 1rem;
  color: #666;
  margin-left: 0.5rem;
}

.plan-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.plan-details {
  list-style: none;
  padding: 0;
  margin-bottom: 1rem;
  text-align: right;
}

.plan-details li {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.plan-details li::before {
  content: "\2713";
  margin-right: 0.5rem;
  color: #28a745;
  font-size: 1.2rem;
}

.plan-image {
  margin-bottom: 1rem;
}

.plan-image img {
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.plan-button {
  background-color: #007bff;
  border-radius: 30px;
  padding: 0.8rem 1.8rem;
  font-size: 1.1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.plan-button:hover {
  background-color: #0069d9;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.recommended {
  background-color: #e6f3ff;
  border: none;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: scale(1.08);
  position: relative;
  overflow: hidden;
}

.recommended::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(40, 167, 69, 0.2) 0%, rgba(0, 123, 255, 0.2) 100%);
  opacity: 0.5;
  z-index: -1;
}

.recommended-badge {
  background-color: #28a745;
  font-size: 0.9rem;
  padding: 0.3rem 0.6rem;
  border-radius: 20px;
  margin-left: 1rem;
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {

.plans-main{
  max-width: 90%;
    margin: 15px auto;
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    width: 100%;
    margin-left: 26px;
    direction: rtl;

}
  .plans-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .plan-card {
    padding: 1rem;
  }

  .plan-header h3 {
    font-size: 1.2rem;
  }

  .plan-price {
    font-size: 2rem;
  }

  .plan-button {
    font-size: 1rem;
    padding: 0.6rem 1.2rem;
  }
}














.product-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
}

.product-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: box-shadow 0.3s ease;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.product-card h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.product-card p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.product-card .price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.buy-button {
  padding: 0.75rem 1.5rem;
  background-color: #28a745;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1rem;
}

.buy-button:hover {
  background-color: #218838;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .product-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}
</style>