<template>
  <div class="salla-flex salla-flex-col salla-gap-8" dir="rtl">
    <!-- Pre-added Images Gallery -->
    <div class="salla-space-y-4">
      <div class="salla-flex salla-items-center salla-justify-between">
        <h2 class="salla-text-lg salla-font-semibold salla-text-gray-800">اختر صورة مضافة مسبقاً</h2>
        <span class="salla-text-sm salla-text-gray-500">اختر صورة من المعرض</span>
      </div>
      
      <div class="salla-grid salla-grid-cols-2 salla-gap-3">
        <div 
          v-for="(image, index) in preaddedImages" 
          :key="image.id" 
          @click="selectImage(image.url, index)"
          class="salla-group salla-aspect-square salla-rounded-lg salla-overflow-hidden salla-cursor-pointer salla-relative salla-ring-2 salla-ring-transparent hover:salla-ring-primary-500 salla-transition-all"
        >
          <img 
            :src="image.url" 
            :alt="image.name"
            class="salla-w-full salla-h-full salla-object-cover group-hover:salla-scale-105 salla-transition-transform salla-duration-300"
          />
          <div class="salla-absolute salla-inset-0 salla-bg-gradient-to-t salla-from-black/50 salla-to-transparent salla-opacity-0 group-hover:salla-opacity-100 salla-transition-opacity"></div>
        </div>
      </div>
    </div>

    <!-- File Upload Areas -->
    <div class="salla-space-y-6">
      <div 
        v-for="(imageKey, index) in imageKeys" 
        :key="imageKey"
        class="salla-bg-gray-50 salla-rounded-xl salla-p-4 salla-space-y-4"
      >
        <div class="salla-flex salla-items-center salla-justify-between">
          <h3 class="salla-font-medium salla-text-gray-800">الصورة {{ index + 1 }}</h3>
          <div class="salla-flex salla-items-center salla-gap-2">
            <label 
              :for="'file-upload-' + imageKey"
              class="salla-inline-flex salla-items-center salla-gap-2 salla-px-4 salla-py-2 salla-bg-primary-600 salla-text-white salla-text-sm salla-rounded-lg hover:salla-bg-primary-700 salla-transition-colors salla-cursor-pointer"
            >
              <i class="fas fa-cloud-upload-alt"></i>
              رفع صورة
            </label>
            <input 
              :id="'file-upload-' + imageKey" 
              type="file"
              accept="image/*" 
              @change="handleFileUpload(imageKey, $event)" 
              class="salla-hidden"
            />
          </div>
        </div>

        <!-- Preview Area -->
        <div v-if="imageData[imageKey]" class="salla-space-y-3">
          <div class="salla-flex salla-items-center salla-gap-2 salla-text-sm salla-text-gray-600">
            <i class="fas fa-image"></i>
            <span>{{ imageFileName[imageKey] }}</span>
          </div>
          <div class="salla-relative salla-aspect-video salla-rounded-lg salla-overflow-hidden salla-bg-gray-100">
            <img 
              :src="imageData[imageKey]" 
              :alt="imageFileName[imageKey]"
              class="salla-w-full salla-h-full salla-object-contain"
            />
          </div>
        </div>

        <!-- Empty State -->
        <div v-else class="salla-flex salla-flex-col salla-items-center salla-justify-center salla-py-8 salla-text-gray-400">
          <i class="fas fa-image salla-text-3xl salla-mb-2"></i>
          <p class="salla-text-sm">اختر صورة للعرض هنا</p>
        </div>
      </div>
    </div>

    <!-- Product Images Search -->
    <div class="salla-space-y-4">
      <div class="salla-flex salla-items-center salla-justify-between">
        <h2 class="salla-text-lg salla-font-semibold salla-text-gray-800">صور منتجاتك</h2>
      </div>

      <div class="salla-relative">
        <input 
          type="text" 
          v-model="searchQuery" 
          @input="filterProductImages" 
          placeholder="ابحث عن الصور حسب اسم المنتج..." 
          class="salla-w-full salla-pl-10 salla-pr-4 salla-py-2.5 salla-border salla-border-gray-200 salla-rounded-lg salla-bg-gray-50 focus:salla-bg-white focus:salla-ring-2 focus:salla-ring-primary-500 focus:salla-border-primary-500 salla-transition-all"
        />
        <i class="fas fa-search salla-absolute salla-left-3 salla-top-1/2 -salla-translate-y-1/2 salla-text-gray-400"></i>
      </div>
      
      <div class="salla-grid salla-grid-cols-2 sm:salla-grid-cols-3 salla-gap-3">
        <div 
          v-for="(productImage, index) in filteredProductImages" 
          :key="productImage.id" 
          @click="selectImage(productImage.url, index)"
          class="salla-group salla-aspect-square salla-rounded-lg salla-overflow-hidden salla-cursor-pointer salla-relative salla-ring-2 salla-ring-transparent hover:salla-ring-primary-500 salla-transition-all"
        >
          <img 
            :src="productImage.url" 
            :alt="productImage.name"
            class="salla-w-full salla-h-full salla-object-cover group-hover:salla-scale-105 salla-transition-transform salla-duration-300"
          />
          <div class="salla-absolute salla-inset-0 salla-bg-gradient-to-t salla-from-black/50 salla-to-transparent salla-opacity-0 group-hover:salla-opacity-100 salla-transition-opacity"></div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import { API_URL } from '@/config';
  import { mapMutations } from 'vuex';
  
  export default {
    name: 'MediaPreview',
    props: {
      previewInstance: {
        type: Object,
        required: true
      },
      modifications: {
        type: Object,
        required: true
      },
      numImages: {
        type: Number,
        required: true
      },
      selectedDesign: { // Add this prop to receive the selected design
        type: Object,
        required: true
      }
    },
    data() {
      return {
        imageData: {},
        imageFileName: {},
        preaddedImages: [],
        productImages: [],
        filteredProductImages: [],
        searchQuery: '',
        dynamicElementIds: null,
      };
    },
    computed: {
      imageKeys() {
        const keys = [
          'main_image_key',
          'secondary_image_key',
          'third_image_key',
          'fourth_image_key',
          'fifth_image_key',
          'sixth_image_key',
          'seventh_image_key'
        ];
        return keys.slice(0, this.numImages);
      },
    },
    watch: {
      searchQuery() {
        this.filterProductImages();
      },
    },
    methods: {
      ...mapMutations(['updateModifications']),
      handleFileUpload(imageKey, event) {
        const file = event.target.files[0];
        this.imageFileName[imageKey] = file.name;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData[imageKey] = e.target.result;
          console.log('handleFileUpload - imageData updated:', this.imageData);
          this.updateImage(imageKey, this.imageData[imageKey]);
        };
        reader.readAsDataURL(file);
      },
      selectImage(url, index) {
        console.log('selectImage called with url:', url, 'and index:', index);
        const imageKeyIndex = index % this.numImages;
        const imageKey = this.imageKeys[imageKeyIndex];
        console.log('Selected imageKey:', imageKey);
        if (imageKey) {
          this.imageData[imageKey] = url;
          console.log('selectImage - imageData updated:', this.imageData);
          this.updateImage(imageKey, this.imageData[imageKey]);
        } else {
          console.warn(`Invalid image key at index: ${imageKeyIndex}`);
        }
      },
      async loadPreaddedImages() {
        const response = await fetch(`${API_URL}/api/preadded-images`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.preaddedImages = await response.json();
        console.log('loadPreaddedImages - preaddedImages:', this.preaddedImages);
      },
      async fetchProductImages() {
        const response = await fetch(`${API_URL}/api/product-images`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.productImages = await response.json();
        this.filteredProductImages = this.productImages;
        console.log('fetchProductImages - productImages:', this.productImages);
      },
      filterProductImages() {
        if (!this.searchQuery) {
          this.filteredProductImages = this.productImages;
        } else {
          this.filteredProductImages = this.productImages.filter(p =>
            p.name.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        }
        console.log('filterProductImages - filteredProductImages:', this.filteredProductImages);
      },
      updateImage(imageKey, imageData) {
        console.log('updateImage called with imageKey:', imageKey, 'and imageData:', imageData);
        const elementId = this.dynamicElementIds ? this.dynamicElementIds[imageKey] : undefined;
        console.log('Element ID for image key:', elementId);
        if (elementId) {
          this.$emit('update-modifications', { [elementId]: imageData });
          console.log('updateImage - modifications emitted for elementId:', elementId);
        } else {
          console.warn(`Element ID not found for image key: ${imageKey}`);
        }
      },
      async fetchDynamicElementIds() {
        if (!this.selectedDesign || !this.selectedDesign.creatomate_template_id) {
          console.error('Selected design is missing or does not have a template ID');
          return;
        }
        
        const templateId = this.selectedDesign.creatomate_template_id;
        const response = await fetch(`${API_URL}/api/dynamic-element-ids?template_id=${templateId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log('Dynamic Element IDs response:', data);
          this.dynamicElementIds = data.elements;
          console.log('fetchDynamicElementIds - dynamicElementIds:', this.dynamicElementIds);
        } else {
          console.error('Failed to fetch dynamic element IDs:', response.status, await response.text());
        }
      },
    },
    async mounted() {
      console.log('MediaPreview - mounted with modifications prop:', this.modifications);
      await this.loadPreaddedImages();
      await this.fetchProductImages();
      await this.fetchDynamicElementIds();
      console.log('MediaPreview - initial data:', {
        preaddedImages: this.preaddedImages,
        productImages: this.productImages,
        dynamicElementIds: this.dynamicElementIds
      });
    },
  };
</script>

  
  
  


<style scoped>

/* Media Container Styles */
.media-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    margin-bottom: 20px;
}

.section-container {
    margin-bottom: 20px;
}

.section-container h2 {
    margin-bottom: 10px;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
}

.image-item img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.image-item img:hover {
    transform: scale(1.05);
}

.custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #3498db;
    color: white;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.custom-file-upload:hover {
    background-color: #2980b9;
}

.uploaded-image-preview {
    width: 100%;
    max-width: 200px;  /* Adjust this value as needed */
    height: auto;
    margin-top: 10px;
    border-radius: 5px;
}

.search-bar {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .media-container {
        padding: 10px;
    }

    .section-container {
        margin-bottom: 15px;
    }

    .section-container h2 {
        font-size: 1.2rem;
    }

    .image-grid {
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
        gap: 8px;
    }

    .custom-file-upload {
        width: 100%;
        text-align: center;
        padding: 15px;
    }

    .uploaded-image-preview {
        max-width: 100%;
    }

    .search-bar {
        padding: 8px;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .section-container h2 {
        font-size: 1rem;
    }

    .custom-file-upload {
        padding: 10px;
    }

    .search-bar {
        padding: 6px;
        font-size: 0.8rem;
    }
}

</style>
