<template>
  <div class="salla-flex salla-flex-col salla-gap-8 salla-min-h-0" dir="rtl">
    <div ref="previewContainer"></div>

    <!-- Image Categories Tabs -->
    <div class="salla-flex salla-flex-col salla-gap-6">
      <div class="salla-flex salla-items-center salla-gap-4 salla-overflow-x-auto salla-pb-2">
        <button 
          v-for="tab in ['الصور المقترحة', 'رفع الصور', 'صور منتجاتك']" 
          :key="tab"
          @click="activeImageTab = tab"
          class="salla-px-6 salla-py-3 salla-whitespace-nowrap salla-rounded-lg salla-transition-all salla-font-medium"
          :class="[
            activeImageTab === tab 
              ? 'salla-bg-primary-600 salla-text-white salla-shadow-lg' 
              : 'salla-bg-gray-100 salla-text-gray-600 hover:salla-bg-gray-200'
          ]"
        >
          {{ tab }}
        </button>
      </div>

      <!-- Suggested Images Section -->
      <div v-show="activeImageTab === 'الصور المقترحة'" class="salla-space-y-6">
        <div class="salla-grid salla-grid-cols-3 md:salla-grid-cols-4 lg:salla-grid-cols-5 salla-gap-4">
          <div
            v-for="(image, index) in preaddedImages"
            :key="image.id"
            @click="selectImage(image.url, index)"
            class="salla-group salla-relative salla-aspect-square salla-rounded-xl salla-overflow-hidden salla-shadow-md hover:salla-shadow-xl salla-transition-all salla-cursor-pointer hover:salla-scale-105"
          >
            <img 
              :src="image.url" 
              :alt="image.name"
              class="salla-w-full salla-h-full salla-object-cover"
            />
            <div class="salla-absolute salla-inset-0 salla-bg-gradient-to-t salla-from-black/50 salla-to-transparent salla-opacity-0 group-hover:salla-opacity-100 salla-transition-opacity">
              <div class="salla-absolute salla-bottom-2 salla-right-2 salla-text-white salla-text-sm">
                {{ image.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Upload Images Section -->
      <div v-show="activeImageTab === 'رفع الصور'" class="salla-space-y-8">
        <div 
          v-for="(imageKey, index) in imageKeys" 
          :key="imageKey" 
          class="salla-bg-white salla-rounded-xl salla-p-6 salla-shadow-md"
        >
          <div class="salla-flex salla-items-center salla-justify-between salla-mb-4">
            <h3 class="salla-text-lg salla-font-semibold salla-text-gray-800">صورة {{ index + 1 }}</h3>
            <div class="salla-flex salla-items-center salla-gap-2">
              <label 
                :for="'file-upload-' + imageKey" 
                class="salla-px-4 salla-py-2 salla-bg-primary-600 salla-text-white salla-rounded-lg salla-cursor-pointer hover:salla-bg-primary-700 salla-transition-all salla-flex salla-items-center salla-gap-2"
              >
                <i class="fas fa-cloud-upload-alt"></i>
                اختر ملف
              </label>
              <input 
                :id="'file-upload-' + imageKey" 
                type="file" 
                accept="image/*" 
                @change="handleFileUpload(imageKey, $event)" 
                class="salla-hidden"
              />
            </div>
          </div>

          <div 
            v-if="imageData[imageKey]" 
            class="salla-bg-gray-50 salla-rounded-lg salla-p-4"
          >
            <div class="salla-flex salla-items-center salla-gap-4">
              <div class="salla-w-24 salla-h-24 salla-rounded-lg salla-overflow-hidden">
                <img 
                  :src="imageData[imageKey]" 
                  :alt="imageFileName[imageKey]"
                  class="salla-w-full salla-h-full salla-object-cover"
                />
              </div>
              <div>
                <p class="salla-font-medium salla-text-gray-700">{{ imageFileName[imageKey] }}</p>
                <p class="salla-text-sm salla-text-gray-500">تم الرفع بنجاح</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Product Images Section -->
      <div v-show="activeImageTab === 'صور منتجاتك'" class="salla-space-y-6">
        <!-- Enhanced Search Bar -->
        <div class="salla-relative">
          <i class="fas fa-search salla-absolute salla-right-4 salla-top-1/2 -salla-translate-y-1/2 salla-text-gray-400"></i>
          <input 
            type="text" 
            v-model="searchQuery" 
            @input="filterProductImages"
            placeholder="ابحث عن الصور حسب اسم المنتج..." 
            class="salla-w-full salla-pl-4 salla-pr-12 salla-py-3 salla-bg-white salla-rounded-lg salla-border salla-border-gray-200 focus:salla-border-primary-600 focus:salla-ring-2 focus:salla-ring-primary-600/20 salla-outline-none salla-transition-all"
          />
        </div>

        <!-- Product Images Grid -->
        <div class="salla-grid salla-grid-cols-3 md:salla-grid-cols-4 lg:salla-grid-cols-5 salla-gap-4">
          <div
            v-for="(productImage, index) in filteredProductImages"
            :key="productImage.id"
            @click="selectImage(productImage.url, index)"
            class="salla-group salla-relative salla-aspect-square salla-rounded-xl salla-overflow-hidden salla-shadow-md hover:salla-shadow-xl salla-transition-all salla-cursor-pointer hover:salla-scale-105"
          >
            <img 
              :src="productImage.url" 
              :alt="productImage.name"
              class="salla-w-full salla-h-full salla-object-cover"
            />
            <div class="salla-absolute salla-inset-0 salla-bg-gradient-to-t salla-from-black/50 salla-to-transparent salla-opacity-0 group-hover:salla-opacity-100 salla-transition-opacity">
              <div class="salla-absolute salla-bottom-2 salla-right-2 salla-text-white salla-text-sm">
                {{ productImage.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_URL } from '@/config';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'MediaTemplate',
  props: {
    previewInstance: {
      type: Object,
      required: true
    },
    updatePreviewInstance: {
      type: Function,
      required: true
    },
    videoId: {
      type: Number,
      required: true
    },
    numImages: {
      type: Number,
      required: true
    },
    dynamicElementIds: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  data() {
    return {
      videoDetails: null,
      localDynamicElementIds: null,
      imageData: {},
      imageFileName: {},
      preaddedImages: [],
      productImages: [],
      filteredProductImages: [],
      activeImageTab: 'الصور المقترحة',
      searchQuery: '',
      loading: false
    };
  },

  computed: {
    ...mapState(['modifications']),
    imageKeys() {
      const keys = [
        'main_image_key',
        'secondary_image_key',
        'third_image_key',
        'fourth_image_key',
        'fifth_image_key',
        'sixth_image_key',
        'seventh_image_key'
      ];
      return keys.slice(0, this.numImages);
    }
  },

  watch: {
    dynamicElementIds: {
      handler(newVal) {
        if (newVal?.elements) {
          this.localDynamicElementIds = newVal.elements;
          this.loadCurrentImages();
          console.log('Dynamic element IDs updated:', this.localDynamicElementIds);
        }
      },
      immediate: true,
      deep: true
    },
    '$store.state.videoDetails': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.videoDetails = newValue;
          if (newValue.dynamic_element_ids?.elements) {
            this.localDynamicElementIds = newValue.dynamic_element_ids.elements;
            this.loadCurrentImages();
          }
        }
      }
    },
    searchQuery() {
      this.filterProductImages();
    }
  },

  methods: {
    ...mapMutations(['updateModifications']),

    loadCurrentImages() {
      if (!this.localDynamicElementIds) return;
      
      this.imageKeys.forEach(imageKey => {
        const elementId = this.localDynamicElementIds[imageKey];
        if (elementId) {
          const currentModification = this.$store.state.modifications[elementId];
          if (currentModification) {
            this.imageData[imageKey] = currentModification;
          }
        }
      });
    },

    async fetchVideoData() {
      try {
        const response = await fetch(`${API_URL}/api/videos/${this.videoId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        this.videoDetails = data;
        
        if (data.dynamic_element_ids?.elements) {
          this.localDynamicElementIds = data.dynamic_element_ids.elements;
          this.loadCurrentImages();
        }
      } catch (error) {
        console.error('Error fetching video data:', error);
      }
    },

    async loadPreaddedImages() {
      try {
        const response = await fetch(`${API_URL}/api/preadded-images`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        this.preaddedImages = await response.json();
      } catch (error) {
        console.error('Error loading preadded images:', error);
        this.preaddedImages = [];
      }
    },

    async fetchProductImages() {
      try {
        const response = await fetch(`${API_URL}/api/product-images`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        this.productImages = await response.json();
        this.filteredProductImages = this.productImages;
      } catch (error) {
        console.error('Error fetching product images:', error);
        this.productImages = [];
        this.filteredProductImages = [];
      }
    },

    async handleFileUpload(imageKey, event) {
      const file = event.target.files[0];
      if (!file) return;

      this.loading = true;
      this.imageFileName[imageKey] = file.name;

      try {
        const formData = new FormData();
        formData.append('image', file);

        const response = await fetch(`${API_URL}/api/upload-image`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: formData
        });

        if (!response.ok) {
          throw new Error(`Upload failed: ${response.statusText}`);
        }

        const data = await response.json();
        this.imageData[imageKey] = data.url;
        await this.updateImage(imageKey, data.url);
      } catch (error) {
        console.error('Error uploading image:', error);
        alert('Failed to upload image. Please try again.');
      } finally {
        this.loading = false;
      }
    },

    async selectImage(url, index) {
      const imageKey = this.imageKeys[index % this.numImages];
      if (!imageKey) {
        console.warn('Invalid image key for index:', index);
        return;
      }

      try {
        this.imageData[imageKey] = url;
        await this.updateImage(imageKey, url);
      } catch (error) {
        console.error('Error selecting image:', error);
        alert('Failed to select image. Please try again.');
      }
    },

    filterProductImages() {
      if (!this.searchQuery.trim()) {
        this.filteredProductImages = this.productImages;
        return;
      }

      const query = this.searchQuery.toLowerCase();
      this.filteredProductImages = this.productImages.filter(p => 
        p.name.toLowerCase().includes(query)
      );
    },

    async updateImage(imageKey, imageUrl) {
      if (!this.localDynamicElementIds) {
        console.error('Dynamic element IDs not available');
        return;
      }

      const elementId = this.localDynamicElementIds[imageKey];
      if (!elementId) {
        console.error(`No element ID found for image key: ${imageKey}`);
        return;
      }

      try {
        // Update store
        this.updateModifications({ key: elementId, value: imageUrl });
        
        // Save to DB
        await this.saveModificationsToDb();
        
        // Update preview
        if (this.previewInstance && this.updatePreviewInstance) {
          await this.updatePreviewInstance();
        }
      } catch (error) {
        console.error('Error updating image:', error);
        alert('Failed to update image. Please try again.');
      }
    },

    async saveModificationsToDb() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No auth token found');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/videos/${this.videoId}/modifications`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ 
            modifications: this.$store.state.modifications 
          })
        });

        if (!response.ok) {
          throw new Error(`Save failed: ${response.statusText}`);
        }

        await response.json();
      } catch (error) {
        console.error('Error saving modifications:', error);
        throw error;
      }
    }
  },

  async mounted() {
    try {
      await this.fetchVideoData();
      await Promise.all([
        this.loadPreaddedImages(),
        this.fetchProductImages()
      ]);
    } catch (error) {
      console.error('Error in mounted:', error);
    }
  }
};
</script>



  
  
  
<style scoped>

.search-bar {
  padding: 8px;
  margin: 10px 0;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.media-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section-container {
  margin-bottom: 30px;
}

h2 {
  color: #333;
  margin-bottom: 10px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Always two images per row */
  grid-gap: 10px;
}

.image-item {
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
}

.image-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.image-item:hover img {
  transform: scale(1.1);
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  margin-top: 5px;
}

.image-preview {
  margin-top: 10px;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 5px;
}

.uploaded-image-preview {
  max-width: 100%;
  height: auto;
  display: block;
  margin-top: 10px;
}
</style>
