<template>
  <div class="salla-fixed salla-inset-0 salla-bg-gray-100/95 salla-flex salla-items-center salla-justify-center">
    <!-- Main Editor Container -->
    <div class="salla-bg-white salla-w-[95vw] salla-h-[90vh] salla-rounded-2xl salla-shadow-2xl salla-flex salla-flex-col salla-overflow-hidden">
      <!-- Header -->
      <header class="salla-border-b salla-border-gray-200 salla-p-4">
        <div class="salla-flex salla-items-center salla-justify-between">
          <h1 class="salla-text-xl salla-font-bold salla-bg-gradient-to-r salla-from-primary-600 salla-to-primary-800 salla-bg-clip-text salla-text-transparent">
            Edit Video
          </h1>
          <div class="salla-flex salla-items-center salla-gap-3">
            <button 
              @click="saveVideo"
              :disabled="isSaving"
              class="salla-px-4 salla-py-2 salla-bg-primary-600 salla-text-white salla-rounded-lg salla-font-medium hover:salla-bg-primary-700 salla-transition-all disabled:salla-opacity-50 disabled:salla-cursor-not-allowed salla-flex salla-items-center salla-gap-2"
            >
              <i class="fas fa-save"></i>
              {{ isSaving ? 'Saving...' : 'Save' }}
            </button>
            <button 
              @click="closeEditPopup"
              class="salla-p-2 salla-text-gray-500 hover:salla-text-red-500 salla-rounded-lg hover:salla-bg-gray-100 salla-transition-all"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </header>

      <!-- Main Content -->
      <div class="salla-flex salla-flex-1 salla-min-h-0">
        <!-- Sidebar -->
        <nav class="salla-w-20 salla-flex-none salla-bg-gray-50 salla-border-r salla-border-gray-200">
          <div class="salla-flex salla-flex-col salla-items-center salla-gap-4 salla-p-4">
            <!-- Media Tab -->
            <button
              @click="setActiveTab('media')"
              class="salla-w-12 salla-h-12 salla-rounded-xl salla-flex salla-items-center salla-justify-center salla-transition-all"
              :class="[
                activeTab === 'media' 
                  ? 'salla-bg-primary-600 salla-text-white salla-shadow-lg' 
                  : 'salla-bg-white salla-text-gray-600 hover:salla-bg-gray-100'
              ]"
            >
              <i class="fas fa-photo-video salla-text-xl"></i>
            </button>

            <!-- Text Tab -->
            <button
              @click="setActiveTab('texts')"
              class="salla-w-12 salla-h-12 salla-rounded-xl salla-flex salla-items-center salla-justify-center salla-transition-all"
              :class="[
                activeTab === 'texts' 
                  ? 'salla-bg-primary-600 salla-text-white salla-shadow-lg' 
                  : 'salla-bg-white salla-text-gray-600 hover:salla-bg-gray-100'
              ]"
            >
              <i class="fas fa-font salla-text-xl"></i>
            </button>

            <!-- Music Tab -->
            <button
              @click="setActiveTab('music')"
              class="salla-w-12 salla-h-12 salla-rounded-xl salla-flex salla-items-center salla-justify-center salla-transition-all"
              :class="[
                activeTab === 'music' 
                  ? 'salla-bg-primary-600 salla-text-white salla-shadow-lg' 
                  : 'salla-bg-white salla-text-gray-600 hover:salla-bg-gray-100'
              ]"
            >
              <i class="fas fa-music salla-text-xl"></i>
            </button>
          </div>
        </nav>

        <!-- Content Area -->
        <div class="salla-flex salla-flex-1">
          <!-- Editor Panel -->
          <div class="salla-w-[400px] salla-flex-none salla-border-r salla-border-gray-200 salla-overflow-y-auto">
            <div class="salla-p-6">
              <div class="salla-mb-6">
                <h2 class="salla-text-lg salla-font-semibold salla-flex salla-items-center salla-gap-2">
                  <i :class="[
                    'salla-text-primary-600',
                    activeTab === 'media' ? 'fas fa-photo-video' : 
                    activeTab === 'texts' ? 'fas fa-font' : 
                    'fas fa-music'
                  ]"></i>
                  {{ activeTab.charAt(0).toUpperCase() + activeTab.slice(1) }}
                </h2>
              </div>

              <!-- Tab Content -->
              <div v-if="activeTab === 'media'" class="salla-space-y-6">
                <MediaPreview
                  v-if="previewInstance"
                  :previewInstance="previewInstance"
                  :modifications="modifications"
                  :numImages="template.num_images || 1"
                  :selectedDesign="template"
                  @update-modifications="updateModifications"
                />
              </div>

              <div v-if="activeTab === 'texts'" class="salla-space-y-6">
                <TextPreview
                  :previewInstance="previewInstance"
                  :modifications="modifications"
                  :numTexts="template.num_texts || 1"
                  :dynamicElementIds="dynamicElementIds"
                  @update-modifications="updateModifications"
                />
              </div>

              <div v-if="activeTab === 'music'" class="salla-space-y-6">
                <MusicPreview
                  :previewInstance="previewInstance"
                  :modifications="modifications"
                  :dynamicElementIds="dynamicElementIds"
                  @update-modifications="updateModifications"
                />
              </div>
            </div>
          </div>

          <!-- Preview Panel -->
          <div class="salla-flex-1 salla-bg-gray-900 salla-p-6">
            <div class="salla-h-full salla-flex salla-items-center salla-justify-center">
              <div class="salla-w-full salla-max-w-3xl salla-bg-black salla-rounded-2xl salla-shadow-2xl salla-overflow-hidden">
                <!-- Preview Header -->
                <div class="salla-flex salla-items-center salla-justify-between salla-px-4 salla-py-2 salla-bg-gray-800">
                  <div class="salla-flex salla-gap-2">
                    <div class="salla-w-3 salla-h-3 salla-rounded-full salla-bg-red-500"></div>
                    <div class="salla-w-3 salla-h-3 salla-rounded-full salla-bg-yellow-500"></div>
                    <div class="salla-w-3 salla-h-3 salla-rounded-full salla-bg-green-500"></div>
                  </div>
                  <span class="salla-text-gray-400 salla-text-sm">Preview</span>
                </div>

                <!-- Preview Content -->
                <div class="salla-relative salla-bg-gray-900">
                  <div class="salla-aspect-video">
                    <div ref="previewContainer" class="salla-absolute salla-inset-0"></div>
                  </div>
                </div>

                <!-- Timeline -->
                <div class="salla-p-4 salla-bg-gray-800">
                  <div class="salla-w-full salla-h-1 salla-bg-gray-700 salla-rounded-full">
                    <div class="salla-w-1/3 salla-h-full salla-bg-primary-600 salla-rounded-full"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
  <script>
import { Preview } from '@creatomate/preview';
import { API_URL } from '@/config';
import MediaPreview from './MediaPreview.vue';
import TextPreview from './TextPreview.vue';
import MusicPreview from './MusicPreview.vue';

export default {
  name: 'EditPreviewMain',
  emits: ['update-video-preview', 'update-modifications', 'close'],
  components: {
    MediaPreview,
    TextPreview,
    MusicPreview,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    template: {
      type: Object,
      required: true,
    },
    initialModifications: {
      type: Object,
      default: () => ({}),
    },
    dynamicElementIds: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      previewInstance: null,
      activeTab: 'media',
      modifications: {},
      isSaving: false,
      numTexts: this.template.num_texts || 1,
      requiredCredits: 0,
    };
  },
  mounted() {
    console.log('Mounted EditPreviewMain');
    console.log('Initial modifications:', this.initialModifications);
    console.log('Number of texts:', this.numTexts);
    this.modifications = { ...this.initialModifications };
    this.initializePreview();
    this.fetchRequiredCredits();
  },
  methods: {
    async checkCredits() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to save videos.');
        return 0;
      }

      try {
        const response = await fetch(`${API_URL}/api/check-credits`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data.credits;
      } catch (error) {
        console.error('Failed to check credits:', error);
        return 0;
      }
    },
    async fetchRequiredCredits() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to view required credits.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/required-credits`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.length > 0) {
          this.requiredCredits = data[0].requested_credits;
        } else {
          this.requiredCredits = 0;
        }
      } catch (error) {
        console.error('Failed to fetch required credits:', error);
        this.requiredCredits = 0;
      }
    },
    initializePreview() {
      const previewContainer = this.$refs.previewContainer;
      this.previewInstance = new Preview(previewContainer, 'player', 'public-yqbsoiqlkk8rwzj1pf9n9id6');
      this.previewInstance.onReady = () => {
        this.loadPreview();
      };
    },
    loadPreview() {
      const templateId = this.template.creatomate_template_id;
      console.log('Loading preview with template ID:', templateId);
      this.previewInstance.loadTemplate(templateId);
      this.previewInstance.setModifications(this.initialModifications);
    },
    setActiveTab(tabName) {
      console.log('Setting active tab:', tabName);
      this.activeTab = tabName;
    },
    updateModifications(newModifications) {
      this.modifications = { ...this.modifications, ...newModifications };
      console.log('Updated modifications:', this.modifications);
      this.previewInstance.setModifications(this.modifications);
    },
    async saveVideo() {
      console.log('Modifications being sent:', JSON.stringify(this.modifications));
      this.isSaving = true; // Disable the save button and start saving

      try {
        // Update the video preview with new modifications
        this.$emit('update-video-preview', this.modifications);
        this.$emit('update-modifications', this.modifications);
        // Dispatch a notification that the modifications are saved
       

        // Close the edit popup and emit the 'close' event
        this.closeEditPopup();
      } catch (error) {
        console.error('Error saving modifications:', error);
      } finally {
        this.isSaving = false; // Re-enable the save button
      }
    },
    closeEditPopup() {
      this.$emit('close');
    },
  },
};
</script>

  
  
  
  
  
  
  
  
  
  