<template>
  <div class="container">
    <header class="header">
      <h1>جلب المنتجات</h1>
    </header>
  
    <button class="sidebar-toggle" @click="toggleSidebar">
      <div class="icon">
        <span class="middle-bar"></span>
      </div>
    </button>
    <div :class="['overlay', { 'visible': isSidebarOpen }]" @click="toggleSidebar"></div>

    <main class="main-content">
  <div class="form-container">
    <div class="animation-wrapper">
      <div class="animated-background">
        <div class="dots"></div>
        <div class="dots"></div>
        <div class="dots"></div>
      </div>
    </div>
    <h2>أدخل الرابط إلى متجرك الإلكتروني</h2>
    <p class="description">مرحبًا بك! أدخل رابط متجرك لتحويل منتجاتك إلى فيديوهات ترويجية بنقرة واحدة. سنجلب المنتجات، نخزنها، وننشئ فيديوهات لتعزيز مبيعاتك. ابدأ الآن!</p>
    <form @submit.prevent="submitForm" class="submit-form">
      <input type="text" v-model="formInput" placeholder="https://www.test.com/" class="input-field" :disabled="isValidating">
      <button type="submit" :class="['submit-button', { 'validating': isValidating, 'error': hasError }]">التالي</button>
    </form>
    <div v-if="validationMessage" class="validation-message">{{ validationMessage }}</div>
  </div>
  <div v-if="isLoading" class="loading-container">
    <div class="loading-animation">
      <div class="spinner"></div>
      <p>جلب المنتجات... الرجاء الانتظار...</p>
    </div>
  </div>
</main>

  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { API_URL } from '@/config';

export default {
  name: 'ScrapeProducts',
  setup() {
    const router = useRouter();
    const formInput = ref('');
    const isValidating = ref(false);
    const hasError = ref(false);
    const validationMessage = ref('');
    const isLoading = ref(false);

    const submitForm = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        validationMessage.value = 'Please log in to submit.';
        hasError.value = true;
        return;
      }

      isValidating.value = true;
      validationMessage.value = 'Validating your URL...';

      try {
        const response = await fetch(`${API_URL}/process_videos`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ product_url: formInput.value })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        isValidating.value = false;
        isLoading.value = true;

        setTimeout(() => {
          isLoading.value = false;
          router.push('/dashboard');
        }, 150000); // Animation delay for scraping process

      } catch (error) {
        isValidating.value = false;
        hasError.value = true;
        validationMessage.value = 'We are accepting only e-commerce stores. Please check your URL and try again.';
      }
    };

    onMounted(() => {
      formInput.value = '';
    });

    return { formInput, isValidating, hasError, validationMessage, submitForm, isLoading };
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap');

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: #f0f0f0;
  font-family: 'Cairo', sans-serif;
}

.header {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px;
  text-align: center;
  animation: fadeInDown 1s ease;
}

.sidebar {
  width: 250px;
  background-color: #34495e;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
  transition: transform 0.3s ease;
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}

.sidebar-content {
  display: flex;
  flex-direction: column;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.sidebar-item:hover, .sidebar-item.active {
  background-color: #2c3e50;
}

.sidebar-item-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.sidebar-item-text {
  font-size: 16px;
}

.sidebar-separator {
  border-top: 1px solid #ecf0f1;
  margin: 10px 0;
}

.sidebar-credits {
  display: flex;
  align-items: center;
  padding: 10px;
}

.sidebar-credits-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.sidebar-credits-text {
  font-size: 16px;
}

.sidebar-toggle {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.sidebar-toggle .icon {
  width: 30px;
  height: 30px;
}

.sidebar-toggle .icon .middle-bar {
  display: block;
  width: 100%;
  height: 2px;
  background-color: #34495e;
  position: relative;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
}

.overlay.visible {
  visibility: visible;
  opacity: 1;
}

.main-content {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease;
}

.form-container {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 500px;
  position: relative;
  animation: fadeInUp 1s ease;
}

.animation-wrapper {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.animated-background {
  width: 60px;
  height: 60px;
  background: #3498db;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotate 2s infinite linear;
}

.dots {
  width: 10px;
  height: 10px;
  background: #ecf0f1;
  border-radius: 50%;
  margin: 0 2px;
  animation: bounce 1.5s infinite ease-in-out;
}

.dots:nth-child(2) {
  animation-delay: 0.2s;
}

.dots:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #34495e;
  animation: fadeInRight 1s ease;
}

.description {
  font-size: 16px;
  color: #7f8c8d;
  margin-bottom: 20px;
  animation: fadeInLeft 1s ease;
}

.submit-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-field {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: #3498db;
}

.submit-button {
  padding: 12px 30px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;
}

.submit-button.validating {
  background-color: #f1c40f;
}

.submit-button.error {
  background-color: #e74c3c;
}

.validation-message {
  margin-top: 15px;
  color: #e74c3c;
  font-weight: bold;
  animation: fadeInUp 1s ease;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  animation: fadeIn 1s ease;
}

.loading-animation {
  text-align: center;
}

.spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
