<template>
  <div class="salla-min-h-screen salla-flex salla-items-center salla-justify-center salla-bg-gradient-to-br salla-from-primary-900 salla-via-primary-800 salla-to-primary-900 salla-relative salla-overflow-hidden">
    <!-- Decorative Background Elements -->
    <div class="salla-absolute salla-inset-0">
      <div class="salla-absolute salla-top-0 salla-left-1/4 salla-w-96 salla-h-96 salla-bg-primary-500 salla-rounded-full salla-mix-blend-multiply salla-filter salla-blur-3xl salla-opacity-20 animate-pulse"></div>
      <div class="salla-absolute salla-bottom-0 salla-right-1/3 salla-w-96 salla-h-96 salla-bg-primary-400 salla-rounded-full salla-mix-blend-multiply salla-filter salla-blur-3xl salla-opacity-20 animate-pulse-slow"></div>
    </div>

    <div class="salla-w-full salla-max-w-md salla-relative salla-z-10 salla-px-4">
      <!-- Logo/Brand Section -->
      <div class="salla-text-center salla-mb-8">
        <h1 class="salla-text-4xl salla-font-bold salla-text-white salla-mb-2">Filmatex Admin Portal</h1>
        <p class="salla-text-primary-200">Secure Access Dashboard</p>
      </div>

      <!-- Login Card -->
      <div class="salla-bg-white/10 salla-backdrop-blur-lg salla-rounded-2xl salla-shadow-2xl salla-p-8 salla-border salla-border-white/20 animate-fade-in">
        <div class="salla-bg-white salla-rounded-xl salla-p-8">
          <h2 class="salla-text-2xl salla-font-semibold salla-text-gray-800 salla-mb-8 salla-text-center">
            Welcome Back
          </h2>
          
          <form @submit.prevent="login" class="salla-space-y-6">
            <!-- Username Input -->
            <div class="salla-space-y-2">
              <label 
                for="username" 
                class="salla-block salla-text-sm salla-font-medium salla-text-gray-700"
              >
                Username
              </label>
              <div class="salla-relative">
                <div class="salla-absolute salla-inset-y-0 salla-left-0 salla-pl-3 salla-flex salla-items-center salla-pointer-events-none">
                  <svg class="salla-h-5 salla-w-5 salla-text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <input
                  id="username"
                  type="text"
                  v-model="username"
                  required
                  class="salla-pl-10 salla-w-full salla-px-4 salla-py-3 salla-rounded-lg salla-bg-gray-50 salla-border salla-border-gray-300 salla-focus:outline-none salla-focus:ring-2 salla-focus:ring-primary-500 salla-focus:border-transparent salla-transition-all"
                  placeholder="Enter your username"
                />
              </div>
            </div>

            <!-- Password Input -->
            <div class="salla-space-y-2">
              <label 
                for="password" 
                class="salla-block salla-text-sm salla-font-medium salla-text-gray-700"
              >
                Password
              </label>
              <div class="salla-relative">
                <div class="salla-absolute salla-inset-y-0 salla-left-0 salla-pl-3 salla-flex salla-items-center salla-pointer-events-none">
                  <svg class="salla-h-5 salla-w-5 salla-text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                  </svg>
                </div>
                <input
                  id="password"
                  type="password"
                  v-model="password"
                  required
                  class="salla-pl-10 salla-w-full salla-px-4 salla-py-3 salla-rounded-lg salla-bg-gray-50 salla-border salla-border-gray-300 salla-focus:outline-none salla-focus:ring-2 salla-focus:ring-primary-500 salla-focus:border-transparent salla-transition-all"
                  placeholder="Enter your password"
                />
              </div>
            </div>

            <!-- Error Message -->
            <p 
              v-if="error" 
              class="salla-text-red-500 salla-text-sm salla-text-center salla-bg-red-50 salla-p-3 salla-rounded-lg salla-flex salla-items-center salla-justify-center salla-space-x-2"
            >
              <svg class="salla-h-5 salla-w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              <span>{{ error }}</span>
            </p>

            <!-- Login Button -->
            <button
              type="submit"
              class="salla-w-full salla-bg-primary-600 salla-text-white salla-py-3 salla-px-4 salla-rounded-lg salla-font-medium salla-hover:bg-primary-700 salla-transition-all salla-duration-200 salla-flex salla-items-center salla-justify-center salla-space-x-2 salla-group"
            >
              <span>Sign In</span>
              <svg 
                class="salla-w-5 salla-h-5 salla-transform salla-group-hover:translate-x-1 salla-transition-transform" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6"></path>
              </svg>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/authService';

export default {
  data() {
    return {
      username: '',
      password: '',
      error: '',
    };
  },
  methods: {
    async login() {
      try {
        const credentials = {
          username: this.username,
          password: this.password,
        };
        const response = await AuthService.adminLogin(credentials);
        console.log('Admin login successful:', response);
        AuthService.login(response);
        this.$router.push('/admin/dashboard');
      } catch (err) {
        console.error('Admin login failed:', err);
        this.error = 'Login failed. Please try again.';
      }
    },
  },
};
</script>

<style>
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse-slow {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.3;
  }
}

.animate-fade-in {
  animation: fade-in 0.5s ease-out;
}

.animate-pulse-slow {
  animation: pulse-slow 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>