<template>
  <footer class="footer-main">
    <div class="footer-container">
      <div class="footer-logo">
        <img :src="require('@/assets/logo-nobg.png')" alt="Logo" class="logo" />
      </div>
      <div class="footer-links">
        <div class="links-column">
          <h4>Product</h4>
          <router-link to="/pricing">Pricing</router-link>
        </div>
        <div class="links-column">
          <h4>Company</h4>
          <router-link to="/who-we-are">Who We Are</router-link>
          <router-link to="/vision">Our Vision</router-link>
          <router-link to="/contact">Contact Us</router-link>
        </div>
        <div class="links-column">
          <h4>Legal</h4>
          <router-link to="/terms">Terms & Services</router-link>
          <router-link to="/privacy">Privacy Policy</router-link>
          <router-link to="/cookies">Cookies</router-link>
        </div>
      </div>
      <div class="footer-social">
        <h4>Follow Us</h4>
        <div class="social-icons">
          <a v-if="socialMediaLinks.facebook" :href="socialMediaLinks.facebook" class="social-icon"><i class="fab fa-facebook-f"></i></a>
          <a v-if="socialMediaLinks.twitter" :href="socialMediaLinks.twitter" class="social-icon"><i class="fab fa-twitter"></i></a>
          <a v-if="socialMediaLinks.instagram" :href="socialMediaLinks.instagram" class="social-icon"><i class="fab fa-instagram"></i></a>
          <a v-if="socialMediaLinks.linkedin" :href="socialMediaLinks.linkedin" class="social-icon"><i class="fab fa-linkedin-in"></i></a>
          <a v-if="socialMediaLinks.tiktok" :href="socialMediaLinks.tiktok" class="social-icon"><i class="fab fa-tiktok"></i></a>
          <a v-if="socialMediaLinks.snapchat" :href="socialMediaLinks.snapchat" class="social-icon"><i class="fab fa-snapchat-ghost"></i></a>
          <a v-if="socialMediaLinks.youtube" :href="socialMediaLinks.youtube" class="social-icon"><i class="fab fa-youtube"></i></a>
          <a v-if="socialMediaLinks.pinterest" :href="socialMediaLinks.pinterest" class="social-icon"><i class="fab fa-pinterest"></i></a>
          <a v-if="socialMediaLinks.reddit" :href="socialMediaLinks.reddit" class="social-icon"><i class="fab fa-reddit"></i></a>
        </div>
      </div>
      <div class="footer-copy-right">
        © 2023 Filmatex. All rights reserved.
      </div>
    </div>
  </footer>
</template>

<script>
import { API_URL } from '@/config';

export default {
  name: 'FooterMain',
  data() {
    return {
      socialMediaLinks: {}
    };
  },
  methods: {
    fetchSocialMediaLinks() {
      fetch(`${API_URL}/api/social-media-links`)
        .then(response => response.json())
        .then(data => {
          this.socialMediaLinks = data.reduce((acc, link) => {
            acc[link.platform.toLowerCase()] = link.url;
            return acc;
          }, {});
        })
        .catch(error => {
          console.error('Error fetching social media links:', error);
        });
    }
  },
  mounted() {
    this.fetchSocialMediaLinks();
  }
};
</script>



<style scoped>
.footer-main {
    background-color: #333;
    color: #fff;
    padding: 40px 20px;
    z-index: 1;
    position: relative;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo img {
  width: 120px;
}

.footer-links {
  display: flex;
  margin: 20px 0;
}

.links-column {
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.links-column h4 {
  margin-bottom: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.links-column a {
  margin: 5px 0;
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s;
}

.links-column a:hover {
  color: #fff;
}

.footer-social {
  margin: 20px 0;
}

.footer-social h4 {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
}

.social-icon {
  color: #ccc;
  font-size: 20px;
  margin-right: 15px;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #fff;
}

.footer-copy-right {
  font-size: 14px;
  color: #ccc;
  text-align: center;
  width: 100%;
  margin-top: 20px;
}

/* Media Queries for Responsive Design */




</style>