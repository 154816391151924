import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      isConnectedToWooCommerce: false,
      isConnectedToSalla: false,
      isConnectedToZid: false,
      pollingInterval: null,
      lastVideoNotificationTime: null,
      editedData: {},
      modifications: {},
      templateId: null,
      initialModifications: {},
    };
  },
  mutations: {
    updateModification(state, { key, value }) {
      state.modifications[key] = value;
    },
    
    setVideoDetails(state, videoDetails) {
      state.videoDetails = videoDetails;
    },

    setWooCommerceConnectionStatus(state, status) {
      state.isConnectedToWooCommerce = status;
    },
    setSallaConnectionStatus(state, status) {
      state.isConnectedToSalla = status;
    },
    updateZidConnectionStatus(state, status) {
      state.isConnectedToZid = status;
    },
    updateLastVideoNotificationTime(state) {
      state.lastVideoNotificationTime = new Date().getTime();
    },
    updateEditedData(state, { videoId, data }) {
      state.editedData[videoId] = { ...state.editedData[videoId], ...data };
    },
    clearEditedData(state, videoId) {
      delete state.editedData[videoId];
    },
    setModifications(state, modifications) {
      state.modifications = { ...modifications };
    },
    updateModifications(state, { key, value }) {
      if (key !== undefined && value !== undefined) {
        state.modifications[key] = value;
      } else {
        console.warn('Attempted to update modifications with undefined key or value', key, value);
      }
    },
    removeModifications(state, key) {
      delete state.modifications[key];
    },
    setTemplateId(state, templateId) {
      state.templateId = templateId;
    },
    setInitialModifications(state, modifications) {
      state.initialModifications = { ...modifications };
    },
    resetModifications(state) {
      state.modifications = { ...state.initialModifications };
    },
    resetState(state) {
      state.templateId = null;
      state.modifications = {};
      state.initialModifications = {};
    },
    setPollingInterval(state, interval) {
      state.pollingInterval = interval;
    },
  },
  actions: {
    updateWooCommerceConnectionStatus({ commit }, status) {
      commit('setWooCommerceConnectionStatus', status);
    },
    updateSallaConnectionStatus({ commit }, status) {
      commit('setSallaConnectionStatus', status);
    },
    updateZidConnectionStatus({ commit }, status) {
      commit('updateZidConnectionStatus', status);
    },
    updateModificationsAction({ commit }, modifications) {
      commit('updateModifications', modifications);
    },
    resetVideoEditingSession({ commit }) {
      commit('resetState');
    },
    startPolling({ dispatch }) {
      const pollingInterval = setInterval(() => {
        dispatch('fetchNotifications');
      }, 2000); // Poll every 2 seconds
      dispatch('setPollingInterval', pollingInterval);
    },
    stopPolling({ state }) {
      clearInterval(state.pollingInterval);
    },
    setPollingInterval({ commit }, interval) {
      commit('setPollingInterval', interval);
    },
  }
});
