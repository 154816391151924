<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="div">
    <div class="div-2">
      <div class="div-6">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7878cc7c0690e87b2ed236dda80cf6fd1a02c35a62656820c2d9761a883b5ffb?apiKey=07d45e0534b04f87a2eab3c2eaf50ac2&"
          class="img-5"
        />
        <div class="div-7">
          <div class="div-8">تحويل المنتجات إلى <span class="highlight">فيديوهات</span> ترويجية بنقرة واحدة</div>
          <div class="div-9">أدخل الرابط إلى متجرك الإلكتروني</div>
          <div class="div-10">
            <form @submit.prevent="submitForm">
              <div class="div-11">
                <input type="text" v-model="formInput" placeholder="https://www.test.com/" class="div-12" :disabled="isValidating">
                <button type="submit" :class="{'div-15': true, 'validating': isValidating, 'error': hasError}">التالي</button>
              </div>
            </form>
            <div v-if="validationMessage" class="validation-message">{{ validationMessage }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


  
<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { API_URL } from '@/config';

export default {
  name: 'SubmitForm',
  setup() {
    const router = useRouter();
    const formInput = ref('');
    const isValidating = ref(false);
    const hasError = ref(false);
    const validationMessage = ref('');

    const submitForm = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        validationMessage.value = 'Please log in to submit.';
        hasError.value = true;
        return;
      }

      isValidating.value = true;
      validationMessage.value = 'Validating your URL...';

      try {
        const response = await fetch(`${API_URL}/process_videos`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ product_url: formInput.value })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        setTimeout(() => {
          isValidating.value = false;
          router.push('/dashboard');
        }, 2000); // Shortened delay for better UX

      } catch (error) {
        isValidating.value = false;
        hasError.value = true;
        validationMessage.value = 'We are accepting only e-commerce stores. Please check your URL and try again.';
      }
    };

    onMounted(() => {
      formInput.value = '';
    });

    return { formInput, isValidating, hasError, validationMessage, submitForm };
  }
};
</script>


  
  
  
<style scoped>
.div {
  background-color: var(--color-whites-White, #fff);
  display: flex;
  padding-bottom: 80px;
  flex-direction: column;
}

.div-2 {
  border-radius: 498px 0px 0px 498px;
  background: linear-gradient(179deg, rgba(186, 186, 186, 0.06) 1.2%, rgba(0, 152, 253, 0.04) 37.24%);
  display: flex;
  width: 100%;
  padding-bottom: 45px;
  flex-direction: column;
}



.div-6 {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-self: center;
  position: relative;
  display: flex;
  min-height: 526px;
  margin-top: 254px;
  width: 100%;
  max-width: 1206px;
  align-items: center;
  font-weight: 400;
  padding: 7px 60px 80px;
}



.img-5 {
  position: absolute;
  inset: 0;
  height: 87%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.div-7 {
  position: relative;
  display: flex;
  margin-bottom: 58px;
  width: 831px;
  max-width: 100%;
  flex-direction: column;
}



.div-8 {
  color: #303030;
  text-align: center;
  text-transform: capitalize;
  font: 500 52px/69px Tajawal, sans-serif;
}

.highlight {
  background-color: #0098fd; /* Adjust the color to match your image */
  color: #ffffff; /* White color for the text */
  padding: 0 10px; /* Add padding to create space around the text */
  border-radius: 10px; /* Optional: adds rounded corners to the background */
}


.div-9 {
  color: #4e4e4e;
  text-align: center;
  text-transform: capitalize;
  align-self: center;
  margin-top: 29px;
  font: 25px/160% Tajawal, sans-serif;
}


.div-10 {
  display: flex;
  gap: 20px;
  white-space: nowrap;
  justify-content: space-between;
  margin: 53px 28px 0;
}



.div-11 {
  display: inline-block;
  flex-direction: column;
  font-size: 16px;
  color: rgba(62, 64, 73, 0.5);
  line-height: 36px;
  justify-content: center;
}



.div-12 {
  border-radius: 72px;
  width: 325%;
  box-shadow: 2px 3px 69px 0px rgba(149, 149, 149, 0.11);
  background-color: var(--color-whites-White, #fff);
  display: inline;
  gap: 20px;
  justify-content: space-between;
  padding: 25px 50px;
}



.img-6 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
  margin: -80px 0px 50px 400px;
  display: inline;
  left: 80%;
  position: relative;
}

.div-15 {
  display: block;
  color: #fff;
  font-family: sans-serif;
  justify-content: center;
  border-radius: 72px;
  background-color: var(--1, #0098fd);
  padding: 28px 38px 29px;
  width: 10rem;
  margin: 20px auto 0;
  position: relative;
  left: 135%;
  transform: translateX(-50%);
}



.validating {
  background-color: #f0ad4e; /* Yellow color for validating */
  cursor: not-allowed;
}

.error {
  background-color: #d9534f; /* Red color for error */
  animation: shake 0.5s;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.validation-message {
  margin-top: 10px;
  color: #d9534f;
}
</style>

  