<template>
    <div class="modal-overlay" @click.self="closeModal">
      <div class="modal-container">
        <div class="modal-content">
          <div class="modal-header">
            <h2>{{ isEditMode ? 'تعديل المنتج' : 'إضافة منتج' }}</h2>
            <button class="close-btn" @click="closeModal">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <form @submit.prevent="handleSubmit" enctype="multipart/form-data">
  <div class="form-group">
    <label for="name">اسم المنتج</label>
    <input
      v-model="productForm.name"
      type="text"
      id="name"
      placeholder="أدخل اسم المنتج"
      required
    />
  </div>
  <div class="form-group">
    <label for="price">السعر</label>
    <input
      v-model="productForm.price"
      type="text"
      id="price"
      placeholder="أدخل السعر"
      required
    />
  </div>
  <div class="form-group">
    <label for="image">صورة المنتج</label>
    <img v-if="isEditMode && product.image_url" :src="product.image_url" alt="Current Product Image" class="current-image">
    <input
      type="file"
      id="image"
      @change="handleFileUpload"
      accept="image/*"
    />
  </div>
  <div class="form-group">
  <label for="url">رابط الموقع</label>
  <input
    v-model="productForm.url"
    type="text"
    id="url"
    placeholder="أدخل رابط المنتج"
    @input="validateUrl"
  />
  <span v-if="urlError" class="error-message">{{ urlError }}</span>
</div>
  <div class="modal-actions">
    <button type="submit" class="btn-submit">
      {{ isEditMode ? 'تحديث' : 'إضافة' }}
    </button>
    <button class="btn-cancel" @click="closeModal">إلغاء</button>
  </div>
</form>

        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
   props: {
  product: {
    type: Object,
    default: () => ({}) // Ensure an empty object as the default value
  },
  isEditMode: {
    type: Boolean,
    default: false
  }
},
data() {
  return {
    productForm: {
      id: this.product ? this.product.id : '',
      name: this.product ? this.product.name : '',
      price: this.product ? this.product.price : '',
      url: this.product ? this.product.url : '',
      external_id: this.product ? this.product.external_id : '' // Add this line
    },
    selectedFile: null
  };
},
    methods: {
      validateUrl() {
    if (this.productForm.url && !this.isValidUrl(this.productForm.url)) {
      this.urlError = 'الرجاء إدخال رابط صحيح';
    } else {
      this.urlError = '';
    }
  },

      isValidUrl(url) {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  },

      closeModal() {
        this.$emit('close');
      },
      handleSubmit() {
  if (this.productForm.url && !this.isValidUrl(this.productForm.url)) {
    alert('الرجاء إدخال رابط صحيح للموقع');
    return;
  }

  const formData = new FormData();

  if (this.productForm.name !== (this.product && this.product.name)) {
    formData.append('name', this.productForm.name);
  }
  if (this.productForm.price !== (this.product && this.product.price)) {
    formData.append('price', this.productForm.price);
  }
  if (this.productForm.url !== (this.product && this.product.url)) {
    formData.append('url', this.productForm.url);
  }
  if (this.productForm.external_id) { // Handle external_id
    formData.append('external_id', this.productForm.external_id);
  }
  
  if (this.selectedFile) {
    formData.append('image', this.selectedFile);
  } else if (this.isEditMode && !this.selectedFile && this.product.image_url) {
    formData.append('image_url', this.product.image_url);
  }

  if (this.isEditMode && this.product.id) {
    formData.append('id', this.product.id);
  } else if (this.isEditMode) {
    console.error("Product ID is missing");
    return;
  }

  this.$emit('submit-product', formData, this.isEditMode);
  this.closeModal();
},


      handleFileUpload(event) {
        this.selectedFile = event.target.files[0];
      }
    }
  };
  </script>


<style scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-container {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s;
  width: 28%;
}

.modal-content {
  padding: 20px;
  max-width: 500px;
  width: 100%;
  direction: rtl; /* Add this line for RTL layout */
  text-align: right; /* Add this line to align text to the right */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}

.close-btn {
  background-color: transparent;
  border: none;
  color: #666666;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
}

.close-btn:hover {
  color: #333333;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 8px;
}

.form-group input[type="text"],
.form-group input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333333;
  text-align: right; /* Add this line to align text to the right */
}

.form-group input[type="file"] {
  padding: 8px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.current-image {
  width: 120px; /* Fixed width */
  height: 120px; /* Fixed height to maintain aspect ratio */
  object-fit: cover; /* Ensures the image covers the area without distorting */
  border-radius: 8px; /* Rounded corners */
  border: 1px solid #ccc; /* Subtle border around the image */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Soft shadow for depth */
  margin-top: 10px; /* Space above the image */
  margin-bottom: 20px; /* Space below the image */
}

.btn-submit,
.btn-cancel {
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.btn-submit {
  background-color: #4caf50;
  color: #ffffff;
}

.btn-submit:hover {
  background-color: #45a049;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.btn-cancel {
  background-color: #f44336;
  color: #ffffff;
}

.btn-cancel:hover {
  background-color: #d32f2f;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
    padding: 0;
    width: 100%;
    background-color: white;
  }

  .content-header {
    padding: 20px;
    margin: 0;
    box-shadow: none;
  }

  .calendar-navigation {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }

  .weekdays {
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
  }

  .weekday {
    margin: 5px;
    width: auto;
    padding: 5px 10px;
  }

  .day-number {
    font-size: 16px;
    padding: 6px 10px;
    margin-bottom: 4px;
  }

  .day-name {
    font-size: 12px;
  }

  .time-schedule-container {
    gap: 30px;
    margin-top: 10px;
  }

  .time-slot {
    height: 60px;
  }

  .calendar-container {
    padding-left: 0px;
    margin-left: 29px;
    margin-top: -45px;
  }

  .schedule-calendar {
    padding: 8px 10px;
    font-size: 12px;
    margin-top: 39px;
    margin-left: 20px;
  }

  .calendar-title {
    font-size: 14px;
  }

  .calendar-dates,
  .calendar-time {
    font-size: 12px;
  }

  .modal {
    width: 90%;
    max-width: 100%;
  }

  .modal-overlay {
    padding: 10px;
    overflow-y: auto; /* Make the modal scrollable if content is too large */
  }

  .modal-container {
    width: 80%;
  }

  .add-post-button {
    background-color: #2e86c1;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 20px;
    font-weight: 500;
    font-size: 16px;
    margin: 10px;
    width: 80%;
    text-align: center;
    display: block;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .add-post-button:hover {
    background-color: #2563eb;
    transform: scale(1.05);
  }

  .nav-button {
    background: none;
    border: none;
    cursor: pointer;
    margin: 10px;
    transition: transform 0.3s ease;
  }

  .nav-button img {
    width: 24px;
    height: 24px;
  }

  .nav-button:hover {
    transform: scale(1.2);
  }
}


</style>