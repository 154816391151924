<template>
  <div class="text-edit-container" dir="rtl">
    <div class="text-edit-header">
      <h3>تعديل النص</h3>
    </div>
    <div class="text-edit-body">
      <div v-for="(text, index) in textElements" :key="index" class="text-item">
        <div class="text-label">
          <span>{{ text.label }}</span>
        </div>
        <input type="text" v-model="text.content" @input="updateText(text.key, text.content)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { API_URL } from '@/config';

export default {
  name: 'TextTemplate',
  props: {
    previewInstance: {
      type: Object,
      required: true
    },
    videoId: {
      type: Number,
      required: true
    },
    numTexts: {
      type: Number,
      required: true
    },
    dynamicElementIds: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      videoDetails: null,
      textElements: []
    };
  },
  computed: {
    ...mapState(['modifications'])
  },
  watch: {
    videoId: {
      handler() {
        this.fetchVideoData();
      },
      immediate: true
    },
    dynamicElementIds: {
      handler() {
        this.initTextElements();
      },
      deep: true,
      immediate: true
    },
    modifications: {
      handler() {
        this.applyTextModifications();
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations({
      updateModifications: 'updateModifications',
      resetModifications: 'resetModifications'
    }),
    async fetchVideoData() {
      const token = localStorage.getItem('token');
      const requestUrl = `${API_URL}/api/videos/${this.videoId}`;
      if (!token) {
        console.error('No token available. User must be logged in to fetch video details.');
        return;
      }
      try {
        const response = await fetch(requestUrl, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        this.videoDetails = data;
        this.$store.commit('setVideoDetails', data); // Commit videoDetails to the store
      } catch (error) {
        console.error('Error fetching video data:', error);
      }
    },
    initTextElements() {
      if (!this.dynamicElementIds) {
        console.warn('No dynamic element IDs found.');
        return;
      }

      const dynamicElementIds = this.dynamicElementIds.elements;
      const predefinedTextKeys = ['product_name_key', 'product_price_key', 'website_key'];
      const predefinedTextLabels = ['اسم المنتج', 'السعر', 'الموقع الإلكتروني'];

      this.textElements = predefinedTextKeys.map((key, index) => {
        const elementId = dynamicElementIds[key];
        return {
          key: elementId,
          label: predefinedTextLabels[index],
          content: this.modifications[elementId] || ''
        };
      }).filter(element => element.key);

      for (let i = 1; i <= this.numTexts; i++) {
        const key = `text_${i}_key`;
        const elementId = dynamicElementIds[key];
        if (elementId) {
          this.textElements.push({
            key: elementId,
            label: `النص الإضافي ${i}`,
            content: this.modifications[elementId] || ''
          });
        }
      }
    },
    updateText(key, content) {
      this.updateModifications({ key, value: content });
      this.applyTextModifications();
    },
    applyTextModifications() {
      if (this.previewInstance) {
        const modifications = { ...this.modifications };
        this.textElements.forEach(textElement => {
          modifications[textElement.key] = textElement.content;
        });
        this.previewInstance.setModifications(modifications);
        this.saveModificationsToDB(modifications); // Save modifications to the database
      } else {
        console.warn('Preview instance is not initialized');
      }
    },
    async saveModificationsToDB(modifications) {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to save modifications.');
        return;
      }
      try {
        const response = await fetch(`${API_URL}/api/videos/${this.videoId}/modifications`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ modifications })
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Modifications saved to DB successfully:', data);
      } catch (error) {
        console.error('Error saving modifications to DB:', error);
      }
    }
  }
};
</script>










  
  
<style scoped>
.text-edit-container {
  background: #ffffff; /* White background for contrast */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  padding: 25px; /* Ample padding for internal spacing */
  margin: 20px; /* Margin to separate from other content */
  max-width: 600px; /* Max width for better control */
  margin-left: auto;
  margin-right: auto; /* Centering the container */
}

.text-edit-header {
  font-size: 19px;
  color: #2c3e50; /* A deep, professional shade of blue-gray */
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 4px solid #bdc3c7; /* Solid line for a clear division */
  text-transform: uppercase; /* Professional appearance with uppercase */
  letter-spacing: 1.2px; /* Slightly increased spacing for sophistication */
}

.text-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px; /* Increased space between items */
}

.text-label {
  font-size: 18px;
  color: #34495e;
  font-weight: 600; /* Increased weight for emphasis */
  margin-bottom: 10px; /* More space between label and input */
}

input[type="text"] {
  width: 100%;
  padding: 12px 18px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  background-color: #ecf0f1;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Inner shadow for a subtle inset effect */
  transition: all 0.3s ease; /* Smooth transition for interactive states */
}

input[type="text"]:hover,
input[type="text"]:focus {
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.4); /* Brighter shadow on focus or hover */
  background-color: #ffffff; /* Change background to white on focus for contrast */
  outline: none;
}

@media (max-width: 768px) {
  .text-edit-header {
    font-size: 20px; /* Slightly larger on small screens */
  }

  .text-edit-container {
    padding: 20px; /* Slightly less padding on smaller devices */
  }

  input[type="text"] {
    font-size: 15px; /* Adjust font size for smaller devices */
  }
}
</style>
  
  