<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div dir="rtl" class="salla-flex salla-flex-col salla-min-h-screen salla-bg-white">
    <!-- Header -->
    <header class="salla-sticky salla-top-0 salla-z-50 salla-bg-white salla-shadow-sm">
      <div class="salla-container salla-mx-auto salla-px-4 salla-py-4">
        <div class="salla-flex salla-items-center salla-justify-between">
          <!-- Logo Section -->
          <div class="salla-flex salla-items-center salla-gap-2">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/076ad4d43ff739e5b7165868b496c5cce1c42209ae846a2e33f5034beb9bd8ef?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
                 alt="Logo" 
                 class="salla-h-8 salla-w-auto" />
          </div>

          <!-- Action Button -->
          <div class="salla-flex salla-items-center">
            <router-link 
              to="/dashboard" 
              class="salla-inline-flex salla-items-center salla-gap-2 salla-bg-primary-600 salla-text-white salla-px-6 salla-py-2.5 salla-rounded-full hover:salla-bg-primary-700 salla-transition-all salla-duration-200 salla-ease-in-out salla-shadow-md hover:salla-shadow-lg salla-transform hover:salla-scale-105 salla-font-medium"
            >
              <span>لنبدأ</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="salla-h-5 salla-w-5 salla-transform salla-rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </header>

    <!-- Main Content -->
    <main class="salla-flex-1">
      <!-- Hero Section -->
      <section class="salla-relative salla-py-20 salla-overflow-hidden">
        <img 
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/4f64db5236baf9384e8f226f9d808751a0f4cc8d7f906dfa9e96985f12fde6cc?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
          alt="Hero Background" 
          class="salla-absolute salla-inset-0 salla-w-full salla-h-full salla-object-cover" />
        <div class="salla-relative salla-container salla-mx-auto salla-px-4">
          <h1 class="salla-text-5xl salla-font-bold salla-text-center salla-text-gray-900">
            الرؤية والرسالة
          </h1>
        </div>
      </section>

      <!-- Mission Section -->
      <section class="salla-container salla-mx-auto salla-px-4 salla-mt-14">
        <div class="salla-bg-gradient-to-b salla-from-gray-50 salla-to-primary-50/20 salla-rounded-l-[300px] salla-max-w-2xl salla-py-16 salla-px-14">
          <img 
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ab901140ad26920e31a4de901bdf5b9252e24fb40106540f8f2ccd75c7657078?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
            alt="Mission" 
            class="salla-max-w-lg salla-w-full salla-mx-auto" />
        </div>
      </section>

      <!-- Mission Details -->
      <section class="salla-container salla-mx-auto salla-px-4 salla-py-10">
        <div class="salla-flex salla-flex-col lg:salla-flex-row salla-gap-8 salla-items-center">
          <div class="salla-w-full lg:salla-w-1/2 salla-text-right">
            <h2 class="salla-text-4xl salla-font-medium salla-text-gray-900 salla-mb-8">رسالتنا</h2>
            <p class="salla-text-xl salla-leading-relaxed salla-text-gray-800">
              نلتزم في "إبداعي" بتقديم حلول فيديو ترويجية مبتكرة ومتاحة تساعد العلامات التجارية على تعزيز وجودها الرقمي وتحقيق أهدافها التسويكية بكفاءة. نحن نسعى لبناء شراكات طويلة الأمد مع عملائنا عبر تقديم خدمات تتميز بالجودة، الابتكار، والدعم المستمر.
            </p>
          </div>
          <div class="salla-w-full lg:salla-w-1/2">
            <div class="salla-bg-gradient-to-b salla-from-gray-50 salla-to-primary-50/20 salla-rounded-l-[300px] salla-p-16">
              <img 
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6accda49aad69efea17f8d69b6914cf2ea8fd36e42e808feeee83c09b67e15dc?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
                alt="Mission Details" 
                class="salla-max-w-lg salla-w-full salla-mx-auto" />
            </div>
          </div>
        </div>
      </section>

      <!-- Vision Section -->
      <section class="salla-container salla-mx-auto salla-px-4 salla-py-10">
        <div class="salla-bg-gradient-to-b salla-from-gray-50 salla-to-primary-50/20 salla-rounded-l-[300px] salla-max-w-2xl salla-py-20 salla-px-14">
          <img 
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe5828cac8c1c8d1f0498dc15f0e05dd6a01e5f7a12c56bc2bf66e6885b4d28e?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
            alt="Vision" 
            class="salla-max-w-lg salla-w-full salla-mx-auto" />
        </div>
      </section>
    </main>
  </div>
</template>