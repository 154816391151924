<template>
  <div class="main-container">
    <div class="video-preview-page">
      <div v-if="!selectedDesign" class="selection-area">
        <h1>الرجاء اختيار تصميم للمتابعة.</h1>
        <ul class="design-list">
          <li v-for="design in designs" :key="design.id" @click="selectDesign(design.id)">
            <div class="design-item">
              <video 
                v-if="design.preview_url" 
                :src="design.preview_url" 
                controls 
                muted 
                :data-design-id="design.id"
                @play="onVideoPlay(design.id)"
              ></video>
              <span>{{ design.name }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div v-else-if="selectedProduct && selectedDesign" class="video-preview-container">
        <div class="action-buttons">
          <button @click="goBackToDesignSelection">اختيار تصميم آخر</button>
          <button @click="goToProductSelection">اختيار منتج آخر</button>
        </div>
        <h1 v-if="!showPreviewMessage">جاهز لمعاينة الفيديو الخاص بك</h1>
        <h1 v-else>
          {{ previewMessages[currentMessageIndex] }}
          <span class="animated-dots">{{ dots }}</span>
        </h1>
        <div ref="previewContainer" class="video-container">
          <div v-if="isLoading" class="progress-bar-container">
            <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
          </div>
          <div v-else class="video-placeholder">معاينة الفيديو هنا</div>
        </div>
        <button @click="renderVideo" :disabled="isRendering" dir="rtl">
          {{ isRendering ? 'جاري انشاء الفيديو...' : `انشاء الفيديو/ ${requiredCredits} كريديت` }}
        </button>
        <button @click="openEditPopup" dir="rtl">تعديل</button>
      </div>
      <div class="edit-popup" v-if="showEditPopup">
        <div class="edit-popup-content">
          <EditPreviewMain
            ref="editPreviewMain"
            :product="selectedProduct"
            :template="selectedDesign"
            :initialModifications="videoModifications"
            :dynamicElementIds="dynamicElementIds"
            @close="closeEditPopup"
            @update-video-preview="updateVideoPreview"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { Preview } from '@creatomate/preview';
import { API_URL } from '@/config';
import { fetchProducts, fetchDesigns } from './VideosMain.vue';
import EditPreviewMain from './EditPreviewMain.vue';

export default {
  name: 'VideoPreviewPage',
  components: {
    EditPreviewMain,
  },
  props: {
    productId: [String, Number],
    designId: [String, Number],
  },
  data() {
    return {
      selectedProduct: null,
      selectedDesign: null,
      designs: [],
      products: [],
      dynamicElementIds: {},
      previewInstance: null,
      firstSelection: null,
      isRendering: false,
      requiredCredits: 0,
      isLoading: false,
      progress: 0,
      previewMessages: [
        'نحن نعد الفيديو الخاص بك',
        'نضيف بعض السحر',
        'فيديوك سيكون جاهزًا للمشاركة قريبًا',
        'نأمل أن يعجبك',
      ],
      currentMessageIndex: 0,
      videoModifications: null,
      showPreviewMessage: false,
      dots: '',
      showEditPopup: false,
      storedProductId: null,
      storedDesignId: null,
    };
  },
  async mounted() {
  console.log('VideoPreviewPage: mounted hook started');
  this.storedProductId = localStorage.getItem('selectedProductId');
  this.storedDesignId = localStorage.getItem('selectedDesignId');
  console.log(`VideoPreviewPage: Retrieved from localStorage - productId: ${this.storedProductId}, designId: ${this.storedDesignId}`);
  
  await this.fetchRequiredCredits();
  
  if (this.storedProductId && this.storedDesignId) {
    console.log('VideoPreviewPage: Both product and design IDs found, loading stored selections');
    await this.loadStoredSelections();
  } else if (this.storedProductId) {
    console.log('VideoPreviewPage: Only product ID found, fetching product and designs');
    this.firstSelection = 'product';
    await this.fetchProduct();
    await this.fetchDesignsAndDisplay();
  } else if (this.storedDesignId) {
    console.log('VideoPreviewPage: Only design ID found, fetching designs and products');
    this.firstSelection = 'design';
    await this.fetchDesignsAndDisplay();
    await this.fetchProductsAndDisplay();
  } else {
    console.error('VideoPreviewPage: No stored selections found');
  }

  if (this.designs.length === 0) {
    console.log('VideoPreviewPage: No designs loaded, fetching designs');
    await this.fetchDesignsAndDisplay();
  }
  console.log('VideoPreviewPage: mounted hook completed');
},
  watch: {
  designs: {
    immediate: true,
    handler(newDesigns) {
      this.$nextTick(() => {
        // Clear old refs
        this.videoRefs = {};
        // Set new refs
        newDesigns.forEach(design => {
          const el = this.$refs[`video_${design.id}`];
          if (el && el[0]) {
            this.videoRefs[design.id] = el[0];
          }
        });
      });
    }
  }
},
  methods: {

  goToProductSelection() {
    console.log('VideoPreviewPage: Redirecting to product selection');
    localStorage.removeItem('selectedProductId');
    this.$router.push('/products');
  },

    async loadStoredSelections() {
  console.log('VideoPreviewPage: loadStoredSelections started');
  await this.fetchProduct();
  await this.fetchDesignsAndDisplay();
  this.selectedProduct = this.products.find(p => p.id === parseInt(this.storedProductId));
  this.selectedDesign = this.designs.find(d => d.id === parseInt(this.storedDesignId));
  console.log(`VideoPreviewPage: Selected product: ${JSON.stringify(this.selectedProduct)}`);
  console.log(`VideoPreviewPage: Selected design: ${JSON.stringify(this.selectedDesign)}`);
  if (this.selectedProduct && this.selectedDesign) {
    console.log('VideoPreviewPage: Both product and design selected, initializing preview');
    this.initializePreview();
  } else {
    console.log('VideoPreviewPage: Missing product or design selection');
  }
  console.log('VideoPreviewPage: loadStoredSelections completed');
},

    openEditPopup() {
  this.showEditPopup = true;
  this.$nextTick(() => {
    if (this.$refs.editPreviewMain) {
      this.$refs.editPreviewMain.$emit('update-modifications', (modifications) => {
        this.videoModifications = modifications;
      });
    } else {
      console.error('editPreviewMain ref is not available');
    }
  });
},
    closeEditPopup() {
      this.showEditPopup = false;
    },
    async goBackToDesignSelection() {
  console.log('VideoPreviewPage: goBackToDesignSelection started');
  localStorage.removeItem('selectedDesignId');
  this.selectedDesign = null;
  this.firstSelection = 'product'; // Force it to show designs
  console.log('VideoPreviewPage: Cleared selectedDesignId from localStorage and reset selectedDesign');
  await this.fetchDesignsAndDisplay();
  // Don't reassign the selected design here
  console.log('VideoPreviewPage: goBackToDesignSelection completed');
},
    cyclePreviewMessages() {
      console.log('Starting message cycling...');
      this.showPreviewMessage = true;
      this.currentMessageIndex = 0;
      const messageInterval = setInterval(() => {
        if (this.currentMessageIndex >= this.previewMessages.length - 1) {
          clearInterval(messageInterval);
          this.showPreviewMessage = false;
          console.log('Message cycling completed.');
        } else {
          this.currentMessageIndex += 1;
          console.log('Current message index:', this.currentMessageIndex);
        }
      }, 1000); // Change message every 1 second

      const dotsInterval = setInterval(() => {
        if (!this.showPreviewMessage) {
          clearInterval(dotsInterval);
        } else {
          this.dots = this.dots.length < 3 ? this.dots + '.' : '';
        }
      }, 200); // Update dots every 0.2 second
    },
    async fetchRequiredCredits() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to view required credits.');
        return;
      }
      try {
        const response = await fetch(`${API_URL}/api/required-credits`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data.length > 0) {
            this.requiredCredits = data[0].requested_credits;
          }
        } else {
          console.error('Failed to fetch required credits:', response.status, await response.text());
        }
      } catch (error) {
        console.error('Error fetching required credits:', error);
      }
    },
    async renderVideo() {
  try {
    console.log('Starting video rendering...');
    this.isRendering = true;

    const templateId = this.selectedDesign.creatomate_template_id;
    const modifications = this.videoModifications;

    const response = await fetch(`${API_URL}/api/render-video`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({ templateId, modifications }),
    });

        if (response.ok) {
          const data = await response.json();
          console.log('Video rendered successfully:', data);
          this.$router.push('/dashboard');
        } else {
          const errorData = await response.json();
          if (response.status === 403) {
            window.location.href = errorData.redirect;
          } else {
            console.error('Failed to render video:', response.status, errorData);
          }
        }
      } catch (error) {
        console.error('Error rendering video:', error);
      } finally {
        this.isRendering = false;
        console.log('Video rendering completed.');
      }
    },
    async fetchProduct() {
  try {
    const products = await fetchProducts();
    this.products = products;
    const selectedProduct = products.find(p => p.id === parseInt(this.storedProductId));
    if (selectedProduct) {
      this.selectedProduct = selectedProduct;
    } else {
      console.error('Product not found in the fetched products array');
    }
  } catch (error) {
    console.error('Error fetching product:', error);
  }
},
    async fetchDesignsAndDisplay() {
      try {
        console.log('Fetching designs...');
        const designs = await fetchDesigns();
        this.designs = designs;

        if (designs && designs.length > 0) {
          console.log('Designs fetched successfully:', designs);
          
        } else {
          console.error('No designs found or error in fetching');
        }
      } catch (error) {
        console.error('Error fetching designs:', error);
      }
    },
    async fetchProductsAndDisplay() {
      try {
        const products = await fetchProducts();
        this.products = products;
        console.log('Products fetched:', products);
        if (this.firstSelection === 'design' && !this.selectedProduct) {
          console.log('Design selected first, products are now available for selection');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },
    selectProduct(productId) {
  console.log(`VideoPreviewPage: selectProduct called with productId: ${productId}`);
  const selectedProduct = this.products.find(p => p.id === parseInt(productId));
  if (selectedProduct) {
    this.selectedProduct = selectedProduct;
    localStorage.setItem('selectedProductId', productId);
    console.log(`VideoPreviewPage: Selected product: ${JSON.stringify(selectedProduct)}`);
    if (this.selectedDesign) {
      console.log('VideoPreviewPage: Design already selected, initializing preview');
      this.initializePreview();
    } else {
      console.log('VideoPreviewPage: No design selected, fetching designs');
      this.firstSelection = 'product';
      this.fetchDesignsAndDisplay();
    }
  } else {
    console.error('VideoPreviewPage: Product not found in the fetched products array');
  }
},
    stopAllVideos(exceptVideo) {
    this.$refs.videoElement.forEach(video => {
      if (video !== exceptVideo) {
        video.pause();
        video.currentTime = 0; // Reset to start
      }
    });
  },
  
  handleVideoPlay(event) {
      if (event.target.tagName === 'VIDEO') {
        const playingVideoId = event.target.dataset.designId;
        const videos = document.querySelectorAll('.design-list video');
        
        videos.forEach(video => {
          if (video.dataset.designId !== playingVideoId) {
            video.pause();
            video.currentTime = 0;
          }
        });
      }
    },

  attachVideoEventListeners() {
    this.$nextTick(() => {
      this.$refs.videoElement.forEach(video => {
        video.removeEventListener('play', this.handleVideoPlay); // Ensure no duplicate listeners
        video.addEventListener('play', this.handleVideoPlay);
      });
    });
  },
  onVideoPlay(playingDesignId) {
    console.log('Video playing:', playingDesignId);
    const videos = document.querySelectorAll('.design-list video');
    videos.forEach(video => {
      if (video.dataset.designId !== playingDesignId.toString()) {
        video.pause();
      }
    });
  },

  selectDesign(designId) {
  console.log(`VideoPreviewPage: selectDesign called with designId: ${designId}`);
  const selectedDesign = this.designs.find(d => d.id === parseInt(designId));
  if (selectedDesign) {
    this.selectedDesign = selectedDesign;
    localStorage.setItem('selectedDesignId', designId);
    console.log(`VideoPreviewPage: Selected design: ${JSON.stringify(selectedDesign)}`);
    if (this.selectedProduct) {
      console.log('VideoPreviewPage: Product already selected, initializing preview');
      this.initializePreview();
    } else {
      console.log('VideoPreviewPage: No product selected, fetching products');
      this.firstSelection = 'design';
      this.fetchProductsAndDisplay();
    }
  } else {
    console.error('VideoPreviewPage: Design not found in the fetched designs array');
  }
},

async updatePreviewWithNewDesign(newDesign) {
  await this.fetchDynamicElementIds();
  const modifications = this.buildModificationsFromProduct(this.selectedProduct, this.dynamicElementIds);
  this.previewInstance.loadTemplate(newDesign.creatomate_template_id);
  this.previewInstance.setModifications(modifications);
  this.videoModifications = modifications;
},

stopAllVideosExcept(playingDesignId) {
  if (this.$refs.videoElement) {
    this.$refs.videoElement.forEach(video => {
      if (video.closest('li').getAttribute('data-design-id') !== playingDesignId.toString()) {
        video.pause();
        video.currentTime = 0;
      }
    });
  }
},
    initializePreview() {
      console.log('Initializing Preview...');
      this.cyclePreviewMessages(); // Start cycling preview messages here

      if (!this.selectedProduct || !this.selectedDesign) {
        console.error('Initialization error: Product or Design not selected');
        console.log('Selected Product:', this.selectedProduct);
        console.log('Selected Design:', this.selectedDesign);
        return;
      }

      console.log('Selected Product and Design are available');

      this.$nextTick(() => {
        console.log('Checking if preview container is available in the DOM...');
        const previewContainer = this.$refs.previewContainer;

        if (previewContainer) {
          console.log('Preview container found in the DOM');
          this.previewInstance = new Preview(previewContainer, 'player', 'public-yqbsoiqlkk8rwzj1pf9n9id6');
          console.log('Preview instance created:', this.previewInstance);
          this.previewInstance.onReady = () => {
            console.log('Preview is ready');
            console.log('Preview methods available:', Object.keys(this.previewInstance));
            this.loadPreview();
          };
        } else {
          console.error('Preview container is not available in the DOM.');
        }
      });
    },
    async loadPreview() {
      try {
        const templateId = this.selectedDesign.creatomate_template_id;
        console.log('Loading template with ID:', templateId);

        await this.fetchDynamicElementIds();
        const modifications = this.buildModificationsFromProduct(this.selectedProduct, this.dynamicElementIds);
        console.log('Applying modifications:', modifications);

        this.previewInstance.loadTemplate(templateId);
        this.previewInstance.setModifications(modifications);
        this.videoModifications = modifications; // Store the initial modifications
        console.log('Preview loaded and modifications set.');
      } catch (error) {
        console.error('Error during preview initialization:', error);
      }
    },
    async fetchDynamicElementIds() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('JWT token is missing');
        return;
      }

      if (!this.selectedDesign || !this.selectedDesign.creatomate_template_id) {
        console.error('The selected design object or its creatomate_template_id is missing');
        return;
      }

      const url = `${API_URL}/api/dynamic-element-ids?template_id=${this.selectedDesign.creatomate_template_id}`;
      try {
        const response = await fetch(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('Dynamic Element IDs response:', response);
        if (!response.ok) {
          throw new Error(`Failed to fetch dynamic element IDs: status ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched Dynamic Element IDs:', data.elements);
        this.dynamicElementIds = data.elements;
        console.log('Dynamic Element IDs fetched:', this.dynamicElementIds);
      } catch (error) {
        console.error('Error fetching dynamic element IDs:', error);
      }
    },
    buildModificationsFromProduct(product, dynamicElementIds) {
      const modifications = {};

      if (!dynamicElementIds || !product) {
        console.error('Missing dynamic element IDs or product details');
        return {};
      }

      const elements = dynamicElementIds.elements || dynamicElementIds;

      console.log('Dynamic element IDs:', dynamicElementIds);
      console.log('Product details:', product);

      Object.entries(elements).forEach(([key, elementId]) => {
        console.log('Processing element:', key, elementId);

        if (key.startsWith('main_image_key') || key.startsWith('secondary_image_key') || key.startsWith('third_image_key') || key.startsWith('fourth_image_key') || key.startsWith('fifth_image_key') || key.startsWith('sixth_image_key') || key.startsWith('seventh_image_key')) {
          modifications[elementId] = product.image_url;
          console.log('Assigned image URL to element:', elementId);
        } else if (key === 'product_name_key') {
          modifications[elementId] = product.name;
          console.log('Assigned product name to element:', elementId);
        } else if (key === 'product_price_key') {
          modifications[elementId] = product.price;
          console.log('Assigned product price to element:', elementId);
        } else if (key.startsWith('text_')) {
          // Assign a default value for text elements
          modifications[elementId] = '';
          console.log('Assigned default value to text element:', elementId);
        } else if (key === 'website_key') {
          modifications[elementId] = product.website_url || '';
          console.log('Assigned website URL to element:', elementId);
        } else if (key === 'audio_key') {
          if (product.audio_url) {
            modifications[elementId] = product.audio_url;
            console.log('Assigned audio URL to element:', elementId);
          }
          // If no audio_url in product, do not assign a value, use template's default
        }
      });

      console.log('Built modifications:', modifications);

      return modifications;
    },
    updateVideoPreview(modifications) {
      this.videoModifications = modifications;

      // Ensure that the modifications are applied correctly
      if (this.previewInstance && this.previewInstance.ready) {
        this.previewInstance.setModifications(modifications);
        console.log('Modifications applied to preview instance:', modifications);
      } else {
        console.error('Preview instance is not ready to apply modifications.');
      }
    },
  },
};
</script>














<style scoped>

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 20px; /* This creates space between the buttons */
  margin-bottom: 30px;
}

.action-buttons button {
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50; /* Green background */
  color: white;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.action-buttons button:hover {
  background-color: #45a049; /* Darker green on hover */
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.action-buttons button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

/* If you want to differentiate the buttons */
.action-buttons button:nth-child(2) {
  background-color: #008CBA; /* Blue background for the second button */
}

.action-buttons button:nth-child(2):hover {
  background-color: #007B9A; /* Darker blue on hover */
}

.edit-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.edit-popup-content {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.animated-dots {
  display: inline-block;
  margin-left: 5px;
}

.main-container {
  display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    margin-bottom: 0px;
    top: 15px;
    border-radius: 20px;
    position: relative;
    background-color: #ffffff;
  direction: rtl; /* Ensure RTL layout */
}

.video-preview-page {
  width: 100%;
  max-width: 960px; /* Optimal width for readability and layout */
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
}

.selection-area {
  text-align: center;
  margin-bottom: 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

ul {
  list-style: none;
  padding: 0;
  margin: 20px auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
}

li {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

li:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.2);
}

.design-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
}

.design-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.design-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.design-item video {
  width: 100%;
  height: 150px; /* Adjust the height as needed */
  object-fit: cover;
}

.design-item span {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.product-image {
  width: 100px; /* or any size that fits your design */
  height: auto;
  border-radius: 4px;
  margin-right: 10px; /* Adjust spacing for RTL */
}

.video-preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-container {
  width: 100%;
  height: 50vh;
  background-color: #000;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
}

button {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 20px;
}

button:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
}
</style>

