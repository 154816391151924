<template>
    <div class="products-main">
      <div class="header">
        <h1>المنتجات</h1>
        <div class="button-container">
          <button class="add-btn" @click="openAddModal">
            <i class="fas fa-plus"></i>
            إضافة منتج جديد
          </button>
          <button class="connect-btn" @click="connectWithPlatform">
            <i class="fas fa-link"></i>
            ربط مع منصتك لاستيراد المنتجات
          </button>
        </div>
      </div>
      <div v-if="products.length" class="product-grid">
        <div
          v-for="product in products"
          :key="product.id"
          class="product-card"
        >
          <div class="product-image">
            <img :src="product.image_url" :alt="product.name" />
          </div>
          <div class="product-info">
            <h3>{{ product.name }}</h3>
            <p class="price">{{ product.price }}</p>
            <p class="website-link">{{ product.url }}</p>
          </div>
          <div class="product-actions">
            <button class="edit-btn" @click="openEditModal(product)">
              <i class="fas fa-edit"></i>
              تعديل
            </button>
            <button class="remove-btn" @click="removeProduct(product.id)">
              <i class="fas fa-trash"></i>
              حذف
            </button>
            <button class="create-video-btn" @click="selectProductForVideo(product)">إنشاء فيديو</button>
          </div>
        </div>
      </div>
      <div v-else class="no-products">
        <p>لم يتم العثور على منتجات.</p>
      </div>
      <add-product-modal
  v-if="showAddProductModal"
  :product="currentProduct"
  :isEditMode="currentProduct !== null" 
  @close="showAddProductModal = false"
  @submit-product="handleProductSubmit"
/>
    </div>
  </template>



  <script>

import { API_URL } from '@/config'; // Ensure this import is correct based on your project structure
import AddProductModal from './AddProductModal.vue';


export default {
  name: 'ProductsMain',
  components: {
    AddProductModal
  },
  data() {
    return {
      products: [],
      showAddProductModal: false,
      currentProduct: null,
      token: localStorage.getItem('token') // Assuming token is stored in localStorage
    };
  },
  methods: {


    selectProductForVideo(product) {
  console.log('ProductsMain: Selecting product for video:', product);
  
  // Clear any existing product and design selections
  localStorage.removeItem('selectedProductId');
  localStorage.removeItem('selectedDesignId');
  
  // Set the new product selection
  localStorage.setItem('selectedProductId', product.id);
  console.log('ProductsMain: Set selectedProductId in localStorage:', product.id);

  // Redirect to the VideoPreviewPage component with the selected product
  this.$router.push({ 
    name: 'Preview', 
    params: { productId: product.id },
    query: { source: 'productPage' }  // Add a query parameter to indicate the source
  });
},


    connectWithPlatform() {
      this.$router.push('/Connect');
    },

    async fetchProducts(query = '') {
      const requestUrl = `${API_URL}/get_products?query=${query}`;
      try {
        const response = await fetch(requestUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${this.token}`,
          },
        });
        if (!response.ok) throw new Error('Failed to fetch products');
        const data = await response.json();
        this.products = data.products;
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },
    openAddModal() {
      this.currentProduct = null;
      this.showAddProductModal = true;
    },
    openEditModal(product) {
      this.currentProduct = { ...product };
      this.showAddProductModal = true;
    },
    handleProductSubmit(formData, isEditMode) {
  if (isEditMode) {
    this.editProduct(formData);
  } else {
    this.addProduct(formData);
  }
},

    async addProduct(formData) {
  const requestUrl = `${API_URL}/add_product`;
  try {
    console.log('FormData:', formData); // Log the FormData object

    const response = await fetch(requestUrl, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.token}`,
      },
      body: formData
    });

    console.log('Response status:', response.status); // Log the response status

    if (!response.ok) throw new Error('Failed to add product');

    const data = await response.json();
    console.log('Response data:', data); // Log the response data

    if (data.product_id) {
      const newProduct = {
        id: data.product_id,
        name: formData.get('name'),
        price: formData.get('price'),
        image_url: formData.get('image_url') || '', // Use the image_url from the FormData if available, otherwise set an empty string
        url: formData.get('url')
      };
      this.products.push(newProduct);
      window.location.reload(); // Reload the page on successful addition
    } else {
      console.error('Invalid response:', data);
    }
  } catch (error) {
    console.error('Error adding product:', error);
  }
},

async editProduct(formData) {
  const requestUrl = `${API_URL}/edit_product/${formData.get('id')}`;
  try {
    const response = await fetch(requestUrl, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${this.token}`,
      },
      body: formData
    });
    if (!response.ok) throw new Error('Failed to edit product');
    const data = await response.json();
    if (data.message === 'Product updated successfully') {
      window.location.reload(); // Reload the page on successful edit
    } else {
      console.error('Invalid response:', data);
    }
  } catch (error) {
    console.error('Error editing product:', error);
  }
},
    async removeProduct(productId) {
      const requestUrl = `${API_URL}/remove_product/${productId}`;
      try {
        const response = await fetch(requestUrl, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${this.token}`,
          },
        });
        if (!response.ok) throw new Error('Failed to remove product');
        await response.json();
        this.products = this.products.filter(p => p.id !== productId);
        
      } catch (error) {
        console.error('Error removing product:', error);
      }
    }
  },
  mounted() {
    this.fetchProducts();
  }
};
</script>



<style scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");

.products-main {
  max-width: 90%;
    width: 100%;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    top: 14px;
    direction: rtl;
    margin-left: 8px;
    text-align: right;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.button-container {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: flex-start; /* Align buttons to the left */
}

.header h1 {
  font-size: 28px;
  font-weight: 600;
  color: #333333;
}

.add-btn, .connect-btn {
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px; /* Space between buttons */
}

.add-btn:hover {
  background-color: #45a049;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.add-btn i {
  margin-left: 8px; /* Adjusted margin for RTL layout */
}

.connect-btn {
  background-color: #2196f3; /* Example: Blue background */
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.connect-btn:hover {
  background-color: #1976d2;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.connect-btn i {
  margin-left: 8px; /* Adjusted margin for RTL layout */
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.product-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s;
}

.product-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.product-image {
  text-align: center;
  margin-bottom: 16px;
  width: 200px;
  height: 200px;
}

.product-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

.product-info {
  flex-grow: 1;
  margin-bottom: 16px;
}

.product-info h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 8px;
}

.price {
  color: #4caf50;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
}

.website-link {
  color: #2196f3;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  transition: color 0.3s;
}

.website-link:hover {
  color: #1976d2;
}

.website-link i {
  margin-right: 4px; /* Adjusted margin for RTL layout */
}

.product-actions {
  display: flex;
  justify-content: flex-start; /* Adjusted for RTL layout */
  gap: 8px;
}

.edit-btn,
.remove-btn {
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.edit-btn {
  background-color: #2196f3;
  color: #ffffff;
}

.edit-btn:hover {
  background-color: #1976d2;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.remove-btn {
  background-color: #f44336;
  color: #ffffff;
}

.remove-btn:hover {
  background-color: #d32f2f;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.edit-btn i,
.remove-btn i {
  margin-left: 4px; /* Adjusted margin for RTL layout */
}

.create-video-btn {
  background-color: #673ab7; /* Deep purple background */
  color: #ffffff; /* White text color */
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.create-video-btn:hover {
  background-color: #5c3c8e;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.no-products {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #666666;
}
@media (max-width: 768px) {
.products-main{
max-width: 102%; 
    width: 114%;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    top: 14px;
    direction: rtl;
    margin-left: 1px;
    text-align: right;  
}

.connect-btn{
  background-color: #2196f3;
    color: #ffffff;
    border: none;
    padding: 0px 0px;
    border-radius: 6px;
    width: 148px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}
}
</style>

