<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<!-- eslint-disable vue/require-v-for-key -->
<template>
  <main class="main-content">
    <section class="content">
      <div class="content-header">
        <!-- Calendar navigation -->
        <nav class="calendar-navigation">
          <!-- Navigation arrows -->
          <button @click="goToPreviousWeek" class="nav-button">
            <img :src="require('@/assets/left.png')" alt="Previous Week">
          </button>
          <!-- Date range title -->
          <span class="date-range">{{ dateRange }}</span>
          <!-- Navigation arrows -->
          <button @click="goToNextWeek" class="nav-button">
            <img :src="require('@/assets/right.png')" alt="Next Week">
          </button>
          <!-- Add new post button -->
          <button @click="addNewCalendar" class="add-post-button">
            Add new Calendar
          </button>
        </nav> 
        <!-- Weekdays -->
    <div class="weekdays">
      <button
        v-for="day in weekDays"
        :key="day.text"
        class="weekday"
        :class="{ 'selected': day.fullDate === selectedDay }"
        @click="selectDay(day)"
      >
        <span class="day-number">{{ day.date }}</span>
        <span class="day-name">{{ day.text }}</span>
      </button>
    </div>

    <!-- Time schedule container -->
    <div class="time-schedule-container">
      <div class="time-slot" v-for="hour in hours" :key="hour">
        <div class="time">{{ hour }}:00</div>
        <div class="minute-line" v-for="minute in [15, 30, 45]" :style="{left: (minute * 100 / 60) + '%'}"></div>
        <div class="calendar-container">
          <div v-for="calendar in getCalendarsForHourAndDay(hour, selectedDay)"
               :key="calendar.id"
               :style="getCalendarPosition(calendar)"
               class="schedule-calendar">
            <div class="calendar-details">
              <div class="calendar-title">{{ calendar.title }}</div>
              <div class="calendar-dates">{{ calendar.start_date }} - {{ calendar.end_date }}</div>
              <div class="calendar-time">{{ calendar.time }}</div>
            </div>
            <button @click="deleteCalendar(calendar.id)">Delete</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Calendar Modal -->
    <div v-if="showModal" class="modal-overlay">
      <div class="modal">
        <h2>Add New Calendar</h2>
        <form @submit.prevent="submitCalendar">
          <label for="calendarTitle">Calendar Title:</label>
          <input type="text" id="calendarTitle" v-model="newCalendar.title">

          <label for="calendarStartDate">Start Date:</label>
          <input type="date" id="calendarStartDate" v-model="newCalendar.start_date">

          <label for="calendarEndDate">End Date:</label>
          <input type="date" id="calendarEndDate" v-model="newCalendar.end_date">

          <label for="calendarTime">Time:</label>
          <input type="time" id="calendarTime" v-model="newCalendar.time">

          <div class="form-actions">
            <button type="submit">Add Calendar</button>
            <button @click="showModal = false">Cancel</button>
          </div>
        </form>
      </div>
    </div>

  </div>
</section>
</main>
</template>
  
  
  


<script>
import { API_URL } from '@/config';
import moment from 'moment';
import AuthService from '@/services/authService';

export default {
  data() {
    return {
      currentDate: new Date(),
      weekDays: [],
      hours: this.generateHoursArray(),
      calendars: [],
      selectedDay: null,
      showModal: false,
      newCalendar: {
        title: '',
        start_date: '',
        end_date: '',
        time: '',
      },
      computedDateRange: '', // Add a new data property
    };
  },
  created() {
    this.computeWeekDays(); // Initialize the weekdays and select the first day
    this.fetchCalendars();
  },
  methods: {
    getCalendarsForHourAndDay(hour, day) {
  const selectedDate = new Date(day);
  const filteredCalendars = this.calendars.filter(calendar => {
    const startDateTime = moment(`${calendar.start_date} ${calendar.time}`, 'YYYY-MM-DD HH:mm');
    const calendarDate = startDateTime.toDate();
    return (
      calendarDate.getHours() === parseInt(hour) &&
      calendarDate.getDate() === selectedDate.getDate() &&
      calendarDate.getMonth() === selectedDate.getMonth() &&
      calendarDate.getFullYear() === selectedDate.getFullYear()
    );
  });
  console.log(`Calendars for hour ${hour} on ${day}: `, filteredCalendars);
  return filteredCalendars;
},

    getCalendarPosition(calendar) {
      const calendarDate = new Date(`${calendar.start_date} ${calendar.time}`);
      const minutes = calendarDate.getMinutes();
      const position = minutes / 60 * 100; // Calculate horizontal position within the time slot
      return {
        left: `${position}%`,
        
      };
    },

    goToPreviousWeek() {
      this.currentDate.setDate(this.currentDate.getDate() - 7);
      this.computeWeekDays();
    },
    goToNextWeek() {
      this.currentDate.setDate(this.currentDate.getDate() + 7);
      this.computeWeekDays();
    },
    addNewCalendar() {
      this.showModal = true; // Open the modal
    },

    async submitCalendar() {
      const token = AuthService.getToken();
      if (token) {
        try {
          const response = await fetch(`${API_URL}/api/calendars`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              title: this.newCalendar.title,
              start_date: this.newCalendar.start_date + ' ' + this.newCalendar.time,
              end_date: this.newCalendar.end_date + ' ' + this.newCalendar.time,
              time: this.newCalendar.time,
              all_day: false, // Set all_day to false for time-specific calendars
            }),
          });
          if (response.ok) {
            this.showModal = false;
            this.newCalendar = {
              title: '',
              start_date: '',
              end_date: '',
              time: '',
            };
            await this.fetchCalendars(); // Refresh the calendars list
          } else {
            console.error('Failed to create calendar');
          }
        } catch (error) {
          console.error('Error creating calendar:', error);
        }
      }
    },

    async deleteCalendar(calendarId) {
      const token = AuthService.getToken();
      if (token) {
        try {
          const response = await fetch(`${API_URL}/api/calendars/${calendarId}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (response.ok) {
            await this.fetchCalendars(); // Refresh the calendars list
          } else {
            console.error('Failed to delete calendar');
          }
        } catch (error) {
          console.error('Error deleting calendar:', error);
        }
      }
    },

    async fetchCalendars() {
  const token = AuthService.getToken();
  if (token) {
    try {
      const response = await fetch(`${API_URL}/api/calendars`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Fetched calendars:', data);
        data.forEach(calendar => {
          console.log('start_date:', calendar.start_date);
          console.log('time:', calendar.time);
        });
        this.calendars = data;
      } else {
        console.error('Failed to fetch calendars');
      }
    } catch (error) {
      console.error('Error fetching calendars:', error);
    }
  }
},

    computeWeekDays() {
      const startOfWeek = this.currentDate.getDate() - this.currentDate.getDay();
      this.weekDays = Array.from({ length: 7 }, (_, index) => {
        const day = new Date(this.currentDate);
        day.setDate(startOfWeek + index);
        return {
          date: day.getDate(),
          fullDate: day.toISOString().substring(0, 10), // YYYY-MM-DD format
          text: day.toLocaleDateString('default', { weekday: 'short' }),
        };
      });
      this.selectedDay = this.weekDays[0].fullDate; // Automatically select the first day
      this.updateDateRange();
    },
    updateDateRange() {
      const startDay = this.weekDays[0].date;
      const endDay = this.weekDays[this.weekDays.length - 1].date;
      const month = this.currentDate.toLocaleString('default', { month: 'long' });
      const year = this.currentDate.getFullYear();
      this.computedDateRange = `${year} ${startDay}-${endDay}, ${month}`; // Update the computed date range
    },
    generateHoursArray() {
      return Array.from({ length: 18 }, (_, index) => (index + 7).toString().padStart(2, '0'));
    },
    selectDay(day) {
      this.selectedDay = day.fullDate;
      console.log("Day selected: ", this.selectedDay);
      this.$forceUpdate();
    },
  },
  computed: {
    dateRange() {
      return this.computedDateRange; // Return the computed value from the data property
    },
  }
}
</script>
  
  
  


  <style scoped>


.calendar-navigation {
  display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #E0E0E0;
    margin-left: 474px;
}

.nav-button {
  background: none;
  border: none;
  cursor: pointer;
}

.nav-button img {
  width: 24px;
  height: 24px;
  filter: brightness(0) invert(0.5);
}

.date-range {
  font-size: 18px;
  color: #333;
  flex-grow: 1;
  text-align: center;
  margin: 0 1rem;
}

.add-post-button {
  background-color: #2E86C1;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 500;
  text-transform: none;
  font-size: 14px;
  margin-left: 50px;
}

.weekdays {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 1rem;
  padding: 0;
}

.weekday {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 14%;
  cursor: pointer;
  background: none;
  padding: 6px;
  margin: 0 2px;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  box-shadow: none;
  margin-left: 15px;
}

.weekday:hover, .weekday.selected {
  background-color: #2E86C1;
  color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.selected {
  background-color: #2E86C1;
  font-weight: bold;
}

.day-number, .day-name {
  color: inherit;
}

.day-number {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  background-color: #D6EAF8;
  padding: 8px 12px;
  border-radius: 50%;
  margin-bottom: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day-name {
  font-size: 14px;
  color: #333;
  text-transform: uppercase;
}

.time-schedule-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 50px;
  margin-top: 15px;
}

.time-slot {
  position: relative;
  padding: 10px 0;
  width: 100%;
  height: 80px;
}

.time-slot::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #E0E0E0;
}

.minute-line {
  position: absolute;
  left: calc(100% / 60);
  top: 0;
  bottom: 0;
  border-left: 1px dashed #ccc;
}

.time {
  color: #333;
  width: 50px;
  text-align: right;
  position: absolute;
  left: 0;
  top: 0;
  padding-right: 1rem;
  z-index: 1;
}

.time-slot::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #E0E0E0;
}

.calendar-container {
  display: flex;
  flex-grow: 1;
  padding-left: 70px;
  margin-left: 50px;
  position: relative;
}

.schedule-calendar {
  position: absolute;
  background-color: #2E86C1;
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calendar-details {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.calendar-title {
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 4px;
}

.calendar-dates,
.calendar-time {
  color: #ffffff;
  font-size: 14px;
}

.schedule-calendar button {
  background-color: transparent;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  margin-left: 10px;
  transition: color 0.3s ease;
}

.schedule-calendar button:hover {
  color: #f2f2f2;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-out;
  transform: scale(1.05);
  animation: scaleIn 0.3s forwards;
}

@keyframes scaleIn {
  from {
    transform: scale(0.7);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

input[type="text"],
input[type="time"] {
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

button[type="submit"] {
  float: right;
}

label {
  display: block;
  margin-top: 20px;
  color: #333;
  font-size: 16px;
}

h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.dashboard {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 15px;
  width: 100%;
  margin-left: -200px;
}

.main-content {
  width: 100%;
  max-width: 95%;
  margin-left: -8px;
  margin-top: -1px;
}

.content {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
}

.content-header {
  border-radius: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  padding: 30px 40px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  height: 300vh;
  margin: 15px;
}

.social-links {
  display: flex;
  gap: 20px;
  font-size: 16px;
  color: #121b25;
  font-weight: 500;
  margin-bottom: 20px;
}

.social-button {
  font-family: Inter, sans-serif;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  background: none;
  color: #6b7280;
  transition: color 0.3s;
}

.social-button:hover {
  color: #374151;
}

.social-button.active {
  color: #2563eb;
  border-bottom: 2px solid #2563eb;
}

.section-title {
  color: #111827;
  letter-spacing: -0.5px;
  font-size: 28px;
  font-weight: 600;
  line-height: 165%;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .main-content {
    margin-left: 15px;
        padding: 0;
  }

  .content-header {
    padding: 20px;
    margin: 0;
    box-shadow: none;
  }

  
  .calendar-navigation {
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 10px;
    width: 100%;
  }

  .nav-button {
    background: none;
    border: none;
    cursor: pointer;
    margin: 10px;
    transition: transform 0.3s ease;
  }

  .nav-button img {
    width: 24px;
    height: 24px;
  }

  .nav-button:hover {
    transform: scale(1.2);
  }


  .weekdays {
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
  }

  .date-range {
    font-size: 16px;
    margin: 10px 0;
    text-align: center;
  }

  .add-post-button {
    background-color: #2e86c1;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 20px;
    font-weight: 500;
    font-size: 16px;
    margin: 10px;
    width: 80%;
    text-align: center;
    display: block;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  .add-post-button:hover {
    background-color: #2563eb;
    transform: scale(1.05);
  }

  .weekday {
    margin: 5px;
    width: auto;
    padding: 5px 10px;
  }

  .day-number {
    font-size: 16px;
    padding: 6px 10px;
    margin-bottom: 4px;
  }

  .day-name {
    font-size: 12px;
  }

  .time-schedule-container {
    gap: 30px;
    margin-top: 10px;
  }

  .time-slot {
    height: 60px;
  }

  .calendar-container {
    padding-left: 0px;
        margin-left: 29px;
        margin-top: -45px;
  }

  .schedule-calendar {
    padding: 8px 10px;
        font-size: 12px;
        margin-top: 39px;
        margin-left: 20px;
    
  }

  .calendar-title {
    font-size: 14px;
  }

  .calendar-dates,
  .calendar-time {
    font-size: 12px;
  }

  .modal {
    width: 90%;
    max-width: 100%;
  }

  .modal-overlay {
    padding: 10px;
  }
}


  </style>