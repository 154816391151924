<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="salla-min-h-screen salla-bg-gradient-to-b salla-from-primary-50/50 salla-to-white">
    <div class="salla-container salla-mx-auto salla-px-4 salla-py-16 md:salla-py-24">
      <!-- Card Container -->
      <div class="salla-max-w-7xl salla-mx-auto salla-bg-white salla-rounded-3xl salla-shadow-2xl salla-overflow-hidden">
        <div class="salla-flex salla-flex-col md:salla-flex-row">
          <!-- Left Column - Form -->
          <div class="salla-w-full md:salla-w-5/12 salla-p-8 md:salla-p-12 salla-space-y-8">
            <!-- Header Section -->
            <div class="salla-text-center">
              <div class="salla-relative">
                <div class="salla-absolute salla-inset-0 salla-flex salla-items-center salla-justify-center">
                  <div class="salla-w-24 salla-h-24 salla-bg-primary-100 salla-rounded-full salla-animate-pulse"></div>
                </div>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f71e6eb12d9eabd08f6c8f796b8ab5e9c2bea6392f77fbb4ba68b721a967cba4?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&"
                  alt="Activation code icon"
                  class="salla-relative salla-mx-auto salla-w-24 salla-h-24 salla-object-contain"
                />
              </div>
              <h2 class="salla-mt-8 salla-text-3xl salla-font-bold salla-text-gray-900">رمز التحقق</h2>
              <p class="salla-mt-4 salla-text-primary-600 salla-text-lg">
                تم إرسال رمز التفعيل المكون من 4 أرقام إلى
                <span class="salla-block salla-mt-2 salla-font-semibold salla-text-primary-700">{{ userEmail }}</span>
              </p>
            </div>

            <!-- Verification Form -->
            <div class="salla-space-y-8">
              <!-- Code Input Section -->
              <div class="salla-space-y-6">
                <div class="salla-flex salla-justify-center salla-gap-4">
                  <template v-for="(digit, index) in 4" :key="index">
                    <div class="salla-group salla-relative">
                      <input
                        v-model="verificationCode[index]"
                        type="text"
                        maxlength="1"
                        :class="[
                          'salla-w-16 salla-h-16 md:salla-w-20 md:salla-h-20',
                          'salla-text-2xl md:salla-text-3xl salla-font-bold',
                          'salla-text-center salla-bg-gray-50',
                          'salla-border-2 salla-border-gray-200',
                          'salla-rounded-2xl salla-shadow-sm',
                          'focus:salla-border-primary-500 focus:salla-ring-4 focus:salla-ring-primary-100',
                          'group-hover:salla-border-primary-300',
                          'salla-transition-all salla-duration-200',
                          'salla-appearance-none'
                        ]"
                        @input="handleInputChange(index)"
                        @keydown.backspace="handleBackspace(index, $event)"
                        @paste="handlePaste($event)"
                        :ref="(el) => { if (index === currentInputIndex) el.focus() }"
                      />
                      <div class="salla-absolute salla-bottom-0 salla-left-1/2 salla-w-8 salla-h-1 salla-bg-primary-100 salla--translate-x-1/2 salla-rounded-full salla-transition-all group-hover:salla-bg-primary-200"></div>
                    </div>
                  </template>
                </div>

                <!-- Timer Section -->
                <div class="salla-flex salla-justify-center">
                  <div class="salla-text-sm salla-text-gray-500">
                    يمكنك إعادة إرسال الرمز بعد
                    <span class="salla-font-mono salla-text-primary-600">{{ formatTime(timer) }}</span>
                  </div>
                </div>
              </div>

              <!-- Submit Button -->
              <button 
                @click="submitVerificationCode"
                :disabled="isSubmitting || !isCodeComplete"
                :class="[
                  'salla-w-full salla-py-5',
                  'salla-bg-primary-600',
                  'salla-text-white salla-font-medium',
                  'salla-rounded-xl',
                  'salla-shadow-lg',
                  'salla-transition-all salla-duration-300',
                  'hover:salla-bg-primary-700 hover:salla-shadow-xl',
                  'disabled:salla-opacity-50 disabled:salla-cursor-not-allowed',
                  isSubmitting ? 'salla-animate-pulse' : ''
                ]"
              >
                <span v-if="!isSubmitting">تأكيد الرمز</span>
                <span v-else class="salla-flex salla-items-center salla-justify-center salla-gap-2">
                  <svg class="salla-animate-spin salla-h-5 salla-w-5" viewBox="0 0 24 24">
                    <circle class="salla-opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="salla-opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  جاري التحقق...
                </span>
              </button>

              <!-- Error Message -->
              <div v-if="errorMessage" class="salla-bg-red-50 salla-border-l-4 salla-border-red-500 salla-p-4 salla-rounded-lg">
                <div class="salla-flex">
                  <div class="salla-shrink-0">
                    <svg class="salla-h-5 salla-w-5 salla-text-red-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"/>
                    </svg>
                  </div>
                  <div class="salla-mr-3">
                    <p class="salla-text-sm salla-text-red-700">{{ errorMessage }}</p>
                  </div>
                </div>
              </div>

              <!-- Resend Code -->
              <div class="salla-text-center salla-space-y-2">
                <p class="salla-text-gray-600">لم تتلقى الرمز؟</p>
                <button 
                  @click="resendCode"
                  :disabled="timer > 0"
                  class="salla-text-primary-600 hover:salla-text-primary-700 salla-font-medium salla-transition-colors disabled:salla-opacity-50 disabled:salla-cursor-not-allowed"
                >
                  إعادة إرسال الرمز
                </button>
              </div>
            </div>
          </div>

          <!-- Right Column - Illustration -->
          <div class="salla-hidden md:salla-block salla-w-7/12 salla-bg-gradient-to-br salla-from-primary-50 salla-to-primary-100">
            <div class="salla-relative salla-h-full salla-p-12">
              <div class="salla-absolute salla-inset-0 salla-bg-white/40 salla-mix-blend-overlay"></div>
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6f79a99596c963a878d9c70e08b561ef68807dec882a3d97f9e56db37f2867b5?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&"
                alt="Activation code illustration"
                class="salla-relative salla-w-full salla-h-full salla-object-contain"
              />
              <div class="salla-absolute salla-inset-0 salla-bg-gradient-to-t salla-from-primary-100/20 salla-to-transparent"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/services/authService';
import { API_URL } from '@/config';

export default {
  setup() {
    const router = useRouter();
    const verificationCode = ref(['', '', '', '']);
    const currentInputIndex = ref(0);
    const errorMessage = ref('');
    const isSubmitting = ref(false);
    const timer = ref(60); // 60 seconds countdown
    let timerInterval;

    const isCodeComplete = computed(() => {
      return verificationCode.value.every(digit => digit.length === 1);
    });

    const startTimer = () => {
      timer.value = 60;
      clearInterval(timerInterval);
      timerInterval = setInterval(() => {
        if (timer.value > 0) {
          timer.value--;
        } else {
          clearInterval(timerInterval);
        }
      }, 1000);
    };

    const formatTime = (seconds) => {
      const mins = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${mins}:${secs.toString().padStart(2, '0')}`;
    };

    onMounted(() => {
      startTimer();
    });

    onUnmounted(() => {
      clearInterval(timerInterval);
    });

    const handleInputChange = (index) => {
      // Only allow numbers
      verificationCode.value[index] = verificationCode.value[index].replace(/[^0-9]/g, '');
      
      if (verificationCode.value[index].length === 1) {
        const nextIndex = index + 1;
        if (nextIndex < 4) {
          currentInputIndex.value = nextIndex;
          // Focus next input
          setTimeout(() => {
            document.querySelectorAll('input')[nextIndex]?.focus();
          }, 0);
        }
      }
    };

    const handleBackspace = (index, event) => {
      if (event.key === 'Backspace' && !verificationCode.value[index]) {
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
          verificationCode.value[prevIndex] = '';
          currentInputIndex.value = prevIndex;
          setTimeout(() => {
            document.querySelectorAll('input')[prevIndex]?.focus();
          }, 0);
        }
      }
    };

    const handlePaste = (event) => {
      event.preventDefault();
      const pastedData = event.clipboardData.getData('text');
      const numbers = pastedData.replace(/[^0-9]/g, '').split('').slice(0, 4);
      numbers.forEach((num, index) => {
        if (index < 4) {
          verificationCode.value[index] = num;
        }
      });
    };

    const resendCode = async () => {
      if (timer.value > 0) return;
      
      try {
        const token = AuthService.getToken();
        const response = await fetch(`${API_URL}/resend-code`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.ok) {
          startTimer();
          // Clear current verification code
          verificationCode.value = ['', '', '', ''];
          currentInputIndex.value = 0;
          errorMessage.value = '';
        } else {
          throw new Error('فشل إعادة إرسال الرمز');
        }
      } catch (error) {
        errorMessage.value = error.message;
      }
    };

    const submitVerificationCode = async () => {
      if (isSubmitting.value || !isCodeComplete.value) return;

      if (!AuthService.isLoggedIn()) {
        console.error('No valid session found, redirecting to login.');
        await router.push('/login');
        return;
      }

      const token = AuthService.getToken();
      const code = verificationCode.value.join('');

      if (!token) {
        console.error('JWT Token is missing, redirecting to login.');
        await router.push('/login');
        return;
      }

      try {
        isSubmitting.value = true;
        errorMessage.value = '';
        
        const response = await fetch(`${API_URL}/verify`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            verification_code: code
          })
        });
        
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || 'فشل التحقق من الرمز');
        }

        if (data.success) {
          try {
            await router.push(data.redirect);
          } catch (routerError) {
            console.error("Router push failed, using window.location:", routerError);
            window.location.href = data.redirect;
          }
        } else {
          throw new Error(data.message || 'فشل التحقق من الرمز');
        }
      } catch (error) {
        console.error('Error during verification:', error);
        errorMessage.value = error.message || 'حدث خطأ. الرجاء المحاولة مرة أخرى';
        // Clear verification code on error
        verificationCode.value = ['', '', '', ''];
        currentInputIndex.value = 0;
      } finally {
        isSubmitting.value = false;
      }
    };

    const userEmail = computed(() => {
      return AuthService.getUserEmail() || 'your@email.com';
    });

    return {
      verificationCode,
      currentInputIndex,
      errorMessage,
      isSubmitting,
      isCodeComplete,
      timer,
      handleInputChange,
      handleBackspace,
      handlePaste,
      submitVerificationCode,
      resendCode,
      userEmail,
      formatTime,
    };
  },
};
</script>

<style scoped>
/* Optional: Add any specific custom styles here */
input[type="text"]::-webkit-inner-spin-button,
input[type="text"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="text"] {
  -moz-appearance: textfield;
}

/* For Firefox */
input[type="text"] {
  -moz-appearance: textfield;
}

/* Disable text selection for inputs */
input {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Custom autofill style */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #1e40af;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}
</style>