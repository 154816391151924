<template>
  <div class="salla-flex salla-flex-col salla-gap-6 salla-p-6 salla-bg-gray-50">
    <!-- Page Header with Explanation -->
    <div class="salla-bg-white salla-rounded-lg salla-shadow-md salla-p-6">
      <div class="salla-border-l-4 salla-border-primary-500 salla-pl-4">
        <h1 class="salla-text-2xl salla-font-bold salla-text-gray-800">Video Creation Credit Requirements</h1>
        <p class="salla-mt-2 salla-text-gray-600">
          Set and manage the number of credits required for users to create videos. This helps control resource usage and maintain system balance.
        </p>
      </div>
    </div>

    <!-- Current Requirements Section -->
    <section class="salla-bg-white salla-rounded-lg salla-shadow-md salla-p-6">
      <h2 class="salla-text-xl salla-font-semibold salla-text-gray-800 salla-mb-4">Current Credit Requirements</h2>
      
      <div class="salla-overflow-x-auto">
        <table v-if="creditRequirements.length" class="salla-min-w-full salla-divide-y salla-divide-gray-200">
          <thead class="salla-bg-gray-50">
            <tr>
              <th class="salla-px-6 salla-py-3 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-600">
                Required Credits
              </th>
              <th class="salla-px-6 salla-py-3 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-600">
                Actions
              </th>
            </tr>
          </thead>
          <tbody class="salla-bg-white salla-divide-y salla-divide-gray-200">
            <tr v-for="requirement in creditRequirements" :key="requirement.id" 
                class="salla-hover:salla-bg-gray-50 salla-transition-colors">
              <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap">
                <div class="salla-flex salla-items-center">
                  <div class="salla-h-8 salla-w-8 salla-flex salla-items-center salla-justify-center salla-bg-primary-100 salla-rounded-full">
                    <span class="salla-text-primary-600 salla-font-semibold">₡</span>
                  </div>
                  <span class="salla-ml-3 salla-text-gray-900 salla-font-medium">
                    {{ requirement.requested_credits }} credits
                  </span>
                </div>
              </td>
              <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap">
                <button 
                  @click="editRequirement(requirement.id)"
                  class="salla-inline-flex salla-items-center salla-px-4 salla-py-2 salla-bg-primary-600 salla-text-white salla-rounded-md salla-hover:salla-bg-primary-700 salla-transition-colors"
                >
                  Edit Requirement
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="salla-text-center salla-py-8">
          <div class="salla-text-gray-400 salla-mb-3">
            <svg class="salla-mx-auto salla-h-12 salla-w-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" 
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"/>
            </svg>
          </div>
          <p class="salla-text-gray-500">No credit requirements have been set yet</p>
        </div>
      </div>
    </section>

    <!-- Set Requirements Section -->
    <section class="salla-bg-white salla-rounded-lg salla-shadow-md salla-p-6">
      <h2 class="salla-text-xl salla-font-semibold salla-text-gray-800 salla-mb-4">Set Credit Requirement</h2>
      
      <form @submit.prevent="submitCreditRequirement" v-if="!creditRequirements.length" 
            class="salla-max-w-2xl">
        <div class="salla-bg-gray-50 salla-rounded-lg salla-p-6">
          <div class="salla-mb-6">
            <label for="requested-credits" 
                   class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">
              Required Credits
              <span class="salla-text-red-500">*</span>
            </label>
            <div class="salla-mt-1 salla-relative">
              <input 
                type="number" 
                id="requested-credits" 
                v-model.number="requestedCredits"
                min="1"
                required
                class="salla-block salla-w-full salla-rounded-md salla-border salla-border-gray-300 salla-pl-3 salla-pr-12 salla-py-2 salla-text-gray-900 focus:salla-ring-2 focus:salla-ring-primary-500 focus:salla-border-primary-500"
              >
              <div class="salla-absolute salla-inset-y-0 salla-right-0 salla-flex salla-items-center salla-pr-3">
                <span class="salla-text-gray-500">credits</span>
              </div>
            </div>
            <p class="salla-mt-2 salla-text-sm salla-text-gray-500">
              Enter the number of credits required for creating one video
            </p>
          </div>

          <button 
            type="submit"
            class="salla-w-full salla-flex salla-justify-center salla-py-2 salla-px-4 salla-border salla-border-transparent salla-rounded-md salla-shadow-sm salla-text-sm salla-font-medium salla-text-white salla-bg-primary-600 salla-hover:salla-bg-primary-700 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-offset-2 focus:salla-ring-primary-500"
          >
            Set Credit Requirement
          </button>
        </div>
      </form>
      
      <div v-else class="salla-bg-yellow-50 salla-border-l-4 salla-border-yellow-400 salla-p-4">
        <div class="salla-flex">
          <div class="salla-flex-shrink-0">
            <svg class="salla-h-5 salla-w-5 salla-text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"/>
            </svg>
          </div>
          <div class="salla-ml-3">
            <p class="salla-text-sm salla-text-yellow-700">
              A credit requirement already exists. Please edit the existing one.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Information Section -->
    <section class="salla-bg-white salla-rounded-lg salla-shadow-md salla-p-6">
      <h2 class="salla-text-xl salla-font-semibold salla-text-gray-800 salla-mb-4">About Credit Requirements</h2>
      
      <div class="salla-prose salla-max-w-none">
        <div class="salla-grid salla-grid-cols-1 md:salla-grid-cols-2 salla-gap-6">
          <div class="salla-bg-gray-50 salla-rounded-lg salla-p-4">
            <h3 class="salla-text-lg salla-font-medium salla-text-gray-900 salla-mb-2">
              What are Credit Requirements?
            </h3>
            <p class="salla-text-gray-600">
              Credit requirements determine how many credits a user needs to create a new video. 
              This system helps manage resource allocation and ensures fair usage across all users.
            </p>
          </div>

          <div class="salla-bg-gray-50 salla-rounded-lg salla-p-4">
            <h3 class="salla-text-lg salla-font-medium salla-text-gray-900 salla-mb-2">
              Managing Requirements
            </h3>
            <p class="salla-text-gray-600">
              Only one credit requirement can be active at a time. To change the requirement, 
              use the edit button on the existing requirement or set a new one if none exists.
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Alert Messages -->
    <div v-if="message" 
         class="salla-fixed salla-bottom-4 salla-right-4 salla-max-w-sm salla-w-full salla-shadow-lg salla-rounded-lg salla-pointer-events-auto salla-transition-all salla-duration-300"
         :class="{
           'salla-bg-green-50': success,
           'salla-bg-red-50': !success
         }">
      <div class="salla-p-4">
        <div class="salla-flex salla-items-start">
          <div class="salla-flex-shrink-0">
            <svg v-if="success" class="salla-h-6 salla-w-6 salla-text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
            </svg>
            <svg v-else class="salla-h-6 salla-w-6 salla-text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
            </svg>
          </div>
          <div class="salla-ml-3 salla-w-0 salla-flex-1">
            <p class="salla-text-sm salla-font-medium" :class="{
              'salla-text-green-800': success,
              'salla-text-red-800': !success
            }">
              {{ message }}
            </p>
          </div>
          <div class="salla-ml-4 salla-flex-shrink-0 salla-flex">
            <button @click="message = ''" 
                    class="salla-bg-transparent salla-rounded-md salla-inline-flex salla-text-gray-400 salla-hover:salla-text-gray-500">
              <span class="salla-sr-only">Close</span>
              <svg class="salla-h-5 salla-w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import { API_URL } from '@/config';
  
  export default {
    name: 'CreditsRequired',
    data() {
      return {
        requestedCredits: null,
        message: '',
        success: false,
        creditRequirements: []
      };
    },
    created() {
      this.fetchCreditRequirements();
    },
    methods: {
      submitCreditRequirement() {
        const token = localStorage.getItem('token');
        if (!token) {
          alert('No token found. You must be logged in to set credit requirements.');
          return;
        }
        if (!this.requestedCredits) {
          alert("Please enter the requested credits.");
          return;
        }
  
        fetch(`${API_URL}/admin/add-credit-requirement`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ requested_credits: this.requestedCredits })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(() => {
          this.message = 'Credit requirement added successfully';
          this.success = true;
          this.requestedCredits = null; // Reset the input after successful submission
          this.fetchCreditRequirements(); // Refresh the list of credit requirements
        })
        .catch(error => {
          console.error("Failed to add credit requirement:", error);
          this.message = 'Failed to add credit requirement';
          this.success = false;
        });
      },
      fetchCreditRequirements() {
        const token = localStorage.getItem('token');
        if (!token) {
          alert('No token found. You must be logged in to view credit requirements.');
          return;
        }
  
        fetch(`${API_URL}/api/required-credits`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => response.json())
        .then(data => {
          this.creditRequirements = data;
        })
        .catch(error => {
          console.error("Failed to fetch credit requirements:", error);
        });
      },
      editRequirement(creditId) {
        const token = localStorage.getItem('token');
        const newCredits = prompt("Enter the new requested credits:");
        if (!newCredits || isNaN(newCredits) || newCredits <= 0) {
          alert("Invalid credit value.");
          return;
        }
  
        fetch(`${API_URL}/admin/edit-credit-requirement/${creditId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ requested_credits: parseInt(newCredits) })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(() => {
          this.message = 'Credit requirement updated successfully';
          this.success = true;
          this.fetchCreditRequirements(); // Refresh the list of credit requirements
        })
        .catch(error => {
          console.error("Failed to update credit requirement:", error);
          this.message = 'Failed to update credit requirement';
          this.success = false;
        });
      }
    }
  };
  </script>
