<template>
  <div class="audio-admin-container">
    <div class="main-content">
      <section id="audio-upload" class="section">
        <h2>Audio Upload</h2>
        <form @submit.prevent="uploadAudio" class="form">
          <div class="form-group">
            <label for="audio-file">Select Audio File</label>
            <input type="file" id="audio-file" @change="handleFileChange" accept="audio/*" required>
          </div>
          <button type="submit" class="btn-submit" :disabled="!file">Upload Audio</button>
        </form>
      </section>

      <section id="uploaded-audios" class="section">
        <h2>Uploaded Audios</h2>
        <table class="table" v-if="audios.length > 0">
          <thead>
            <tr>
              <th>Title</th>
              <th>Audio</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="audio in audios" :key="audio.id">
              <td>{{ audio.title }}</td>
              <td>
                <audio controls>
                  <source :src="audio.url" type="audio/mpeg">
                  Your browser does not support the audio element.
                </audio>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-else>No audio files have been uploaded yet.</p>
      </section>
    </div>
  </div>
</template>

<script>
import { API_URL } from '@/config';

export default {
  name: 'AudioAdmin',
  data() {
    return {
      file: null,
      audios: []
    };
  },
  mounted() {
    this.fetchAudios();
  },
  methods: {
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    uploadAudio() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to upload audio.');
        return;
      }
      if (!this.file) {
        alert("Please select a file first.");
        return;
      }

      const formData = new FormData();
      formData.append('audio', this.file);

      fetch(`${API_URL}/upload_audio`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        this.audios.push(data);
        alert("Audio uploaded successfully!");
        this.file = null; // Reset the file input after successful upload
      })
      .catch(error => {
        console.error("Failed to upload audio:", error);
        alert("Failed to upload audio.");
      });
    },
    fetchAudios() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to view audios.');
        return;
      }

      fetch(`${API_URL}/get_audio_files`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        this.audios = data;
      })
      .catch(error => {
        console.error("Failed to fetch audios:", error);
        alert("Failed to fetch audios.");
      });
    }
  }
};
</script>

<style scoped>
.audio-admin-container {
  display: flex;
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f4f5f7;
}

.main-content {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.section {
  margin-bottom: 40px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-group input[type="file"] {
  display: block;
  margin-top: 5px;
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.btn-submit {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-submit:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table th,
.table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.table th {
  background-color: #f4f5f7;
  font-weight: bold;
}

audio {
  width: 100%;
}
</style>
