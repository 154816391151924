<template>
  <div class="salla-flex salla-flex-col salla-gap-8">
    <!-- Enhanced Header -->
    <div class="salla-bg-white salla-rounded-xl salla-shadow-sm salla-p-6">
      <div class="salla-flex salla-flex-col salla-gap-4 sm:salla-flex-row sm:salla-items-center sm:salla-justify-between">
        <!-- Enhanced Search -->
        <div class="salla-relative salla-flex-1">
          <i class="fas fa-search salla-absolute salla-right-4 salla-top-1/2 -salla-translate-y-1/2 salla-text-gray-400"></i>
          <input 
            type="text" 
            v-model="searchQuery"
            placeholder="ابحث عن قالب..." 
            class="salla-w-full salla-pl-4 salla-pr-12 salla-py-3 salla-bg-gray-50 salla-rounded-lg salla-border salla-border-gray-200 focus:salla-border-primary-600 focus:salla-ring-2 focus:salla-ring-primary-600/20 salla-outline-none salla-transition-all"
          />
        </div>

      
      </div>
    </div>

    <!-- Enhanced Templates Grid -->
    <div class="salla-grid salla-grid-cols-1 sm:salla-grid-cols-2 lg:salla-grid-cols-3 salla-gap-6">
      <div 
        v-for="template in filteredTemplates" 
        :key="template.id"
        class="salla-group salla-bg-white salla-rounded-xl salla-shadow-sm salla-overflow-hidden hover:salla-shadow-lg salla-transition-all"
      >
        <!-- Preview Container -->
        <div class="salla-relative salla-aspect-video salla-bg-gray-900 salla-overflow-hidden">
          <!-- Video Preview -->
          <video 
            v-if="template.preview_url.endsWith('.mp4')"
            :src="template.preview_url"
            class="salla-w-full salla-h-full salla-object-cover"
            :id="`video-${template.id}`"
            loop
            :muted="!isPlaying[template.id]"
            ref="previewVideo"
          ></video>
          
          <!-- Image Preview -->
          <img 
            v-else 
            :src="template.preview_url" 
            :alt="template.name"
            class="salla-w-full salla-h-full salla-object-cover"
          />

          <!-- Enhanced Video Controls Overlay -->
          <div 
            class="salla-absolute salla-inset-0 salla-bg-gradient-to-t salla-from-black/60 salla-via-black/20 salla-to-transparent salla-opacity-0 group-hover:salla-opacity-100 salla-transition-opacity"
          >
            <!-- Video Controls -->
            <div class="salla-absolute salla-top-4 salla-right-4 salla-flex salla-gap-2">
              <button 
                v-if="template.preview_url.endsWith('.mp4')"
                @click.stop="togglePlay(template.id)"
                class="salla-p-2 salla-bg-white/20 salla-text-white salla-rounded-lg hover:salla-bg-white/30 salla-backdrop-blur-sm salla-transition-all"
              >
                <i :class="['fas', isPlaying[template.id] ? 'fa-pause' : 'fa-play']"></i>
              </button>
              <button 
                v-if="template.preview_url.endsWith('.mp4')"
                @click.stop="toggleMute(template.id)"
                class="salla-p-2 salla-bg-white/20 salla-text-white salla-rounded-lg hover:salla-bg-white/30 salla-backdrop-blur-sm salla-transition-all"
              >
                <i :class="['fas', isMuted[template.id] ? 'fa-volume-mute' : 'fa-volume-up']"></i>
              </button>
            </div>

            <!-- Action Buttons -->
            <div class="salla-absolute salla-bottom-4 salla-left-4 salla-right-4 salla-flex salla-items-center salla-justify-between">
              <button 
                @click.stop="selectTemplate(template)"
                class="salla-px-4 salla-py-2 salla-bg-primary-600 salla-text-white salla-rounded-lg hover:salla-bg-primary-700 salla-transition-all salla-transform salla-translate-y-2 group-hover:salla-translate-y-0"
              >
                استخدام القالب
              </button>
              <button 
                @click.stop="previewTemplate(template)"
                class="salla-p-2 salla-bg-white/20 salla-text-white salla-rounded-lg hover:salla-bg-white/30 salla-backdrop-blur-sm salla-transition-all salla-transform salla-translate-y-2 group-hover:salla-translate-y-0"
                title="عرض بالحجم الكامل"
              >
                <i class="fas fa-expand"></i>
              </button>
            </div>
          </div>

          <!-- Template Quality Badge -->
          <div 
            v-if="template.quality"
            class="salla-absolute salla-top-3 salla-right-3 salla-px-2 salla-py-1 salla-bg-primary-600 salla-text-white salla-text-xs salla-rounded-full salla-shadow-lg"
          >
            {{ template.quality }}
          </div>
        </div>

        <!-- Enhanced Template Info -->
        <div class="salla-p-4 salla-space-y-2">
          <div class="salla-flex salla-items-center salla-justify-between">
            <h4 class="salla-font-semibold salla-text-gray-800">{{ template.name }}</h4>
            <span class="salla-flex salla-items-center salla-gap-1 salla-text-sm salla-text-primary-600">
              <i class="fas fa-clock salla-text-xs"></i>
              {{ template.duration }}s
            </span>
          </div>
          
          <p class="salla-text-sm salla-text-gray-600 salla-line-clamp-2">{{ template.description }}</p>
          
          <!-- Tags with Enhanced Design -->
          <div class="salla-flex salla-flex-wrap salla-gap-2 salla-pt-2">
            <span 
              v-for="tag in template.tags" 
              :key="tag"
              class="salla-px-2 salla-py-1 salla-text-xs salla-bg-primary-50 salla-text-primary-600 salla-rounded-full salla-border salla-border-primary-100"
            >
              {{ tag }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Enhanced Full Preview Modal -->
    <div 
      v-if="showPreview" 
      class="salla-fixed salla-inset-0 salla-bg-black/75 salla-flex salla-items-center salla-justify-center salla-z-50"
      @click="closePreview"
    >
      <div 
        class="salla-bg-black salla-rounded-2xl salla-overflow-hidden salla-max-w-5xl salla-w-full salla-mx-4"
        @click.stop
      >
        <!-- Enhanced Video Preview -->
        <div class="salla-relative salla-aspect-video">
          <video 
            v-if="selectedTemplate?.preview_url.endsWith('.mp4')"
            :src="selectedTemplate?.preview_url"
            class="salla-w-full salla-h-full salla-object-contain"
            controls
            autoplay
            ref="modalVideo"
          ></video>
          <img 
            v-else 
            :src="selectedTemplate?.preview_url"
            :alt="selectedTemplate?.name"
            class="salla-w-full salla-h-full salla-object-contain"
          />
        </div>

        <!-- Enhanced Template Info in Modal -->
        <div class="salla-p-6 salla-bg-white">
          <div class="salla-flex salla-items-start salla-justify-between salla-gap-4">
            <div class="salla-flex-1">
              <h3 class="salla-text-xl salla-font-semibold salla-text-gray-800">{{ selectedTemplate?.name }}</h3>
              <p class="salla-mt-2 salla-text-gray-600">{{ selectedTemplate?.description }}</p>
            </div>
            <div class="salla-flex salla-gap-3">
              <button 
                @click="closePreview"
                class="salla-px-4 salla-py-2 salla-bg-gray-100 salla-text-gray-700 salla-rounded-lg hover:salla-bg-gray-200 salla-transition-all"
              >
                إغلاق
              </button>
              <button 
                @click="selectAndClose(selectedTemplate)"
                class="salla-px-4 salla-py-2 salla-bg-primary-600 salla-text-white salla-rounded-lg hover:salla-bg-primary-700 salla-transition-all"
              >
                استخدام القالب
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'DesignTemplate',
  props: {
    templates: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      searchQuery: '',
      showPreview: false,
      selectedTemplate: null,
      aspectRatios: [
        { label: '1:1', width: 1080, height: 1080 },
        { label: '16:9', width: 1920, height: 1080 },
        { label: '9:16', width: 1080, height: 1920 },
        { label: '4:5', width: 864, height: 1080 },
        { label: '5:4', width: 1080, height: 864 },
      ],
      selectedAspectRatio: { label: '1:1', width: 1080, height: 1080 },
      isPlaying: {},
      isMuted: {},
      videoElements: {}
    };
  },
  computed: {
    ...mapState({
      modifications: state => state.modifications
    }),
    filteredTemplates() {
      if (!this.searchQuery) return this.templates;
      
      const query = this.searchQuery.toLowerCase();
      return this.templates.filter(template => 
        template.name.toLowerCase().includes(query) ||
        template.description.toLowerCase().includes(query) ||
        template.tags?.some(tag => tag.toLowerCase().includes(query))
      );
    }
  },
  methods: {
    togglePlay(templateId) {
      const video = document.getElementById(`video-${templateId}`);
      if (video) {
        if (this.isPlaying[templateId]) {
          video.pause();
        } else {
          video.play();
        }
        this.$set(this.isPlaying, templateId, !this.isPlaying[templateId]);
      }
    },
    toggleMute(templateId) {
      const video = document.getElementById(`video-${templateId}`);
      if (video) {
        video.muted = !video.muted;
        this.$set(this.isMuted, templateId, video.muted);
      }
    },
    selectTemplate(template) {
      this.$emit('template-selected', {
        template,
        modifications: this.modifications,
        aspectRatio: this.selectedAspectRatio
      });
    },
    selectAspectRatio(ratio) {
      this.selectedAspectRatio = ratio;
    },
    previewTemplate(template) {
      this.pauseAllVideos();
      this.selectedTemplate = template;
      this.showPreview = true;
    },
    closePreview() {
      if (this.$refs.modalVideo) {
        this.$refs.modalVideo.pause();
      }
      this.showPreview = false;
      this.selectedTemplate = null;
    },
    selectAndClose(template) {
      this.selectTemplate(template);
      this.closePreview();
    },
    pauseAllVideos() {
      Object.keys(this.isPlaying).forEach(templateId => {
        const video = document.getElementById(`video-${templateId}`);
        if (video) {
          video.pause();
          this.$set(this.isPlaying, templateId, false);
        }
      });
    }
  },
  beforeUnmount() {
    this.pauseAllVideos();
    if (this.$refs.modalVideo) {
      this.$refs.modalVideo.pause();
    }
  }
};
</script>