<template>
  <div class="salla-flex salla-flex-col salla-gap-6" dir="rtl">
    <!-- Text Fields Section -->
    <div class="salla-space-y-6">
      <!-- Standard Fields -->
      <div v-for="(text, index) in textElements" :key="index" class="salla-bg-gray-50 salla-rounded-xl salla-p-4 salla-space-y-3">
        <div class="salla-flex salla-items-center salla-justify-between">
          <label class="salla-text-gray-700 salla-font-medium salla-text-sm salla-flex salla-items-center salla-gap-2">
            <i class="fas fa-font salla-text-primary-500"></i>
            {{ text.label }}
          </label>
          <span class="salla-text-xs salla-text-gray-400">{{ text.content?.length || 0 }}/100</span>
        </div>

        <div class="salla-relative">
          <input 
            type="text" 
            v-model="text.content" 
            @input="updateText(text.key, text.content)"
            :placeholder="`ادخل ${text.label}`"
            class="salla-w-full salla-px-4 salla-py-3 salla-border salla-border-gray-200 salla-rounded-lg salla-bg-white salla-text-gray-900 focus:salla-ring-2 focus:salla-ring-primary-500 focus:salla-border-primary-500 salla-transition-all salla-text-sm" 
          />
          <div 
            v-if="text.content" 
            class="salla-absolute salla-left-2 salla-top-1/2 -salla-translate-y-1/2 salla-flex salla-items-center salla-gap-1"
          >
            <button 
              @click="text.content = ''" 
              class="salla-p-1.5 salla-text-gray-400 hover:salla-text-red-500 salla-rounded-full hover:salla-bg-gray-100 salla-transition-all"
              title="مسح النص"
            >
              <i class="fas fa-times salla-text-xs"></i>
            </button>
            <span class="salla-w-px salla-h-6 salla-bg-gray-200"></span>
            <button 
              class="salla-p-1.5 salla-text-gray-400 hover:salla-text-primary-500 salla-rounded-full hover:salla-bg-gray-100 salla-transition-all"
              title="نسخ النص"
            >
              <i class="fas fa-copy salla-text-xs"></i>
            </button>
          </div>
        </div>

        <!-- Field Helper Text -->
        <div class="salla-flex salla-items-center salla-gap-2 salla-text-xs salla-text-gray-400">
          <i class="fas fa-info-circle"></i>
          <span>سيظهر هذا النص في الفيديو النهائي</span>
        </div>
      </div>
    </div>

    <!-- Tips Section -->
    <div class="salla-bg-primary-50 salla-rounded-xl salla-p-4">
      <div class="salla-flex salla-items-start salla-gap-3">
        <div class="salla-w-8 salla-h-8 salla-bg-primary-100 salla-rounded-lg salla-flex salla-items-center salla-justify-center salla-text-primary-600">
          <i class="fas fa-lightbulb"></i>
        </div>
        <div class="salla-space-y-1">
          <h3 class="salla-text-sm salla-font-medium salla-text-gray-900">نصائح للنصوص</h3>
          <ul class="salla-text-xs salla-text-gray-500 salla-space-y-1">
            <li class="salla-flex salla-items-center salla-gap-1">
              <i class="fas fa-check salla-text-primary-500"></i>
              استخدم نصوصًا قصيرة وواضحة
            </li>
            <li class="salla-flex salla-items-center salla-gap-1">
              <i class="fas fa-check salla-text-primary-500"></i>
              تأكد من عدم وجود أخطاء إملائية
            </li>
            <li class="salla-flex salla-items-center salla-gap-1">
              <i class="fas fa-check salla-text-primary-500"></i>
              اجعل النص متناسقًا مع محتوى الفيديو
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>


  <script>
  import { mapMutations } from 'vuex';
  
  export default {
    name: 'TextPreview',
    props: {
      previewInstance: {
        type: Object,
        required: true,
      },
      modifications: {
        type: Object,
        required: true,
      },
      numTexts: {
        type: Number,
        required: true,
      },
      dynamicElementIds: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        textElements: [],
      };
    },
    mounted() {
      console.log('Mounted TextPreview');
      console.log('Initializing text elements with modifications:', this.modifications);
      console.log('Number of texts:', this.numTexts);
      this.initTextElements();
    },
    methods: {
      ...mapMutations({
        updateModifications: 'updateModifications',
      }),
      initTextElements() {
        const predefinedTextKeys = ['product_name_key', 'product_price_key', 'website_key'];
        const predefinedTextLabels = ['اسم المنتج', 'السعر', 'الموقع الإلكتروني'];
  
        // Initialize predefined text elements
        this.textElements = predefinedTextKeys.map((key, index) => {
          const elementId = this.dynamicElementIds[key];
          console.log(`Predefined Text Key: ${key}, Element ID: ${elementId}`);
          if (elementId) {
            return {
              key: elementId,
              label: predefinedTextLabels[index],
              content: this.modifications[elementId] || '',
            };
          }
        }).filter(element => element);
  
        // Initialize additional text elements
        for (let i = 1; i <= this.numTexts; i++) {
          const key = `text_${i}_key`;
          const elementId = this.dynamicElementIds[key];
          console.log(`Additional Text Key: ${key}, Element ID: ${elementId}`);
          if (elementId) {
            this.textElements.push({
              key: elementId,
              label: `النص الإضافي ${i}`,
              content: this.modifications[elementId] || '',
            });
          }
        }
  
        console.log('Text elements after initialization:', this.textElements);
        this.loadInitialTextValues();
      },
      loadInitialTextValues() {
        this.textElements.forEach(textElement => {
          const initialValue = this.modifications[textElement.key] || '';
          textElement.content = initialValue;
        });
        console.log('Text elements after loading initial values:', this.textElements);
      },
      updateText(key, content) {
        console.log('updateText called with key:', key, 'and content:', content);
        this.$emit('update-modifications', { [key]: content });
      },
    },
  };
  </script>
  
  
  