<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div dir="rtl" class="salla-flex salla-flex-col salla-min-h-screen salla-bg-white">
    <!-- Header -->
    <header class="salla-sticky salla-top-0 salla-z-50 salla-bg-white salla-shadow-sm">
  <div class="salla-container salla-mx-auto salla-px-4 salla-py-4">
    <div class="salla-flex salla-items-center salla-justify-between">
      <!-- Logo Section - Right Side for RTL -->
      <div class="salla-flex salla-items-center salla-gap-2">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/076ad4d43ff739e5b7165868b496c5cce1c42209ae846a2e33f5034beb9bd8ef?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
             alt="Logo" 
             class="salla-h-8 salla-w-auto" />
      </div>

      <!-- Navigation Links - Center -->
      <nav class="salla-flex salla-items-center salla-gap-6 salla-mr-4">
        <router-link 
          v-for="(link, index) in [
            { to: '/pricing', text: 'الأسعار' },
            { to: '/who-we-are', text: 'من نحن' },
            { to: '/vision', text: 'رؤيتنا' },
            { to: '/contact', text: 'تواصل معنا' },
            { to: '/terms', text: 'الشروط والأحكام' },
            { to: '/privacy', text: 'سياسة الخصوصية' }
          ]"
          :key="index"
          :to="link.to"
          class="salla-text-gray-700 hover:salla-text-primary-600 salla-transition-colors salla-text-sm salla-font-medium salla-py-2 salla-px-3 salla-rounded-lg hover:salla-bg-gray-50"
        >
          {{ link.text }}
        </router-link>
      </nav>

      <!-- Action Button - Left Side for RTL -->
      <div class="salla-flex salla-items-center">
        <router-link 
          to="/dashboard" 
          class="salla-inline-flex salla-items-center salla-gap-2 salla-bg-primary-600 salla-text-white salla-px-6 salla-py-2.5 salla-rounded-full hover:salla-bg-primary-700 salla-transition-all salla-duration-200 salla-ease-in-out salla-shadow-md hover:salla-shadow-lg salla-transform hover:salla-scale-105 salla-font-medium"
        >
          <span>لنبدأ</span>
          <svg xmlns="http://www.w3.org/2000/svg" class="salla-h-5 salla-w-5 salla-transform salla-rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</header>

    <!-- Hero Section -->
    <section class="salla-relative salla-py-20 salla-overflow-hidden">
      <img 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/a9225584de39ad18e8223f8ccc1562ff571d19b3ba99325a03905da7607b5be1?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
        alt="Hero Background" 
        class="salla-absolute salla-inset-0 salla-w-full salla-h-full salla-object-cover" />
      <div class="salla-relative salla-container salla-mx-auto salla-px-4">
        <h1 class="salla-text-5xl salla-font-bold salla-text-center salla-text-gray-900">
          من نحن
        </h1>
      </div>
    </section>

    <!-- Journey Illustration Section -->
    <section class="salla-bg-gradient-to-b salla-from-gray-50 salla-to-primary-50 salla-py-16">
      <div class="salla-container salla-mx-auto salla-px-4">
        <img 
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/0bfa7d748070cee6089edb221384fef9a6b3c0cd55aa4a06c6affa06e5eb2e01?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
          alt="Journey Illustration" 
          class="salla-max-w-2xl salla-mx-auto" />
      </div>
    </section>

    <!-- Story Section -->
    <section class="salla-py-20">
      <div class="salla-container salla-mx-auto salla-px-4">
        <div class="salla-grid salla-grid-cols-1 salla-lg:grid-cols-2 salla-gap-12 salla-items-center">
          <div class="salla-order-2 salla-lg:order-1">
            <h2 class="salla-text-4xl salla-font-bold salla-text-gray-900 salla-mb-8 salla-text-right">رحلتنا</h2>
            <p class="salla-text-lg salla-text-gray-600 salla-leading-relaxed salla-text-right">
              بدأت قصتنا في 2020، عندما لاحظنا التحديات التي تواجه العلامات التجارية في إنشاء محتوى فيديو جذاب ومؤثر دون الحاجة إلى ميزانيات ضخمة أو خبرات فنية متخصصة. من هنا، وُلدت "إبداعي" لتقديم حلول مبتكرة تدمج بين الذكاء الاصطناعي والإبداع البشري، مما يمكننا من إنتاج فيديوهات فريدة تلائم كل علامة تجارية وتساعدها على التألق.
            </p>
          </div>
          <div class="salla-order-1 salla-lg:order-2">
            <img 
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/1bd92921133d464bed0c46402388d5b4af21498c3607d6522aa3dd8731745d41?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
              alt="Story Illustration" 
              class="salla-w-full salla-rounded-3xl salla-shadow-xl" />
          </div>
        </div>
      </div>
    </section>

    <!-- Additional Illustrations -->
    <section class="salla-bg-gradient-to-b salla-from-gray-50 salla-to-primary-50 salla-py-16">
      <div class="salla-container salla-mx-auto salla-px-4">
        <img 
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d20ddf9758689e0eff3c82284a89b61e989da554a75d08fa8e0c77fd3651628?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
          alt="Additional Illustration" 
          class="salla-max-w-2xl salla-mx-auto" />
      </div>
    </section>

    <!-- Join Us Section -->
    <section class="salla-relative salla-py-20 salla-overflow-hidden">
      <img 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/7bf0ce0d47183ff18f8fd6ae66088616edc4f59a2fd2fd22ad74437356634cb0?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
        alt="Join Us Background" 
        class="salla-absolute salla-inset-0 salla-w-full salla-h-full salla-object-cover" />
      <div class="salla-relative salla-container salla-mx-auto salla-px-4 salla-text-center">
        <div class="salla-max-w-2xl salla-mx-auto">
          <h2 class="salla-text-4xl salla-font-bold salla-text-white salla-mb-6">انضم إلينا في رحلتنا</h2>
          <p class="salla-text-xl salla-text-white salla-mb-8">
            معًا، يمكننا إعادة تعريف مستقبل التسويق الرقمي. مرحبًا بك في العائلة.
          </p>
          <button 
            @click="redirectToRegister" 
            class="salla-px-8 salla-py-4 salla-bg-white salla-text-primary-600 salla-rounded-full hover:salla-bg-gray-100 salla-transition-colors salla-text-lg">
            التجربة مجانا
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'AboutUsPage',
  setup() {
    const router = useRouter();
    
    const redirectToRegister = () => {
      router.push('/register');
    };

    return {
      redirectToRegister,
    };
  },
});
</script>