<template>
    <div class="salla-min-h-screen salla-bg-gray-50">
      <div class="salla-max-w-7xl salla-mx-auto salla-px-4 sm:salla-px-6 lg:salla-px-8 salla-py-10">
        <!-- Header Section with Breadcrumb -->
        <div class="salla-mb-8">
          <nav class="salla-flex salla-mb-4" aria-label="Breadcrumb">
            <ol class="salla-flex salla-items-center salla-space-x-2">
              <li>
                <div class="salla-flex salla-items-center">
                  <svg class="salla-h-5 salla-w-5 salla-text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                  <span class="salla-ml-2 salla-text-sm salla-font-medium salla-text-gray-500">Dashboard</span>
                </div>
              </li>
              <li>
                <div class="salla-flex salla-items-center">
                  <svg class="salla-h-5 salla-w-5 salla-text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <span class="salla-ml-2 salla-text-sm salla-font-medium salla-text-gray-900">Salla Integration</span>
                </div>
              </li>
            </ol>
          </nav>
          
          <div class="salla-flex salla-justify-between salla-items-center">
            <div>
              <h1 class="salla-text-3xl salla-font-extrabold salla-text-gray-900">Salla Integration</h1>
              <p class="salla-mt-2 salla-text-lg salla-text-gray-600">Manage your marketplace integration settings and users</p>
            </div>
            <div class="salla-flex salla-space-x-3">
            
              <button 
                @click="openNewPlanModal"
                class="salla-inline-flex salla-items-center salla-px-4 salla-py-2 salla-border salla-border-transparent salla-rounded-md salla-shadow-sm salla-text-sm salla-font-medium salla-text-white salla-bg-blue-600 hover:salla-bg-blue-700 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-offset-2 focus:salla-ring-blue-500"
              >
                <svg class="salla--ml-1 salla-mr-2 salla-h-5 salla-w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                </svg>
                Add New Plan
              </button>
            </div>
          </div>
        </div>
  
        <!-- Stats Overview with Animation -->
        <div class="salla-grid salla-grid-cols-1 md:salla-grid-cols-3 salla-gap-6 salla-mb-8">
          <!-- Total Users Card -->
          <div class="salla-bg-white salla-overflow-hidden salla-rounded-lg salla-shadow-sm salla-transition-all hover:salla-shadow-lg">
            <div class="salla-p-6">
              <div class="salla-flex salla-items-center">
                <div class="salla-flex-shrink-0 salla-p-3 salla-bg-blue-50 salla-rounded-md">
                  <svg class="salla-h-6 salla-w-6 salla-text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </div>
                <div class="salla-ml-5 salla-w-0 salla-flex-1">
                  <dl>
                    <dt class="salla-text-sm salla-font-medium salla-text-gray-500 salla-truncate">
                      Total Users
                    </dt>
                    <dd class="salla-flex salla-items-baseline">
                      <div class="salla-text-2xl salla-font-semibold salla-text-gray-900">
                        {{ sallaUsers.length }}
                      </div>
                      <div class="salla-ml-2 salla-flex salla-items-baseline salla-text-sm salla-font-semibold salla-text-green-600">
                        <svg class="salla-w-3 salla-h-3 salla-mr-1" fill="currentColor" viewBox="0 0 8 8">
                          <path fill-rule="evenodd" d="M0 8l4-8 4 8H0z" />
                        </svg>
                        <span>12%</span>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div class="salla-bg-gray-50 salla-px-5 salla-py-3">
              
            </div>
          </div>
  
          <!-- Active Subscriptions Card -->
          <div class="salla-bg-white salla-overflow-hidden salla-rounded-lg salla-shadow-sm salla-transition-all hover:salla-shadow-lg">
            <div class="salla-p-6">
              <div class="salla-flex salla-items-center">
                <div class="salla-flex-shrink-0 salla-p-3 salla-bg-green-50 salla-rounded-md">
                  <svg class="salla-h-6 salla-w-6 salla-text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                  </svg>
                </div>
                <div class="salla-ml-5 salla-w-0 salla-flex-1">
                  <dl>
                    <dt class="salla-text-sm salla-font-medium salla-text-gray-500 salla-truncate">
                      Active Subscriptions
                    </dt>
                    <dd class="salla-flex salla-items-baseline">
                      <div class="salla-text-2xl salla-font-semibold salla-text-gray-900">
                        {{ subscriptions.filter(sub => sub.status === 'active').length }}
                      </div>
                      <div class="salla-ml-2 salla-flex salla-items-baseline salla-text-sm salla-font-semibold salla-text-green-600">
                        <svg class="salla-w-3 salla-h-3 salla-mr-1" fill="currentColor" viewBox="0 0 8 8">
                          <path fill-rule="evenodd" d="M0 8l4-8 4 8H0z" />
                        </svg>
                        <span>8%</span>
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div class="salla-bg-gray-50 salla-px-5 salla-py-3">
              
            </div>
          </div>
  
          <!-- Total Plans Card -->
          <div class="salla-bg-white salla-overflow-hidden salla-rounded-lg salla-shadow-sm salla-transition-all hover:salla-shadow-lg">
            <div class="salla-p-6">
              <div class="salla-flex salla-items-center">
                <div class="salla-flex-shrink-0 salla-p-3 salla-bg-purple-50 salla-rounded-md">
                  <svg class="salla-h-6 salla-w-6 salla-text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                  </svg>
                </div>
                <div class="salla-ml-5 salla-w-0 salla-flex-1">
                  <dl>
                    <dt class="salla-text-sm salla-font-medium salla-text-gray-500 salla-truncate">
                      Available Plans
                    </dt>
                    <dd class="salla-flex salla-items-baseline">
                      <div class="salla-text-2xl salla-font-semibold salla-text-gray-900">
                        {{ plans.length }}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div class="salla-bg-gray-50 salla-px-5 salla-py-3">
              
            </div>
          </div>
        </div>
        <!-- Plans Management Section -->
      <div class="salla-bg-white salla-rounded-xl salla-shadow-sm salla-mb-8">
        <div class="salla-border-b salla-border-gray-200">
          <div class="salla-p-6">
            <div class="salla-flex salla-items-center salla-justify-between">
              <div>
                <h2 class="salla-text-xl salla-font-semibold salla-text-gray-900">Plans Management</h2>
                <p class="salla-mt-1 salla-text-sm salla-text-gray-500">Configure and manage subscription plans for your users</p>
              </div>
              <div class="salla-flex salla-items-center salla-space-x-3">
                <div class="salla-relative">
                  <input
                    type="text"
                    placeholder="Search plans..."
                    class="salla-w-64 salla-rounded-lg salla-border salla-border-gray-300 salla-py-2 salla-pl-10 salla-pr-4 salla-text-sm focus:salla-border-blue-500 focus:salla-ring-1 focus:salla-ring-blue-500"
                  >
                  <div class="salla-absolute salla-left-3 salla-top-2.5">
                    <svg class="salla-h-5 salla-w-5 salla-text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
                <button
                  @click="openNewPlanModal"
                  class="salla-inline-flex salla-items-center salla-px-4 salla-py-2 salla-border salla-border-transparent salla-rounded-lg salla-shadow-sm salla-text-sm salla-font-medium salla-text-white salla-bg-blue-600 hover:salla-bg-blue-700 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-offset-2 focus:salla-ring-blue-500"
                >
                  <svg class="salla--ml-1 salla-mr-2 salla-h-5 salla-w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                  </svg>
                  New Plan
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="salla-overflow-x-auto">
          <table class="salla-min-w-full salla-divide-y salla-divide-gray-200">
            <thead class="salla-bg-gray-50">
              <tr>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">
                  Plan Details
                </th>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">
                  Tokens
                </th>
                
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">
                  Created
                </th>
                <th scope="col" class="salla-relative salla-px-6 salla-py-3">
                  <span class="salla-sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody class="salla-bg-white salla-divide-y salla-divide-gray-200">
              <tr v-for="plan in plans" :key="plan.id" class="hover:salla-bg-gray-50">
                <td class="salla-px-6 salla-py-4">
                  <div class="salla-flex salla-items-center">
                    <div class="salla-flex-shrink-0 salla-h-10 salla-w-10 salla-rounded-full salla-bg-blue-100 salla-flex salla-items-center salla-justify-center">
                      <svg class="salla-h-6 salla-w-6 salla-text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                    <div class="salla-ml-4">
                      <div class="salla-text-sm salla-font-medium salla-text-gray-900">{{ plan.plan_name }}</div>
                      <div class="salla-text-sm salla-text-gray-500">ID: #{{ plan.id }}</div>
                    </div>
                  </div>
                </td>
                <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap">
                  <div class="salla-text-sm salla-text-gray-900">{{ plan.tokens_amount.toLocaleString() }}</div>
                  <div class="salla-text-sm salla-text-gray-500">Available tokens</div>
                </td>
               
                <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap">
                  <div class="salla-text-sm salla-text-gray-900">{{ formatDate(plan.created_at) }}</div>
                  <div class="salla-text-sm salla-text-gray-500">
                    Last updated: {{ formatDate(plan.updated_at) }}
                  </div>
                </td>
                <td class="salla-px-6 salla-py-4 salla-text-right salla-text-sm salla-font-medium">
                  <div class="salla-flex salla-items-center salla-justify-end salla-space-x-3">
                    <button
                      @click="editPlan(plan)"
                      class="salla-text-blue-600 hover:salla-text-blue-900 salla-flex salla-items-center"
                    >
                      <svg class="salla-h-4 salla-w-4 salla-mr-1" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                      Edit
                    </button>
                    <button
                      @click="deletePlan(plan.id)"
                      class="salla-text-red-600 hover:salla-text-red-900 salla-flex salla-items-center"
                    >
                      <svg class="salla-h-4 salla-w-4 salla-mr-1" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                      </svg>
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="salla-bg-gray-50 salla-px-6 salla-py-4 salla-border-t salla-border-gray-200">
          <div class="salla-flex salla-items-center salla-justify-between">
            <div class="salla-text-sm salla-text-gray-600">
              Showing <span class="salla-font-medium">1</span> to <span class="salla-font-medium">10</span> of <span class="salla-font-medium">20</span> plans
            </div>
            <div class="salla-flex salla-space-x-2">
              <button class="salla-px-3 salla-py-1 salla-rounded-md salla-text-sm salla-font-medium salla-text-gray-700 salla-bg-white salla-border salla-border-gray-300 hover:salla-bg-gray-50">
                Previous
              </button>
              <button class="salla-px-3 salla-py-1 salla-rounded-md salla-text-sm salla-font-medium salla-text-gray-700 salla-bg-white salla-border salla-border-gray-300 hover:salla-bg-gray-50">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Users Section -->
      <div class="salla-bg-white salla-rounded-xl salla-shadow-sm salla-mb-8">
        <div class="salla-border-b salla-border-gray-200">
          <div class="salla-p-6">
            <div class="salla-flex salla-items-center salla-justify-between">
              <div>
                <h2 class="salla-text-xl salla-font-semibold salla-text-gray-900">Salla Users</h2>
                <p class="salla-mt-1 salla-text-sm salla-text-gray-500">Manage and monitor all connected Salla marketplace users</p>
              </div>
              <div class="salla-flex salla-items-center salla-space-x-3">
                <div class="salla-relative">
                  <select class="salla-rounded-lg salla-border salla-border-gray-300 salla-py-2 salla-pl-3 salla-pr-10 salla-text-sm focus:salla-border-blue-500 focus:salla-ring-1 focus:salla-ring-blue-500">
                    <option>All Users</option>
                    <option>Active Users</option>
                    <option>Inactive Users</option>
                  </select>
                </div>
                <div class="salla-relative">
                  <input
                    type="text"
                    placeholder="Search users..."
                    class="salla-w-64 salla-rounded-lg salla-border salla-border-gray-300 salla-py-2 salla-pl-10 salla-pr-4 salla-text-sm focus:salla-border-blue-500 focus:salla-ring-1 focus:salla-ring-blue-500"
                  >
                  <div class="salla-absolute salla-left-3 salla-top-2.5">
                    <svg class="salla-h-5 salla-w-5 salla-text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="salla-overflow-x-auto">
          <table class="salla-min-w-full salla-divide-y salla-divide-gray-200">
            <thead class="salla-bg-gray-50">
              <tr>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">
                  User
                </th>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">
                  Merchant ID
                </th>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">
                  Status
                </th>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">
                  Current Plan
                </th>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">
                  Subscription
                </th>
              </tr>
            </thead>
            <tbody class="salla-bg-white salla-divide-y salla-divide-gray-200">
              <tr v-for="user in sallaUsers" :key="user.id" class="hover:salla-bg-gray-50">
                <td class="salla-px-6 salla-py-4">
                  <div class="salla-flex salla-items-center">
                    <div class="salla-flex-shrink-0 salla-h-10 salla-w-10">
                      <div class="salla-h-10 salla-w-10 salla-rounded-full salla-bg-gray-200 salla-flex salla-items-center salla-justify-center">
                        <span class="salla-text-sm salla-font-medium salla-text-gray-600">
                          {{ user.username.charAt(0).toUpperCase() }}
                        </span>
                      </div>
                    </div>
                    <div class="salla-ml-4">
                      <div class="salla-text-sm salla-font-medium salla-text-gray-900">{{ user.username }}</div>
                      <div class="salla-text-sm salla-text-gray-500">{{ user.email }}</div>
                    </div>
                  </div>
                </td>
                <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap">
                  <div class="salla-flex salla-items-center">
                    <svg class="salla-h-5 salla-w-5 salla-text-gray-400 salla-mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                    </svg>
                    <span class="salla-text-sm salla-text-gray-900">{{ user.merchant_id }}</span>
                  </div>
                </td>
                <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap">
                  <span
                    :class="{
                      'salla-bg-green-100 salla-text-green-800': user.is_subscribed,
                      'salla-bg-red-100 salla-text-red-800': !user.is_subscribed
                    }"
                    class="salla-px-3 salla-py-1 salla-inline-flex salla-text-xs salla-leading-5 salla-font-semibold salla-rounded-full"
                  >
                    <svg v-if="user.is_subscribed" class="salla-mr-1.5 salla-h-4 salla-w-4 salla-text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <svg v-else class="salla-mr-1.5 salla-h-4 salla-w-4 salla-text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    {{ user.is_subscribed ? 'Active' : 'Inactive' }}
                  </span>
                </td>
                <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap">
                  <div class="salla-text-sm salla-text-gray-900">
                    {{ user.active_subscription?.plan_name || 'No active plan' }}
                  </div>
                  <div v-if="user.active_subscription?.plan_name" class="salla-text-sm salla-text-gray-500">
                    Started {{ formatDate(user.active_subscription?.start_date) }}
                  </div>
                </td>
                <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap">
                  <div class="salla-flex salla-items-center">
                    <div v-if="user.active_subscription?.end_date" class="salla-flex salla-items-center">
                      <svg class="salla-h-5 salla-w-5 salla-text-gray-400 salla-mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                      </svg>
                      <div class="salla-text-sm salla-text-gray-500">
                        Expires {{ formatDate(user.active_subscription?.end_date) }}
                      </div>
                    </div>
                    <div v-else class="salla-text-sm salla-text-gray-500">
                      No active subscription
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="salla-bg-gray-50 salla-px-6 salla-py-4 salla-border-t salla-border-gray-200">
          <div class="salla-flex salla-items-center salla-justify-between">
            <div class="salla-flex salla-items-center">
              <select class="salla-mr-2 salla-rounded-md salla-border salla-border-gray-300 salla-py-1 salla-pl-3 salla-pr-8 salla-text-sm">
                <option>10 per page</option>
                <option>25 per page</option>
                <option>50 per page</option>
              </select>
              <span class="salla-text-sm salla-text-gray-500">
                Showing 1 to 10 of {{ sallaUsers.length }} users
              </span>
            </div>
            <nav class="salla-relative salla-z-0 salla-inline-flex salla-rounded-md salla-shadow-sm -salla-space-x-px">
              <a href="#" class="salla-relative salla-inline-flex salla-items-center salla-px-2 salla-py-2 salla-rounded-l-md salla-border salla-border-gray-300 salla-bg-white salla-text-sm salla-font-medium salla-text-gray-500 hover:salla-bg-gray-50">
                Previous
              </a>
              <a href="#" class="salla-relative salla-inline-flex salla-items-center salla-px-4 salla-py-2 salla-border salla-border-gray-300 salla-bg-white salla-text-sm salla-font-medium salla-text-gray-700 hover:salla-bg-gray-50">
                1
              </a>
              <a href="#" class="salla-relative salla-inline-flex salla-items-center salla-px-4 salla-py-2 salla-border salla-border-gray-300 salla-bg-blue-50 salla-text-sm salla-font-medium salla-text-blue-600">
                2
              </a>
              <a href="#" class="salla-relative salla-inline-flex salla-items-center salla-px-4 salla-py-2 salla-border salla-border-gray-300 salla-bg-white salla-text-sm salla-font-medium salla-text-gray-700 hover:salla-bg-gray-50">
                3
              </a>
              <a href="#" class="salla-relative salla-inline-flex salla-items-center salla-px-2 salla-py-2 salla-rounded-r-md salla-border salla-border-gray-300 salla-bg-white salla-text-sm salla-font-medium salla-text-gray-500 hover:salla-bg-gray-50">
                Next
              </a>
            </nav>
          </div>
        </div>
      </div>
      <!-- Subscriptions Section -->
      <div class="salla-bg-white salla-rounded-xl salla-shadow-sm salla-mb-8">
        <div class="salla-border-b salla-border-gray-200">
          <div class="salla-p-6">
            <div class="salla-flex salla-items-center salla-justify-between">
              <div>
                <h2 class="salla-text-xl salla-font-semibold salla-text-gray-900">Subscription History</h2>
                <p class="salla-mt-1 salla-text-sm salla-text-gray-500">Track all subscription activities and transactions</p>
              </div>
              <div class="salla-flex salla-items-center salla-space-x-3">
                <div class="salla-relative">
                  <select class="salla-rounded-lg salla-border salla-border-gray-300 salla-py-2 salla-pl-3 salla-pr-10 salla-text-sm focus:salla-border-blue-500 focus:salla-ring-1 focus:salla-ring-blue-500">
                    <option>All Status</option>
                    <option>Active</option>
                    <option>Expired</option>
                    <option>Canceled</option>
                  </select>
                </div>
                <button class="salla-inline-flex salla-items-center salla-px-4 salla-py-2 salla-border salla-border-gray-300 salla-rounded-lg salla-shadow-sm salla-text-sm salla-font-medium salla-text-gray-700 salla-bg-white hover:salla-bg-gray-50">
                  <svg class="salla--ml-1 salla-mr-2 salla-h-5 salla-w-5 salla-text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                  Export Report
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="salla-overflow-x-auto">
          <table class="salla-min-w-full salla-divide-y salla-divide-gray-200">
            <thead class="salla-bg-gray-50">
              <tr>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">
                  Subscription Details
                </th>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">
                  Plan & Status
                </th>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">
                  Period
                </th>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">
                  Amount
                </th>
                <th scope="col" class="salla-relative salla-px-6 salla-py-3">
                  <span class="salla-sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody class="salla-bg-white salla-divide-y salla-divide-gray-200">
              <tr v-for="sub in subscriptions" :key="sub.id" class="hover:salla-bg-gray-50">
                <td class="salla-px-6 salla-py-4">
                  <div class="salla-flex salla-items-center">
                    <div class="salla-flex-shrink-0 salla-h-10 salla-w-10">
                      <div class="salla-h-10 salla-w-10 salla-rounded-full salla-bg-gray-200 salla-flex salla-items-center salla-justify-center">
                        <span class="salla-text-sm salla-font-medium salla-text-gray-600">
                          {{ sub.user_email.charAt(0).toUpperCase() }}
                        </span>
                      </div>
                    </div>
                    <div class="salla-ml-4">
                      <div class="salla-text-sm salla-font-medium salla-text-gray-900">{{ sub.user_email }}</div>
                      <div class="salla-text-sm salla-text-gray-500">ID: #{{ sub.id }}</div>
                    </div>
                  </div>
                </td>
                <td class="salla-px-6 salla-py-4">
                  <div class="salla-text-sm salla-font-medium salla-text-gray-900">{{ sub.plan_name }}</div>
                  <span
                    :class="{
                      'salla-bg-green-100 salla-text-green-800': sub.status === 'active',
                      'salla-bg-red-100 salla-text-red-800': sub.status === 'canceled',
                      'salla-bg-yellow-100 salla-text-yellow-800': sub.status === 'expired'
                    }"
                    class="salla-mt-1 salla-px-2.5 salla-py-0.5 salla-inline-flex salla-text-xs salla-leading-5 salla-font-medium salla-rounded-full"
                  >
                    {{ sub.status.charAt(0).toUpperCase() + sub.status.slice(1) }}
                  </span>
                </td>
                <td class="salla-px-6 salla-py-4">
                  <div class="salla-flex salla-items-center">
                    <svg class="salla-h-5 salla-w-5 salla-text-gray-400 salla-mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <div>
                      <div class="salla-text-sm salla-text-gray-900">{{ formatDate(sub.start_date) }}</div>
                      <div class="salla-text-sm salla-text-gray-500">Until {{ formatDate(sub.end_date) }}</div>
                    </div>
                  </div>
                </td>
                <td class="salla-px-6 salla-py-4">
                  <div class="salla-text-sm salla-font-medium salla-text-gray-900">
                    ${{ sub.price.toLocaleString('en-US', { minimumFractionDigits: 2 }) }}
                  </div>
                  <div class="salla-text-xs salla-text-gray-500">USD</div>
                </td>
                <td class="salla-px-6 salla-py-4 salla-text-right salla-text-sm salla-font-medium">
                  <button class="salla-text-blue-600 hover:salla-text-blue-900">
                    View Details
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Pagination -->
        <div class="salla-bg-gray-50 salla-px-6 salla-py-4 salla-border-t salla-border-gray-200">
          <div class="salla-flex salla-items-center salla-justify-between">
            <div class="salla-flex salla-items-center">
              <select class="salla-mr-2 salla-rounded-md salla-border salla-border-gray-300 salla-py-1 salla-pl-3 salla-pr-8 salla-text-sm">
                <option>10 per page</option>
                <option>25 per page</option>
                <option>50 per page</option>
              </select>
              <span class="salla-text-sm salla-text-gray-500">
                Showing 1 to 10 of {{ subscriptions.length }} subscriptions
              </span>
            </div>
            <nav class="salla-relative salla-z-0 salla-inline-flex salla-rounded-md salla-shadow-sm -salla-space-x-px">
              <a href="#" class="salla-relative salla-inline-flex salla-items-center salla-px-2 salla-py-2 salla-rounded-l-md salla-border salla-border-gray-300 salla-bg-white salla-text-sm salla-font-medium salla-text-gray-500 hover:salla-bg-gray-50">
                Previous
              </a>
              <a href="#" class="salla-relative salla-inline-flex salla-items-center salla-px-4 salla-py-2 salla-border salla-border-gray-300 salla-bg-white salla-text-sm salla-font-medium salla-text-gray-700 hover:salla-bg-gray-50">
                1
              </a>
              <a href="#" class="salla-relative salla-inline-flex salla-items-center salla-px-4 salla-py-2 salla-border salla-border-gray-300 salla-bg-blue-50 salla-text-sm salla-font-medium salla-text-blue-600">
                2
              </a>
              <a href="#" class="salla-relative salla-inline-flex salla-items-center salla-px-4 salla-py-2 salla-border salla-border-gray-300 salla-bg-white salla-text-sm salla-font-medium salla-text-gray-700 hover:salla-bg-gray-50">
                3
              </a>
              <a href="#" class="salla-relative salla-inline-flex salla-items-center salla-px-2 salla-py-2 salla-rounded-r-md salla-border salla-border-gray-300 salla-bg-white salla-text-sm salla-font-medium salla-text-gray-500 hover:salla-bg-gray-50">
                Next
              </a>
            </nav>
          </div>
        </div>
      </div>

      <!-- Plan Modal -->
      <Transition
        enter-active-class="salla-transition-opacity salla-duration-300 salla-ease-out"
        enter-from-class="salla-opacity-0"
        enter-to-class="salla-opacity-100"
        leave-active-class="salla-transition-opacity salla-duration-200 salla-ease-in"
        leave-from-class="salla-opacity-100"
        leave-to-class="salla-opacity-0"
      >
        <div v-if="showPlanModal" class="salla-fixed salla-inset-0 salla-z-50">
          <!-- Background overlay -->
          <div class="salla-fixed salla-inset-0 salla-bg-gray-500 salla-bg-opacity-75 salla-transition-opacity" @click="showPlanModal = false"></div>

          <!-- Modal panel -->
          <div class="salla-fixed salla-inset-0 salla-z-10 salla-overflow-y-auto">
            <div class="salla-flex salla-min-h-full salla-items-end salla-justify-center salla-p-4 salla-text-center sm:salla-items-center sm:salla-p-0">
              <Transition
                enter-active-class="salla-ease-out salla-duration-300"
                enter-from-class="salla-opacity-0 salla-translate-y-4 sm:salla-translate-y-0 sm:salla-scale-95"
                enter-to-class="salla-opacity-100 salla-translate-y-0 sm:salla-scale-100"
                leave-active-class="salla-ease-in salla-duration-200"
                leave-from-class="salla-opacity-100 salla-translate-y-0 sm:salla-scale-100"
                leave-to-class="salla-opacity-0 salla-translate-y-4 sm:salla-translate-y-0 sm:salla-scale-95"
              >
                <div v-if="showPlanModal" class="salla-relative salla-transform salla-overflow-hidden salla-rounded-lg salla-bg-white salla-px-4 salla-pt-5 salla-pb-4 salla-text-left salla-shadow-xl salla-transition-all sm:salla-my-8 sm:salla-w-full sm:salla-max-w-lg sm:salla-p-6">
                  <div class="salla-absolute salla-top-0 salla-right-0 salla-hidden salla-pt-4 salla-pr-4 sm:salla-block">
                    <button
                      type="button"
                      class="salla-rounded-md salla-bg-white salla-text-gray-400 hover:salla-text-gray-500 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-blue-500 focus:salla-ring-offset-2"
                      @click="showPlanModal = false"
                    >
                      <span class="salla-sr-only">Close</span>
                      <svg class="salla-h-6 salla-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>

                  <div class="sm:salla-flex sm:salla-items-start">
                    <div class="salla-mx-auto salla-flex salla-h-12 salla-w-12 salla-flex-shrink-0 salla-items-center salla-justify-center salla-rounded-full salla-bg-blue-100 sm:salla-mx-0 sm:salla-h-10 sm:salla-w-10">
                      <svg class="salla-h-6 salla-w-6 salla-text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                    </div>
                    <div class="salla-mt-3 salla-text-center sm:salla-mt-0 sm:salla-ml-4 sm:salla-text-left">
                      <h3 class="salla-text-lg salla-font-medium salla-leading-6 salla-text-gray-900">
                        {{ editingPlan ? 'Edit Plan' : 'Create New Plan' }}
                      </h3>
                      <div class="salla-mt-2">
                        <p class="salla-text-sm salla-text-gray-500">
                          {{ editingPlan ? 'Update the plan details below.' : 'Fill in the details for your new plan.' }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <form @submit.prevent="savePlan" class="salla-mt-6 salla-space-y-6">
                    <div>
                      <label for="planName" class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">
                        Plan Name
                      </label>
                      <div class="salla-mt-1">
                        <input
                          id="planName"
                          type="text"
                          v-model="planForm.plan_name"
                          required
                          class="salla-block salla-w-full salla-rounded-md salla-border-gray-300 salla-shadow-sm focus:salla-border-blue-500 focus:salla-ring-blue-500 sm:salla-text-sm"
                          placeholder="Enter plan name"
                        />
                      </div>
                    </div>

                    <div>
                      <label for="tokensAmount" class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">
                        Tokens Amount
                      </label>
                      <div class="salla-mt-1">
                        <div class="salla-relative salla-rounded-md salla-shadow-sm">
                          <input
                            id="tokensAmount"
                            type="number"
                            v-model.number="planForm.tokens_amount"
                            required
                            min="1"
                            class="salla-block salla-w-full salla-rounded-md salla-border-gray-300 salla-pl-3 salla-pr-12 focus:salla-border-blue-500 focus:salla-ring-blue-500 sm:salla-text-sm"
                            placeholder="Enter tokens amount"
                          />
                          <div class="salla-absolute salla-inset-y-0 salla-right-0 salla-flex salla-items-center salla-pr-3">
                            <span class="salla-text-gray-500 sm:salla-text-sm">tokens</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="salla-mt-6 salla-flex salla-items-center salla-justify-end salla-space-x-4">
                      <button
                        type="button"
                        class="salla-inline-flex salla-justify-center salla-rounded-md salla-border salla-border-gray-300 salla-bg-white salla-px-4 salla-py-2 salla-text-sm salla-font-medium salla-text-gray-700 salla-shadow-sm hover:salla-bg-gray-50 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-blue-500 focus:salla-ring-offset-2"
                        @click="showPlanModal = false"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        class="salla-inline-flex salla-justify-center salla-rounded-md salla-border salla-border-transparent salla-bg-blue-600 salla-px-4 salla-py-2 salla-text-sm salla-font-medium salla-text-white salla-shadow-sm hover:salla-bg-blue-700 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-blue-500 focus:salla-ring-offset-2"
                      >
                        {{ editingPlan ? 'Update Plan' : 'Create Plan' }}
                      </button>
                    </div>
                  </form>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { API_URL } from '@/config';

export default {
  name: 'SallaSettings',
  setup() {
    const sallaUsers = ref([]);
    const plans = ref([]);
    const subscriptions = ref([]);
    const showPlanModal = ref(false);
    const editingPlan = ref(null);
    const planForm = ref({
      plan_name: '',
      tokens_amount: null
    });

    const fetchSallaUsers = async () => {
      try {
        const response = await fetch(`${API_URL}/api/admin/salla/users`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();
        sallaUsers.value = data.users;
      } catch (error) {
        console.error('Error fetching Salla users:', error);
        // Add toast notification for error
      }
    };

    const fetchPlans = async () => {
      try {
        const response = await fetch(`${API_URL}/api/admin/salla/plans`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();
        plans.value = data.plans;
      } catch (error) {
        console.error('Error fetching plans:', error);
        // Add toast notification for error
      }
    };

    const fetchSubscriptions = async () => {
      try {
        const response = await fetch(`${API_URL}/api/admin/salla/subscriptions`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();
        subscriptions.value = data.subscriptions;
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
        // Add toast notification for error
      }
    };

    const openNewPlanModal = () => {
      editingPlan.value = null;
      planForm.value = { plan_name: '', tokens_amount: null };
      showPlanModal.value = true;
    };

    const editPlan = (plan) => {
      editingPlan.value = plan;
      planForm.value = { ...plan };
      showPlanModal.value = true;
    };

    const savePlan = async () => {
      try {
        const url = editingPlan.value
          ? `${API_URL}/api/admin/salla/plans/${editingPlan.value.id}`
          : `${API_URL}/api/admin/salla/plans`;

        const response = await fetch(url, {
          method: editingPlan.value ? 'PUT' : 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify(planForm.value)
        });

        if (response.ok) {
          showPlanModal.value = false;
          await fetchPlans();
          // Add success toast notification
        }
      } catch (error) {
        console.error('Error saving plan:', error);
        // Add error toast notification
      }
    };

    const deletePlan = async (planId) => {
      if (!confirm('Are you sure you want to delete this plan?')) return;

      try {
        const response = await fetch(`${API_URL}/api/admin/salla/plans/${planId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.ok) {
          await fetchPlans();
          // Add success toast notification
        }
      } catch (error) {
        console.error('Error deleting plan:', error);
        // Add error toast notification
      }
    };

    const formatDate = (dateString) => {
      if (!dateString) return '-';
      return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    };

    onMounted(() => {
      fetchSallaUsers();
      fetchPlans();
      fetchSubscriptions();
    });

    return {
      sallaUsers,
      plans,
      subscriptions,
      showPlanModal,
      editingPlan,
      planForm,
      openNewPlanModal,
      editPlan,
      savePlan,
      deletePlan,
      formatDate
    };
  }
};
</script>
