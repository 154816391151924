<template>
    <div class="dashboard">
      <main class="main-content">
        <section class="content">
          <div class="content-header">
            <div class="social-links">
              <button class="social-button active">Twitter</button>
              <button class="social-button">Instagram</button>
              <button class="social-button">Facebook</button>
              <button class="social-button">Tiktok</button>
            </div>
            <h2 class="section-title">Analytics</h2>
            <!-- Insert Chart Component directly below the analytics title -->
            <ChartComponent />
          </div>
        </section>
      </main>
    </div>
</template>

<script>
import ChartComponent from './ChartComponent.vue'; // Adjust the path as necessary

export default {
  components: {
    ChartComponent
  }
}
</script>


  <style scoped>
  .dashboard {
    display: flex;
    justify-content: center; /* Centers your main content horizontally */
    align-items: flex-start; /* Aligns content to the top */
    padding: 15px; /* Adds some padding around the dashboard */
    width: 100%;
    margin-left: -200px;
  }
  
  .main-content {
    width: 100%;
    max-width: 95%;
    margin-left: 192px;
    margin-top: -1px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 100%; /* This will now take up 100% of the .main-content width */
  }
  
  .content-header {
    border-radius: 20px;
    background-color: #ffffff; /* Assuming a variable or fallback color here */
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%; /* This ensures the header stretches to the full width of .content */
    padding: 30px 40px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px; /* Adjusted for spacing */
    height: 250vh;
  }
  
  .social-links {
    display: flex;
    gap: 20px;
    font-size: 16px;
    color: #121b25;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .social-button {
    font-family: Inter, sans-serif;
    padding: 8px 16px; /* Adjust padding to increase button size */
    border: none;
    cursor: pointer;
    background: none;
    color: #6b7280; /* Assuming a variable or fallback color here */
    transition: color 0.3s;
  }
  
  .social-button:hover {
    color: #374151; /* Assuming a variable or fallback color here */
  }
  
  .social-button.active {
    color: #2563eb; /* Assuming a variable or fallback color here */
    border-bottom: 2px solid #2563eb; /* Assuming a variable or fallback color here */
  }
  
  .section-title {
    color: #111827; /* Assuming a variable or fallback color here */
    letter-spacing: -0.5px;
    font-size: 28px; /* Increased font size for the title */
    font-weight: 600;
    line-height: 165%;
    margin-bottom: 20px; /* Add space below the title */
  }
  
  /* Add responsive breakpoints if necessary to handle different screen sizes */
/* Responsive Styles */
@media (max-width: 1200px) {
  .dashboard {
    margin-left: 0;
  }

  .main-content {
    max-width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 992px) {
  .content-header {
    height: auto;
    padding: 20px;
  }

  .section-title {
    font-size: 24px;
  }

  .social-links {
    margin-bottom: 15px;
  }

  .social-button {
    padding: 6px 12px;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .dashboard {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .content {
    width: 100%;
  }

  .content-header {
    width: 100%;
    border-radius: 10px;
    padding: 15px;
  }

  .section-title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .social-links {
    gap: 10px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .social-button {
    padding: 4px 8px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .content-header {
    padding: 10px;
  }

  .section-title {
    font-size: 18px;
  }

  .social-links {
    flex-wrap: wrap;
    justify-content: center;
  }

  .social-button {
    padding: 2px 6px;
    font-size: 10px;
  }
}
  </style>
  