<template>
    <div class="salla-fixed salla-inset-0 salla-z-50 salla-overflow-y-auto">
      <div class="salla-flex salla-items-center salla-justify-center salla-min-h-screen salla-px-4 salla-pt-4 salla-pb-20 salla-text-center sm:salla-block sm:salla-p-0">
        <!-- Backdrop -->
        <div class="salla-fixed salla-inset-0 salla-bg-gray-500 salla-bg-opacity-75 salla-transition-opacity"></div>
  
        <!-- Modal Panel -->
        <div class="salla-inline-block salla-align-bottom salla-bg-white salla-rounded-lg salla-text-left salla-overflow-hidden salla-shadow-xl salla-transform salla-transition-all sm:salla-my-8 sm:salla-align-middle sm:salla-max-w-4xl sm:salla-w-full">
          <!-- Header -->
          <div class="salla-bg-white salla-px-6 salla-py-4 salla-border-b salla-border-gray-200">
            <div class="salla-flex salla-items-center salla-justify-between">
              <h3 class="salla-text-lg salla-font-medium salla-text-gray-900">
                {{ mode === 'view' ? 'User Details' : 'Edit User' }}
              </h3>
              <button 
                @click="$emit('close')"
                class="salla-rounded-md salla-text-gray-400 hover:salla-text-gray-500"
              >
                <span class="salla-sr-only">Close</span>
                <span class="salla-text-2xl">&times;</span>
              </button>
            </div>
          </div>
  
          <!-- Loading State -->
          <div v-if="isLoading" class="salla-p-6 salla-text-center">
            <div class="salla-animate-spin salla-h-8 salla-w-8 salla-border-4 salla-border-primary-500 salla-border-t-transparent salla-rounded-full salla-mx-auto"></div>
            <p class="salla-mt-2 salla-text-sm salla-text-gray-500">Loading...</p>
          </div>
  
          <!-- Error State -->
          <div v-else-if="error" class="salla-p-6 salla-text-center">
            <div class="salla-text-red-600">{{ error }}</div>
            <button 
              @click="fetchUserData"
              class="salla-mt-4 salla-text-primary-600 hover:salla-text-primary-900"
            >
              Try Again
            </button>
          </div>
  
          <!-- Content -->
          <div v-else class="salla-bg-white salla-px-6 salla-py-4">
            <div class="salla-flex salla-flex-col salla-gap-6">
              <!-- Tabs - Only show certain tabs in edit mode -->
              <div class="salla-border-b salla-border-gray-200">
                <nav class="salla-flex salla-space-x-8" aria-label="Tabs">
                  <button
                    v-for="tab in getVisibleTabs"
                    :key="tab.value"
                    @click="activeTab = tab.value"
                    :class="[
                      'salla-whitespace-nowrap salla-py-4 salla-px-1 salla-border-b-2 salla-font-medium salla-text-sm',
                      activeTab === tab.value
                        ? 'salla-border-primary-500 salla-text-primary-600'
                        : 'salla-border-transparent salla-text-gray-500 hover:salla-text-gray-700 hover:salla-border-gray-300'
                    ]"
                  >
                    {{ tab.label }}
                  </button>
                </nav>
              </div>
  
              <!-- Tab Content -->
              <div class="salla-flex-1 salla-min-h-0 salla-overflow-y-auto" style="max-height: 60vh;">
                <!-- General Info Tab -->
                <div v-if="activeTab === 'general'" class="salla-space-y-6">
                  <div class="salla-grid salla-grid-cols-1 salla-gap-6 sm:salla-grid-cols-2">
                    <!-- General Info Fields as before -->
                    <div class="salla-space-y-1">
                      <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">Username</label>
                      <input
                        v-model="userData.username"
                        :disabled="mode === 'view'"
                        type="text"
                        class="salla-mt-1 salla-block salla-w-full salla-rounded-md salla-border-gray-300 salla-shadow-sm focus:salla-border-primary-500 focus:salla-ring-primary-500 sm:salla-text-sm"
                      />
                    </div>
  
                    <div class="salla-space-y-1">
                      <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">Email</label>
                      <input
                        v-model="userData.email"
                        :disabled="mode === 'view'"
                        type="email"
                        class="salla-mt-1 salla-block salla-w-full salla-rounded-md salla-border-gray-300 salla-shadow-sm focus:salla-border-primary-500 focus:salla-ring-primary-500 sm:salla-text-sm"
                      />
                    </div>
  
                    <div class="salla-space-y-1">
                      <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">Phone</label>
                      <input
                        v-model="userData.phone"
                        :disabled="mode === 'view'"
                        type="text"
                        class="salla-mt-1 salla-block salla-w-full salla-rounded-md salla-border-gray-300 salla-shadow-sm focus:salla-border-primary-500 focus:salla-ring-primary-500 sm:salla-text-sm"
                      />
                    </div>
  
                    <div class="salla-space-y-1">
                      <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">Address</label>
                      <input
                        v-model="userData.address"
                        :disabled="mode === 'view'"
                        type="text"
                        class="salla-mt-1 salla-block salla-w-full salla-rounded-md salla-border-gray-300 salla-shadow-sm focus:salla-border-primary-500 focus:salla-ring-primary-500 sm:salla-text-sm"
                      />
                    </div>
                  </div>
  
                  <div class="salla-flex salla-flex-wrap salla-gap-4">
                    <div class="salla-flex salla-items-center">
                      <input
                        v-model="userData.is_verified"
                        :disabled="mode === 'view'"
                        type="checkbox"
                        class="salla-h-4 salla-w-4 salla-rounded salla-border-gray-300 salla-text-primary-600 focus:salla-ring-primary-500"
                      />
                      <label class="salla-ml-2 salla-block salla-text-sm salla-text-gray-900">Verified User</label>
                    </div>
  
                    <div class="salla-flex salla-items-center">
                      <input
                        v-model="userData.is_admin"
                        :disabled="mode === 'view'"
                        type="checkbox"
                        class="salla-h-4 salla-w-4 salla-rounded salla-border-gray-300 salla-text-primary-600 focus:salla-ring-primary-500"
                      />
                      <label class="salla-ml-2 salla-block salla-text-sm salla-text-gray-900">Admin Access</label>
                    </div>
  
                    <div class="salla-flex salla-items-center">
                      <input
                        v-model="userData.is_subscribed"
                        :disabled="mode === 'view'"
                        type="checkbox"
                        class="salla-h-4 salla-w-4 salla-rounded salla-border-gray-300 salla-text-primary-600 focus:salla-ring-primary-500"
                      />
                      <label class="salla-ml-2 salla-block salla-text-sm salla-text-gray-900">Subscribed</label>
                    </div>
                  </div>
                </div>
  
                <!-- Videos Tab -->
                <div v-if="activeTab === 'videos'" class="salla-space-y-6">
                  <div class="salla-grid salla-grid-cols-1 md:salla-grid-cols-2 salla-gap-6">
                    <div v-for="video in userData.videos" :key="video.id" class="salla-bg-gray-50 salla-rounded-lg salla-p-4">
                      <video 
                        :src="video.video_url"
                        controls
                        class="salla-w-full salla-rounded-lg salla-mb-2"
                      ></video>
                      <div class="salla-space-y-2">
                        <p class="salla-text-sm salla-text-gray-600">Created: {{ formatDate(video.created_at) }}</p>
                        <p class="salla-text-sm salla-text-gray-600">Duration: {{ video.duration }}s</p>
                        <p class="salla-text-sm salla-text-gray-600">Format: {{ video.output_format }}</p>
                      </div>
                    </div>
                  </div>
                </div>
  
                <!-- Subscription Tab -->
                <div v-if="activeTab === 'subscription'" class="salla-space-y-6">
                  <div v-if="userData.subscription" class="salla-bg-white salla-rounded-lg salla-border salla-p-4">
                    <div class="salla-grid salla-grid-cols-2 salla-gap-4">
                      <div class="salla-space-y-2">
                        <h4 class="salla-text-sm salla-font-medium salla-text-gray-500">Status</h4>
                        <p class="salla-text-sm salla-font-semibold">
                          <span 
                            :class="[
                              'salla-px-2 salla-py-1 salla-rounded-full salla-text-xs',
                              userData.subscription.status === 'active' 
                                ? 'salla-bg-green-100 salla-text-green-800' 
                                : 'salla-bg-red-100 salla-text-red-800'
                            ]"
                          >
                            {{ userData.subscription.status }}
                          </span>
                        </p>
                      </div>
                      <div class="salla-space-y-2">
                        <h4 class="salla-text-sm salla-font-medium salla-text-gray-500">Plan</h4>
                        <p class="salla-text-sm">{{ userData.subscription.plan_product_id }}</p>
                      </div>
                      <div class="salla-space-y-2">
                        <h4 class="salla-text-sm salla-font-medium salla-text-gray-500">Current Period Start</h4>
                        <p class="salla-text-sm">{{ formatDate(userData.subscription.current_period_start) }}</p>
                      </div>
                      <div class="salla-space-y-2">
                        <h4 class="salla-text-sm salla-font-medium salla-text-gray-500">Current Period End</h4>
                        <p class="salla-text-sm">{{ formatDate(userData.subscription.current_period_end) }}</p>
                      </div>
                    </div>
                  </div>
                  <div v-else class="salla-text-center salla-py-6 salla-text-gray-500">
                    No active subscription found
                  </div>
                </div>
  
                <!-- Products Tab -->
                <div v-if="activeTab === 'products'" class="salla-space-y-6">
                  <div class="salla-grid salla-grid-cols-1 md:salla-grid-cols-3 salla-gap-6">
                    <div v-for="product in userData.products" :key="product.id" 
                      class="salla-bg-white salla-rounded-lg salla-border salla-overflow-hidden">
                      <img 
                        :src="product.image_url" 
                        :alt="product.name"
                        class="salla-w-full salla-h-48 salla-object-cover"
                      />
                      <div class="salla-p-4 salla-space-y-2">
                        <h4 class="salla-font-medium salla-text-gray-900">{{ product.name }}</h4>
                        <p class="salla-text-sm salla-text-gray-500">Price: {{ product.price }}</p>
                        <a 
                          :href="product.url" 
                          target="_blank"
                          class="salla-text-primary-600 salla-text-sm hover:salla-text-primary-700"
                        >
                          View Product
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
  
                <!-- Credits Tab -->
                <div v-if="activeTab === 'credits'" class="salla-space-y-6">
                  <div v-if="mode === 'edit'" class="salla-bg-gray-50 salla-p-4 salla-rounded-lg">
                    <h4 class="salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-4">Add Credits</h4>
                    <div class="salla-grid salla-grid-cols-2 salla-gap-4">
                      <div class="salla-space-y-1">
                        <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">Amount</label>
                        <input
                          v-model="newCredit.amount"
                          type="number"
                          class="salla-mt-1 salla-block salla-w-full salla-rounded-md salla-border-gray-300 salla-shadow-sm focus:salla-border-primary-500 focus:salla-ring-primary-500 sm:salla-text-sm"
                        />
                      </div>
                      <div class="salla-space-y-1">
                        <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">Expiry Date</label>
                      <input
                        v-model="newCredit.expires_at"
                        type="date"
                        class="salla-mt-1 salla-block salla-w-full salla-rounded-md salla-border-gray-300 salla-shadow-sm focus:salla-border-primary-500 focus:salla-ring-primary-500 sm:salla-text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div class="salla-space-y-4">
                  <h4 class="salla-text-sm salla-font-medium salla-text-gray-700">Credits History</h4>
                  <div class="salla-space-y-3">
                    <div v-for="credit in userData.credits" :key="credit.id" 
                      class="salla-bg-white salla-rounded-lg salla-border salla-p-4">
                      <div class="salla-grid salla-grid-cols-3 salla-gap-4">
                        <div>
                          <p class="salla-text-sm salla-font-medium salla-text-gray-500">Amount</p>
                          <p class="salla-text-sm">{{ credit.credits_amount }}</p>
                        </div>
                        <div>
                          <p class="salla-text-sm salla-font-medium salla-text-gray-500">Purchased</p>
                          <p class="salla-text-sm">{{ formatDate(credit.purchased_at) }}</p>
                        </div>
                        <div>
                          <p class="salla-text-sm salla-font-medium salla-text-gray-500">Expires</p>
                          <p class="salla-text-sm">{{ formatDate(credit.expires_at) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Payments Tab -->
              <div v-if="activeTab === 'payments'" class="salla-space-y-6">
                <div class="salla-overflow-hidden salla-shadow ring-1 ring-black ring-opacity-5 md:salla-rounded-lg">
                  <table class="salla-min-w-full salla-divide-y salla-divide-gray-300">
                    <thead class="salla-bg-gray-50">
                      <tr>
                        <th class="salla-px-3 salla-py-3.5 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-900">Date</th>
                        <th class="salla-px-3 salla-py-3.5 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-900">Amount</th>
                        <th class="salla-px-3 salla-py-3.5 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-900">Card</th>
                        <th class="salla-px-3 salla-py-3.5 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-900">Status</th>
                      </tr>
                    </thead>
                    <tbody class="salla-divide-y salla-divide-gray-200 salla-bg-white">
                      <tr v-for="payment in userData.payments" :key="payment.id">
                        <td class="salla-whitespace-nowrap salla-px-3 salla-py-4 salla-text-sm salla-text-gray-500">
                          {{ formatDate(payment.created) }}
                        </td>
                        <td class="salla-whitespace-nowrap salla-px-3 salla-py-4 salla-text-sm salla-text-gray-500">
                          {{ formatAmount(payment.amount, payment.currency) }}
                        </td>
                        <td class="salla-whitespace-nowrap salla-px-3 salla-py-4 salla-text-sm salla-text-gray-500">
                          **** {{ payment.card_last4 }}
                        </td>
                        <td class="salla-whitespace-nowrap salla-px-3 salla-py-4 salla-text-sm">
                          <span 
                            :class="[
                              'salla-px-2 salla-inline-flex salla-text-xs salla-leading-5 salla-font-semibold salla-rounded-full',
                              payment.status === 'succeeded' 
                                ? 'salla-bg-green-100 salla-text-green-800' 
                                : 'salla-bg-red-100 salla-text-red-800'
                            ]"
                          >
                            {{ payment.status }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer -->
        <div class="salla-bg-gray-50 salla-px-6 salla-py-4 salla-flex salla-justify-end salla-space-x-3">
          <button
            @click="$emit('close')"
            class="salla-bg-white salla-py-2 salla-px-4 salla-border salla-border-gray-300 salla-rounded-md salla-shadow-sm salla-text-sm salla-font-medium salla-text-gray-700 hover:salla-bg-gray-50 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-offset-2 focus:salla-ring-primary-500"
          >
            Cancel
          </button>
          <button
            v-if="mode === 'edit'"
            @click="saveChanges"
            :disabled="isLoading"
            class="salla-bg-primary-600 salla-py-2 salla-px-4 salla-border salla-border-transparent salla-rounded-md salla-shadow-sm salla-text-sm salla-font-medium salla-text-white hover:salla-bg-primary-700 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-offset-2 focus:salla-ring-primary-500"
          >
            {{ isLoading ? 'Saving...' : 'Save Changes' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { API_URL } from '@/config';

export default {
  name: 'UserAdminEdit',
  props: {
    userId: {
      type: [String, Number],
      required: true
    },
    mode: {
      type: String,
      default: 'view',
      validator: (value) => ['view', 'edit'].includes(value)
    }
  },
  emits: ['close', 'update'],
  
  setup(props, { emit }) {
    const userData = ref({
      username: '',
      email: '',
      phone: '',
      address: '',
      is_verified: false,
      is_admin: false,
      is_subscribed: false,
      subscription: null,
      credits: [],
      payments: [],
      videos: [],
      products: []
    });

    const activeTab = ref('general');
    const isLoading = ref(false);
    const error = ref(null);
    const newCredit = ref({
      amount: 0,
      expires_at: ''
    });

    // Define all available tabs
    const allTabs = [
      { label: 'General Info', value: 'general' },
      { label: 'Videos', value: 'videos' },
      { label: 'Subscription', value: 'subscription' },
      { label: 'Products', value: 'products' },
      { label: 'Credits', value: 'credits' },
      { label: 'Payments', value: 'payments' }
    ];

    // Computed property to show different tabs based on mode
    const getVisibleTabs = computed(() => {
      if (props.mode === 'edit') {
        return allTabs.filter(tab => ['general', 'credits'].includes(tab.value));
      }
      return allTabs;
    });

    const formatDate = (date) => {
      if (!date) return 'N/A';
      return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    };

    const formatAmount = (amount, currency) => {
      if (!amount) return 'N/A';
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'USD'
      }).format(amount);
    };

    const fetchUserData = async () => {
      isLoading.value = true;
      error.value = null;

      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No authentication token found');

        const response = await fetch(`${API_URL}/api/admin/users/${props.userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to fetch user data');

        const data = await response.json();
        userData.value = {
          ...userData.value,
          ...data.user
        };
      } catch (err) {
        error.value = err.message;
        console.error('Error fetching user data:', err);
      } finally {
        isLoading.value = false;
      }
    };

    const saveChanges = async () => {
      isLoading.value = true;
      error.value = null;

      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No authentication token found');

        const dataToSave = {
          ...userData.value,
          ...(activeTab.value === 'credits' && {
            credits_amount: newCredit.value.amount,
            expires_at: newCredit.value.expires_at
          })
        };

        const response = await fetch(`${API_URL}/api/admin/users/${props.userId}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(dataToSave)
        });

        if (!response.ok) throw new Error('Failed to update user');

        const data = await response.json();
        emit('update', data.user);
        emit('close');
      } catch (err) {
        error.value = err.message;
        console.error('Error updating user:', err);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchUserData();
    });

    return {
      userData,
      activeTab,
      isLoading,
      error,
      newCredit,
      getVisibleTabs,
      formatDate,
      formatAmount,
      saveChanges,
      fetchUserData
    };
  }
};
</script>