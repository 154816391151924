<template>
  <header class="header">
    <div class="logo-wrapper" @click="goToDashboard">
      <img :src="logoUrl" alt="Logo" class="logo" />
      <div class="logo-text">
        <span class="logo-text-content">{{ siteTitle }}</span>
      </div>
    </div>

    <div class="user-info">
      <div class="user-greeting" dir="rtl">مرحبا <span class="user-name">{{ username }}</span></div>
      <div class="user-actions">
        <button class="notification-btn" @click="toggleNotifications">
          <img :src="require('@/assets/notification-icon.png')" alt="Notification Icon" class="notification-icon" />
          <span class="notification-badge" v-if="notifications.length">{{ notifications.length }}</span>
        </button>
        <div class="notification-dropdown" v-show="notificationsOpen">
          <ul v-if="notifications.length">
            <li v-for="(notification) in notifications" :key="notification.id">
              <div class="notification-content">
                <span :class="['notification-type', notification.type]">{{ notification.type }}</span>
                {{ notification.message }}
                <button class="clear-btn" @click="clearNotification(notification.id)">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </li>
          </ul>
          <p v-else class="no-notifications">You're all caught up! No notifications here.</p>
        </div>
        <div class="dropdown" @click="toggleDropdown">
          <button class="avatar-btn">
            <img :src="profileImageUrl || require('@/assets/user-avatar.png')" alt="User Avatar" class="avatar" />
          </button>
          <div class="dropdown-content" v-show="dropdownOpen">
            <a href="#" @click.prevent="handleLogout">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/services/authService';
import { API_URL } from '@/config';

export default {
  setup() {
    const router = useRouter();
    const username = ref('');
    const profileImageUrl = ref('');
    const dropdownOpen = ref(false);
    const notificationsOpen = ref(false);
    const notifications = ref([]);
    const logoUrl = ref(require('@/assets/logo.png'));
    const siteTitle = ref('Ebda3i.com');
    const pollingInterval = ref(null);

    const startPolling = () => {
      pollingInterval.value = setInterval(() => {
        fetchNotifications();
      }, 2000); // Poll every 2 seconds
    };

    const stopPolling = () => {
      clearInterval(pollingInterval.value);
    };

    const fetchNotifications = async () => {
      const token = AuthService.getToken();
      if (!token) {
        console.log('No token found, aborting fetchNotifications');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/notifications`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        notifications.value = data;
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      }
    };

    const fetchSiteSettings = async () => {
      const token = AuthService.getToken();
      if (!token) {
        console.log('No token found, aborting fetchSiteSettings');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/admin/site-settings`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          logoUrl.value = data.logo_url || logoUrl.value;
          siteTitle.value = data.site_title || siteTitle.value;
        } else {
          console.error('Failed to fetch site settings:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching site settings:', error);
      }
    };

    onMounted(() => {
      if (!AuthService.isLoggedIn()) {
        console.log('No valid session found, redirecting to login.');
        AuthService.clearUserData();
        router.push('/login');
      } else {
        verifyUserStatus();
        startPolling(); // Start polling when the component is mounted
      }
      fetchSiteSettings();
    });

    const verifyUserStatus = async () => {
      const token = AuthService.getToken();
      if (!token || AuthService.isTokenExpired()) {
        console.log('Token expired or not available, redirecting to login.');
        AuthService.clearUserData();
        router.push('/login');
        return;
      }

      const response = await fetch(`${API_URL}/is-verified`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        if (!data.isVerified) {
          console.log('User is not verified, redirecting to verification page.');
          router.push('/verification');
        } else {
          fetchUserInfo();
        }
      } else {
        console.error('Failed to check verification status:', response.statusText);
        AuthService.clearUserData();
        router.push('/login');
      }
    };

    const fetchUserInfo = async () => {
      const token = AuthService.getToken();
      const response = await fetch(`${API_URL}/get_user_info`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        username.value = data.username || 'Anonymous';
        profileImageUrl.value = data.profile_image_url || '';
      } else {
        console.error('Failed to fetch user info:', response.statusText);
        AuthService.clearUserData();
        router.push('/login');
      }
    };

    const toggleDropdown = () => {
      dropdownOpen.value = !dropdownOpen.value;
    };

    const toggleNotifications = () => {
      notificationsOpen.value = !notificationsOpen.value;
      if (notificationsOpen.value) {
        document.addEventListener('click', closeNotificationsOnOutsideClick);
      } else {
        document.removeEventListener('click', closeNotificationsOnOutsideClick);
      }
    };

    const closeNotificationsOnOutsideClick = (event) => {
      if (!event.target.closest('.notification-dropdown') && !event.target.closest('.notification-btn')) {
        notificationsOpen.value = false;
      }
    };

    const clearNotification = async (notificationId) => {
      const token = AuthService.getToken();
      if (!token) {
        console.log('No token found, aborting clearNotification');
        return;
      }

      try {
        await fetch(`${API_URL}/notifications/${notificationId}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        notifications.value = notifications.value.filter(n => n.id !== notificationId);
      } catch (error) {
        console.error('Failed to mark notification as read:', error);
      }
    };

    const handleLogout = () => {
      AuthService.logout();
      router.push('/login');
    };

    const goToDashboard = () => {
      router.push('/dashboard');
    };

    onUnmounted(() => {
      stopPolling(); // Stop polling when the component is unmounted
    });

    return {
      username,
      profileImageUrl,
      dropdownOpen,
      notificationsOpen,
      notifications,
      logoUrl,
      siteTitle,
      toggleDropdown,
      toggleNotifications,
      clearNotification,
      handleLogout,
      goToDashboard
    };
  }
};
</script>


  
  

  

  
<style scoped>
.header {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  transition: background-color 0.3s ease;
  z-index: 1000;
}

.logo-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
    width: auto;
    margin-right: -8px;
    margin-bottom: -1px;
}

.logo-text-content {
  font-size: 20px;
  font-weight: 100;
  color: #000;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-greeting {
  margin-right: -3px;
  font-size: 16px;
  color: #333;
}

.user-name {
  font-weight: bold;
}

.user-actions {
  display: flex;
  align-items: center;
  position: relative;
}

.notification-btn {
  position: relative;
    border: none;
    background: none;
    cursor: pointer;
    margin-right: -18px;
}

.notification-icon {
  height: 24px;
  width: auto;
}

.notification-badge {
  position: absolute;
    top: 5px;
    right: 14px;
    padding: 2px 6px;
    border-radius: 50%;
    background-color: red;
    color: white;
    font-size: 12px;
    z-index: 2;
}

.notification-dropdown {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: #fff;
  min-width: 240px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  border-radius: 4px;
}

.notification-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.notification-dropdown li {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.notification-dropdown li:last-child {
  border-bottom: none;
}

.notification-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-message {
  flex-grow: 1;
  margin-right: 10px;
  font-size: 14px;
  color: #333;
}

.notification-type {
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 10px;
}

.notification-type.success {
  background-color: #d4edda;
  color: #155724;
}

.notification-type.error {
  background-color: #f8d7da;
  color: #721c24;
}

.notification-type.info {
  background-color: #d1ecf1;
  color: #0c5460;
}

.clear-btn {
  border: none;
  background: none;
  color: #888;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.3s;
}

.clear-btn:hover {
  color: #f00;
}

.clear-btn i {
  margin-left: 5px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.avatar-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.dropdown-content {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: #fff;
  min-width: 120px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1;
}

.dropdown-content a {
  display: block;
  text-decoration: none;
  color: #333;
  padding: 5px 0;
}

.no-notifications {
  color: #888;
  font-style: italic;
  margin: 0;
  padding: 10px;
  text-align: center;
}

</style>

 