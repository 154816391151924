import { API_URL } from '@/config';

class AuthService {
  isAdmin() {
    const user = this.getUser();
    console.log('Checking if user is admin:', user);
    return user && user.is_admin;
  }

  async adminLogin(credentials) {
    try {
      console.log('Sending admin login request with credentials:', credentials);
      const response = await fetch(`${API_URL}/api/admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });
      console.log('Admin login response status:', response.status);
      if (!response.ok) {
        throw new Error('Failed to login as admin');
      }
      const data = await response.json();
      console.log('Admin login response data:', data);
      return data;
    } catch (error) {
      console.error('Failed to login as admin:', error);
      throw error;
    }
  }

  getToken() {
    const user = localStorage.getItem('user');
    if (!user) {
      console.warn("No user data found in local storage.");
      return null;
    }
    try {
      const userData = JSON.parse(user);
      
      return userData.token;
    } catch (e) {
      console.error("Error parsing user data from local storage:", e);
      return null;
    }
  }

  isTokenExpired() {
    const user = this.getUser();
    if (!user || !user.tokenExpiration) {
      console.warn("User data or token expiration not found.");
      return true;
    }
    const currentTime = Date.now() / 1000;
    const isExpired = user.tokenExpiration < currentTime;
    console.log(`Token expiration: ${user.tokenExpiration}, Current time: ${currentTime}, Expired: ${isExpired}`);
    return isExpired;
  }

  isLoggedIn() {
    console.log('Checking if user is logged in.');
    const token = this.getToken();
    console.log('Token retrieved:', token);
    const tokenExpired = this.isTokenExpired();
    const loggedIn = !!token && !tokenExpired;
    console.log(`User logged in status: ${loggedIn}`);
    return loggedIn;
  }

  login(response) {
    console.log('Login response received:', response);
    if (!response || (!response.token && !response.access_token)) {
      console.error("Invalid response object or missing token:", response);
      return;
    }
    const token = response.token || response.access_token;
    console.log('Logging in user with token:', token);
    const currentTime = Date.now() / 1000;
    const expirationTime = currentTime + 24 * 60 * 60;  // 24 hours expiration
    console.log('Token expiration time:', expirationTime);
    const user = {
      token: token,
      email: response.email || '',
      is_admin: response.is_admin || (response.access_token ? true : false),
      tokenExpiration: expirationTime,
    };
    try {
      localStorage.setItem('user', JSON.stringify(user));
      console.log('User logged in and data stored in local storage:', user);
    } catch (e) {
      console.error("Failed to serialize user data:", e);
    }
  }

  logout() {
    localStorage.removeItem('user');
    console.log('User logged out and data removed from local storage.');
  }

  getUser() {
    console.log('Getting user from local storage.');
    const user = localStorage.getItem('user');
    if (!user) {
      console.warn("No user data found in local storage.");
      return null;
    }
    try {
      const parsedUser = JSON.parse(user);
      console.log('Retrieved user data:', parsedUser);
      return parsedUser;
    } catch (e) {
      console.error("Error parsing user data:", e);
      return null;
    }
  }

  clearUserData() {
    localStorage.removeItem('user');
    console.log('User data cleared from local storage.');
  }

  getUserEmail() {
    const user = this.getUser();
    if (user && user.email) {
      console.log('User email:', user.email);
      return user.email;
    }
    console.warn("User email not found.");
    return '';
  }
}

export default new AuthService();
