<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div dir="rtl" class="salla-flex salla-flex-col salla-min-h-screen salla-bg-white">
    <!-- Header -->
    <header class="salla-sticky salla-top-0 salla-z-50 salla-bg-white salla-shadow-sm">
      <div class="salla-container salla-mx-auto salla-px-4 salla-py-4">
        <div class="salla-flex salla-items-center salla-justify-between">
          <!-- Logo Section -->
          <div class="salla-flex salla-items-center salla-gap-2">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/076ad4d43ff739e5b7165868b496c5cce1c42209ae846a2e33f5034beb9bd8ef?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
                 alt="Logo" 
                 class="salla-h-8 salla-w-auto" />
          </div>

          <!-- Action Button -->
          <div class="salla-flex salla-items-center">
            <router-link 
              to="/dashboard" 
              class="salla-inline-flex salla-items-center salla-gap-2 salla-bg-primary-600 salla-text-white salla-px-6 salla-py-2.5 salla-rounded-full hover:salla-bg-primary-700 salla-transition-all salla-duration-200 salla-ease-in-out salla-shadow-md hover:salla-shadow-lg salla-transform hover:salla-scale-105 salla-font-medium"
            >
              <span>لنبدأ</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="salla-h-5 salla-w-5 salla-transform salla-rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </header>

    <!-- Main Content -->
    <main class="salla-flex-1">
      <!-- Hero Section -->
      <section class="salla-relative salla-py-20 salla-overflow-hidden">
        <img 
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/75cb945eb5a73e27dc1957579339054510327a97c47fce8444f50b8cd4efc819?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
          alt="Hero Background" 
          class="salla-absolute salla-inset-0 salla-w-full salla-h-full salla-object-cover" />
        <div class="salla-relative salla-container salla-mx-auto salla-px-4">
          <h1 class="salla-text-5xl salla-font-bold salla-text-center salla-text-gray-900">
            سياسة الخصوصية
          </h1>
        </div>
      </section>

      <!-- Policy Content -->
      <section class="salla-container salla-mx-auto salla-px-4 salla-py-16">
        <div class="salla-flex salla-gap-12">
          <article class="salla-flex-1 salla-text-right">
            <!-- Introduction -->
            <div class="salla-mb-12">
              <h2 class="salla-text-3xl salla-font-bold salla-text-gray-900 salla-mb-4">التزامنا:</h2>
              <p class="salla-text-lg salla-text-gray-700 salla-leading-relaxed">
                في "فيلماتيكس"، نتعامل مع بياناتك الشخصية بمسؤولية وشفافية. سياسة الخصوصية هذه توضح كيفية جمعنا، استخدامنا، وحمايتنا لبياناتك الشخصية.
              </p>
            </div>

            <!-- Data Collection -->
            <div class="salla-mb-12">
              <h2 class="salla-text-3xl salla-font-bold salla-text-gray-900 salla-mb-4">جمع البيانات:</h2>
              <ul class="salla-space-y-3">
                <li class="salla-flex salla-items-start salla-gap-3">
                  <span class="salla-w-2 salla-h-2 salla-mt-3 salla-rounded-full salla-bg-primary-600"></span>
                  <p class="salla-text-lg salla-text-gray-700">نجمع بيانات شخصية عندما تسجل لاستخدام خدماتنا، مثل الاسم، البريد الإلكتروني، ورقم الهاتف.</p>
                </li>
                <li class="salla-flex salla-items-start salla-gap-3">
                  <span class="salla-w-2 salla-h-2 salla-mt-3 salla-rounded-full salla-bg-primary-600"></span>
                  <p class="salla-text-lg salla-text-gray-700">نجمع أيضًا بيانات غير شخصية تتعلق بكيفية استخدامك لموقعنا.</p>
                </li>
                <li class="salla-flex salla-items-start salla-gap-3">
                  <span class="salla-w-2 salla-h-2 salla-mt-3 salla-rounded-full salla-bg-primary-600"></span>
                  <p class="salla-text-lg salla-text-gray-700">عند تسجيل الدخول باستخدام Google، نجمع معلومات ملفك الشخصي في Google.</p>
                </li>
              </ul>
            </div>

            <!-- Data Protection -->
            <div class="salla-mb-12">
              <h2 class="salla-text-3xl salla-font-bold salla-text-gray-900 salla-mb-4">حماية البيانات:</h2>
              <ul class="salla-space-y-3">
                <li class="salla-flex salla-items-start salla-gap-3">
                  <span class="salla-w-2 salla-h-2 salla-mt-3 salla-rounded-full salla-bg-primary-600"></span>
                  <p class="salla-text-lg salla-text-gray-700">نتخذ جميع الإجراءات المعقولة لحماية بياناتك من الوصول غير المصرح به.</p>
                </li>
                <li class="salla-flex salla-items-start salla-gap-3">
                  <span class="salla-w-2 salla-h-2 salla-mt-3 salla-rounded-full salla-bg-primary-600"></span>
                  <p class="salla-text-lg salla-text-gray-700">يتم تخزين بياناتك بأمان والوصول إليها محدود فقط للأفراد المصرح لهم.</p>
                </li>
              </ul>
            </div>

            <!-- Data Usage -->
            <div class="salla-mb-12">
              <h2 class="salla-text-3xl salla-font-bold salla-text-gray-900 salla-mb-4">استخدام البيانات:</h2>
              <ul class="salla-space-y-3">
                <li class="salla-flex salla-items-start salla-gap-3">
                  <span class="salla-w-2 salla-h-2 salla-mt-3 salla-rounded-full salla-bg-primary-600"></span>
                  <p class="salla-text-lg salla-text-gray-700">نستخدم بياناتك لتقديم وتحسين خدماتنا، وللتواصل معك بخصوص حسابك أو خدماتنا.</p>
                </li>
              </ul>
            </div>

            <!-- Contact Information -->
            <div class="salla-mb-12">
              <h2 class="salla-text-3xl salla-font-bold salla-text-gray-900 salla-mb-4">معلومات الاتصال:</h2>
              <ul class="salla-space-y-3">
                <li class="salla-flex salla-items-start salla-gap-3">
                  <span class="salla-w-2 salla-h-2 salla-mt-3 salla-rounded-full salla-bg-primary-600"></span>
                  <p class="salla-text-lg salla-text-gray-700">إذا كانت لديك أي أسئلة أو مخاوف بشأن سياسة الخصوصية هذه، يرجى الاتصال بنا على info@filmatex.com</p>
                </li>
              </ul>
            </div>
          </article>

          <!-- Decorative Elements -->
          <aside class="salla-hidden lg:salla-flex salla-flex-col salla-gap-16">
            <div class="salla-w-3 salla-h-3 salla-rounded-full salla-bg-primary-600"></div>
            <div class="salla-w-3 salla-h-3 salla-rounded-full salla-bg-primary-600"></div>
            <div class="salla-w-3 salla-h-3 salla-rounded-full salla-bg-primary-600"></div>
          </aside>
        </div>
      </section>
    </main>
  </div>
</template>

