<template>
  <div class="settings-admin">
    <h2>Site Settings</h2>
    <form @submit.prevent="updateSiteSettings" class="settings-form">
      <div class="form-group">
        <label for="site-title">Site Title:</label>
        <input v-model="siteSettings.site_title" id="site-title" required>
      </div>
      <div class="form-group">
        <label for="logo">Logo:</label>
        <input type="file" @change="onFileChange('logo', $event)">
      </div>
      <div class="form-group">
        <label for="favicon">Favicon:</label>
        <input type="file" @change="onFileChange('favicon', $event)">
      </div>
      <button type="submit" class="btn btn-primary">Save Settings</button>
    </form>

    <h2>Social Media Links</h2>
    <form @submit.prevent="addSocialMediaLink" class="social-media-form">
      <div class="form-group">
        <label for="platform">Platform:</label>
        <input v-model="newLink.platform" id="platform" required>
      </div>
      <div class="form-group">
        <label for="url">URL:</label>
        <input v-model="newLink.url" id="url" required>
      </div>
      <button type="submit" class="btn btn-primary">Add Link</button>
    </form>

    <h3>Existing Links</h3>
    <ul class="links-list">
      <li v-for="link in socialMediaLinks" :key="link.id" class="link-item">
        <span class="link-platform">{{ link.platform }}</span>: <span class="link-url">{{ link.url }}</span>
        <button @click="deleteLink(link.id)" class="btn btn-danger btn-sm">Delete</button>
      </li>
    </ul>

    
    <h2>Sidebar Visibility</h2>
    <ul class="visibility-list">
      <li v-for="item in sidebarItems" :key="item.id" class="visibility-item">
        <div class="item-text">
          <span>{{ item.text }}</span>
        </div>
        <div class="item-toggle">
          <label class="toggle-switch">
            <input type="checkbox" :checked="visibilitySettings[item.id]" @change="toggleVisibility(item.id, $event)">
            <span class="toggle-slider round"></span>
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { API_URL } from '@/config';

export default {
  name: 'SettingsAdmin',
  data() {
    return {
      socialMediaLinks: [],
      newLink: {
        platform: '',
        url: ''
      },
      siteSettings: {
        site_title: '',
        logo: null,
        favicon: null
      },
      sidebarItems: [
        { id: 1, text: 'فيديوهات' },
        { id: 2, text: 'المفضلة' },
        { id: 3, text: 'التحليلات' },
        { id: 4, text: 'مشاركة' },
        { id: 5, text: 'ربط مع' },
        { id: 7, text: 'المنتجات' },
        { id: 8, text: 'الخطط والاشتراكات' },
        { id: 6, text: 'الإعدادات' },
        { id: 9, text: 'الدعم الفني' },
        { id: 10, text: 'جلب المنتجات' },
      ],
      visibilitySettings: {}
    };
  },
  methods: {
    fetchSocialMediaLinks() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to fetch social media links.');
        return;
      }

      fetch(`${API_URL}/api/admin/social-media-links`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          this.socialMediaLinks = data;
        })
        .catch(error => {
          console.error('Error fetching social media links:', error);
          alert('Failed to fetch social media links');
        });
    },
    fetchSiteSettings() {
      const token = localStorage.getItem('token');
      fetch(`${API_URL}/api/admin/site-settings`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.json())
        .then(data => {
          this.siteSettings = data;
        })
        .catch(error => {
          console.error('Error fetching site settings:', error);
        });
    },
    onFileChange(type, event) {
      const file = event.target.files[0];
      this.siteSettings[type] = file;
    },
    updateSiteSettings() {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('site_title', this.siteSettings.site_title);
      if (this.siteSettings.logo) {
        formData.append('logo', this.siteSettings.logo);
      }
      if (this.siteSettings.favicon) {
        formData.append('favicon', this.siteSettings.favicon);
      }

      fetch(`${API_URL}/api/admin/site-settings`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      })
        .then(response => response.json())
        .then(() => {
          alert('Site settings updated successfully');
        })
        .catch(error => {
          console.error('Error updating site settings:', error);
          alert('Failed to update site settings');
        });
    },
    addSocialMediaLink() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to add a social media link.');
        return;
      }

      fetch(`${API_URL}/api/admin/social-media-links`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(this.newLink)
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(() => {
          this.newLink.platform = '';
          this.newLink.url = '';
          this.fetchSocialMediaLinks();
        })
        .catch(error => {
          console.error('Error adding social media link:', error);
          alert('Failed to add social media link');
        });
    },
    deleteLink(id) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to delete a social media link.');
        return;
      }

      fetch(`${API_URL}/api/admin/social-media-links/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(() => {
          this.fetchSocialMediaLinks();
        })
        .catch(error => {
          console.error('Error deleting social media link:', error);
          alert('Failed to delete social media link');
        });
    },
    fetchSidebarVisibility() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. Admin must be logged in to fetch sidebar visibility.');
        return;
      }

      const requestUrl = `${API_URL}/sidebar-visibility`;
      fetch(requestUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        this.visibilitySettings = data.reduce((acc, item) => {
          acc[item.tab_id] = item.is_visible;
          return acc;
        }, {});
      })
      .catch(error => {
        console.error('Error fetching sidebar visibility:', error);
      });
    },
    toggleVisibility(itemId, event) {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. Admin must be logged in to update sidebar visibility.');
        return;
      }

      const requestUrl = `${API_URL}/admin/sidebar-visibility`;
      fetch(requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          tab_id: itemId,
          is_visible: event.target.checked
        })
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(() => {
        this.visibilitySettings[itemId] = event.target.checked;
      })
      .catch(error => {
        console.error('Error updating sidebar visibility:', error);
      });
    }
  },
  mounted() {
    this.fetchSocialMediaLinks();
    this.fetchSiteSettings();
    this.fetchSidebarVisibility();
  }
};
</script>



<style scoped>
.visibility-list {
  list-style-type: none;
  padding: 0;
}

.visibility-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.visibility-item:hover {
  transform: translateY(-3px);
}

.item-text {
  flex: 1;
}

.item-text span {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.item-toggle {
  margin-left: 20px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #2196f3;
}

input:focus + .toggle-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

.toggle-slider.round {
  border-radius: 34px;
}

.toggle-slider.round:before {
  border-radius: 50%;
}

.settings-admin {
  padding: 20px;
  max-width: 95%;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.settings-admin h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.settings-form,
.social-media-form {
  margin-bottom: 40px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-group input[type="text"],
.form-group input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
  border: none;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.btn-danger {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 8px 15px;
  font-size: 14px;
}

.btn-danger:hover {
  background-color: #c82333;
}

.btn-sm {
  padding: 5px 10px;
  font-size: 14px;
}

.links-list {
  list-style: none;
  padding: 0;
}

.link-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f8f8f8;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  transition: transform 0.3s ease;
}

.link-item:hover {
  transform: translateY(-3px);
}

.link-platform {
  font-weight: bold;
  color: #333;
  font-size: 18px;
}

.link-url {
  color: #555;
  font-size: 16px;
  margin-left: 10px;
}

</style>
