<template>
    <div>
      <h2>Simple Video Editor</h2>
      <div ref="previewContainer"></div>
  
      <!-- New section for pre-added images -->
      <div>
        <h3>Select a Pre-added Image</h3>
        <div class="image-grid">
          <div
            v-for="image in preaddedImages"
            :key="image.id"
            class="image-item"
            @click="selectPreaddedImage(image.url)"
          >
            <img :src="image.url" :alt="image.name" />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Preview } from '@creatomate/preview';
  
  export default {
    name: 'SimpleVideoEditor',
    data() {
      return {
        apiBaseUrl: 'http://127.0.0.1:5000',
        videoId: 4,
        preview: null,
        videoUrl: '',
        videoDetails: {},
        preaddedImages: [],
        isPreviewReady: false,
      };
    },
    mounted() {
      this.fetchVideoData();
      this.fetchPreaddedImages();
    },
    methods: {
      selectPreaddedImage(imageUrl) {
        console.log('Image selected:', imageUrl);
        this.updateVideoWithNewImage(imageUrl);
      },
      async updateVideoWithNewImage(imageUrl) {
        if (!this.isPreviewReady || !this.preview) {
          console.error('Preview is not ready or not initialized');
          return;
        }
        const modifications = {
          '52fc13a1-5e2d-4862-9cae-4441acfae84d': imageUrl, // ID of the dynamic image element
        };
        try {
          await this.preview.setModifications(modifications);
          console.log('Image updated successfully!');
        } catch (error) {
          console.error('Failed to update image:', error);
        }
      },
      async fetchPreaddedImages() {
        const token = localStorage.getItem('token');
        const response = await fetch(`${this.apiBaseUrl}/api/preadded-images`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          console.error(`Failed to fetch pre-added images: status ${response.status}`);
          return;
        }
        this.preaddedImages = await response.json();
      },
      async fetchVideoData() {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('JWT token is missing');
          return;
        }
  
        try {
          const response = await fetch(`${this.apiBaseUrl}/api/videos/${this.videoId}`, {
            method: 'GET',
            headers: {'Authorization': `Bearer ${token}`}
          });
          if (!response.ok) throw new Error(`Failed to fetch video data: status ${response.status}`);
          const data = await response.json();
          this.videoUrl = data.video_url;
          this.videoDetails = {
            creatomate_template_id: data.creatomate_template_id,
            modifications: data.modifications,
          };
          this.initializePreview();
        } catch (error) {
          console.error('Error fetching video data:', error);
        }
      },
  
      initializePreview() {
        console.log('Initializing preview...');
        const previewContainer = this.$refs.previewContainer;
        this.preview = new Preview(previewContainer, 'player', 'public-yqbsoiqlkk8rwzj1pf9n9id6');
        this.preview.onReady = () => {
          console.log('Preview is ready');
          this.isPreviewReady = true;
          this.applyVideoSettings();
        };
      },
  
      applyVideoSettings() {
        if (!this.isPreviewReady) {
          console.error('SDK is not ready. Delaying settings application.');
          return;
        }
        if (this.videoUrl) {
          console.log('Setting source from videoUrl:', this.videoUrl);
          this.preview.setSource(this.videoUrl);
        }
        if (this.videoDetails?.creatomate_template_id) {
          console.log('Loading template:', this.videoDetails.creatomate_template_id);
          this.preview.loadTemplate(this.videoDetails.creatomate_template_id);
        }
        if (this.videoDetails?.modifications) {
          console.log('Applying modifications:', this.videoDetails.modifications);
          this.preview.setModifications(this.videoDetails.modifications);
        }
      }
    }
  };
</script>

  
  <style scoped>
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.image-item {
  cursor: pointer;
}

.image-item img {
  width: 100%;
  height: auto;
}
</style>