<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="login-page">
    <header class="header">
      <div class="header-actions">
        <router-link to="/register" class="register-button">تسجيل</router-link>
        <router-link to="/login" class="login-link">تسجيل الدخول</router-link>
      </div>

  
      <div class="header-icons">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/1909eed33e4c92c0c6a7ae656b371072f6a331d8caa1d3f9aff6a1b1f6fa06f2?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Icon" class="icon" />
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/076ad4d43ff739e5b7165868b496c5cce1c42209ae846a2e33f5034beb9bd8ef?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Logo" class="logo" />
      </div>
    </header>
    
    <main class="main-content">
      <div class="login-container">
        <section class="login-form">
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/f71e6eb12d9eabd08f6c8f796b8ab5e9c2bea6392f77fbb4ba68b721a967cba4?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Logo" class="form-logo" />
          
          <form @submit.prevent="register">
            <label for="username" class="visually-hidden">Username</label>
            <div class="input-wrapper username-input">
              <input type="text" v-model="username" dir="rtl" placeholder="اسم المستخدم" required />
            </div>

            <label for="email" class="visually-hidden">Email</label>
            <div class="input-wrapper email-input">
              <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/ba4d293dd96f69d372585e4eb4776fdde2d5c7a363c18a6ebd4f21b761b84c12?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Email icon" class="input-icon" />
              <input type="email" v-model="email" dir="rtl" placeholder="الايميل" required />
            </div>

            <label for="password" class="visually-hidden">Password</label>  
            <div class="input-wrapper password-input">
              <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/9578524c5a9dbfc2743e3d462217bf3f590a13f1347b2e8dd280e4880768e0c0?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Password icon" class="input-icon" />
              <input type="password" v-model="password" dir="rtl" placeholder="كلمة السر" required />
            </div>

            <label for="confirm-password" class="visually-hidden">Confirm Password</label>
            <div class="input-wrapper confirm-password-input">
              <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/9578524c5a9dbfc2743e3d462217bf3f590a13f1347b2e8dd280e4880768e0c0?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Password icon" class="input-icon" />
              <input type="password" v-model="confirmPassword" dir="rtl" placeholder="تاكيد كلمة السر" required />
            </div>

            

            <button type="submit" class="submit-button" :disabled="loading">
              <!-- Show loading text or spinner when loading -->
              <span v-if="loading">جاري التسجيل...</span>
              <span v-else>تسجيل</span>
            </button>
            <p v-if="errorMessage">{{ errorMessage }}</p>
          </form>

          <div class="login-prompt" dir="rtl">
            <span>هل لديك حساب بالفعل؟</span>
            <router-link to="/login">تسجيل الدخول</router-link>
          </div>

          <div class="social-login">
            <button class="google-login" @click="signInWithGoogle">
              <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/4d324a6b55750eac2f50c4706865ec2a9d319160330a05d44ac2dbe275002259?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Google icon" class="social-icon" />
            </button>
            <button class="facebook-login">
              <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/b9670aa0de034794eba33b74d82129501077f41fc81f54464398444976a10ff5?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Facebook icon" class="social-icon" />  
            </button>
          </div>
        </section>

        <section class="login-image">
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/8650436a78d52786ae81e1de7cd517cb7c328b10e423e20d3fd4c98ba03b9413?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" alt="Login illustration" class="illustration" />
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { API_URL } from '@/config';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebaseConfig';

export default {
  setup() {
    const router = useRouter();
    const username = ref('');
    const email = ref('');
    const password = ref('');
    const confirmPassword = ref('');
    const errorMessage = ref('');
    const loading = ref(false);

    const register = async () => {
      if (password.value !== confirmPassword.value) {
        errorMessage.value = 'Passwords do not match.';
        return;
      }

      loading.value = true;
      try {
        const response = await fetch(`${API_URL}/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: username.value,
            email: email.value,
            password: password.value,
            password2: confirmPassword.value,
          }),
        });

        const data = await response.json();

        if (data.success) {
          router.push({ name: 'VerificationPage', query: { email: email.value } });
        } else {
          errorMessage.value = data.message;
        }
      } catch (error) {
        errorMessage.value = 'An error occurred. Please try again.';
        console.error('Registration error:', error);
      } finally {
        loading.value = false;
      }
    };

    const signInWithGoogle = async () => {
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        const token = await result.user.getIdToken();
        const response = await fetch(`${API_URL}/api/auth/google`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token })
        });

        const data = await response.json();
        if (data.success) {
          localStorage.setItem('token', data.user.token);
          router.push(data.redirect || '/dashboard');
        } else {
          errorMessage.value = data.message;
        }
      } catch (error) {
        console.error('Google sign-in error:', error);
        errorMessage.value = 'Google sign-in failed. Please try again.';
      }
    };

    return {
      username,
      email,
      password,
      confirmPassword,
      errorMessage,
      register,
      loading,
      signInWithGoogle
    };
  },
};
</script>

  
  


  
  <style scoped>
  /* Mobile-first styles */
  .login-page {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
  }
  
  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(231, 231, 231, 0.2);
  }
  
  .header-actions,
  .header-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    font-size: 1rem;
  }
  
  .register-button {
    padding: 0.75rem 1.5rem;
    border-radius: 2rem;
    background-color: #0098fd;
    color: #fff;
    font: 400 1rem/160% Tajawal, sans-serif;
    text-transform: uppercase;
  }
  
  .login-link {
    color: #0098fd;
    font: 400 1rem/150% Tajawal, sans-serif;
    text-align: center;
  }
  
  .header-icons {
    display: flex;
    gap: 0.5rem;
  }
  
  .icon {
    width: 1.5rem;
    aspect-ratio: 1;
    object-fit: contain;
  }
  
  .logo {
    width: 5rem;
    aspect-ratio: 6.25;
    object-fit: contain;
  }
  
  .main-content {
    width: 100%;
    max-width: 1273px;
    margin: 2rem auto 0;
  }
  
  .login-container {
  display: flex;
  gap: 20px;
}

.login-form {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  margin: auto 0;
  padding: 0 20px;
}

.form-logo {
  width: 119px;
  max-width: 100%;
  aspect-ratio: 1.61;
  object-fit: contain;
  align-self: center;
}

.input-wrapper {
  display: flex;
  align-items: center;
  padding: 0px;
  border: 1px solid rgba(35, 40, 51, 0.1);
  border-radius: 8px;
  background-color: #fff;
  max-width: 350px;
  margin: 10px auto;
}

.input-wrapper input {
  width: 100%;
}

.email-input {
  margin-top: 13px;
}

.password-input {
  margin-top: 24px;
}

.input-icon {
  width: 18px;
    aspect-ratio: 1;
    -o-object-fit: contain;
    object-fit: contain;
    margin-right: 69px;
    left: 10px;
    position: relative;
}

input {
  flex: 1;
    border: none;
    outline: none;
    font: 400 16px / 150% Tajawal, sans-serif;
    padding: 0px;
    margin: 14px;
}

.forgot-password-link {
  display: block;
  text-align: right;
  margin-top: 10px;
}

.submit-button {
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #0066ff;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
  display: block;
  margin: 20px auto;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0055dd;
}

.login-prompt {
  display: flex;
  gap: 6px;
  margin-top: 12px;
  align-self: center;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 150%;
}

.login-prompt span {
  color: #393939;
  font: 400 14px/150% Tajawal, sans-serif;
}

.login-prompt button {
  color: #0098fd;
  font: 400 14px/150% Tajawal, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
}

.social-login {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}

.google-login,
.facebook-login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 60px;
  border: 1px solid rgba(35, 40, 51, 0.1);
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
}

.social-icon {
  width: 18px;
  aspect-ratio: 1;
  object-fit: contain;
}

.login-image {
  width: 98%;
    margin-left: 20px;
}
  
.illustration {
  width: 100%;
  aspect-ratio: 1.41;
  object-fit: contain;
  flex-grow: 1;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
  
@media (max-width: 600px) {
  .header {
    padding: 10px 20px;
  }

  .header-actions, .header-nav {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .register-button, .google-login, .facebook-login {
    padding: 15px 20px;
    font-size: 14px;
  }

  .login-link, .nav-link {
    font-size: 14px;
  }

  .main-content, .login-container, .login-form {
    margin: 20px auto;
    padding: 0 15px;
    width: 100%;
  }

  .input-wrapper {
    align-items: center;
  }

  .input-icon {
    margin: 0 0 5px;
  }

  input[type="text"], input[type="password"] {
    font-size: 14px;
  }

  .login-image, .illustration {
    display: none;
  }

  .forgot-password-link {
    font-size: 14px;
  }

  .social-login {
    gap: 8px;
  }
}
</style>