<template>
  <div class="salla-flex salla-h-screen salla-bg-gray-50">
    <!-- Sidebar -->
    <aside class="salla-w-72 salla-flex salla-flex-col salla-transition-all salla-duration-300 salla-relative salla-z-20">
      <!-- Gradient Background - Darker and more contrasted -->
      <div class="salla-absolute salla-inset-0 salla-bg-gradient-to-br salla-from-primary-900 salla-to-primary-800"></div>
      
      <!-- Content Container -->
      <div class="salla-relative salla-flex salla-flex-col salla-h-full">
        <!-- Brand/Logo Section -->
        <div class="salla-p-6 salla-border-b salla-border-primary-700">
          <div class="salla-flex salla-items-center salla-space-x-4">
            <div class="salla-w-10 salla-h-10 salla-bg-white salla-rounded-xl salla-flex salla-items-center salla-justify-center salla-shadow-lg salla-group">
              <span class="salla-text-primary-600 salla-font-bold salla-text-xl salla-group-hover:salla-scale-110 salla-transition-transform">A</span>
            </div>
            <h1 class="salla-text-white salla-text-xl salla-font-bold">Admin Portal</h1>
          </div>
        </div>

        <!-- Navigation Menu with Enhanced Visibility -->
        <nav class="salla-flex-1 salla-overflow-y-auto">
          <ul class="salla-p-4 salla-space-y-1">
            <template v-for="(item, index) in menuItems" :key="item.path">
              <li 
                class="animate-slide-in" 
                :style="{ animationDelay: `${index * 50}ms` }"
              >
                <router-link
                  :to="item.path"
                  :exact="item.exact"
                  class="salla-flex salla-items-center salla-px-4 salla-py-3 salla-rounded-lg salla-transition-all salla-duration-200 salla-group salla-relative"
                  :class="[
                    $route.path === item.path 
                      ? 'salla-bg-white salla-text-primary-900 salla-shadow-md' 
                      : 'salla-text-white hover:salla-bg-white/20'
                  ]"
                >
                  <component 
                    :is="item.icon" 
                    class="salla-w-5 salla-h-5 salla-mr-3"
                    :class="$route.path === item.path ? 'salla-text-primary-900' : 'salla-text-white'"
                  />
                  <span class="salla-flex-1 salla-font-medium">{{ item.name }}</span>
                  
                  <!-- Badge for Support -->
                  <span 
                    v-if="item.name === 'Support' && openTicketCount > 0"
                    class="salla-px-2.5 salla-py-0.5 salla-bg-red-500 salla-text-white salla-text-xs salla-font-bold salla-rounded-full"
                  >
                    {{ openTicketCount }}
                  </span>
                </router-link>
              </li>
            </template>
          </ul>
        </nav>

        <!-- User Profile Section -->
        <div class="salla-p-4 salla-border-t salla-border-primary-700">
          <div class="salla-flex salla-items-center salla-space-x-3 salla-px-4 salla-py-3 salla-rounded-lg salla-bg-white/10 hover:salla-bg-white/20 salla-transition-all salla-cursor-pointer salla-group">
            <div class="salla-w-10 salla-h-10 salla-rounded-lg salla-bg-white salla-flex salla-items-center salla-justify-center">
              <span class="salla-text-primary-900 salla-font-semibold salla-text-lg">A</span>
            </div>
            <div class="salla-flex-1">
              <h3 class="salla-text-white salla-font-medium">Admin User</h3>
              <p class="salla-text-white/90 salla-text-sm">System Administrator</p>
            </div>
            <button class="salla-p-2 salla-rounded-lg hover:salla-bg-white/10">
              <svg class="salla-w-5 salla-h-5 salla-text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </aside>

    <!-- Main Content -->
    <main class="salla-flex-1 salla-flex salla-flex-col salla-min-h-screen salla-bg-gray-50">
      <!-- Header -->
      <header class="salla-bg-white salla-border-b salla-border-gray-200 salla-shadow-sm">
        <div class="salla-px-6 salla-py-4 salla-flex salla-items-center salla-justify-between">
          <h2 class="salla-text-2xl salla-font-semibold salla-text-gray-800 animate-fade-in">
            {{ $route.name }}
          </h2>
          <div class="salla-flex salla-items-center salla-space-x-4">
            <button class="salla-p-2 salla-rounded-lg hover:salla-bg-gray-100 salla-transition-colors">
              <svg class="salla-w-6 salla-h-6 salla-text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
              </svg>
            </button>
          </div>
        </div>
      </header>

      <!-- Page Content -->
      <div class="salla-flex-1 salla-p-6">
        <div class="salla-bg-white salla-rounded-xl salla-shadow-sm salla-p-6 animate-fade-in">
          <router-view></router-view>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { API_URL } from "@/config";

// Import icons (using heroicons as example)
const menuItems = [
  { name: 'Dashboard', path: '/admin/dashboard', exact: true, icon: 'HomeIcon' },
  { name: 'Users', path: '/admin/dashboard/users', icon: 'UsersIcon' },
  { name: 'Stripe Plans', path: '/admin/dashboard/stripeplan', icon: 'CreditCardIcon' },
  { name: 'Credits', path: '/admin/dashboard/credits', icon: 'CurrencyDollarIcon' },
  { name: 'Templates', path: '/admin/dashboard/template', icon: 'TemplateIcon' },
  { name: 'Images', path: '/admin/dashboard/template-images', icon: 'PhotographIcon' },
  { name: 'Audio', path: '/admin/dashboard/audio', icon: 'MusicNoteIcon' },
  { name: 'Support', path: '/admin/dashboard/support', icon: 'SupportIcon' },
  { name: 'Salla Settings', path: '/admin/dashboard/salla-settings', icon: 'CogIcon' },
  { name: 'Settings', path: '/admin/dashboard/settings', icon: 'AdjustmentsIcon' },
];

export default {
  data() {
    return {
      openTicketCount: 0,
      menuItems,
    };
  },
  mounted() {
    this.fetchOpenTicketCount();
  },
  methods: {
    async fetchOpenTicketCount() {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        const response = await fetch(`${API_URL}/api/admin/tickets/open-count`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        this.openTicketCount = data.count;
      } catch (error) {
        console.error("Error fetching open ticket count:", error);
      }
    },
  },
};
</script>

<style>
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-fade-in {
  animation: fade-in 0.5s ease-out forwards;
}

.animate-slide-in {
  animation: slide-in 0.5s ease-out forwards;
}
.salla-scrollbar-thin {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}

.salla-scrollbar-thin::-webkit-scrollbar {
  width: 4px;
}

.salla-scrollbar-thin::-webkit-scrollbar-track {
  background: transparent;
}

.salla-scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
}

/* Your existing animations remain the same */
</style>