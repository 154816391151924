<template>
  <main class="ticket-system-main-content">
    <div class="ticket-system-header">
      <h2>نظام التذاكر</h2>
      <button class="ticket-system-raise-new-ticket" @click="showModal = true">إنشاء تذكرة جديدة</button>
    </div>
    <table class="ticket-system-table">
      <thead>
        <tr>
          <th>رقم التذكرة</th>
          <th>العنوان</th>
          <th>الفئة</th>
          <th>التاريخ</th>
          <th>الحالة</th>
          <th>الإجراءات</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="ticket in tickets" :key="ticket.id">
          <td data-label="رقم التذكرة">{{ ticket.id }}</td>
          <td data-label="العنوان">{{ ticket.title }}</td>
          <td data-label="الفئة">{{ ticket.category_name }}</td>
          <td data-label="التاريخ">{{ formatDate(ticket.created_at) }}</td>
          <td :class="ticket.status.toLowerCase()" data-label="الحالة">{{ ticket.status }}</td>
          <td data-label="الإجراءات">
            <button class="ticket-system-see-all" @click="viewTicketDetails(ticket.id)">عرض التفاصيل</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Raise Ticket Modal -->
    <div v-if="showModal" class="ticket-system-modal">
      <div class="ticket-system-modal-content">
        <span class="ticket-system-close" @click="showModal = false">&times;</span>
        <h3>إنشاء تذكرة جديدة</h3>
        <form @submit.prevent="createTicket">
          <label>العنوان:
            <input v-model="newTicket.title" required>
          </label>
          <label>الفئة:
            <select v-model="newTicket.category_id" required>
              <option value="" disabled>اختر فئة</option>
              <option v-for="category in ticketCategories" :key="category.id" :value="category.id">
                {{ category.name }}
              </option>
            </select>
          </label>
          <label>الرسالة:
            <textarea v-model="newTicket.message" required></textarea>
          </label>
          <button type="submit">إرسال</button>
          <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        </form>
      </div>
    </div>

    <!-- Ticket Details Modal -->
    <div v-if="showTicketDetails" class="ticket-system-modal">
      <div class="ticket-system-modal-content ticket-system-ticket-details">
        <span class="ticket-system-close" @click="showTicketDetails = false">&times;</span>
        <h3>تفاصيل التذكرة</h3>
        <div class="ticket-system-info">
          <p><strong>العنوان:</strong> {{ selectedTicket.title }}</p>
          <p><strong>الفئة:</strong> {{ selectedTicket.category_name }}</p>
          <p><strong>الحالة:</strong> <span :class="selectedTicket.status.toLowerCase()">{{ selectedTicket.status }}</span></p>
          <p><strong>تاريخ الإنشاء:</strong> {{ formatDate(selectedTicket.created_at) }}</p>
        </div>

        <div class="ticket-system-messages">
          <h4>الرسائل:</h4>
          <div v-for="message in selectedTicket.messages" :key="message.id" class="ticket-system-message" :class="{ 'ticket-system-admin-reply': message.is_admin_reply }">
            <div class="ticket-system-message-header">
              <span class="ticket-system-message-sender">{{ message.is_admin_reply ? 'الإدارة' : 'المستخدم' }}</span>
              <span class="ticket-system-message-date">{{ formatDate(message.created_at) }}</span>
            </div>
            <div class="ticket-system-message-content">
              <p>{{ message.message }}</p>
            </div>
          </div>
        </div>

        <div class="ticket-system-reply">
          <h4>الرد:</h4>
          <div v-if="selectedTicket.status.toLowerCase() === 'resolved'" class="ticket-system-resolved-message">
            تم حل المشكلة في هذه التذكرة. يرجى فتح تذكرة جديدة.
          </div>
          <form v-else @submit.prevent="addTicketMessage">
            <label>الرسالة:
              <textarea v-model="newMessage" required></textarea>
            </label>
            <button type="submit">إرسال</button>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { API_URL } from '@/config';

export default {
  data() {
    return {
      showModal: false,
      showTicketDetails: false,
      newTicket: {
        title: '',
        category_id: '',
        message: ''
      },
      newMessage: '',
      tickets: [],
      ticketCategories: [],
      selectedTicket: null,
      errorMessage: '' // Add this to show error messages
    };
  },
  mounted() {
    this.fetchUserTickets();
    this.fetchTicketCategories();
  },
  methods: {
    async fetchUserTickets() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found. User must be logged in.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/tickets`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        this.tickets = data.tickets.sort((a, ) => a.status.toLowerCase() === 'open' ? -1 : 1);
      } catch (error) {
        console.error('Error fetching user tickets:', error);
      }
    },
    async fetchTicketCategories() {
      try {
        const response = await fetch(`${API_URL}/api/ticket-categories`, {
          method: 'GET'
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        this.ticketCategories = data.categories;
      } catch (error) {
        console.error('Error fetching ticket categories:', error);
      }
    },
    async createTicket() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found. User must be logged in.');
        return;
      }

      // Frontend validation to check for empty or whitespace-only fields
      if (!this.newTicket.title.trim() || !this.newTicket.message.trim()) {
    this.errorMessage = 'العنوان والرسالة لا يمكن أن يكونا فارغين أو يحتويان على مسافات فقط.';
    return;
}


      try {
        const response = await fetch(`${API_URL}/api/tickets`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(this.newTicket)
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        this.resetForm();
        this.showModal = false;
        this.fetchUserTickets();
      } catch (error) {
        console.error('Error creating ticket:', error);
      }
    },
    resetForm() {
      this.newTicket = {
        title: '',
        category_id: '',
        message: ''
      };
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    async viewTicketDetails(ticketId) {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found. User must be logged in.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/tickets/${ticketId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        this.selectedTicket = data.ticket;
        this.showTicketDetails = true;
      } catch (error) {
        console.error('Error fetching ticket details:', error);
      }
    },
    async addTicketMessage() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found. User must be logged in.');
        return;
      }

      // Frontend validation to check for empty or whitespace-only fields
      if (!this.newMessage.trim()) {
        this.errorMessage = 'Message cannot be empty or whitespace only.';
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/tickets/${this.selectedTicket.id}/messages`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ message: this.newMessage })
        });

        if (!response.ok) {
          const errorData = await response.json();
          this.errorMessage = errorData.error; // Capture the error message
          return;
        }

        this.newMessage = '';
        this.viewTicketDetails(this.selectedTicket.id);
        this.errorMessage = ''; // Clear the error message on success
      } catch (error) {
        console.error('Error adding ticket message:', error);
      }
    }
  }
};
</script>

  
  <style>


.error-message {
  color: #ff4d4d;
  background-color: #ffe6e6;
  border: 1px solid #ff4d4d;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* General Styles */
body {
  font-family: 'Roboto', Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  color: #333;
}

h2 {
  margin-bottom: 20px;
  color: #333;
  font-weight: 600;
  font-size: 28px;
}

.ticket-system-main-content {
  width: 86%;
  max-width: 95%;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-right: 9px;
  margin-top: 16px;
  direction: rtl;
  text-align: right;
}

/* Table Styles */
.ticket-system-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.ticket-system-table th,
.ticket-system-table td {
  padding: 15px;
  text-align: right;
  vertical-align: middle;
  border: none;
}

.ticket-system-table th {
  background-color: #f5f5f5;
  color: #666;
  font-weight: 600;
  text-transform: uppercase;
}

.ticket-system-table td {
  font-size: 16px;
}

.ticket-system-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.ticket-system-table td.status {
  text-transform: capitalize;
  font-weight: 600;
}

/* Button Styles */
button {
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 16px;
  font-weight: 600;
}

button:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.ticket-system-raise-new-ticket {
  background-color: #007bff;
  color: white;
}

.ticket-system-see-all {
  background-color: #28a745;
  color: white;
}

.ticket-system-see-all:hover {
  background-color: #218838;
}

.open {
  color: #28a745;
}

.closed {
  color: #dc3545;
}

/* Modal Styles */
.ticket-system-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
}

.ticket-system-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90%;
  overflow-y: auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s;
}

.ticket-system-ticket-details {
  width: 100%;
}

.ticket-system-close {
  color: #aaa;
  float: left;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
}

.ticket-system-close:hover,
.ticket-system-close:focus {
  color: #000;
  text-decoration: none;
}

/* Form Styles */
form label {
  display: block;
  margin-bottom: 10px;
  color: #666;
  font-weight: 500;
}

form input,
form select,
form textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

form input:focus,
form select:focus,
form textarea:focus {
  outline: none;
  border-color: #007bff;
}

form button[type="submit"] {
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 16px;
  font-weight: 600;
}

form button[type="submit"]:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Ticket Details Styles */
.ticket-system-info {
  margin-bottom: 20px;
}

.ticket-system-info p {
  margin: 10px 0;
}

.ticket-system-info span {
  font-weight: 600;
}

.ticket-system-messages {
  margin-bottom: 20px;
}

.ticket-system-messages h4 {
  margin-bottom: 15px;
  font-size: 20px;
}

.ticket-system-message {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.ticket-system-admin-reply {
  background-color: #e1f3ff;
}

.ticket-system-message-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
  color: #666;
}

.ticket-system-message-sender {
  font-weight: 600;
}

.ticket-system-message-content p {
  margin: 0;
  font-size: 16px;
}

.ticket-system-reply h4 {
  margin-bottom: 15px;
  font-size: 20px;
}

.ticket-system-resolved-message {
  background-color: #ffefef;
  color: #d9534f;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



@media screen and (max-width: 768px) {
  body {
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }

  .ticket-system-main-content {
    width: 100%;
        margin: 0;
        padding: 20px;
        border-radius: 0;
        margin-left: 15px;
        box-shadow: none;
        background-color: #f3f3f3;
  }

  .ticket-system-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: #fff;
    color: #333;
    margin-right: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .ticket-system-raise-new-ticket {
    margin-top: 0;
    width: auto;
    padding: 12px 24px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .ticket-system-raise-new-ticket:hover {
    background-color: #0056b3;
  }

  .ticket-system-table {
    border-spacing: 0;
    box-shadow: none;
    width: 100%;
    margin-right: 0;
    overflow-x: auto;
    display: block;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    margin-top: 20px;
  }

  .ticket-system-table thead,
  .ticket-system-table tbody,
  .ticket-system-table tr,
  .ticket-system-table th,
  .ticket-system-table td {
    display: block;
  }

  .ticket-system-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .ticket-system-table tr {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .ticket-system-table td {
    border: none;
    position: relative;
    padding-left: 45%;
    text-align: left;
    min-height: 35px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    color: #333;
  }

  .ticket-system-table td:before {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    width: 40%;
    padding-right: 15px;
    white-space: nowrap;
    content: attr(data-label);
    font-weight: bold;
    color: #777;
    font-size: 14px;
  }

  .ticket-system-table td:last-child {
    text-align: right;
    padding-left: 15px;
    padding-right: 15px;
  }

  .ticket-system-table td:nth-child(4) {
    font-size: 14px;
    color: #777;
    text-align: left;
  }

  .ticket-system-see-all {
    padding: 10px 20px;
    font-size: 16px;
    white-space: nowrap;
    display: inline-block;
    margin-top: 10px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .ticket-system-see-all:hover {
    background-color: #218838;
  }

  .ticket-system-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }

  .ticket-system-modal-content {
    width: 90%;
    max-width: 400px;
    max-height: 80vh;
    border-radius: 8px;
    animation: slideUp 0.3s;
    background-color: #fff;
    padding: 30px;
    position: relative;
    overflow-y: auto;
  }

  .ticket-system-modal-content h3 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }

  .ticket-system-close {
    font-size: 24px;
    padding: 10px;
    color: #999;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    transition: color 0.3s ease;
  }

  .ticket-system-close:hover {
    color: #333;
  }

  form input,
  form select,
  form textarea {
    padding: 12px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    width: 100%;
    transition: border-color 0.3s ease;
  }

  form input:focus,
  form select:focus,
  form textarea:focus {
    outline: none;
    border-color: #007bff;
  }

  form button[type="submit"] {
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  form button[type="submit"]:hover {
    background-color: #0056b3;
  }

  .ticket-system-info {
    margin-bottom: 20px;
  }

  .ticket-system-info p {
    font-size: 16px;
    margin: 10px 0;
    color: #333;
  }

  .ticket-system-messages {
    margin-bottom: 20px;
  }

  .ticket-system-messages h4,
  .ticket-system-reply h4 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #333;
  }

  .ticket-system-message {
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
  }

  .ticket-system-message-header {
    font-size: 16px;
    margin-bottom: 10px;
    color: #777;
  }

  .ticket-system-message-content p {
    font-size: 16px;
    color: #333;
    line-height: 1.5;
  }

  .ticket-system-resolved-message {
    padding: 20px;
    font-size: 16px;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #d4edda;
    color: #155724;
    text-align: center;
  }

  @keyframes slideUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
</style>

