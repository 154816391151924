<template>
  <div class="salla-h-full salla-w-full salla-bg-white" dir="rtl">
    <!-- Main Container -->
    <div class="salla-h-full salla-flex salla-flex-col">
      <!-- Music Library Section -->
      <div class="salla-p-6 salla-flex-1">
        <div class="salla-flex salla-items-center salla-justify-between salla-mb-6">
          <div class="salla-flex salla-items-center salla-gap-3">
            <div class="salla-w-10 salla-h-10 salla-bg-primary-50 salla-rounded-xl salla-flex salla-items-center salla-justify-center">
              <i class="fas fa-music salla-text-xl salla-text-primary-600"></i>
            </div>
            <div>
              <h2 class="salla-text-lg salla-font-semibold salla-text-gray-900">المكتبة الموسيقية</h2>
              <p class="salla-text-sm salla-text-gray-500">{{ adminMusic.length }} مقطع موسيقي</p>
            </div>
          </div>

          <label 
            for="file-upload" 
            class="salla-flex salla-items-center salla-gap-2 salla-px-4 salla-py-2.5 salla-bg-primary-600 salla-text-white salla-rounded-lg hover:salla-bg-primary-700 salla-transition-all salla-cursor-pointer salla-text-sm salla-font-medium"
          >
            <i class="fas fa-cloud-upload-alt"></i>
            رفع موسيقى جديدة
          </label>
          <input id="file-upload" type="file" accept="audio/*" @change="handleFileUpload" class="salla-hidden" />
        </div>

        <!-- Current Track - If uploaded/selected -->
        <div v-if="audioData" class="salla-mb-6">
          <div class="salla-bg-gray-50 salla-rounded-xl salla-p-4">
            <div class="salla-flex salla-items-center salla-gap-4 salla-mb-4">
              <div class="salla-w-12 salla-h-12 salla-bg-primary-100 salla-rounded-xl salla-flex salla-items-center salla-justify-center">
                <i class="fas fa-play salla-text-primary-600"></i>
              </div>
              <div class="salla-flex-1">
                <h3 class="salla-font-medium salla-text-gray-900">{{ audioFileName }}</h3>
                <p class="salla-text-sm salla-text-gray-500">المقطع المحدد حالياً</p>
              </div>
              <button class="salla-p-2 salla-text-gray-400 hover:salla-text-red-500 salla-transition-colors">
                <i class="fas fa-trash"></i>
              </button>
            </div>
            <audio :src="audioData" controls class="salla-w-full salla-rounded-lg"></audio>
          </div>
        </div>

        <!-- Suggested Tracks Grid -->
        <div v-if="adminMusic.length" class="salla-grid salla-grid-cols-2 salla-gap-4">
          <div 
            v-for="track in adminMusic" 
            :key="track.id" 
            class="salla-bg-gray-50 hover:salla-bg-gray-100 salla-rounded-xl salla-p-4 salla-transition-colors salla-group salla-cursor-pointer"
            @click="selectTrack(track.url)"
          >
            <div class="salla-flex salla-items-center salla-gap-4">
              <div class="salla-w-12 salla-h-12 salla-bg-white salla-rounded-xl salla-flex salla-items-center salla-justify-center salla-group-hover:salla-bg-primary-50 salla-transition-colors">
                <i class="fas fa-music salla-text-primary-600"></i>
              </div>
              <div class="salla-flex-1 salla-min-w-0">
                <h3 class="salla-font-medium salla-text-gray-900 salla-truncate">{{ track.title }}</h3>
                <p class="salla-text-sm salla-text-gray-500">موسيقى خلفية</p>
              </div>
              <button class="salla-p-2 salla-bg-white salla-rounded-lg salla-text-primary-600 salla-opacity-0 group-hover:salla-opacity-100 salla-transition-opacity">
                <i class="fas fa-play"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- Loading State -->
        <div v-if="isLoading" class="salla-fixed salla-inset-0 salla-bg-white/80 salla-flex salla-items-center salla-justify-center salla-z-50">
          <div class="salla-flex salla-items-center salla-gap-3 salla-bg-white salla-px-6 salla-py-4 salla-rounded-xl salla-shadow-lg">
            <div class="salla-w-5 salla-h-5 salla-border-2 salla-border-primary-500 salla-border-t-transparent salla-rounded-full salla-animate-spin"></div>
            <span class="salla-text-primary-600 salla-font-medium">جاري رفع الملف...</span>
          </div>
        </div>
      </div>

      <!-- Tips Footer -->
      <div class="salla-border-t salla-border-gray-200">
        <div class="salla-p-4 salla-bg-primary-50/50">
          <div class="salla-flex salla-items-start salla-gap-3">
            <div class="salla-w-8 salla-h-8 salla-bg-primary-100 salla-rounded-lg salla-flex salla-items-center salla-justify-center salla-text-primary-600">
              <i class="fas fa-lightbulb"></i>
            </div>
            <div>
              <h3 class="salla-text-sm salla-font-medium salla-text-gray-900 salla-mb-1">نصائح للموسيقى</h3>
              <p class="salla-text-xs salla-text-gray-600">استخدم موسيقى عالية الجودة ومناسبة لمحتوى الفيديو للحصول على أفضل النتائج</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
  <script>
import { API_URL } from '@/config';
import { mapMutations } from 'vuex';

export default {
  name: 'MusicPreview',
  props: {
    previewInstance: {
      type: Object,
      required: true
    },
    modifications: {
      type: Object,
      required: true
    },
    dynamicElementIds: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      audioData: null,
      audioFileName: '',
      adminMusic: [],
      isLoading: false,
    };
  },
  mounted() {
    this.loadInitialAudioData();
    this.fetchAdminMusic();
  },
  methods: {
    ...mapMutations(['updateModifications']),
    handleFileUpload(event) {
    const file = event.target.files[0];
    this.audioFileName = file.name;
    this.uploadFileToBackend(file);
  },
  async uploadFileToBackend(file) {
    const formData = new FormData();
    formData.append('audio', file);
    const token = localStorage.getItem('token');
    this.isLoading = true;

    try {
      const response = await fetch(`${API_URL}/api/upload_audio`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log('File uploaded successfully:', data);
      this.audioData = data.url;
      this.updateAudio('audio_key', this.audioData);
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      this.isLoading = false;
    }
  },
  loadInitialAudioData() {
    const audioKey = 'audio_key';
    const elementId = this.dynamicElementIds[audioKey];
    if (elementId && this.modifications[elementId]) {
      this.audioData = this.modifications[elementId];
    }
  },
  updateAudio(key, audioData) {
    const elementId = this.dynamicElementIds[key];
    if (elementId) {
      this.updateModifications({ key: elementId, value: audioData });
      this.$emit('update-modifications', { [elementId]: audioData });
    } else {
      console.warn(`Element ID not found for audio key: ${key}`);
    }
  },
    applyAudioModifications() {
      if (this.previewInstance) {
        const modifications = { ...this.modifications };
        const audioElementId = this.dynamicElementIds['audio_key'];
        if (audioElementId) {
          modifications[audioElementId] = this.audioData;
          console.log('Applying modifications:', modifications);
          try {
            this.previewInstance.setModifications(modifications);
            console.log('Modifications applied successfully to preview instance.');
          } catch (error) {
            console.error('Error applying modifications to preview instance:', error);
          }
        } else {
          console.warn('Audio element ID is not defined for applying modifications.');
        }
      } else {
        console.warn('Preview instance is not defined.');
      }
    },
    fetchAdminMusic() {
    const token = localStorage.getItem('token');
    fetch(`${API_URL}/get_audio_files_users`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        this.adminMusic = data;
      })
      .catch(error => {
        console.error('Error fetching admin music:', error);
      });
  },
  selectTrack(url) {
    this.audioData = url;
    this.updateAudio('audio_key', url);
  }
}
};
</script>

<style scoped>
.music-edit-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  background: #f4f4f8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.music-edit-header h1 {
  color: #333;
  text-align: center;
}

.music-edit-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.suggested-music h2,
.upload-section h2 {
  color: #555;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.track-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.play-button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  margin-right: 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.play-button i {
  margin-right: 5px;
}

.track-title {
  flex-grow: 1;
  margin-right: 20px;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #007BFF;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
}

.custom-file-upload i {
  margin-right: 8px;
}

#file-upload {
  display: none;
}

.audio-info {
  margin-top: 10px;
  background: #e9ecef;
  padding: 10px;
  border-radius: 4px;
}

.audio-info p {
  margin: 0;
  color: #333;
}

.loading-indicator {
  margin-top: 10px;
  color: #007bff;
  font-size: 16px;
  text-align: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #007bff;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
