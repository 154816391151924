<template>
  <div class="dashboard">
    <main class="main-content">
      <section class="content">
        <div class="content-header">
         
          <h2 class="section-title">Favourite Templates</h2>
          <section class="video-grid">
            <div v-for="video in favoriteVideos" :key="video.id" class="video-item">
              <div class="video-wrapper">
                <video :src="video.video_url" controls></video>
                <div class="video-actions">
                  <button class="favorite-btn" @click="removeFromFavorites(video.id)">
                    <i class="fas fa-heart"></i>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { API_URL } from '@/config';

export default {
  data() {
    return {
      favoriteVideos: [],
    };
  },
  mounted() {
    this.fetchFavoriteVideos();
  },
  methods: {
    fetchFavoriteVideos() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to fetch favorite videos.');
        return;
      }
      const requestUrl = `${API_URL}/api/favorite-videos`;
      fetch(requestUrl, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          this.favoriteVideos = data;
        })
        .catch(error => {
          console.error('Error fetching favorite videos:', error);
        });
    },
    removeFromFavorites(videoId) {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to remove a video from favorites.');
        return;
      }
      const requestUrl = `${API_URL}/api/videos/${videoId}/unfavorite`;
      fetch(requestUrl, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('Video removed from favorites successfully:', data);
          // Remove the video from the favoriteVideos array
          this.favoriteVideos = this.favoriteVideos.filter(video => video.id !== videoId);
        })
        .catch(error => {
          console.error('Error removing video from favorites:', error);
        });
    },
  },
};
</script>
  
<style scoped>
.dashboard {
  display: flex;
  justify-content: center; /* Centers your main content horizontally */
  align-items: flex-start; /* Aligns content to the top */
  padding: 15px; /* Adds some padding around the dashboard */
  width: 100%;
  margin-left: -200px;
}

.main-content {
  width: 100%;
  max-width: 95%;
  margin-left: 191px;
  margin-top: -1px;
}

.content {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%; /* This will now take up 100% of the .main-content width */
}

.content-header {
  border-radius: 20px;
  background-color: #ffffff; /* Assuming a variable or fallback color here */
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%; /* This ensures the header stretches to the full width of .content */
  padding: 30px 40px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px; /* Adjusted for spacing */
}

.social-links {
  display: flex;
  gap: 20px;
  font-size: 16px;
  color: #121b25;
  font-weight: 500;
  margin-bottom: 20px;
}

.social-button {
  font-family: Inter, sans-serif;
  padding: 8px 16px; /* Adjust padding to increase button size */
  border: none;
  cursor: pointer;
  background: none;
  color: #6b7280; /* Assuming a variable or fallback color here */
  transition: color 0.3s;
}

.social-button:hover {
  color: #374151; /* Assuming a variable or fallback color here */
}

.social-button.active {
  color: #2563eb; /* Assuming a variable or fallback color here */
  border-bottom: 2px solid #2563eb; /* Assuming a variable or fallback color here */
}

.section-title {
  color: #111827; /* Assuming a variable or fallback color here */
  letter-spacing: -0.5px;
  font-size: 28px; /* Increased font size for the title */
  font-weight: 600;
  line-height: 165%;
  margin-bottom: 20px; /* Add space below the title */
}

.video-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Display 3 videos per row */
  gap: 20px; /* Adjust the gap between videos */
}

.video-item {
  width: 100%; /* Make each video item take up the full width */
  aspect-ratio: 16/9; /* Maintain a 16:9 aspect ratio for the video */
  position: relative; /* Set position to relative for absolute positioning of elements inside */
}

.video-wrapper {
  width: 100%;
  height: 100%;
  position: relative; /* Set position to relative for absolute positioning of elements inside */
}

.video-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and fill the available space */
}

.video-actions {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.favorite-btn {
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.favorite-btn:hover {
  background-color: #fff;
}

.favorite-btn i {
  font-size: 16px;
  color: red;
}



@media (max-width: 1200px) {
  .video-grid {
    grid-template-columns: repeat(2, 1fr); /* Display 2 videos per row */
  }
}

@media (max-width: 768px) {
  .dashboard {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin-left: 0;
    background-color: white;
  }

  .main-content {
    width: 108%;
        margin-left: 0;
        margin-top: 0;
        max-width: 200%;
        background-color: white;
  }

  .content-header {
    padding: 20px;
    margin-bottom: 20px;
    margin-top: -324px;

  }

  .social-links {
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
  }

  .section-title {
    font-size: 24px; /* Adjusted font size for smaller screens */
    text-align: center;
    margin-bottom: 15px;
  }

  .video-grid {
    grid-template-columns: 1fr; /* Display 1 video per row */
    gap: 15px; /* Adjust the gap between videos */
  }

  .video-item {
    aspect-ratio: 16/9; /* Maintain a 16:9 aspect ratio for the video */
  }

  .favorite-btn {
    width: 25px;
    height: 25px;
  }

  .favorite-btn i {
    font-size: 14px;
  }
}

</style>
  