<!-- eslint-disable vue/no-parsing-error -->
<template>
  <section class="salla-w-full salla-text-center salla-bg-gray-50 salla-relative" style="font-family: 'Tajawal', sans-serif;">
    <!-- Header - Enhanced spacing and alignment -->
    <header class="salla-flex salla-flex-wrap salla-justify-between salla-items-center salla-px-8 salla-py-6 salla-bg-white salla-shadow-md">
  <div class="salla-flex salla-gap-5">
    <router-link 
      to="/dashboard" 
      class="salla-px-8 salla-py-3.5 salla-rounded-full salla-bg-primary-500 salla-text-white salla-font-bold salla-text-lg hover:salla-bg-primary-600 salla-transition-all salla-tracking-wide"
    >
      لنبدأ
    </router-link>
  </div>

  <nav class="salla-flex salla-gap-10 salla-my-auto">
    <router-link 
      v-for="(link, index) in ['pricing', 'who-we-are', 'vision']" 
      :key="index"
      :to="`/${link}`" 
      class="salla-text-lg salla-font-semibold salla-leading-relaxed hover:salla-text-primary-500 salla-transition-colors salla-tracking-wide"
    >
      {{ link === 'pricing' ? 'الأسعار' : link === 'who-we-are' ? 'من نحن' : 'رؤيتنا' }}
    </router-link>
  </nav>

  <div class="salla-flex salla-gap-4 salla-my-auto">
    <img 
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1909eed33e4c92c0c6a7ae656b371072f6a331d8caa1d3f9aff6a1b1f6fa06f2" 
      class="salla-w-10 salla-h-10 salla-object-contain" 
      alt="Icon"
    />
    <img 
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/076ad4d43ff739e5b7165868b496c5cce1c42209ae846a2e33f5034beb9bd8ef" 
      class="salla-w-28 salla-object-contain salla-my-auto" 
      alt="Logo"
    />
  </div>
</header>

    <!-- Hero Section - Improved typography and contrast -->
    <div class="salla-relative salla-w-full">
      <img 
        class="salla-w-full salla-h-[600px] salla-object-cover"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/e10882cdb04faf6a0132636ef208861570258418e0825307f47985d3a87efb47"
        alt="Hero Image"
      >
      <div class="salla-absolute salla-inset-0 salla-bg-black salla-opacity-30"></div>
      <div class="salla-absolute salla-top-1/2 salla-left-1/2 salla-transform -salla-translate-x-1/2 -salla-translate-y-1/2 salla-text-white salla-max-w-4xl salla-px-4">
        <h1 class="salla-text-5xl salla-font-bold salla-mb-6">
          تحويل المنتجات إلى 
          <span class="salla-text-primary-400">فيديوهات ترويجية</span> 
          بنقرة واحدة
        </h1>
        <p class="salla-text-xl salla-leading-relaxed salla-mb-8 salla-opacity-90">
          نسهل لك إنتاج فيديوهات ترويجية للوصول لأكبر عدد من العملاء في وسائل التواصل الاجتماعي. اختر من قوالب تصميمنا أو قم بتصميم فيديو يناسبك مع وسائل التواصل الاجتماعي لتعزيز حضورك على الإنترنت.
        </p>
        <router-link 
          to="/dashboard" 
          class="salla-inline-block salla-bg-primary-500 salla-text-white salla-px-8 salla-py-4 salla-rounded-full salla-text-lg salla-font-semibold salla-transition-all hover:salla-bg-primary-600 salla-shadow-lg"
        >
          التجربة مجاناً
        </router-link>
      </div>
    </div>

    <img 
      class="salla-w-full salla-max-w-4xl salla-mt-8 salla-mx-auto"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/2ab011620cb5972ddd36e976ed1cb142716e36f36b79155c0da88b9906560519"
      alt="Additional Image"
    >

    <!-- Trusted Section - Improved spacing and alignment -->
    <section class="salla-w-full salla-bg-white salla-py-16">
      <div class="salla-max-w-5xl salla-mx-auto salla-text-center">
        <h2 class="salla-text-4xl salla-font-bold salla-text-gray-800 salla-mb-12">موثوق من 25 ألف شركة</h2>
        <div class="salla-grid salla-grid-cols-5 salla-gap-8 salla-items-center">
          <div class="salla-text-xl salla-text-gray-800 salla-font-bold salla-uppercase hover:salla-text-primary-500 salla-transition-colors">ProSaas</div>
          <div class="salla-text-xl salla-text-gray-800 salla-font-bold salla-uppercase hover:salla-text-primary-500 salla-transition-colors">No1 City</div>
          <div class="salla-text-xl salla-text-gray-800 salla-font-bold salla-uppercase hover:salla-text-primary-500 salla-transition-colors">Mata</div>
          <div class="salla-text-xl salla-text-gray-800 salla-font-bold salla-uppercase hover:salla-text-primary-500 salla-transition-colors">BRAND</div>
          <div class="salla-text-xl salla-text-gray-800 salla-font-bold salla-uppercase hover:salla-text-primary-500 salla-transition-colors">Tech House</div>
        </div>
      </div>
    </section>

    <!-- Steps Section - Improved layout and spacing -->
    <section class="salla-w-full salla-bg-white salla-py-20">
      <div class="salla-max-w-6xl salla-mx-auto salla-flex salla-justify-between salla-items-start salla-gap-16">
        <div class="salla-w-1/2">
          <img 
            class="salla-w-4/5 salla-h-auto salla-ml-auto"
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3654435a0c3ebe2aa114a37431ffc37c16f67f9888ac2a7c7f1aaba413c81dc2"
            alt="Phone Screens"
          >
        </div>
        <div class="salla-w-1/2 salla-text-right" style="direction: rtl;">
          <h2 class="salla-text-4xl salla-font-bold salla-text-gray-800 salla-mb-12">كيف يعمل Ebda3i؟</h2>
          
          <!-- Steps with consistent spacing -->
          <div class="salla-space-y-12">
            <div class="salla-flex salla-items-start salla-gap-6">
              <div class="salla-w-12 salla-h-12 salla-bg-primary-500 salla-text-white salla-rounded-full salla-flex salla-justify-center salla-items-center salla-text-2xl salla-font-bold salla-shrink-0">1</div>
              <p class="salla-text-xl salla-text-gray-800 salla-font-medium salla-mt-2">
                قم بإضافة الرابط: يتم إضافة الرابط الخاص بالعميل أو المنتج الذي تريده.
              </p>
            </div>

            <div class="salla-flex salla-items-start salla-gap-6">
              <div class="salla-w-12 salla-h-12 salla-bg-primary-500 salla-text-white salla-rounded-full salla-flex salla-justify-center salla-items-center salla-text-2xl salla-font-bold salla-shrink-0">2</div>
              <p class="salla-text-xl salla-text-gray-800 salla-font-medium salla-mt-2">
                إضافة المحتوى: يتم الدخول على الفيديوهات، وضمن الخطوات يمكنك تحميل عدد لا نهائي من القوالب المعدة.
              </p>
            </div>

            <div class="salla-flex salla-items-start salla-gap-6">
              <div class="salla-w-12 salla-h-12 salla-bg-primary-500 salla-text-white salla-rounded-full salla-flex salla-justify-center salla-items-center salla-text-2xl salla-font-bold salla-shrink-0">3</div>
              <p class="salla-text-xl salla-text-gray-800 salla-font-medium salla-mt-2">
                تخصيص المنتج: يتم تعديل النص المقترح على المقاطع وجعل المنتج جاهز للعرض بحلته النهائية.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Info Section - Enhanced layout -->
    <section class="salla-w-full salla-bg-gray-50 salla-py-20">
      <div class="salla-max-w-6xl salla-mx-auto salla-flex salla-justify-between salla-items-center salla-gap-16">
        <div class="salla-w-1/2 salla-text-right">
          <h2 class="salla-text-4xl salla-font-bold salla-text-gray-800 salla-mb-8">الأثر القوي للفيديوهات القصيرة</h2>
          <div class="salla-space-y-6">
            <p class="salla-text-xl salla-text-gray-700 salla-leading-relaxed">
              دراسات مستفيضة أثبتت أن الفيديوهات التي مدتها 60 ثانية تجذب انتباه الناس وتزيد محتواها أكثر من الفيديوهات الطويلة.
            </p>
            <p class="salla-text-xl salla-text-gray-700 salla-leading-relaxed">
              تحليل الفيديوهات والنتائج يمكن أن يؤدي إلى اتخاذ إجراءات وتحسين مستمر.
            </p>
          </div>
        </div>
        <div class="salla-w-1/2">
          <img 
            class="salla-w-full salla-h-auto"
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/c3c4d7d6564356619891f646a59a3acabcee681d5cd7c15f7003e5b05bfd056f"
            alt="Video Impact"
          >
        </div>
      </div>
    </section>

    <!-- Features Section - Improved grid layout -->
    <section class="salla-w-full salla-bg-white salla-py-20">
      <div class="salla-max-w-6xl salla-mx-auto salla-flex salla-justify-between salla-items-start salla-gap-16">
        <div class="salla-w-2/5">
          <img 
            class="salla-w-full salla-h-auto"
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/bbaa03da2ae1b3d4897db46c6d085c8bf8e312fd65ff3f44ee57e2d1a7d1fbb8"
            alt="Phone Features"
          >
        </div>
        <div class="salla-w-3/5 salla-text-right">
          <h2 class="salla-text-4xl salla-font-bold salla-text-gray-800 salla-mb-12">مزایا الخدمة</h2>
          <div class="salla-grid salla-grid-cols-2 salla-gap-8">
            <div class="salla-bg-white salla-p-8 salla-rounded-xl salla-shadow-lg hover:salla-shadow-xl salla-transition-shadow salla-text-center">
              <img 
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a02f5ac870c6d7f089c3792a6663782d976db558eda45560216c10a51b35f52"
                class="salla-w-16 salla-h-16 salla-mx-auto salla-mb-6"
                alt="Template design icon"
              />
              <p class="salla-text-xl salla-text-gray-800 salla-font-medium">
                إنتاج فيديوهات ترويجية بجودة عالية في دقائق
              </p>
            </div>

            <div class="salla-bg-white salla-p-8 salla-rounded-xl salla-shadow-lg hover:salla-shadow-xl salla-transition-shadow salla-text-center">
              <img 
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1cde5be10e88f2d14510fc71d474d9e23b452f3e07536f524daeba876f456ee"
                class="salla-w-16 salla-h-16 salla-mx-auto salla-mb-6"
                alt="High quality production icon"
              />
              <p class="salla-text-xl salla-text-gray-800 salla-font-medium">
                قوالب مصممة بعناية لتناسب مختلف الصناعات والمنتجات
              </p>
            </div>

            <div class="salla-bg-white salla-p-8 salla-rounded-xl salla-shadow-lg hover:salla-shadow-xl salla-transition-shadow salla-text-center salla-col-span-2 salla-w-2/3 salla-mx-auto">
              <img 
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/92741879ec167e794ad8f6a11319a22c2a94100d2cda9922843596c8b58a0609"
                class="salla-w-16 salla-h-16 salla-mx-auto salla-mb-6"
                alt="Analysis and evaluation icon"
              />
              <p class="salla-text-xl salla-text-gray-800 salla-font-medium">
                إمكانية التحليل والتقييم لأداء الفيديوهات عبر منصات متعددة
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Benefits Section - Improved grid and spacing -->
    <section class="salla-w-full salla-bg-gray-50 salla-py-20">
  <div class="salla-max-w-6xl salla-mx-auto">
    <h2 class="salla-text-4xl salla-font-bold salla-text-gray-800 salla-mb-16 salla-text-center">
      لماذا إبداعي؟
    </h2>
    <div class="salla-grid salla-grid-cols-2 salla-gap-8">
      <div class="salla-bg-white salla-p-8 salla-rounded-xl salla-shadow-md hover:salla-shadow-lg salla-transition-shadow salla-text-center">
        <i class="fas fa-network-wired salla-text-5xl salla-text-primary-500 salla-mb-6"></i>
        <p class="salla-text-lg salla-text-gray-800 salla-font-medium">
          تكامل واسع النطاق: يوفر نظامنا تحليلات شاملة
        </p>
      </div>

      <div class="salla-bg-white salla-p-8 salla-rounded-xl salla-shadow-md hover:salla-shadow-lg salla-transition-shadow salla-text-center">
        <i class="fas fa-bullseye salla-text-5xl salla-text-primary-500 salla-mb-6"></i>
        <p class="salla-text-lg salla-text-gray-800 salla-font-medium">
          استهداف فعال: نساعدك في الوصول إلى جمهورك المستهدف
        </p>
      </div>

      <div class="salla-bg-white salla-p-8 salla-rounded-xl salla-shadow-md hover:salla-shadow-lg salla-transition-shadow salla-text-center">
        <i class="fas fa-clock salla-text-5xl salla-text-primary-500 salla-mb-6"></i>
        <p class="salla-text-lg salla-text-gray-800 salla-font-medium">
          توفير الوقت: إنشاء محتوى احترافي في دقائق معدودة
        </p>
      </div>

      <div class="salla-bg-white salla-p-8 salla-rounded-xl salla-shadow-md hover:salla-shadow-lg salla-transition-shadow salla-text-center">
        <i class="fas fa-award salla-text-5xl salla-text-primary-500 salla-mb-6"></i>
        <p class="salla-text-lg salla-text-gray-800 salla-font-medium">
          جودة عالية: محتوى احترافي يعكس هوية علامتك التجارية
        </p>
      </div>

      <div class="salla-bg-white salla-p-8 salla-rounded-xl salla-shadow-md hover:salla-shadow-lg salla-transition-shadow salla-text-center">
        <i class="fas fa-headset salla-text-5xl salla-text-primary-500 salla-mb-6"></i>
        <p class="salla-text-lg salla-text-gray-800 salla-font-medium">
          دعم مستمر: فريق متخصص لمساعدتك على مدار الساعة
        </p>
      </div>

      <div class="salla-bg-white salla-p-8 salla-rounded-xl salla-shadow-md hover:salla-shadow-lg salla-transition-shadow salla-text-center">
        <i class="fas fa-chart-line salla-text-5xl salla-text-primary-500 salla-mb-6"></i>
        <p class="salla-text-lg salla-text-gray-800 salla-font-medium">
          تحليلات متقدمة: تتبع أداء محتواك وتحسينه باستمرار
        </p>
      </div>
    </div>
  </div>
</section>

    <!-- Numbers Section - Enhanced styling -->
    <section class="salla-w-full salla-bg-white salla-py-20">
      <h2 class="salla-text-4xl salla-font-bold salla-text-gray-800 salla-mb-12 salla-text-center">بالأرقام</h2>
      <div class="salla-w-[90%] salla-max-w-6xl salla-mx-auto salla-bg-primary-500 salla-p-12 salla-rounded-3xl salla-flex salla-justify-around salla-items-center salla-shadow-xl">
        <div class="salla-text-center salla-text-white">
          <h3 class="salla-text-5xl salla-font-bold salla-mb-4">+1000000</h3>
          <p class="salla-text-lg salla-font-medium">وصول إلى ملايين المتابعين عبر الفيديوهات المنشورة</p>
        </div>
        <div class="salla-text-center salla-text-white">
          <h3 class="salla-text-5xl salla-font-bold salla-mb-4">+500</h3>
          <p class="salla-text-lg salla-font-medium">قالب متنوع</p>
        </div>
        <div class="salla-text-center salla-text-white">
          <h3 class="salla-text-5xl salla-font-bold salla-mb-4">+10,000</h3>
          <p class="salla-text-lg salla-font-medium">فيديو تم تقديمه حتى الآن</p>
        </div>
      </div>
    </section>

    <!-- Testimonials Section - Enhanced carousel -->
    <section class="salla-w-full salla-bg-gray-50 salla-py-20">
      <h2 class="salla-text-4xl salla-font-bold salla-text-gray-800 salla-mb-12 salla-text-center">استمع إلى ما يقوله عملاؤنا</h2>
      <div class="salla-relative salla-w-[90%] salla-max-w-6xl salla-mx-auto salla-flex salla-items-center">
        <button 
          class="salla-bg-white salla-w-12 salla-h-12 salla-rounded-full salla-flex salla-justify-center salla-items-center salla-shadow-md hover:salla-shadow-lg salla-transition-shadow salla-text-primary-500" 
          @click="scrollLeft"
        >
          <span class="salla-text-2xl">←</span>
        </button>
        
        <div 
          class="salla-flex salla-overflow-x-auto salla-scroll-smooth salla-gap-6 salla-no-scrollbar salla-px-4"
          ref="container" 
          @scroll="handleScroll"
        >
          <div 
            v-for="(testimonial, index) in infiniteTestimonials" 
            :key="index"
            class="salla-bg-white salla-p-8 salla-rounded-xl salla-shadow-lg hover:salla-shadow-xl salla-transition-shadow salla-text-center salla-min-w-[320px] salla-flex-shrink-0"
          >
            <img 
              :src="testimonial.image" 
              alt="User Image" 
              class="salla-w-24 salla-h-24 salla-rounded-full salla-mx-auto salla-mb-6 salla-object-cover"
            >
            <p class="salla-text-lg salla-text-gray-700 salla-mb-6 salla-leading-relaxed">{{ testimonial.text }}</p>
            <h3 class="salla-text-xl salla-font-bold salla-text-gray-800 salla-mb-2">{{ testimonial.name }}</h3>
            <p class="salla-text-base salla-text-gray-600 salla-mb-4">{{ testimonial.role }}</p>
            <div class="salla-text-2xl salla-text-yellow-400">★★★★★</div>
          </div>
        </div>

        <button 
          class="salla-bg-white salla-w-12 salla-h-12 salla-rounded-full salla-flex salla-justify-center salla-items-center salla-shadow-md hover:salla-shadow-lg salla-transition-shadow salla-text-primary-500" 
          @click="scrollRight"
        >
          <span class="salla-text-2xl">→</span>
        </button>
      </div>
    </section>

    <!-- Image Section - Enhanced presentation -->
    <section class="salla-w-full salla-flex salla-justify-center salla-items-center salla-py-20 salla-bg-white">
      <div class="salla-max-w-[90%]">
        <img 
          loading="lazy" 
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/0ca525371d9f615b3dc374cfa4bef23613bea88ea54399d76e778cc8480c2c90" 
          alt="Centered Image"
          class="salla-w-full salla-h-auto salla-rounded-2xl salla-shadow-xl"
        >
      </div>
    </section>

    <!-- CTA Section - Enhanced design -->
    <section class="salla-w-full salla-bg-gray-50 salla-py-20">
      <div class="salla-max-w-4xl salla-mx-auto salla-bg-primary-500 salla-text-center salla-text-white salla-p-16 salla-rounded-3xl salla-relative salla-shadow-xl salla-overflow-hidden" style="direction: rtl;">
        <div class="salla-relative salla-z-10">
          <h2 class="salla-text-4xl salla-font-bold salla-mb-6">انضم إلى رحلة الإبداع مع Ebda3i</h2>
          <p class="salla-text-xl salla-leading-relaxed salla-mb-8 salla-opacity-90">
            ابدأ الآن واكتشف كيف يمكن لـ "Ebda3i" أن يحول أفكارك إلى واقع يلامس ويخدم جمهورك. لا تفوت فرصة تعزيز علامتك التجارية بالفيديوهات الترويجية الجذابة والمؤثرة.
          </p>
          <router-link 
            to="/dashboard" 
            class="salla-inline-block salla-bg-white salla-text-primary-500 salla-px-8 salla-py-4 salla-rounded-full salla-text-lg salla-font-semibold salla-transition-colors hover:salla-bg-gray-100 salla-shadow-lg"
          >
            التجربة مجاناً
          </router-link>
        </div>

        <!-- Enhanced Floating Circles -->
        <div class="salla-absolute salla-inset-0 salla-pointer-events-none">
          <div class="salla-absolute salla-w-[50px] salla-h-[50px] salla-bg-white/30 salla-rounded-full salla-opacity-60 salla-bottom-[20%] salla-left-[10%] salla-animate-float"></div>
          <div class="salla-absolute salla-w-[75px] salla-h-[75px] salla-bg-white/30 salla-rounded-full salla-opacity-60 salla-top-[25%] salla-right-[15%] salla-animate-float-delay-1"></div>
          <div class="salla-absolute salla-w-[100px] salla-h-[100px] salla-bg-white/30 salla-rounded-full salla-opacity-60 salla-bottom-[30%] salla-right-[5%] salla-animate-float-delay-2"></div>
          <div class="salla-absolute salla-w-[60px] salla-h-[60px] salla-bg-white/30 salla-rounded-full salla-opacity-60 salla-top-[50%] salla-left-[20%] salla-animate-float-delay-3"></div>
          <div class="salla-absolute salla-w-[80px] salla-h-[80px] salla-bg-white/30 salla-rounded-full salla-opacity-60 salla-bottom-[40%] salla-left-[30%] salla-animate-float-delay-4"></div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  name: 'LandingPage',
  data() {
    return {
      testimonials: [
        {
          id: 1,
          image: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
          text: 'استخدام إبداعي لإنشاء الفيديوهات الترويجية هو شيء لا يُصدق. لقد ساعدنا في الوصول إلى جمهور أوسع بوقت قياسي!',
          name: 'محمد علي',
          role: 'مدير',
        },
        {
          id: 2,
          image: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
          text: 'إبداعي هو الأداة المثالية لتحسين تواجدنا على وسائل التواصل الاجتماعي. نستخدمها لإنشاء محتوى رائع وجذاب.',
          name: 'أحمد حسن',
          role: 'مصمم',
        },
        {
          id: 3,
          image: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
          text: 'نوصي بشدة باستخدام إبداعي لأي شركة ترغب في تعزيز وجودها الرقمي وتحقيق نتائج ملموسة.',
          name: 'سارة يوسف',
          role: 'مستشار تسويق',
        },
        {
          id: 4,
          image: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
          text: 'إبداعي يوفر أدوات ممتازة وسهلة الاستخدام لإنتاج فيديوهات عالية الجودة بسرعة.',
          name: 'خالد أحمد',
          role: 'مسوق',
        },
        {
          id: 5,
          image: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
          text: 'لقد شهدنا زيادة كبيرة في التفاعل على منصات التواصل الاجتماعي بفضل إبداعي.',
          name: 'ليلى محمد',
          role: 'مدير وسائل التواصل الاجتماعي',
        },
        {
          id: 6,
          image: 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
          text: 'نحب استخدام إبداعي لإنشاء محتوى مرئي رائع يجذب جمهورنا.',
          name: 'يوسف علي',
          role: 'مصمم جرافيك',
        },
      ],
    };
  },
  computed: {
    infiniteTestimonials() {
      return [...this.testimonials, ...this.testimonials];
    },
  },
  methods: {
    scrollLeft() {
      this.$refs.container.scrollBy({
        left: -300,
        behavior: 'smooth',
      });
    },
    scrollRight() {
      this.$refs.container.scrollBy({
        left: 300,
        behavior: 'smooth',
      });
    },
    handleScroll() {
      const container = this.$refs.container;
      if (container.scrollLeft === 0) {
        container.scrollLeft = container.scrollWidth / 2;
      } else if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
        container.scrollLeft = container.scrollWidth / 2 - container.clientWidth;
      }
    },
  },
};
</script>