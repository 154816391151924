<template>
  <div class="salla-flex salla-flex-col salla-gap-8" dir="rtl">
    <!-- Enhanced Header -->
    <div class="salla-flex salla-items-center salla-justify-between salla-bg-white salla-p-6 salla-rounded-xl salla-shadow-sm">
      <div class="salla-flex salla-items-center salla-gap-3">
        <i class="fas fa-music salla-text-2xl salla-text-primary-600"></i>
        <h1 class="salla-text-xl salla-font-bold salla-text-gray-800">تعديل الموسيقى</h1>
      </div>
      <div class="salla-flex salla-items-center salla-gap-3">
        <button 
          @click="toggleMute"
          class="salla-p-2 salla-rounded-lg hover:salla-bg-gray-100 salla-transition-all"
          :class="{ 'salla-text-primary-600': !isMuted }"
        >
          <i :class="['fas', isMuted ? 'fa-volume-mute' : 'fa-volume-up']"></i>
        </button>
      </div>
    </div>

    <!-- Suggested Music Section -->
    <div v-if="adminMusic.length" class="salla-bg-white salla-rounded-xl salla-shadow-sm salla-overflow-hidden">
      <div class="salla-p-6 salla-border-b salla-border-gray-100">
        <h2 class="salla-text-lg salla-font-semibold salla-text-gray-800">الموسيقى المقترحة</h2>
      </div>
      
      <div class="salla-divide-y salla-divide-gray-100">
        <div 
          v-for="track in adminMusic" 
          :key="track.id"
          class="salla-flex salla-items-center salla-gap-4 salla-p-4 hover:salla-bg-gray-50 salla-transition-colors salla-group"
        >
          <button 
            @click="selectTrack(track.url)"
            class="salla-w-10 salla-h-10 salla-rounded-full salla-bg-primary-600 salla-text-white salla-flex salla-items-center salla-justify-center salla-shadow-lg hover:salla-shadow-xl salla-transition-all hover:salla-scale-105"
            :class="{ 'salla-bg-primary-700': currentTrack === track.url }"
          >
            <i :class="['fas', currentTrack === track.url ? 'fa-pause' : 'fa-play']"></i>
          </button>
          
          <div class="salla-flex-1">
            <p class="salla-font-medium salla-text-gray-800">{{ track.title }}</p>
            <div class="salla-w-full salla-h-1 salla-bg-gray-200 salla-rounded-full salla-mt-2 salla-overflow-hidden">
              <div 
                class="salla-h-full salla-bg-primary-600 salla-transition-all"
                :style="{ width: currentTrack === track.url ? `${playProgress}%` : '0%' }"
              ></div>
            </div>
          </div>

          <div class="salla-opacity-0 group-hover:salla-opacity-100 salla-transition-opacity">
            <button 
              @click="selectTrack(track.url)"
              class="salla-px-4 salla-py-2 salla-bg-primary-50 salla-text-primary-600 salla-rounded-lg hover:salla-bg-primary-100 salla-transition-all"
            >
              اختيار
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Upload Section -->
    <div class="salla-bg-white salla-rounded-xl salla-shadow-sm salla-overflow-hidden">
      <div class="salla-p-6 salla-border-b salla-border-gray-100">
        <h2 class="salla-text-lg salla-font-semibold salla-text-gray-800">رفع موسيقاك</h2>
      </div>

      <div class="salla-p-6">
        <div 
          class="salla-border-2 salla-border-dashed salla-border-gray-200 salla-rounded-xl salla-p-8 salla-text-center hover:salla-border-primary-600 salla-transition-colors"
          :class="{ 'salla-border-primary-600': isDragging }"
          @dragover.prevent="isDragging = true"
          @dragleave.prevent="isDragging = false"
          @drop.prevent="handleDrop"
        >
          <div class="salla-space-y-4">
            <div class="salla-w-16 salla-h-16 salla-mx-auto salla-rounded-full salla-bg-primary-50 salla-flex salla-items-center salla-justify-center">
              <i class="fas fa-cloud-upload-alt salla-text-2xl salla-text-primary-600"></i>
            </div>
            
            <div>
              <p class="salla-text-gray-600">اسحب وأفلت الملف الصوتي هنا أو</p>
              <label 
                for="file-upload" 
                class="salla-inline-block salla-mt-2 salla-px-6 salla-py-3 salla-bg-primary-600 salla-text-white salla-rounded-lg salla-cursor-pointer hover:salla-bg-primary-700 salla-transition-all"
              >
                اختر ملف
              </label>
              <input 
                id="file-upload" 
                type="file" 
                accept="audio/*" 
                @change="handleFileUpload" 
                class="salla-hidden"
              />
            </div>
            
            <p class="salla-text-sm salla-text-gray-500">
              MP3, WAV حتى 10 ميجابايت
            </p>
          </div>
        </div>

        <!-- Audio Preview -->
        <div 
          v-if="audioData" 
          class="salla-mt-6 salla-p-4 salla-bg-gray-50 salla-rounded-xl"
        >
          <div class="salla-flex salla-items-center salla-gap-4">
            <div class="salla-w-12 salla-h-12 salla-rounded-lg salla-bg-primary-600 salla-flex salla-items-center salla-justify-center salla-text-white">
              <i class="fas fa-music"></i>
            </div>
            
            <div class="salla-flex-1">
              <p class="salla-font-medium salla-text-gray-800">{{ audioFileName }}</p>
              <audio :src="audioData" controls class="salla-w-full salla-mt-2"></audio>
            </div>

            <button 
              @click="clearAudio"
              class="salla-p-2 salla-text-gray-400 hover:salla-text-red-600 salla-transition-colors"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>

        <!-- Loading State -->
        <div 
          v-if="loading" 
          class="salla-mt-4 salla-flex salla-items-center salla-justify-center salla-gap-2 salla-text-primary-600"
        >
          <i class="fas fa-spinner salla-animate-spin"></i>
          <span>جاري التحميل...</span>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { API_URL } from '@/config';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'MusicTemplate',
  props: {
    previewInstance: {
      type: Object,
      required: true
    },
    videoId: {
      type: Number,
      required: true
    },
    dynamicElementIds: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      videoDetails: null,
      localDynamicElementIds: {},
      audioData: null,
      audioFileName: '',
      adminMusic: [],
      loading: false,
      currentTrack: null, // Added this line to fix the warning
      isDragging: false, // Also added this since it's used in template but was missing
      isMuted: false    // Added for the mute toggle functionality
    };
  },
  computed: {
    ...mapState(['modifications']),
    audioElementId() {
      return this.localDynamicElementIds?.audio_key || null;
    }
  },
  watch: {
    videoId: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fetchVideoData();
        }
      },
      immediate: true
    },
    dynamicElementIds: {
      handler(newVal) {
        this.updateDynamicElementIds(newVal);
      },
      immediate: true,
      deep: true
    },
    modifications: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.applyAudioModifications();
        }
      },
      deep: true
    }
  },
  async mounted() {
    await this.fetchVideoData();
    this.fetchAdminMusic();
    this.updateDynamicElementIds(this.dynamicElementIds);
  },
  methods: {
    updateDynamicElementIds(newDynamicElementIds) {
      this.localDynamicElementIds = newDynamicElementIds.elements || {};
      this.updateAudioModificationForNewTemplate();
      this.loadInitialAudioData();
    },
    updateAudioModificationForNewTemplate() {
      const audioKey = this.localDynamicElementIds?.audio_key || null;
      if (audioKey && this.videoDetails) {
        const audioData = this.modifications[audioKey] || (this.videoDetails.modifications && this.videoDetails.modifications[audioKey]) || '';
        this.updateAudio(audioData);
      } else {
        console.warn('Video details or audio key is not available.');
      }
    },
    ...mapMutations(['updateModifications']),
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.audioFileName = file.name;
      this.uploadFileToBackend(file);
    },
    async uploadFileToBackend(file) {
      this.loading = true; // Set loading to true when upload starts
      const formData = new FormData();
      formData.append('audio', file);
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`${API_URL}/api/upload_audio`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('File uploaded successfully:', data);
        this.audioData = data.url;
        this.updateAudio(this.audioData);
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        this.loading = false; // Set loading to false when upload finishes
      }
    },
    loadInitialAudioData() {
      const audioKey = this.localDynamicElementIds?.audio_key || null;
      if (this.videoDetails && audioKey) {
        const initialAudioData = this.modifications[audioKey] || this.videoDetails.modifications[audioKey] || '';
        if (initialAudioData) {
          this.audioData = initialAudioData;
          console.log('Initial audio data loaded:', this.audioData);
        }
      } else {
        console.warn('Video details or audio key is not available.');
      }
    },
    updateAudio(audioData) {
      const audioElementId = this.audioElementId;
      if (audioElementId) {
        console.log('Updating audio for element ID:', audioElementId, 'with data:', audioData);
        this.updateModifications({ key: audioElementId, value: audioData });
        this.applyAudioModifications();
      } else {
        console.warn('Audio element ID is not defined.');
      }
    },
    async fetchVideoData() {
      try {
        const response = await fetch(`${API_URL}/api/videos/${this.videoId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();
        this.videoDetails = data;
        this.localDynamicElementIds = data.dynamic_element_ids.elements || {};
        console.log('Video data fetched:', this.videoDetails);
        this.loadInitialAudioData();
      } catch (error) {
        console.error('Error fetching video data:', error);
        this.videoDetails = null; // Reset videoDetails on error
      }
    },
    fetchAdminMusic() {
      const token = localStorage.getItem('token');
      fetch(`${API_URL}/get_audio_files_users`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => response.json())
      .then(data => {
        this.adminMusic = data;
        console.log('Admin music fetched:', this.adminMusic);
      })
      .catch(error => {
        console.error('Error fetching admin music:', error);
      });
    },
    selectTrack(url) {
      console.log('Track selected:', url);
      this.audioData = url;
      this.updateAudio(url);
    },
    applyAudioModifications() {
      if (this.previewInstance) {
        const modifications = { ...this.modifications };
        const audioElementId = this.audioElementId;
        if (audioElementId) {
          modifications[audioElementId] = this.audioData;
          console.log('Applying modifications:', modifications);
          try {
            this.previewInstance.setModifications(modifications);
            console.log('Modifications applied successfully to preview instance.');
          } catch (error) {
            console.error('Error applying modifications to preview instance:', error);
          }
        } else {
          console.warn('Audio element ID is not defined for applying modifications.');
        }
        this.saveModificationsToDB(modifications); // Save modifications to the database
      } else {
        console.warn('Preview instance is not defined.');
      }
    },
    async saveModificationsToDB(modifications) {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to save modifications.');
        return;
      }
      try {
        const response = await fetch(`${API_URL}/api/videos/${this.videoId}/modifications`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ modifications })
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Modifications saved to DB successfully:', data);
      } catch (error) {
        console.error('Error saving modifications to DB:', error);
      }
    }
  }
};
</script>


