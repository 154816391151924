<template>
  <div class="salla-flex salla-flex-col salla-h-full salla-bg-gradient-to-br salla-from-gray-50 salla-to-gray-100">
    <!-- Enhanced Header -->
    <div class="salla-flex salla-justify-between salla-items-center salla-p-6 salla-bg-white salla-border-b salla-border-gray-200 salla-shadow-sm">
      <div class="salla-flex salla-items-center salla-gap-4">
        <h2 class="salla-text-2xl salla-font-bold salla-bg-gradient-to-r salla-from-primary-600 salla-to-primary-800 salla-bg-clip-text salla-text-transparent">تعديل الفيديو</h2>
        <!-- Aspect Ratio Selector -->
        
      </div>
      <div class="salla-flex salla-gap-3">
        <button 
          @click="shareVideo" 
          :disabled="isSharing"
          class="salla-px-6 salla-py-2.5 salla-bg-primary-600 salla-text-white salla-rounded-lg salla-font-medium salla-transition-all hover:salla-bg-primary-700 disabled:salla-bg-gray-400 disabled:salla-cursor-not-allowed salla-flex salla-items-center salla-gap-2 salla-shadow-lg hover:salla-shadow-xl salla-transform hover:salla-scale-105"
        >
          <span v-if="isSharing" class="salla-animate-spin">⌛</span>
          <i class="fas fa-share-alt salla-mr-2"></i>
          {{ isSharing ? 'جاري التحميل...' : 'مشاركة' }} / {{ requiredCredits }} كريديت
        </button>
        <button 
          @click="showCloseEditModal" 
          class="salla-p-2.5 salla-bg-gray-100 salla-text-gray-700 salla-rounded-lg hover:salla-bg-gray-200 salla-transition-all hover:salla-text-red-600"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>

    <!-- Main Content Area with Enhanced Layout -->
    <div class="salla-flex salla-flex-1 salla-min-h-0 salla-relative">
      <!-- Enhanced Sidebar -->
<div class="salla-w-20 salla-bg-white salla-py-6 salla-border-r salla-border-gray-200 salla-shadow-lg salla-z-10">
  <div class="salla-flex salla-flex-col salla-gap-8">
    <div
      v-for="(tab, index) in [
        { id: 'media', icon: 'fas fa-photo-video', label: 'وسائط' },
        { id: 'texts', icon: 'fas fa-font', label: 'نصوص' },
        { id: 'music', icon: 'fas fa-music', label: 'موسيقى' },
        { id: 'design', icon: 'fas fa-paint-brush', label: 'تصاميم' }
      ]"
      :key="index"
      @click="setActiveTab(tab.id)"
      class="salla-flex salla-flex-col salla-items-center salla-gap-2 salla-cursor-pointer salla-relative salla-group salla-transition-all hover:salla-transform hover:salla-scale-105"
      :class="{ 'salla-text-primary-600': activeTab === tab.id }"
    >
      <!-- Enhanced Icon Container -->
      <div 
        class="salla-w-14 salla-h-14 salla-rounded-xl salla-flex salla-items-center salla-justify-center salla-transition-all salla-relative salla-overflow-hidden"
        :class="[
          activeTab === tab.id 
            ? 'salla-bg-primary-50 salla-ring-2 salla-ring-primary-600 salla-ring-offset-2'
            : 'salla-bg-white salla-shadow-md group-hover:salla-shadow-lg'
        ]"
      >
        <!-- Background Animation for Active State -->
        <div
          v-if="activeTab === tab.id"
          class="salla-absolute salla-inset-0 salla-bg-primary-600/10 salla-animate-pulse"
        ></div>

        <!-- Icon with Enhanced Visibility -->
        <i 
          :class="[
            tab.icon,
            'salla-text-xl salla-relative salla-z-10',
            activeTab === tab.id ? 'salla-text-primary-600' : 'salla-text-gray-600 group-hover:salla-text-primary-600'
          ]"
        ></i>
      </div>

      <!-- Label -->
      <span 
        class="salla-text-sm salla-font-medium"
        :class="[
          activeTab === tab.id 
            ? 'salla-text-primary-600' 
            : 'salla-text-gray-600 group-hover:salla-text-primary-600'
        ]"
      >
        {{ tab.label }}
      </span>

      <!-- Active Indicator -->
      <div 
        v-if="activeTab === tab.id"
        class="salla-absolute salla-left-0 salla-h-14 salla-w-1 salla-bg-primary-600 salla-rounded-r salla-shadow-lg"
      ></div>
    </div>
  </div>
</div>

      <!-- Enhanced Content Area with Split View -->
      <div class="salla-flex salla-flex-1">
        <!-- Main Editing Area -->
        <div class="salla-w-[45%] salla-bg-white salla-overflow-y-auto salla-p-6 salla-border-r salla-border-gray-200">
          <div v-if="activeTab === 'media'" class="salla-space-y-6" dir="rtl">
            <div class="salla-flex salla-items-center salla-gap-2 salla-mb-6">
              <i class="fas fa-photo-video salla-text-primary-600 salla-text-xl"></i>
              <h3 class="salla-text-xl salla-font-semibold salla-text-gray-800">وسائط</h3>
            </div>
            <MediaTemplate
              v-if="previewInstance"
              :previewInstance="previewInstance"
              :videoId="videoId"
              :update-preview-instance="updatePreviewInstance"
              :num-images="numImages"
              :dynamic-element-ids="dynamicElementIds"
              @modification-updated="handleModificationUpdate"
            />
          </div>

          <!-- Similar enhanced headers for other tabs -->
          <div v-if="activeTab === 'texts'" class="salla-space-y-6" dir="rtl">
            <div class="salla-flex salla-items-center salla-gap-2 salla-mb-6">
              <i class="fas fa-font salla-text-primary-600 salla-text-xl"></i>
              <h3 class="salla-text-xl salla-font-semibold salla-text-gray-800">نصوص</h3>
            </div>
            <TextTemplate
              :previewInstance="previewInstance"
              :videoId="videoId"
              :update-preview-instance="updatePreviewInstance"
              :numTexts="numTexts"
              :dynamic-element-ids="dynamicElementIds"
              @modification-updated="handleModificationUpdate"
            />
          </div>

          <!-- Enhanced Music Tab -->
          <div v-if="activeTab === 'music'" class="salla-space-y-6" dir="rtl">
            <div class="salla-flex salla-items-center salla-gap-2 salla-mb-6">
              <i class="fas fa-music salla-text-primary-600 salla-text-xl"></i>
              <h3 class="salla-text-xl salla-font-semibold salla-text-gray-800">موسيقى</h3>
            </div>
            <MusicTemplate
              ref="musicTemplate"
              :previewInstance="previewInstance"
              :videoId="videoId"
              :dynamic-element-ids="dynamicElementIds"
              @modification-updated="handleModificationUpdate"
            />
          </div>

          <!-- Enhanced Design Tab -->
          <div v-if="activeTab === 'design'" class="salla-space-y-6" dir="rtl">
            <div class="salla-flex salla-items-center salla-gap-2 salla-mb-6">
              <i class="fas fa-paint-brush salla-text-primary-600 salla-text-xl"></i>
              <h3 class="salla-text-xl salla-font-semibold salla-text-gray-800">تصاميم</h3>
            </div>
            <DesignTemplate
              :templates="templates"
              @template-selected="showChangeTemplateModal"
            />
          </div>
        </div>

        <!-- Enhanced Preview Area -->
        <div class="salla-flex-1 salla-bg-gray-900 salla-p-8 salla-flex salla-flex-col salla-items-center salla-justify-center">
          <div class="salla-w-full salla-max-w-4xl salla-mx-auto salla-bg-black salla-rounded-2xl salla-shadow-2xl salla-overflow-hidden">
            <!-- Preview Controls -->
            <div class="salla-flex salla-items-center salla-justify-between salla-px-6 salla-py-4 salla-bg-gray-800">
              <div class="salla-flex salla-items-center salla-gap-4">
                <div class="salla-w-3 salla-h-3 salla-rounded-full salla-bg-red-500"></div>
                <div class="salla-w-3 salla-h-3 salla-rounded-full salla-bg-yellow-500"></div>
                <div class="salla-w-3 salla-h-3 salla-rounded-full salla-bg-green-500"></div>
              </div>
              <div class="salla-text-gray-400 salla-text-sm">معاينة الفيديو</div>
            </div>
            
            <!-- Preview Container -->
            <div class="salla-relative salla-bg-gray-900 salla-aspect-video">
              <div ref="previewVideo" class="salla-absolute salla-inset-0"></div>
            </div>

            <!-- Timeline Indicator (decorative) -->
            <div class="salla-px-6 salla-py-4 salla-bg-gray-800">
              <div class="salla-w-full salla-h-1 salla-bg-gray-700 salla-rounded-full">
                <div class="salla-w-1/3 salla-h-full salla-bg-primary-600 salla-rounded-full"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals remain the same -->
    <ModalComponent
      :show="showCloseEditModalFlag"
      message="إغلاق المحرر سيؤدي إلى فقدان جميع التعديلات."
      cancelText="إلغاء"
      confirmText="تأكيد"
      @cancel="cancelCloseEdit"
      @confirm="confirmCloseEdit"
    />

    <ModalComponent
      :show="showChangeTemplateModalFlag"
      message="تغيير القالب سيؤدي إلى فقدان جميع التعديلات."
      cancelText="إلغاء"
      confirmText="تأكيد"
      @cancel="cancelChangeTemplate"
      @confirm="confirmChangeTemplate"
    />
  </div>
</template>


<script>
import { API_URL } from '@/config';
import TextTemplate from './TextTemplate.vue';
import MusicTemplate from './MusicTemplate.vue';
import MediaTemplate from './MediaTemplate.vue';
import DesignTemplate from './DesignTemplate.vue';
import { Preview } from '@creatomate/preview';
import { mapState } from 'vuex';
import ModalComponent from './ModalComponent.vue'; // Import your modal component

export default {
  name: 'EditMain',
  computed: {
    audioElementId() {
      return this.dynamicElementIds?.elements?.audio_key;
    },
    ...mapState({
      modifications: state => state.modifications
    })
  },
  components: {
  TextTemplate,
  MusicTemplate,
  MediaTemplate,
  DesignTemplate,
  ModalComponent,

},
  props: {
    videoId: {
      type: [Number, String],
      required: false,
    },
    product: {
      type: Object,
      required: false,
    },
    designTemplate: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      videoUrl: '',
      videoDetails: null,
      templates: [],
      previewInstance: null,
      activeTab: 'media',
      isPreviewReady: false,
      dynamicElementIds: {},
      isSharing: false,
      currentProduct: null,
      currentDesignTemplate: null,
      requiredCredits: 0,
      musicTemplate: null,
      numImages: 1,
      numTexts: 1,
      showCloseEditModalFlag: false,
      showChangeTemplateModalFlag: false,
      selectedTemplateData: null, // To store template data for changing template
      aspectRatios: [
      { label: '1:1', width: 1080, height: 1080 },
      { label: '16:9', width: 1920, height: 1080 },
      { label: '9:16', width: 1080, height: 1920 },
      { label: '4:5', width: 864, height: 1080 },
      { label: '5:4', width: 1080, height: 864 },
    ],
    selectedAspectRatio: { label: '1:1', width: 1080, height: 1080 }, // Set default aspect ratio
  };
  },
  mounted() {
    window.addEventListener('beforeunload', this.beforeUnload);

    if (this.videoId === 'preview' && this.product && this.designTemplate) {
      this.initializePreviewFromProductAndDesign();
    } else {
      this.fetchVideoDetails();
    }
    this.fetchRequiredCredits();
    this.fetchTemplates();
    this.musicTemplate = this.$refs.musicTemplate;
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  },
  watch: {
    activeTab(newTab, oldTab) {
      if (newTab !== oldTab) {
        this.applyAllModifications();
      }
    },
    '$store.state.videoDetails'(newValue) {
      if (newValue) {
        this.numTexts = newValue.num_texts || 1;
        this.applyAllModifications();
      }
    },
  },
  methods: {

    onAspectRatioSelected(ratio) {
    this.selectedAspectRatio = ratio;
  },

    selectAspectRatio(aspectRatio) {
    this.selectedAspectRatio = aspectRatio;
  },

    async beforeUnload(event) {
  if (Object.keys(this.$store.state.modifications).length > 0) {
    event.preventDefault();
    event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';

    if (event.defaultPrevented) {
      try {
        await fetch(`${API_URL}/api/videos/${this.videoId}/clear-modifications`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
        });
        console.log('Modifications cleared successfully');
      } catch (error) {
        console.error('Error clearing modifications:', error);
      }
    }
  }
},


    showCloseEditModal() {
      this.showCloseEditModalFlag = true;
    },
    cancelCloseEdit() {
      this.showCloseEditModalFlag = false;
    },
    confirmCloseEdit() {
      this.showCloseEditModalFlag = false;
      this.closeEditPopup();
    },
    showChangeTemplateModal(data) {
      this.selectedTemplateData = data;
      this.showChangeTemplateModalFlag = true;
    },
    cancelChangeTemplate() {
      this.showChangeTemplateModalFlag = false;
    },
    confirmChangeTemplate() {
      this.showChangeTemplateModalFlag = false;
      if (this.selectedTemplateData) {
        this.changeTemplate(this.selectedTemplateData);
      }
    },
    async checkCredits() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to share videos.');
        return 0;
      }

      try {
        const response = await fetch(`${API_URL}/api/check-credits`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data.credits;
      } catch (error) {
        console.error("Failed to check credits:", error);
        return 0;
      }
    },

    async fetchRequiredCredits() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to view required credits.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/required-credits`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.length > 0) {
          this.requiredCredits = data[0].requested_credits;
        } else {
          this.requiredCredits = 0;
        }
      } catch (error) {
        console.error("Failed to fetch required credits:", error);
        this.requiredCredits = 0;
      }
    },

  async shareVideo() {
  console.log('Checking user credits...');
  const credits = await this.checkCredits();

  console.log('User credits:', credits);
  console.log('Required credits:', this.requiredCredits);

  if (credits < this.requiredCredits) {
    console.log('Insufficient credits to share the video');
    return;
  }

  console.log('Sufficient credits. Proceeding with sharing...');
  this.isSharing = true;

  try {
    console.log('Sharing video with ID:', this.videoId);

    // Fetch the user modification for the current user and video
    console.log('Fetching user modification from the backend...');
    const userModificationResponse = await fetch(`${API_URL}/api/videos/${this.videoId}/user-modifications`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    console.log('User modification response status:', userModificationResponse.status);

    if (!userModificationResponse.ok) {
      throw new Error(`Failed to fetch user modification: ${userModificationResponse.status}`);
    }

    console.log('User modification fetched successfully');

    const userModification = await userModificationResponse.json();
    const newModifications = userModification.modifications;
    const currentTemplateId = userModification.template_id;

    console.log('User modification:', userModification);
    console.log('Current Template ID:', currentTemplateId);
    console.log('New Modifications:', newModifications);

    const templateId = this.currentDesignTemplate && this.currentDesignTemplate.id !== currentTemplateId
      ? this.currentDesignTemplate.id
      : currentTemplateId;

    console.log('Sending share request to the backend...');
    const response = await fetch(`${API_URL}/api/videos/${this.videoId}/share`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({
        modifications: newModifications,
        template_id: templateId,
        width: this.selectedAspectRatio.width,
        height: this.selectedAspectRatio.height,
      })
    });

    console.log('Share request response status:', response.status);

    if (response.ok) {
      const responseData = await response.json();
      console.log('Video shared successfully');
      console.log('Response data:', responseData);

      // Update the video with new modifications, template ID, and aspect ratio if changed
      await this.updateVideoTemplate(this.videoId, templateId, newModifications, this.selectedAspectRatio);

      // Clear modifications after sharing
      console.log('Clearing modifications after sharing...');
      await fetch(`${API_URL}/api/videos/${this.videoId}/clear-modifications`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      console.log('Modifications cleared successfully');
            
      this.closeEditPopup();
      location.reload();
    } else { 
      console.error('Failed to share video:', response.status);
      const errorText = await response.text();
      console.error('Error details:', errorText);
    }
  } catch (error) {
    console.error('Error sharing video:', error);
  } finally {
    this.isSharing = false;
    console.log('Sharing process completed');
  }
},

async updateVideoTemplate(videoId, templateId, modifications,  aspectRatio) {
  const token = localStorage.getItem('token');
  if (!token) {
    alert('No token found. You must be logged in to update video template.');
    return;
  }

  try {
    const response = await fetch(`${API_URL}/api/videos/${videoId}/update-template`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ 
        template_id: templateId, 
        modifications,
        width: aspectRatio.width,
        height: aspectRatio.height
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    console.log('Video template and modifications updated successfully');
  } catch (error) {
    console.error('Failed to update video template:', error);
  }
},

    updatePreviewInstance() {
      if (this.isPreviewReady && this.previewInstance) {
        const modifications = this.$store.state.modifications;
        console.log('Applying modifications to preview instance:', modifications);
        this.previewInstance.setModifications(modifications);
      } else {
        console.error('Preview instance is not ready or not initialized');
      }
    },

    initializePreviewFromProductAndDesign() {
      if (!this.previewInstance || !this.currentDesignTemplate || !this.currentProduct) {
        console.error("Preview instance or design template or product is not initialized");
        return;
      }
      console.log('Current Design Template:', this.currentDesignTemplate);
      console.log('Dynamic Elements:', this.currentDesignTemplate.dynamic_element_ids);
      this.previewInstance.loadTemplate(this.currentDesignTemplate.creatomate_template_id);
      const modifications = this.buildModificationsFromProduct(this.currentProduct, this.currentDesignTemplate.dynamic_element_ids);
      console.log('Modifications built from product and design:', modifications);
      this.previewInstance.setModifications(modifications);
      this.previewInstance.render();
      this.saveInitialModifications(this.videoId, this.currentDesignTemplate.id, modifications);
    },

    buildModificationsFromProduct(product, newDynamicElementIds) {
      const modifications = {};
      const mainElements = ['main_image_key', 'product_name_key', 'product_price_key', 'audio_key'];
      const optionalElements = [
        'secondary_image_key', 'third_image_key', 'fourth_image_key',
        'fifth_image_key', 'sixth_image_key', 'seventh_image_key',
        'website_key', 'text_1_key', 'text_2_key', 'text_3_key', 'text_4_key'
      ];

      const processElements = (elementKeys) => {
        elementKeys.forEach(key => {
          if (newDynamicElementIds.elements[key]) {
            const newElementId = newDynamicElementIds.elements[key];
            const oldElementId = this.videoDetails.dynamic_element_ids.elements[key];
            const currentModification = oldElementId ? this.$store.state.modifications[oldElementId] : null;
            modifications[newElementId] = currentModification || this.getDefaultValueForKey(key, product);
          }
        });
      };

      if (newDynamicElementIds && newDynamicElementIds.elements) {
        processElements(mainElements);
        processElements(optionalElements);
      }

      return modifications;
    },

    getDefaultValueForKey(key, product) {
      switch (key) {
        case 'main_image_key':
        case 'secondary_image_key':
        case 'third_image_key':
        case 'fourth_image_key':
        case 'fifth_image_key':
        case 'sixth_image_key':
        case 'seventh_image_key':
          return product?.image_url || '';
        case 'product_name_key':
          return product?.name || '';
        case 'product_price_key':
          return product?.price || '';
        case 'website_key':
          return product?.website_url || '';
        case 'audio_key':
          return product?.audio_url || '';
        case 'text_1_key':
        case 'text_2_key':
        case 'text_3_key':
        case 'text_4_key':
          return '';
        default:
          return '';
      }
    },

    open(selectedProduct, selectedDesign) {
      if (!selectedProduct || !selectedDesign) {
        return;
      }
      this.currentProduct = selectedProduct;
      this.currentDesignTemplate = selectedDesign;
      this.initializePreviewFromProductAndDesign();
    },

    applyModification(key, value) {
      const newKey = Object.keys(this.dynamicElementIds.elements).find(
        (elementKey) => this.dynamicElementIds.elements[elementKey] === key
      );

      if (newKey) {
        console.log('Applying modification:', { key: newKey, value });
        this.$store.commit('updateModifications', { key: newKey, value });

        // Save modifications to the backend
        this.saveModifications(this.videoId, this.$store.state.modifications);
      } else {
        console.warn(`No matching dynamic element ID found for modification key: ${key}`);
      }

      this.applyAllModifications();
    },

    async saveModifications(videoId, modifications) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to save modifications.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/videos/${videoId}/modifications`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ modifications })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Modifications saved successfully:', data);
      } catch (error) {
        console.error('Failed to save modifications:', error);
      }
    },

    async saveInitialModifications(videoId, templateId, modifications) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to save modifications.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/videos/${videoId}/template`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ template_id: templateId, modifications })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Initial modifications and template saved successfully:', data);
      } catch (error) {
        console.error('Failed to save initial modifications and template:', error);
      }
    },

    async initializePreview() {
      return new Promise((resolve) => {
        const previewContainer = this.$refs.previewVideo;
        this.previewInstance = new Preview(previewContainer, 'player', 'public-yqbsoiqlkk8rwzj1pf9n9id6');
        this.previewInstance.onReady = () => {
          this.isPreviewReady = true;
          this.applyVideoSettings();
          resolve();
        };
      });
    },

    fetchVideoDetails() {
    const token = localStorage.getItem('token');
    if (!token) {
      return;
    }
    const requestUrl = `${API_URL}/api/videos/${this.videoId}`;
    fetch(requestUrl, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        this.videoDetails = data.video;
        this.videoUrl = data.video.video_url;

        // Derive numImages from dynamicElementIds
        const imageKeys = [
          'main_image_key',
          'secondary_image_key',
          'third_image_key',
          'fourth_image_key',
          'fifth_image_key',
          'sixth_image_key',
          'seventh_image_key'
        ];
        let numImages = 0;
        imageKeys.forEach(key => {
          if (this.videoDetails.dynamic_element_ids.elements[key]) {
            numImages += 1;
          }
        });
        this.numImages = numImages || 1;

        this.numTexts = data.video.num_texts || 1;

        if (!this.videoDetails.video_source || this.videoDetails.video_source === '') {
          this.videoDetails.video_source = JSON.stringify({ elements: [] });
        }

        this.$store.commit('setInitialModifications', data.video.modifications || {});
        this.$store.commit('setTemplateId', data.video.creatomate_template_id);
        this.dynamicElementIds = data.video.dynamic_element_ids;
        this.resetModifications();

        this.$store.commit('setVideoDetails', data.video);

        console.log('Fetched video details:', data);

        this.initializePreview().then(() => {});
      })
      .catch(error => {
        console.error("Error fetching video details:", error);
      });
  },

    async fetchTemplates() {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_URL}/api/design-templates`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      this.templates = await response.json();
    },

    async fetchDynamicElementIds() {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_URL}/api/dynamic-element-ids?template_id=${this.currentDesignTemplate.creatomate_template_id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      this.dynamicElementIds = await response.json();
      console.log('Fetched dynamic element IDs:', this.dynamicElementIds);
      this.$store.commit('setVideoDetails', {
        ...this.videoDetails,
        dynamic_element_ids: this.dynamicElementIds
      });
      console.log('Updated videoDetails in the store:', this.$store.state.videoDetails);
    },

    async changeTemplate(data) {
  console.log('Changing template to:', data.template);
  this.currentDesignTemplate = data.template;

  console.log('Current design template:', this.currentDesignTemplate);

  await this.fetchDynamicElementIds();
  this.previewInstance.loadTemplate(this.currentDesignTemplate.creatomate_template_id);

  console.log('Dynamic element IDs after fetching:', this.dynamicElementIds);

  // Get the default modifications for the new template
  const defaultModifications = await this.getDefaultModifications(data.template);

  // Update the template_id and modifications in the UserModification table
  await this.updateUserModification(data.template.id, defaultModifications);

  // Set video details with the new dynamic element IDs
  this.$store.commit('setVideoDetails', {
    ...this.videoDetails,
    dynamic_element_ids: this.dynamicElementIds,
    num_texts: data.template.num_texts || 1,
  });
  console.log('Updated videoDetails in the store:', this.$store.state.videoDetails);

  console.log('Loading new template into preview instance:', data.template.creatomate_template_id);
  this.previewInstance.loadTemplate(data.template.creatomate_template_id);

  this.numImages = data.template.num_images || 1;
  this.numTexts = data.template.num_texts || 1;
  console.log('Updated number of images:', this.numImages);
  console.log('Updated number of texts:', this.numTexts);

  this.updateMusicTemplate();

  // Save template and modifications to the backend
  this.saveTemplate(this.videoId, this.currentDesignTemplate.id, defaultModifications);
},

async getDefaultModifications(template) {
  const token = localStorage.getItem('token');
  if (!token) {
    alert('No token found. You must be logged in to get default modifications.');
    return {};
  }

  try {
    const response = await fetch(`${API_URL}/api/dynamic-element-ids?template_id=${template.creatomate_template_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const dynamicElementIds = await response.json();
    const defaultModifications = {};

    if (dynamicElementIds.elements) {
      for (const key in dynamicElementIds.elements) {
        const elementId = dynamicElementIds.elements[key];
        defaultModifications[elementId] = this.getDefaultValueForKey(key);
      }
    }

    return defaultModifications;
  } catch (error) {
    console.error('Failed to get default modifications:', error);
    return {};
  }
},

async updateUserModification(templateId, modifications) {
  const token = localStorage.getItem('token');
  if (!token) {
    alert('No token found. You must be logged in to update user modification.');
    return;
  }

  try {
    const response = await fetch(`${API_URL}/api/videos/${this.videoId}/user-modifications`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ template_id: templateId, modifications })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    console.log('User modification updated successfully');
  } catch (error) {
    console.error('Failed to update user modification:', error);
  }
},

async saveTemplate(videoId, templateId, modifications) {
  const token = localStorage.getItem('token');
  if (!token) {
    alert('No token found. You must be logged in to save template.');
    return;
  }

  try {
    const response = await fetch(`${API_URL}/api/videos/${videoId}/template`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ template_id: templateId, modifications: modifications })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Template saved successfully:', data);
  } catch (error) {
    console.error('Failed to save template:', error);
  }
},

    applyAllModifications() {
      if (this.isPreviewReady && this.previewInstance) {
        const modifications = this.$store.state.modifications;
        console.log('Applying modifications to preview instance:', modifications);
        this.previewInstance.setModifications(modifications);
      }
    },

    updateMusicTemplate() {
      if (this.isPreviewReady && this.previewInstance) {
        const modifications = this.$store.state.modifications;
        if (this.currentDesignTemplate && this.currentDesignTemplate.audio_key) {
          const audioKey = this.currentDesignTemplate.audio_key;
          const audioValue = this.getDefaultValueForKey(audioKey, this.currentProduct);
          console.log('Updating audio modification:', { key: audioKey, value: audioValue });
          modifications[audioKey] = audioValue;
          this.previewInstance.setModifications(modifications);
        }
      }
    },

    setActiveTab(tabName) {
      this.activeTab = tabName;
      this.applyAllModifications();
    },

    async closeEditPopup() {
  this.resetModifications();
  this.$store.commit('resetState');

  console.log('Clearing modifications...');
  try {
    const response = await fetch(`${API_URL}/api/videos/${this.videoId}/clear-modifications`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    });

    if (response.ok) {
      console.log('Modifications cleared successfully');
    } else {
      console.error('Failed to clear modifications:', response.status);
      const errorText = await response.text();
      console.error('Error details:', errorText);
    }
  } catch (error) {
    console.error('Error clearing modifications:', error);
  } finally {
    // Ensure the popup closes
    this.$emit('close');
  }
},





applyVideoSettings() {
  if (!this.previewInstance) {
    return;
  }
  if (this.videoUrl) {
    this.previewInstance.setSource(this.videoUrl);
  }

  const templateId = this.$store.state.templateId;
  // Ensure templateId is the Creatomate template ID (UUID string)
  if (templateId) {
    this.previewInstance.loadTemplate(templateId);
  }
  if (Object.keys(this.$store.state.initialModifications).length > 0) {
    this.previewInstance.setModifications(this.$store.state.initialModifications);
  }
},

    resetModifications() {
      const initialModifications = this.$store.state.initialModifications;
      this.$store.commit('setModifications', initialModifications);
      if (this.previewInstance) {
        this.previewInstance.setModifications(initialModifications);
      }
    },

    findElementById(elements, id) {
      if (!Array.isArray(elements)) {
        return null;
      }

      for (let element of elements) {
        if (element.id === id) {
          return element;
        }
        if (element.elements && element.elements.length) {
          const found = this.findElementById(element.elements, id);
          if (found) {
            return found;
          }
        }
      }
      return null;
    },

    saveEditedVideo(templateId, updatedVideoSource) {
      const token = localStorage.getItem('token');
      if (!token) {
        return;
      }
      const requestUrl = `${API_URL}/api/videos/${this.videoId}`;
      const modifications = this.previewInstance.getModifications();
      const requestData = {
        video_source: updatedVideoSource,
        template_id: templateId,
        modifications: modifications,
      };
      console.log('Saving edited video with data:', requestData);
      fetch(requestUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Video saved successfully:', data);
          this.videoDetails.video_source = updatedVideoSource;
          this.videoDetails.modifications = modifications;
        })
        .catch(error => {
          console.error('Error saving edited video:', error);
        });
    },

    handleModificationUpdate(modification) {
      this.$store.commit('updateModifications', modification);
      this.saveModifications(this.videoId, this.$store.state.modifications);
      this.applyAllModifications();
    },
  },
};
</script>














    




<style scoped>
.edit-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.header-actions {
  display: flex;
  gap: 10px;
}

.share-btn,
.exit-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

.share-btn {
  background-color: #007bff;
  color: #fff;
}

.share-btn:disabled,
.exit-btn:disabled {
  cursor: default;
  background-color: #aaa;
  color: #666;
}

.exit-btn {
  background-color: #eee;
  color: #333;
}

.edit-content {
  display: flex;
  height: calc(100% - 80px);
  border-left: 1px solid #dcdcdc;
}

.sidebar {
  width: 80px;
  background-color: #f5f5f5;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
  position: relative;
}

.icon-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.sidebar-item.active .icon-wrapper {
  background-color: #007bff;
  color: #fff;
}

.sidebar-item.active::before {
  content: '';
  position: absolute;
  left: -19px;
  top: 34%;
  transform: translateY(-50%);
  width: 4px;
  height: 55px;
  background-color: #007bff;
  border-radius: 0 4px 4px 0;
}

.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
}

.video-preview {
  width: 400px;
  padding: 20px;
  display: flex;
  justify-content: center;
  background-color: #e6e6e6;
}

.video-preview video {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}




@media (max-width: 768px) {
  .edit-container {
    display: flex;
    flex-direction: column;
  }

  .edit-header {
    padding: 10px;
  }

  .header-actions {
    gap: 5px;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    z-index: 10000;
    width: 100%;
    max-width: 1200px;
    height: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .share-btn,
  .exit-btn {
    padding: 6px 10px;
    font-size: 14px;
  }

  .edit-content {
    flex-direction: column;
    height: calc(100% - 60px);
    border-left: none;
    padding: 10px;
    overflow: auto;
  }

  .sidebar {
    width: 100%;
    height: 60px;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
    box-shadow: none;
    border-top: none;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 1000;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }

  .sidebar-item {
    margin-bottom: 0;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    color: #8c8c8c;
    transition: color 0.3s ease;
  }

  .sidebar-item.active {
    color: #007bff;
  }

  .icon-wrapper {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    background-color: transparent;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sidebar-item.active .icon-wrapper {
    background-color: transparent;
    color: #007bff;
  }

  .sidebar-item span {
    display: block;
    font-size: 12px;
    font-weight: 500;
  }

  .sidebar-item.active::before {
    display: none;
  }

  .video-preview {
    width: 100%;
    padding: 0;
    height: 2dvh;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  .video-preview > div {
    width: 100%;
    height: 34vh;
    overflow: hidden;
  }

  .video-preview iframe {
    width: 70%;
    height: 70%;
    border: none;
  }

  .main-content {
    padding: 10px;
    flex-grow: 1;
    order: 1;
  }
}

@media (max-width: 480px) {
  .edit-header {
    padding: 5px;
  }

  .share-btn,
  .exit-btn {
    padding: 4px 8px;
    font-size: 12px;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    z-index: 10000;
    width: 100%;
    max-width: 1200px;
    height: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  }

  .edit-content {
    flex-direction: column;
    height: calc(100% - 50px); /* Account for the navbar height */
    border-left: none;
    padding: 5px;
    overflow: auto;
  }

  .sidebar {
    width: 100%;
        height: 67px;
        flex-direction: row;
        justify-content: space-around;
        padding: 5px 0;
        box-shadow: none;
        border-top: 1px solid #dcdcdc;
        position: fixed;
        bottom: 0;
        left: 0px;
        background-color: #168eff;

  }

  .sidebar-item {
    margin-bottom: 0;
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  .icon-wrapper {
    width: 59px;
        height: 45px;
        margin-bottom: 0;
  }

  .sidebar-item span {
    display: none;
  }

  .sidebar-item.active::before {
    display: none;
  }

  .video-preview {
    width: 100%;
        padding: 0;
        height: 2dvh;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
  }

  .video-preview > div {
    width: 100%;
        height: 34vh;
    overflow: hidden;
  }

  .video-preview iframe {
    width: 70%;
    height: 70%; /* Make iframe take full height of its container */
    border: none;
  }

  .main-content {
    padding: 5px;
    flex-grow: 1;
    order: 1; /* Ensure the main content is below the video preview */
  }
}

</style>