<template>
    <div class="loader" v-if="loading">
      <div class="loader" id="sq1"></div>
      <div class="loader" id="sq2"></div>
      <div class="loader" id="sq3"></div>
      <div class="loader" id="sq4"></div>
      <div class="loader" id="sq5"></div>
      <div class="loader" id="sq6"></div>
      <div class="loader" id="sq7"></div>
      <div class="loader" id="sq8"></div>
      <div class="loader" id="sq9"></div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: true
      }
    },
    mounted() {
      setTimeout(() => {
        this.loading = false;
      }, 5000); // Hide the loader after 3 seconds
    }
  }
  </script>
  
  <style>
  @keyframes loader_5191 {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .loader {
    background: #ddd;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -5px;
    transition: opacity 15s, visibility 3.75s;
  }
  
  #sq1 {
    margin-top: -25px;
    margin-left: -25px;
    background-color: rgb(196, 113, 237);
    animation: loader_5191 1000ms ease-in-out 0s infinite alternate;
  }
  
  #sq2 {
    margin-top: -25px;
    background-color: rgb(17, 173, 207);
    /* animation: loader_5191 1000ms ease-in-out 75ms infinite alternate; */
  }
  
  #sq3 {
    margin-top: -25px;
    margin-left: 15px;
    background-color: rgb(196, 113, 237);
    animation: loader_5191 1000ms ease-in-out 150ms infinite;
  }
  
  #sq4 {
    margin-left: -25px;
    background-color: rgb(196, 113, 237);
    /* animation: loader_5191 1000ms ease-in-out 225ms infinite; */
  }
  
  #sq5 {
    background-color: rgb(17, 173, 207);
    /* animation: loader_5191 1000ms ease-in-out 300ms infinite; */
  }
  
  #sq6 {
    background-color: rgb(17, 173, 207);
    margin-left: 15px;
    /* animation: loader_5191 1000ms ease-in-out 375ms infinite; */
  }
  
  #sq7 {
    background-color: rgb(17, 173, 207);
    margin-top: 15px;
    margin-left: -25px;
    animation: loader_5191 1000ms ease-in-out 450ms infinite;
  }
  
  #sq8 {
    background-color: rgb(196, 113, 237);
    margin-top: 15px;
    /* animation: loader_5191 1000ms ease-in-out 525ms infinite; */
  }
  
  #sq9 {
    background-color: rgb(196, 113, 237);
    margin-top: 15px;
    margin-left: 15px;
    animation: loader_5191 1000ms ease-in-out 600ms infinite;
  }
  
  .loader--hidden {
    opacity: 0;
    visibility: hidden;
  }
  
  @keyframes loading {
    from {
      transform: rotate(0turn);
    }
    to {
      transform: rotate(1turn);
    }
  }
  </style>