<template>
  <div class="salla-bg-gray-50 salla-min-h-screen salla-p-6">
    <!-- Header Section -->
    <div class="salla-mb-8">
      <div class="salla-flex salla-justify-between salla-items-center">
        <h1 class="salla-text-2xl salla-font-bold salla-text-gray-900">User Management</h1>
        <button 
          class="salla-inline-flex salla-items-center salla-px-4 salla-py-2 salla-bg-primary-600 salla-text-white salla-rounded-lg salla-shadow-sm hover:salla-bg-primary-700 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-offset-2 focus:salla-ring-primary-500"
          @click="addNewUser"
        >
          <span class="salla-mr-2">+</span> Add New User
        </button>
      </div>
      
      <!-- Search and Filter Section -->
      <div class="salla-mt-4 salla-flex salla-flex-col md:salla-flex-row salla-gap-4">
        <div class="salla-flex-1">
          <input
            type="text"
            v-model="searchQuery"
            placeholder="Search users..."
            class="salla-w-full salla-px-4 salla-py-2 salla-rounded-lg salla-border salla-border-gray-300 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-primary-500"
          />
        </div>
        <div class="salla-flex salla-gap-2">
          <select 
            class="salla-px-4 salla-py-2 salla-rounded-lg salla-border salla-border-gray-300 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-primary-500"
            v-model="statusFilter"
          >
            <option value="">All Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
          <select 
            class="salla-px-4 salla-py-2 salla-rounded-lg salla-border salla-border-gray-300 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-primary-500"
            v-model="subscriptionFilter"
          >
            <option value="">All Subscriptions</option>
            <option value="subscribed">Subscribed</option>
            <option value="unsubscribed">Unsubscribed</option>
          </select>
        </div>
      </div>
    </div>

    <!-- Users Table -->
    <div class="salla-bg-white salla-rounded-lg salla-shadow">
      <div class="salla-overflow-x-auto">
        <table class="salla-min-w-full salla-divide-y salla-divide-gray-200">
          <thead class="salla-bg-gray-50">
            <tr>
              <th class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">User</th>
              <th class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">Status</th>
              <th class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">Subscription</th>
              <th class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">Last Payment</th>
              <th class="salla-px-6 salla-py-3 salla-text-right salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody class="salla-bg-white salla-divide-y salla-divide-gray-200">
            <tr v-for="user in paginatedUsers" :key="user.id" class="hover:salla-bg-gray-50">
              <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap">
                <div class="salla-flex salla-items-center">
                  <div class="salla-flex-shrink-0 salla-h-10 salla-w-10">
                    <img 
  :src="user.profile_image || `${API_URL}/api/default-avatar`" 
  :alt="user.username"
  @error="handleImageError"
  class="salla-h-10 salla-w-10 salla-rounded-full salla-object-cover"
/>
                  </div>
                  <div class="salla-ml-4">
                    <div class="salla-text-sm salla-font-medium salla-text-gray-900">{{ user.username }}</div>
                    <div class="salla-text-sm salla-text-gray-500">{{ user.email }}</div>
                  </div>
                </div>
              </td>
              <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap">
                <span 
                  :class="[
                    'salla-px-2 salla-inline-flex salla-text-xs salla-leading-5 salla-font-semibold salla-rounded-full',
                    user.subscription?.status === 'active' 
                      ? 'salla-bg-green-100 salla-text-green-800' 
                      : 'salla-bg-red-100 salla-text-red-800'
                  ]"
                >
                  {{ user.subscription?.status === 'active' ? 'Active' : 'Inactive' }}
                </span>
              </td>
              <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap">
                <span 
                  :class="[
                    'salla-px-2 salla-inline-flex salla-text-xs salla-leading-5 salla-font-semibold salla-rounded-full',
                    user.is_subscribed 
                      ? 'salla-bg-blue-100 salla-text-blue-800' 
                      : 'salla-bg-gray-100 salla-text-gray-800'
                  ]"
                >
                  {{ user.is_subscribed ? 'Subscribed' : 'Not Subscribed' }}
                </span>
              </td>
              <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap salla-text-sm salla-text-gray-500">
                {{ user.payments && user.payments.length > 0 
                  ? `****${user.payments[user.payments.length - 1].card_last4}` 
                  : 'No payment info' }}
              </td>
              <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap salla-text-right salla-text-sm salla-font-medium">
                <div class="salla-flex salla-justify-end salla-space-x-2">
                  <button 
                    @click="viewUser(user.id)"
                    class="salla-text-primary-600 hover:salla-text-primary-900 salla-px-2 salla-py-1 salla-rounded-md hover:salla-bg-primary-50"
                  >
                    View
                  </button>
                  <button 
                    @click="editUser(user.id)"
                    class="salla-text-primary-600 hover:salla-text-primary-900 salla-px-2 salla-py-1 salla-rounded-md hover:salla-bg-primary-50"
                  >
                    Edit
                  </button>
                  <button 
                    @click="confirmDeleteUser(user.id)"
                    class="salla-text-red-600 hover:salla-text-red-900 salla-px-2 salla-py-1 salla-rounded-md hover:salla-bg-red-50"
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      <div class="salla-bg-white salla-px-4 salla-py-3 salla-flex salla-items-center salla-justify-between salla-border-t salla-border-gray-200 sm:salla-px-6">
        <div class="salla-flex-1 salla-flex salla-justify-between sm:salla-hidden">
          <button
            @click="prevPage"
            :disabled="currentPage === 1"
            class="salla-relative salla-inline-flex salla-items-center salla-px-4 salla-py-2 salla-border salla-border-gray-300 salla-text-sm salla-font-medium salla-rounded-md salla-text-gray-700 salla-bg-white hover:salla-bg-gray-50"
          >
            Previous
          </button>
          <button
            @click="nextPage"
            :disabled="currentPage === totalPages"
            class="salla-relative salla-inline-flex salla-items-center salla-px-4 salla-py-2 salla-border salla-border-gray-300 salla-text-sm salla-font-medium salla-rounded-md salla-text-gray-700 salla-bg-white hover:salla-bg-gray-50"
          >
            Next
          </button>
        </div>
        <div class="salla-hidden sm:salla-flex-1 sm:salla-flex sm:salla-items-center sm:salla-justify-between">
          <div>
            <p class="salla-text-sm salla-text-gray-700">
              Showing
              <span class="salla-font-medium">{{ ((currentPage - 1) * usersPerPage) + 1 }}</span>
              to
              <span class="salla-font-medium">{{ Math.min(currentPage * usersPerPage, filteredUsers.length) }}</span>
              of
              <span class="salla-font-medium">{{ filteredUsers.length }}</span>
              results
            </p>
          </div>
          <div>
            <nav class="salla-relative salla-z-0 salla-inline-flex salla-rounded-md salla-shadow-sm -salla-space-x-px">
              <button
                v-for="page in displayedPages"
                :key="page"
                @click="currentPage = page"
                :class="[
                  'salla-relative salla-inline-flex salla-items-center salla-px-4 salla-py-2 salla-border salla-text-sm salla-font-medium',
                  currentPage === page
                    ? 'salla-z-10 salla-bg-primary-50 salla-border-primary-500 salla-text-primary-600'
                    : 'salla-bg-white salla-border-gray-300 salla-text-gray-500 hover:salla-bg-gray-50'
                ]"
              >
                {{ page }}
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <!-- User View/Edit Modal -->
    <UserAdminEdit
      v-if="showUserModal"
      :user-id="selectedUserId"
      :mode="modalMode"
      @close="closeUserModal"
    />

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteModal" class="salla-fixed salla-inset-0 salla-z-10 salla-overflow-y-auto">
      <div class="salla-flex salla-items-end salla-justify-center salla-min-h-screen salla-pt-4 salla-px-4 salla-pb-20 salla-text-center sm:salla-block sm:salla-p-0">
        <div class="salla-fixed salla-inset-0 salla-bg-gray-500 salla-bg-opacity-75 salla-transition-opacity"></div>
        <div class="salla-inline-block salla-align-bottom salla-bg-white salla-rounded-lg salla-text-left salla-overflow-hidden salla-shadow-xl salla-transform salla-transition-all sm:salla-my-8 sm:salla-align-middle sm:salla-max-w-lg sm:salla-w-full">
          <div class="salla-bg-white salla-px-4 salla-pt-5 salla-pb-4 sm:salla-p-6 sm:salla-pb-4">
            <div class="sm:salla-flex sm:salla-items-start">
              <div class="salla-mt-3 salla-text-center sm:salla-mt-0 sm:salla-text-left">
                <h3 class="salla-text-lg salla-leading-6 salla-font-medium salla-text-gray-900">Delete User</h3>
                <div class="salla-mt-2">
                  <p class="salla-text-sm salla-text-gray-500">
                    Are you sure you want to delete this user? This action cannot be undone.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="salla-bg-gray-50 salla-px-4 salla-py-3 sm:salla-px-6 sm:salla-flex sm:salla-flex-row-reverse">
            <button
              @click="deleteUser"
              class="salla-w-full salla-inline-flex salla-justify-center salla-rounded-md salla-border salla-border-transparent salla-shadow-sm salla-px-4 salla-py-2 salla-bg-red-600 salla-text-base salla-font-medium salla-text-white hover:salla-bg-red-700 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-offset-2 focus:salla-ring-red-500 sm:salla-ml-3 sm:salla-w-auto sm:salla-text-sm"
            >
              Delete
            </button>
            <button
              @click="showDeleteModal = false"
              class="salla-mt-3 salla-w-full salla-inline-flex salla-justify-center salla-rounded-md salla-border salla-border-gray-300 salla-shadow-sm salla-px-4 salla-py-2 salla-bg-white salla-text-base salla-font-medium salla-text-gray-700 hover:salla-bg-gray-50 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-offset-2 focus:salla-ring-primary-500 sm:salla-mt-0 sm:salla-ml-3 sm:salla-w-auto sm:salla-text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
  
  
  
  
  
  
<script>
import { ref, computed } from 'vue';
import { API_URL } from '@/config';
import UserAdminEdit from './UserAdminEdit.vue';

export default {
  components: {
    UserAdminEdit
  },
  setup() {
    const users = ref([]);
    const searchQuery = ref('');
    const statusFilter = ref('');
    const subscriptionFilter = ref('');
    const currentPage = ref(1);
    const usersPerPage = ref(10);
    const showUserModal = ref(false);
    const showDeleteModal = ref(false);
    const selectedUserId = ref(null);
    const modalMode = ref('view');
    const userToDelete = ref(null);

    // Computed properties
    const filteredUsers = computed(() => {
      return users.value.filter(user => {
        const matchesSearch = !searchQuery.value || 
          user.username?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          user.email?.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          (user.payments?.length > 0 && user.payments[user.payments.length - 1].card_last4?.includes(searchQuery.value));

        const matchesStatus = !statusFilter.value || 
          (statusFilter.value === 'active' && user.subscription?.status === 'active') ||
          (statusFilter.value === 'inactive' && user.subscription?.status !== 'active');

        const matchesSubscription = !subscriptionFilter.value ||
          (subscriptionFilter.value === 'subscribed' && user.is_subscribed) ||
          (subscriptionFilter.value === 'unsubscribed' && !user.is_subscribed);

        return matchesSearch && matchesStatus && matchesSubscription;
      });
    });

    const paginatedUsers = computed(() => {
      const start = (currentPage.value - 1) * usersPerPage.value;
      const end = start + usersPerPage.value;
      return filteredUsers.value.slice(start, end);
    });

    const totalPages = computed(() => {
      return Math.ceil(filteredUsers.value.length / usersPerPage.value);
    });

    const displayedPages = computed(() => {
      const total = totalPages.value;
      const current = currentPage.value;
      const pages = [];
      
      if (total <= 7) {
        for (let i = 1; i <= total; i++) {
          pages.push(i);
        }
      } else {
        if (current <= 3) {
          for (let i = 1; i <= 5; i++) {
            pages.push(i);
          }
          pages.push('...');
          pages.push(total);
        } else if (current >= total - 2) {
          pages.push(1);
          pages.push('...');
          for (let i = total - 4; i <= total; i++) {
            pages.push(i);
          }
        } else {
          pages.push(1);
          pages.push('...');
          for (let i = current - 1; i <= current + 1; i++) {
            pages.push(i);
          }
          pages.push('...');
          pages.push(total);
        }
      }
      return pages;
    });

    // Methods
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No authentication token found');

        const response = await fetch(`${API_URL}/api/admin/users`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to fetch users');

        const data = await response.json();
        users.value = data.users.map(user => ({
          ...user,
          payments: user.payments || []
        }));
      } catch (error) {
        console.error('Error fetching users:', error);
        alert('Failed to fetch users');
      }
    };

    const viewUser = (userId) => {
      selectedUserId.value = userId;
      modalMode.value = 'view';
      showUserModal.value = true;
    };

    const editUser = (userId) => {
      selectedUserId.value = userId;
      modalMode.value = 'edit';
      showUserModal.value = true;
    };

    const addNewUser = () => {
      selectedUserId.value = null;
      modalMode.value = 'create';
      showUserModal.value = true;
    };

    const closeUserModal = () => {
      showUserModal.value = false;
      selectedUserId.value = null;
      fetchUsers(); // Refresh list after modal closes
    };

    const confirmDeleteUser = (userId) => {
      userToDelete.value = userId;
      showDeleteModal.value = true;
    };

    const deleteUser = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No authentication token found');

        const response = await fetch(`${API_URL}/api/admin/users/${userToDelete.value}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to delete user');

        await fetchUsers();
        showDeleteModal.value = false;
        userToDelete.value = null;
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('Failed to delete user');
      }
    };

    const suspendUser = async (userId) => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No authentication token found');

        const response = await fetch(`${API_URL}/api/admin/users/${userId}/suspend`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to suspend user');

        await fetchUsers();
      } catch (error) {
        console.error('Error suspending user:', error);
        alert('Failed to suspend user');
      }
    };

    const activateUser = async (userId) => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No authentication token found');

        const response = await fetch(`${API_URL}/api/admin/users/${userId}/activate`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to activate user');

        await fetchUsers();
      } catch (error) {
        console.error('Error activating user:', error);
        alert('Failed to activate user');
      }
    };

    const makeAdmin = async (userId) => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No authentication token found');

        const response = await fetch(`${API_URL}/api/admin/users/${userId}/make-admin`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to make user admin');

        await fetchUsers();
      } catch (error) {
        console.error('Error making user admin:', error);
        alert('Failed to make user admin');
      }
    };

    const revokeAdmin = async (userId) => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No authentication token found');

        const response = await fetch(`${API_URL}/api/admin/users/${userId}/revoke-admin`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to revoke admin privileges');

        await fetchUsers();
      } catch (error) {
        console.error('Error revoking admin privileges:', error);
        alert('Failed to revoke admin privileges');
      }
    };

    const handleImageError = (e) => {
  e.target.src = `${API_URL}/api/default-avatar`;
};

    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

    // Initialize
    fetchUsers();

    return {
      // State
      users,
      searchQuery,
      statusFilter,
      subscriptionFilter,
      currentPage,
      usersPerPage,
      showUserModal,
      showDeleteModal,
      selectedUserId,
      modalMode,
      userToDelete,

      // Computed
      filteredUsers,
      paginatedUsers,
      totalPages,
      displayedPages,

      // Methods
      fetchUsers,
      viewUser,
      editUser,
      addNewUser,
      closeUserModal,
      confirmDeleteUser,
      deleteUser,
      suspendUser,
      activateUser,
      makeAdmin,
      revokeAdmin,
      prevPage,
      nextPage,
      handleImageError,
      API_URL,
    };
  }
};
</script>



  
  

  
