<template>
  <div class="salla-auth">
    <div class="salla-logo">
      <img :src="require('../../assets/salla.png')" alt="Salla Icon">
    </div>
    <h2>Connect with Salla</h2>
    <button @click="connectSalla" :disabled="isConnecting">
      <span v-if="isConnecting" class="loading-indicator"></span> Connect
    </button>
  </div>
</template>

<script>
import { API_URL } from '@/config';
import AuthService from '@/services/authService'; // Assuming this service correctly retrieves JWT tokens

export default {
  data() {
    return {
      isConnecting: false
    };
  },
  methods: {
    async connectSalla() {
      this.isConnecting = true;
      const token = AuthService.getToken();
      if (!token) {
        this.$router.push('/login');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/salla_auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        const responseData = await response.json();
        if (responseData.authorization_url) {
          window.location.href = responseData.authorization_url;
        } else {
          this.$store.dispatch('pushNotification', { message: 'Failed to connect to Salla. Please check your credentials and try again.', type: 'error' });
          this.$router.push('/connect');
        }
      } catch (error) {
        this.$store.dispatch('pushNotification', { message: 'Failed to connect to Salla. Please check your network and try again.', type: 'error' });
        this.$router.push('/connect');
      } finally {
        this.isConnecting = false;
      }
    },
    async handleSallaCallback(code, state) {
      const token = AuthService.getToken();
      if (!token) {
        this.$router.push('/login');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/salla_callback?code=${code}&state=${state}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (data.success) {
          await this.$store.dispatch('fetchNotifications'); // Fetch notifications from the backend

          // Fetch products after successful connection
          await this.fetchSallaProducts();
        } else {
          this.$store.dispatch('pushNotification', {
            message: 'Failed to connect to Salla. Please try again.',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Error handling Salla callback:', error);
        this.$store.dispatch('pushNotification', {
          message: 'An error occurred while handling Salla callback.',
          type: 'error'
        });
      } finally {
        this.$router.push('/connect');
      }
    },
    async fetchSallaProducts() {
      const token = AuthService.getToken();
      if (!token) {
        this.$router.push('/login');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/salla/products`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (data.success) {
          this.$store.dispatch('pushNotification', {
            message: 'Products successfully retrieved and stored.',
            type: 'success'
          });
        } else {
          this.$store.dispatch('pushNotification', {
            message: 'Failed to retrieve products. Please try again.',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Error fetching Salla products:', error);
        this.$store.dispatch('pushNotification', {
          message: 'An error occurred while fetching Salla products.',
          type: 'error'
        });
      }
    }
  },
  watch: {
    '$route.query'(newQuery) {
      const code = newQuery.code;
      const state = newQuery.state;

      if (code && state) {
        this.handleSallaCallback(code, state);
      }
    }
  },
  async mounted() {
    const code = this.$route.query.code;
    const state = this.$route.query.state;

    if (code && state) {
      await this.handleSallaCallback(code, state);
    } else {
      // Fetch notifications if there are no code and state in query parameters
      await this.$store.dispatch('fetchNotifications');
    }
  }
};
</script>


<style scoped>
.salla-auth {
  text-align: center;
}


</style>
