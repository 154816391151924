<template>
    <transition name="fade">
      <div v-if="show" class="modal-overlay">
        <div class="modal-content">
          <div class="modal-header">
            <i class="fas fa-exclamation-circle"></i>
          </div>
          <h3>{{ message }}</h3>
          <div class="modal-actions">
            <button class="cancel-btn" @click="cancel">
              <i class="fas fa-times"></i> {{ cancelText }}
            </button>
            <button class="confirm-btn" @click="confirm">
              <i class="fas fa-check"></i> {{ confirmText }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    props: {
      show: Boolean,
      message: String,
      cancelText: String,
      confirmText: String
    },
    methods: {
      cancel() {
        this.$emit('cancel');
      },
      confirm() {
        this.$emit('confirm');
      }
    }
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    animation: slideIn 0.3s ease-in-out;
    width: 300px;
    position: relative;
  }
  
  .modal-header {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    background: #ff4747;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 40px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
  
  .modal-content h3 {
    margin: 60px 0 20px;
    font-size: 20px;
    color: #333;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s, transform 0.3s;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .modal-actions .cancel-btn {
    background: #ccc;
    color: #333;
  }
  
  .modal-actions .confirm-btn {
    background: #007bff;
    color: #fff;
  }
  
  .modal-actions button:hover {
    transform: scale(1.05);
  }
  
  .modal-actions .cancel-btn:hover {
    background: #bbb;
  }
  
  .modal-actions .confirm-btn:hover {
    background: #0056b3;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-20px);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
  }
  
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  