<template>
    <div class="chart-container">
      <div class="video-container">
        <!-- Add video player component here -->
        <!-- This is a placeholder for the video player; you'll replace it with your video player component -->
        <div class="video-placeholder">Video Player</div>
      </div>
      <div class="chart-wrapper">
        <div class="chart-header">
          <div class="chart-title">Product Performance</div>
          <div class="sort-container">
            <span class="sort-by">Sort by:</span>
            <select class="choose-year" v-model="selectedYear">
              <option value="y">Year</option>
              <option value="m">Month</option>
              <option value="d">Day</option>
            </select>
          </div>
        </div>
        <canvas id="myChart"></canvas>
      </div>
    </div>
  </template>
  
  <script>
  import { Chart, registerables } from 'chart.js';
  Chart.register(...registerables);
  
  export default {
    data() {
      return {
        selectedYear: 'y', // Default year selection
      };
    },
    mounted() {
      this.renderChart();
    },
    methods: {
      renderChart() {
        const ctx = document.getElementById('myChart').getContext('2d');
        new Chart(ctx, {
          type: 'bar',
          data: {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [
              {
                label: 'Total Sell',
                data: [1200, 1900, 3000, 5000, 2000, 3000, 4500, 6000, 8000, 8500, 9000, 9500],
                backgroundColor: '#67B7DC',
                borderColor: '#67B7DC',
                borderWidth: 1,
                borderRadius: 4,
              },
              {
                label: 'Total View',
                data: [1500, 2300, 3400, 4500, 3200, 2400, 3500, 6200, 7800, 9200, 8500, 8700],
                backgroundColor: '#0284C7',
                borderColor: '#0284C7',
                borderWidth: 1,
                borderRadius: 4,
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: true,
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  callback: function(value) {
                    return value.toLocaleString();
                  }
                }
              },
              x: {
                grid: {
                  display: false,
                  drawBorder: false,
                },
              }
            },
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  usePointStyle: true,
                  boxWidth: 6,
                }
              },
              title: {
                display: false
              }
            }
          }
        });
      }
    }
  }
  </script>
  
  <style scoped>
  .chart-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    gap: 20px; /* Add space between video and chart */
  }
  
  .video-container {
    width: 50%;
    /* Add more styling for video container as needed */
    background-color: #d0e2ff; /* Temporary background color for placeholder */
    border-radius: 8px; /* Rounded corners for video container */
  }
  
  .chart-wrapper {
    width: 80%;
    background-color: #ffffff; /* White background for the chart wrapper */
    border-radius: 10px; /* Rounded corners for the chart wrapper */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow for chart wrapper */
    padding: 20px;
  }
  
  .chart-header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Align sort and title */
    margin-bottom: 1rem;
  }
  
  .chart-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .sort-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .sort-by {
    margin-right: 0.5rem;
    color: #333;
    font-size: 14px;
  }
  
  .choose-year {
    padding: 4px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  /* Temporary placeholder styles for video player */
  .video-placeholder {
    width: 100%;
    height: 200px; /* Adjust as necessary */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px; /* Placeholder text size */
    color: #fff; /* Placeholder text color */
  }
  
  @media (max-width: 768px) {
    .chart-container {
      flex-direction: column;
    }
    
    .video-container {
      width: 100%;
      margin-bottom: 20px;
    }
    
    .chart-wrapper {
      width: 100%;
    }
  }
  </style>
  