// src/firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBD2fqT_9oRyXZXXBKWe8dlnAXjOaOJubs",
  authDomain: "ebda3i-ec076.firebaseapp.com",
  projectId: "ebda3i-ec076",
  storageBucket: "ebda3i-ec076.appspot.com",
  messagingSenderId: "1036956420367",
  appId: "1:1036956420367:web:d10c1bc29578ee4b00d67a",
  measurementId: "G-4SEPYM00FC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
