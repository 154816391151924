<template>
  <div class="salla-p-6">
    <!-- Summary Header -->
    <div class="salla-mb-8">
      <h2 class="salla-text-2xl salla-font-bold salla-text-gray-800 salla-mb-2">Dashboard Overview</h2>
      <p class="salla-text-gray-600">Monitor your platform's key metrics and performance indicators</p>
    </div>

    <!-- Metrics Grid -->
    <div class="salla-grid salla-grid-cols-1 md:salla-grid-cols-2 lg:salla-grid-cols-3 xl:salla-grid-cols-4 salla-gap-6">
      <div 
        v-for="metric in metrics" 
        :key="metric.title"
        class="salla-bg-white salla-rounded-xl salla-shadow-sm hover:salla-shadow-lg salla-transition-all salla-duration-300 salla-transform hover:salla-scale-105 salla-border salla-border-gray-100 animate-fade-in"
      >
        <div class="salla-p-6">
          <!-- Card Header -->
          <div class="salla-flex salla-items-start salla-justify-between salla-mb-4">
            <div class="salla-flex-1">
              <h3 class="salla-text-sm salla-font-medium salla-text-gray-500 salla-mb-1">{{ metric.title }}</h3>
              <div class="salla-flex salla-items-baseline">
                <span class="salla-text-2xl salla-font-bold salla-text-gray-900">
                  {{ formatValue(metric.value) }}
                </span>
              </div>
            </div>
            <div :class="`salla-p-3 salla-rounded-lg ${getBackgroundColor(metric.icon)}`">
              <!-- SVG Icons -->
              <svg v-if="metric.icon" class="salla-w-6 salla-h-6 salla-text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path 
                  v-if="metric.icon === 'users'" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  stroke-width="2" 
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
                <path 
                  v-else-if="metric.icon === 'user-plus'" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  stroke-width="2" 
                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                />
                <path 
                  v-else-if="metric.icon === 'credit-card'" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  stroke-width="2" 
                  d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                />
                <path 
                  v-else-if="metric.icon === 'star'" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  stroke-width="2" 
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                />
                <!-- Add other icon paths as needed -->
                <path 
                  v-else
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  stroke-width="2" 
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                />
              </svg>
            </div>
          </div>

          <!-- Trend Indicator -->
          <div class="salla-flex salla-items-center salla-space-x-2">
            
          </div>
        </div>
      </div>
    </div>

    <!-- Additional Stats Section -->
    <div class="salla-mt-8 salla-grid salla-grid-cols-1 lg:salla-grid-cols-2 salla-gap-6">
      <!-- Revenue Overview -->
      <div class="salla-bg-white salla-rounded-xl salla-shadow-sm salla-p-6 salla-border salla-border-gray-100">
        <h3 class="salla-text-lg salla-font-semibold salla-text-gray-800 salla-mb-4">Revenue Overview</h3>
        <div class="salla-space-y-4">
          <div class="salla-flex salla-items-center salla-justify-between">
            <span class="salla-text-gray-600">Total Revenue</span>
            <span class="salla-font-semibold">{{ formatValue(getMetricValue('Total Revenue')) }}</span>
          </div>
          <div class="salla-flex salla-items-center salla-justify-between">
            <span class="salla-text-gray-600">Active Subscriptions</span>
            <span class="salla-font-semibold">{{ formatValue(getMetricValue('Total Active Subscriptions')) }}</span>
          </div>
        </div>
      </div>

      <!-- User Statistics -->
      <div class="salla-bg-white salla-rounded-xl salla-shadow-sm salla-p-6 salla-border salla-border-gray-100">
        <h3 class="salla-text-lg salla-font-semibold salla-text-gray-800 salla-mb-4">User Statistics</h3>
        <div class="salla-space-y-4">
          <div class="salla-flex salla-items-center salla-justify-between">
            <span class="salla-text-gray-600">Total Users</span>
            <span class="salla-font-semibold">{{ formatValue(getMetricValue('Total Users')) }}</span>
          </div>
          <div class="salla-flex salla-items-center salla-justify-between">
            <span class="salla-text-gray-600">New Users (7d)</span>
            <span class="salla-font-semibold">{{ formatValue(getMetricValue('New Users (Last 7 Days)')) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { API_URL } from '@/config';
import AuthService from '@/services/authService';

export default {
  name: 'DashboardHome',
  data() {
    return {
      metrics: [
        { title: 'Total Users', value: 0, endpoint: `${API_URL}/api/admin/total-users`, icon: 'users' },
        { title: 'New Users (Last 7 Days)', value: 0, endpoint: `${API_URL}/api/admin/new-users`, icon: 'user-plus' },
        { title: 'Total Credits', value: 0, endpoint: `${API_URL}/api/admin/total-credits`, icon: 'credit-card' },
        { title: 'Subscribed Users', value: 0, endpoint: `${API_URL}/api/admin/subscribed-users`, icon: 'star' },
        { title: 'Total Tickets', value: 0, endpoint: `${API_URL}/api/admin/total-tickets`, icon: 'clipboard-list' },
        { title: 'Open Tickets', value: 0, endpoint: `${API_URL}/api/admin/open-tickets`, icon: 'exclamation-circle' },
        { title: 'Total Revenue', value: 0, endpoint: `${API_URL}/api/admin/total-revenue`, icon: 'dollar-sign' },
        { title: 'WoCommerce Users', value: 0, endpoint: `${API_URL}/api/admin/woocommerce-users`, icon: 'shopping-cart' },
        { title: 'Salla Users', value: 0, endpoint: `${API_URL}/api/admin/salla-users`, icon: 'shopping-bag' },
        { title: 'Zid Users', value: 0, endpoint: `${API_URL}/api/admin/zid-users`, icon: 'shopping-basket' },
        { title: 'Total Videos', value: 0, endpoint: `${API_URL}/api/admin/total-videos`, icon: 'video' },
        { title: 'Total Products', value: 0, endpoint: `${API_URL}/api/admin/total-products`, icon: 'box' },
        { title: 'Total Active Subscriptions', value: 0, endpoint: `${API_URL}/api/admin/total-active-subscriptions`, icon: 'check-circle' },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    if (!AuthService.isLoggedIn() || !AuthService.isAdmin()) {
      next('/admin/login');
    } else {
      next();
    }
  },
  mounted() {
    this.fetchMetrics();
  },
  methods: {
    async fetchMetrics() {
      for (let metric of this.metrics) {
        try {
          const response = await fetch(metric.endpoint, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${AuthService.getToken()}`
            },
          });
          const data = await response.json();
          metric.value = data[Object.keys(data)[0]] ?? 0;
        } catch (error) {
          console.error('Error fetching metric:', metric.title, error);
        }
      }
    },
    formatValue(value) {
      if (typeof value === 'number') {
        return value.toLocaleString();
      }
      return value;
    },
    getMetricValue(title) {
      const metric = this.metrics.find(m => m.title === title);
      return metric ? metric.value : 0;
    },
    getBackgroundColor(icon) {
      const colors = {
        'users': 'salla-bg-blue-500',
        'user-plus': 'salla-bg-green-500',
        'credit-card': 'salla-bg-purple-500',
        'star': 'salla-bg-yellow-500',
        'clipboard-list': 'salla-bg-indigo-500',
        'exclamation-circle': 'salla-bg-red-500',
        'dollar-sign': 'salla-bg-emerald-500',
        'shopping-cart': 'salla-bg-orange-500',
        'shopping-bag': 'salla-bg-pink-500',
        'shopping-basket': 'salla-bg-cyan-500',
        'video': 'salla-bg-teal-500',
        'box': 'salla-bg-violet-500',
        'check-circle': 'salla-bg-lime-500',
      };
      return colors[icon] || 'salla-bg-gray-500';
    },
  },
};
</script>

<style>
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fade-in 0.5s ease-out forwards;
}
</style>