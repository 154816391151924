<template>
    <div class="woocommerce-auth">
      <div class="woocommerce-logo">
        <img :src="require('../../assets/woo.png')" alt="WooCommerce Icon">
      </div>
      <h2>Connect with WooCommerce</h2>
      <form @submit.prevent="connectWooCommerce">
        <div class="form-group">
          <label for="consumer_key">Consumer Key:</label>
          <input type="text" id="consumer_key" v-model="consumerKey" required>
        </div>
        <div class="form-group">
          <label for="consumer_secret">Consumer Secret:</label>
          <input type="text" id="consumer_secret" v-model="consumerSecret" required>
        </div>
        <div class="form-group">
          <label for="store_url">Store URL:</label>
          <input type="text" id="store_url" v-model="storeUrl" required>
        </div>
        <button type="submit" :disabled="isConnecting">
          <span v-if="isConnecting" class="loading-indicator"></span>
          Connect
        </button>
      </form>
    </div>
  </template> 


<script>
import { API_URL } from '@/config';
import AuthService from '@/services/authService';

export default {
  data() {
    return {
      consumerKey: '',
      consumerSecret: '',
      storeUrl: '',
      isConnecting: false
    };
  },
  methods: {
    async connectWooCommerce() {
      console.log("Attempting to connect with WooCommerce..."); // Log at start of method
      const token = AuthService.getToken();
      if (!token) {
        console.error('No token available, redirecting to login');
        this.$router.push('/login');
        return;
      }

      this.isConnecting = true;
      console.log("isConnecting set to true"); // Log when isConnecting is set to true

      try {
        console.log("Sending request to server..."); // Log before sending request
        const response = await fetch(`${API_URL}/woocommerce_auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            consumer_key: this.consumerKey,
            consumer_secret: this.consumerSecret,
            store_url: this.storeUrl
          })
        });
        const responseData = await response.json();
        console.log("Response received:", responseData); // Log server response

        if (responseData.success) {
          console.log("Authentication successful, updating connection status and redirecting...");
          await this.$store.dispatch('updateConnectionStatus', true);
          await this.$router.push('/connect');
        } else {
          console.error('Authentication failed:', responseData.message);
          await this.$store.dispatch('pushNotification', {
            message: 'Please add products in WooCommerce then try to connect again.',
            type: 'warning'
          });
          await this.$router.push('/connect');
        }
      } catch (error) {
        console.error('Connection error:', error);
        await this.$store.dispatch('pushNotification', {
          message: 'Failed to connect to WooCommerce. Please check your network and try again.',
          type: 'error'
        });
        await this.$router.push('/connect');
      } finally {
        this.isConnecting = false; // Ensure loading state is reset
        console.log("isConnecting set to false"); // Log when isConnecting is reset
      }
    }
  }
};
</script>


  
  
  
  
  
  
  <style scoped>

.loading-indicator {
  position: absolute;
  top: 50%;
  right: 12px; /* Adjusted to not overlap the text */
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-top: 2px solid #96588a;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}

button {
  position: relative;
  padding: 10px 50px; /* Increased padding for a larger button */
  background-color: #96588a;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 18px; /* Increased font size for better visibility */
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  overflow: visible; /* Ensure the indicator is not clipped */
}

button:hover {
  background-color: #804977;
}

button:focus {
  outline: none;
}

button:disabled {
  background-color: #b3a1b9; /* Different color to indicate disabled state */
}

  .woocommerce-auth {
    max-width: 400px;
    margin: 0 auto;
    padding: 40px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 15px;
  }
  
  .woocommerce-logo {
    margin-bottom: 30px;
  }
  
  .woocommerce-logo img {
    width: 120px;
    height: auto;
  }
  
  h2 {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 30px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  label {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    margin-bottom: 5px;
    display: block;
  }
  
  input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
    transition: border-color 0.3s ease;
  }
  
  input:focus {
    outline: none;
    border-color: #96588a;
  }
  

  </style>