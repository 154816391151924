<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="settings-container">
    <div class="settings-header">
      <h1 class="settings-title">الإعدادات</h1>
      <div class="settings-tabs">
        <button class="tab-button" :class="{ active: activeTab === 'account' }" @click="setActiveTab('account')">الحساب</button>
        <button class="tab-button" :class="{ active: activeTab === 'payment' }" @click="setActiveTab('payment')">المدفوعات</button>
      </div>
    </div>
    <div class="settings-content">
      <div v-if="activeTab === 'account'" class="account-settings">
        <div class="profile-section">
          <div class="profile-image-container">
            <img :src="profileImageUrl || require('@/assets/user-avatar.png')" alt="صورة المستخدم" class="profile-image" />
            <div class="profile-image-overlay">
              <label for="profileImageInput" class="profile-image-label">
                <i class="edit-icon fas fa-pencil-alt"></i>
                تغيير الصورة
              </label>
              <input type="file" id="profileImageInput" class="profile-image-input" @change="onFileChange" accept="image/*" />
            </div>
          </div>
          <button class="upload-button" @click="uploadImage" :disabled="!selectedFile || isUploading">
            {{ isUploading ? 'جاري الرفع' : 'رفع الصورة' }}
          </button>
          <div class="profile-details">
            <h2 class="profile-name">{{ fullName }}</h2>
            <p class="profile-email">{{ email }}</p>
            <span v-if="isVerified" class="verified-badge">تم التحقق</span>
            <span v-else class="not-verified-badge">لم يتم التحقق</span>
          </div>
        </div>

        <div class="account-form">
          <div class="form-group">
            <label for="fullName" class="form-label">الاسم الكامل</label>
            <input type="text" id="fullName" class="form-input" v-model="fullName" />
          </div>
          <div class="form-group">
            <label for="email" class="form-label">البريد الإلكتروني</label>
            <input type="email" id="email" class="form-input" v-model="email" readonly />
          </div>
          <div class="form-group">
            <label for="phone" class="form-label">رقم الهاتف</label>
            <input type="tel" id="phone" class="form-input" v-model="phone" />
          </div>
          <div class="form-group">
            <label for="address" class="form-label">العنوان</label>
            <textarea id="address" class="form-textarea" v-model="address"></textarea>
          </div>
          <button class="save-button" @click="updateUserInfo">{{ isSaving ? 'جاري الحفظ' : 'حفظ التغييرات' }}</button>
        </div>

        <div class="password-section">
          <h3 class="section-title">تغيير كلمة المرور</h3>
          <button class="change-password-button" @click="showChangePasswordPopup = true">تغيير كلمة المرور</button>
        </div>

        <div class="delete-account-section">
          <h3 class="section-title">حذف الحساب</h3>
          <button class="delete-account-button" @click="goToDeleteAccount">حذف الحساب</button>
        </div>
      </div>

      <div v-else-if="activeTab === 'payment'" class="payment-settings">
        <div class="payment-methods-section">
          <h2 class="section-title">طرق الدفع</h2>
          <div class="payment-methods-list">
            <div v-if="lastPayment && lastPayment.card_last4" class="payment-method">
              <div class="payment-method-logo">
                <img :src="require('@/assets/payments.png')" alt="شعار طريقة الدفع" class="payment-logo-image" />
              </div>
              <div class="payment-method-details">
                <p class="payment-method-number">{{ lastPayment.card_brand ? `${lastPayment.card_brand} تنتهي بـ ${lastPayment.card_last4}` : `بطاقة تنتهي بـ ${lastPayment.card_last4}` }}</p>
                <p class="payment-method-expiry">آخر دفعة: {{ new Date(lastPayment.created).toLocaleDateString() }}</p>
                <p class="payment-method-amount">المبلغ: {{ lastPayment.amount / 100 }} {{ lastPayment.currency.toUpperCase() }}</p>
              </div>
            </div>
            <div v-else>
              <p class="no-payment-info">لا توجد معلومات دفع</p>
            </div>
          </div>
        </div>

        <div class="subscription-section">
          <h2 class="section-title">اشتراكك</h2>
          <div v-if="subscription && subscription.plan_name" class="subscription-details">
            <p class="subscription-plan">الخطة: {{ subscription.plan_name }}</p>
            <p class="subscription-status">الحالة: {{ subscription.status }}</p>
            <p class="subscription-expiry">تجدد في: {{ new Date(subscription.credits_expires_at).toLocaleDateString() }}</p>
            <button class="cancel-subscription-button" @click="cancelSubscription">إلغاء الاشتراك</button>
          </div>
          <div v-else>
            <p class="no-subscription">غير مشترك</p>
          </div>
        </div>

        <div class="payment-credits-section">
          <h2 class="section-title">الرصيد الحالي</h2>
          <div class="payment-credits">
            <span class="payment-credits-amount">{{ userCredits }}</span>
            <span class="payment-credits-label">نقاط</span>
          </div>
        </div>
      </div>

     
    </div>

    <div v-if="showChangePasswordPopup" class="change-password-popup">
      <div class="popup-overlay" @click="showChangePasswordPopup = false"></div>
      <div class="popup-content">
        <h2 class="popup-title">تغيير كلمة المرور</h2>
        <form @submit.prevent="changePassword" class="change-password-form">
          <div class="form-group">
            <label for="currentPassword" class="form-label">كلمة المرور الحالية</label>
            <input type="password" id="currentPassword" class="form-input" v-model="currentPassword" required />
          </div>
          <div class="form-group">
            <label for="newPassword" class="form-label">كلمة المرور الجديدة</label>
            <input type="password" id="newPassword" class="form-input" v-model="newPassword" required />
          </div>
          <div class="form-group">
            <label for="confirmNewPassword" class="form-label">تأكيد كلمة المرور الجديدة</label>
            <input type="password" id="confirmNewPassword" class="form-input" v-model="confirmNewPassword" required />
          </div>
          <button class="submit-button" type="submit">تغيير كلمة المرور</button>
        </form>
        <button class="cancel-button" @click="showChangePasswordPopup = false">إلغاء</button>
        <p v-if="changePasswordMessage" class="change-password-message">{{ changePasswordMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>

import { API_URL } from '@/config';
import AuthService from '@/services/authService';

export default {
  data() {
    return {
      activeTab: 'account',
      profileImageUrl: '',
      selectedFile: null,
      fullName: '',
      email: '',
      phone: '',
      address: '',
      isVerified: false,
      userCredits: 0,
      subscription: null,
      lastPayment: {
        card_brand: '',
        card_last4: '',
        amount: 0,
        currency: '',
        created: 0
      },
      showChangePasswordPopup: false,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      changePasswordMessage: '',
      isUploading: false,  // New state for uploading
      isSaving: false      // New state for saving
    };
  },
  mounted() {
    const storedTab = localStorage.getItem('activeTab');
    if (storedTab) {
      this.activeTab = storedTab;
    }
    this.fetchUserInfo();
    this.fetchUserCredits();
    this.fetchUserSubscription();
    this.fetchLastPaymentInfo();
    this.checkVerificationStatus();
  },
  computed: {
    activeIndicatorStyle() {
      return {
        width: this.activeTab === 'account' ? '75px' : '85px',
        transform: this.activeTab === 'account' ? 'translateX(0)' : 'translateX(75px)',
      };
    },
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
      localStorage.setItem('activeTab', tab);
    },
    onFileChange(event) {
      this.selectedFile = event.target.files[0];
      if (this.selectedFile) {
        this.previewImage();
      }
    },
    previewImage() {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.profileImageUrl = e.target.result;
      };
      reader.readAsDataURL(this.selectedFile);
    },
    async checkVerificationStatus() {
      const token = AuthService.getToken();
      if (token) {
        try {
          const response = await fetch(`${API_URL}/is-verified`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (response.ok) {
            const data = await response.json();
            this.isVerified = data.isVerified;
          }
        } catch (error) {
          console.error('Error checking verification status:', error);
        }
      }
    },
    async uploadImage() {
      if (!this.selectedFile) {
        return;
      }
      this.isUploading = true;  // Set uploading state to true
      const formData = new FormData();
      formData.append('image', this.selectedFile);
      const token = AuthService.getToken();
      if (!token) {
        this.$router.push('/login');
        return;
      }
      try {
        const response = await fetch(`${API_URL}/upload_profile_image`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData
        });
        this.isUploading = false;  // Reset uploading state
        if (response.ok) {
          this.selectedFile = null;
          await this.fetchUserInfo();
          this.$store.dispatch('pushNotification', {
            message: 'Profile picture updated successfully.',
            type: 'success'
          });
        } else {
          const result = await response.json();
          this.$store.dispatch('pushNotification', {
            message: result.message || 'Failed to update profile picture. Please try again.',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        this.isUploading = false;  // Reset uploading state in case of error
        this.$store.dispatch('pushNotification', {
          message: 'An error occurred while updating the profile picture.',
          type: 'error'
        });
      }
    },
    async fetchUserInfo() {
      const token = AuthService.getToken();
      if (!token) {
        this.$router.push('/login');
        return;
      }
      try {
        const response = await fetch(`${API_URL}/get_user_info`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (response.ok) {
          this.profileImageUrl = data.profile_image_url;
          this.fullName = data.username;
          this.email = data.email;
          this.phone = data.phone || '';
          this.address = data.address || '';
        } else {
          throw new Error("Failed to fetch user data");
        }
      } catch (error) {
        console.error('Failed to fetch user info:', error);
        AuthService.clearUserData();
        this.$router.push('/login');
      }
    },
    async updateUserInfo() {
  this.isSaving = true;  // Set saving state to true
  const token = AuthService.getToken();
  if (!token) {
    this.$router.push('/login');
    return;
  }
  try {
    const response = await fetch(`${API_URL}/update_user_info`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: this.fullName,
        email: this.email,
        phone: this.phone,
        address: this.address,
      }),
    });

    // Ensure the "Saving..." message is visible for at least 1 second
    setTimeout(() => {
      this.isSaving = false;  // Reset saving state
    }, 1000);

    if (response.ok) {
      this.$store.dispatch('pushNotification', {
        message: 'User information updated successfully.',
        type: 'success',
      });
    } else {
      throw new Error("Failed to update user information");
    }
  } catch (error) {
    console.error('Failed to update user info:', error);
    this.isSaving = false;  // Reset saving state in case of error
    this.$store.dispatch('pushNotification', {
      message: 'An error occurred while updating user information.',
      type: 'error',
    });
  }
},
    async fetchUserCredits() {
      const token = AuthService.getToken();
      if (!token) {
        this.$router.push('/login');
        return;
      }
      try {
        const response = await fetch(`${API_URL}/get_user_credits`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (response.ok) {
          this.userCredits = data.credits;
        } else {
          throw new Error("Failed to fetch user credits");
        }
      } catch (error) {
        console.error('Failed to fetch user credits:', error);
        AuthService.clearUserData();
        this.$router.push('/login');
      }
    },
    async fetchUserSubscription() {
    const token = AuthService.getToken();
    if (!token) {
      this.$router.push('/login');
      return;
    }
    try {
      const response = await fetch(`${API_URL}/user/subscription`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.ok) {
        if (data.message === "No subscription found") {
          this.subscription = null;
        } else {
          this.subscription = data;
        }
      } else {
        throw new Error("Failed to fetch user subscription");
      }
    } catch (error) {
      console.error('Failed to fetch user subscription:', error);
      AuthService.clearUserData();
      this.$router.push('/login');
    }
  },
    async fetchLastPaymentInfo() {
      const token = AuthService.getToken();
      if (!token) {
        this.$router.push('/login');
        return;
      }
      try {
        const response = await fetch(`${API_URL}/user/last_payment`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (response.ok) {
          this.lastPayment = data;
        } else {
          throw new Error("Failed to fetch last payment information");
        }
      } catch (error) {
        console.error('Failed to fetch last payment information:', error);
        AuthService.clearUserData();
        this.$router.push('/login');
      }
    },
    async cancelSubscription() {
      const token = AuthService.getToken();
      if (!token) {
        this.$router.push('/login');
        return;
      }
      try {
        const response = await fetch(`${API_URL}/user/cancel_subscription`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          this.$store.dispatch('pushNotification', {
            message: 'Subscription cancelled successfully.',
            type: 'success',
          });
          this.subscription = null;
          this.fetchUserCredits();
        } else {
          throw new Error("Failed to cancel subscription");
        }
      } catch (error) {
        console.error('Failed to cancel subscription:', error);
        this.$store.dispatch('pushNotification', {
          message: 'An error occurred while cancelling the subscription.',
          type: 'error',
        });
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
      this.selectedFile = null;
    },
    async changePassword() {
      const token = AuthService.getToken();
      if (!token) {
        this.$router.push('/login');
        return;
      }
      if (this.newPassword !== this.confirmNewPassword) {
        this.changePasswordMessage = 'كلمات المرور الجديدة غير متطابقة';
        return;
      }
      try {
        const response = await fetch(`${API_URL}/change_password`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            currentPassword: this.currentPassword,
            newPassword: this.newPassword,
          }),
        });
        if (response.ok) {
          this.changePasswordMessage = 'تم تغيير كلمة المرور بنجاح';
          setTimeout(() => {
            this.showChangePasswordPopup = false;
            this.changePasswordMessage = '';
          }, 1000);
        } else {
          const data = await response.json();
          this.changePasswordMessage = data.message || 'فشل في تغيير كلمة المرور';
        }
      } catch (error) {
        console.error('Failed to change password:', error);
        this.changePasswordMessage = 'حدث خطأ أثناء تغيير كلمة المرور';
      }
    }
  }
};
</script>

  
  









<style scoped>
.referrals-settings {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.referral-url-section {
  margin-bottom: 40px;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.referral-url {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.referral-url input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  color: #555;
}

.copy-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: #0056b3;
}

.generate-referral-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.generate-referral-button:hover {
  background-color: #1e7e34;
}

.referred-users-section {
  margin-top: 40px;
}

.referred-users-table {
  width: 100%;
  border-collapse: collapse;
}

.referred-users-table th,
.referred-users-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.referred-users-table th {
  background-color: #f8f9fa;
  font-weight: bold;
  color: #333;
}

.referred-users-table tbody tr:nth-child(even) {
  background-color: #f8f9fa;
}

.referred-users-table tbody tr:hover {
  background-color: #e9ecef;
}





.settings-container {
  margin: 0 auto;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    width: 133%;
    max-width: 200%;
    margin-top: 15px;
    margin-left: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

[dir='rtl'] .settings-container {
  margin: 0 auto;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 10px;
    width: 133%;
    max-width: 200%;
    margin-top: 15px;
    margin-right: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}




.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.settings-title {
  font-size: 32px;
  font-weight: bold;
  color: #333333;
}

.settings-tabs {
  display: flex;
  gap: 20px;
}

.tab-button {
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  color: #666666;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: color 0.3s, border-color 0.3s;
}

.tab-button.active {
  color: #0098fd;
  border-color: #0098fd;
}

.account-settings,
.payment-settings {
  padding: 30px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.profile-section {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 40px;
}

.profile-image-container {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.upload-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #0098fd;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 10px;
}

.upload-button:hover {
  background-color: #0076d1;
  transform: translateY(-2px);
}

.upload-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  transform: none;
}

.profile-image-container {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s;
}

.profile-image-container:hover .profile-image-overlay {
  opacity: 1;
}

.profile-image-label {
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}

.profile-image-input {
  display: none;
}

.profile-details {
  display: flex;
  flex-direction: column;
}

.profile-name {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 5px;
}

.profile-email {
  font-size: 16px;
  color: #666666;
  margin-bottom: 10px;
}

.verified-badge,
.not-verified-badge {
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
}

.verified-badge {
  background-color: #00cc66;
  color: #ffffff;
}

.not-verified-badge {
  background-color: #ff6666;
  color: #ffffff;
}

.account-form {
  margin-bottom: 40px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 5px;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.form-textarea {
  resize: vertical;
}

.save-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #0098fd;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.save-button:hover {
  background-color: #0076d1;
}

.password-section,
.delete-account-section {
  margin-bottom: 40px;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}

.change-password-button,
.delete-account-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #ff6666;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.change-password-button:hover,
.delete-account-button:hover {
  background-color: #ff4444;
}

.delete-account-description {
  font-size: 16px;
  color: #666666;
  margin-bottom: 10px;
}

.payment-methods-section,
.subscription-section,
.payment-credits-section {
  margin-bottom: 40px;
}

.payment-methods-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.payment-method {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.payment-logo-image {
  width: 60px;
  height: 60px;
}

.payment-method-details {
  display: flex;
  flex-direction: column;
}

.payment-method-number,
.payment-method-expiry,
.payment-method-amount {
  font-size: 16px;
  color: #333333;
  margin-bottom: 5px;
}

.no-payment-info,
.no-subscription {
  font-size: 16px;
  color: #666666;
}

.subscription-details {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.subscription-plan,
.subscription-status,
.subscription-expiry {
  font-size: 16px;
  color: #a5a5a5;
  margin-bottom: 5px;
}


.cancel-subscription-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: #ff6666;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.cancel-subscription-button:hover {
  background-color: #ff4444;
}

.payment-credits {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.payment-credits-amount {
font-size: 32px;
font-weight: bold;
color: #0098fd;
}
.payment-credits-label {
font-size: 18px;
color: #333333;
}
.change-password-popup {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(0, 0, 0, 0.5);
z-index: 999;
}
.popup-overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
cursor: pointer;
}
.popup-content {
  position: relative;
    max-width: 100%;
    padding: 30px;
    width: 50%;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.popup-title {
font-size: 24px;
font-weight: bold;
color: #333333;
margin-bottom: 20px;
}
.change-password-form {
margin-bottom: 20px;
}
.change-password-message {
font-size: 16px;
color: #666666;
margin-top: 10px;
}
.submit-button {
padding: 10px 20px;
font-size: 16px;
font-weight: bold;
color: #ffffff;
background-color: #0098fd;
border: none;
border-radius: 5px;
cursor: pointer;
transition: background-color 0.3s;
}
.submit-button:hover {
background-color: #0076d1;
}
.cancel-button {
padding: 10px 20px;
font-size: 16px;
font-weight: bold;
color: #666666;
background-color: transparent;
border: none;
border-radius: 5px;
cursor: pointer;
transition: color 0.3s;
}
.cancel-button:hover {
color: #333333;
}
@media screen and (max-width: 768px) {
.settings-container {
padding: 20px;
}
.settings-header {
flex-direction: column;
align-items: flex-start;
gap: 20px;
}
.profile-section {
flex-direction: column;
align-items: flex-start;
}
.profile-image-container {
width: 120px;
height: 120px;
}
.payment-methods-list {
grid-template-columns: 1fr;
}
.popup-content {
max-width: 90%;
}
}
</style>
