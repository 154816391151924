<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div dir="rtl" class="salla-flex salla-flex-col salla-min-h-screen salla-bg-white">
    <!-- Header -->
    <header class="salla-sticky salla-top-0 salla-z-50 salla-bg-white salla-shadow-sm">
      <div class="salla-container salla-mx-auto salla-px-4 salla-py-4">
        <div class="salla-flex salla-items-center salla-justify-between">
          <!-- Logo Section -->
          <div class="salla-flex salla-items-center salla-gap-2">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/076ad4d43ff739e5b7165868b496c5cce1c42209ae846a2e33f5034beb9bd8ef?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
                 alt="Logo" 
                 class="salla-h-8 salla-w-auto" />
          </div>

          <!-- Action Button -->
          <div class="salla-flex salla-items-center">
            <router-link 
              to="/dashboard" 
              class="salla-inline-flex salla-items-center salla-gap-2 salla-bg-primary-600 salla-text-white salla-px-6 salla-py-2.5 salla-rounded-full hover:salla-bg-primary-700 salla-transition-all salla-duration-200 salla-ease-in-out salla-shadow-md hover:salla-shadow-lg salla-transform hover:salla-scale-105 salla-font-medium"
            >
              <span>لنبدأ</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="salla-h-5 salla-w-5 salla-transform salla-rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </header>

    <!-- Main Content -->
    <main class="salla-flex-1">
      <!-- Hero Section -->
      <section class="salla-relative salla-py-20 salla-overflow-hidden salla-bg-gray-50">
        <div class="salla-relative salla-container salla-mx-auto salla-px-4">
          <h1 class="salla-text-5xl salla-font-bold salla-text-center salla-text-gray-900">
            تواصل معنا
          </h1>
        </div>
      </section>

      <!-- Contact Content -->
      <section class="salla-container salla-mx-auto salla-px-4 salla-py-16">
        <div class="salla-max-w-3xl salla-mx-auto salla-text-center">
          <!-- Contact Icon -->
          <img 
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/066183b5f4807e92d98b1014f09184ed3a9ff9877e479b08e79b2cf113bc8292?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
            alt="Contact Icon" 
            class="salla-w-20 salla-h-20 salla-mx-auto salla-mb-8" 
          />

          <h2 class="salla-text-4xl salla-font-medium salla-text-gray-900 salla-mb-6">
            تواصل معنا في "إبداعي"
          </h2>

          <p class="salla-text-lg salla-text-gray-700 salla-leading-relaxed salla-mb-12">
            نحن هنا للإجابة على جميع استفساراتك وتقديم الدعم الذي تحتاجه لإنشاء فيديوهات ترويجية تخطف الأنظار. سواء كان لديك سؤال، تحتاج إلى مساعدة، أو ترغب في مشاركتنا أفكارك، فريقنا مستعد للتواصل معك.
          </p>

          <!-- Contact Info -->
          <div class="salla-space-y-6 salla-mb-12">
            <a href="mailto:info@filmatex.com" class="salla-text-xl salla-text-primary-600 hover:salla-text-primary-700 salla-transition-colors">
              info@filmatex.com
            </a>
          </div>

          <!-- Social Links -->
          <div class="salla-flex salla-justify-center salla-gap-8 salla-mt-12">
            <a href="https://instagram.com/ebda.3i" 
               target="_blank" 
               rel="noopener noreferrer" 
               class="salla-text-gray-500 hover:salla-text-primary-600 salla-transition-colors salla-transform hover:salla-scale-110">
              <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/6ce79af3be0aa30af43388fd405707422798173d243616bfc102e069943f9b47?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
                   alt="Instagram" 
                   class="salla-w-8 salla-h-8" />
            </a>
            <a href="https://www.tiktok.com/@ebda3i" 
               target="_blank" 
               rel="noopener noreferrer" 
               class="salla-text-gray-500 hover:salla-text-primary-600 salla-transition-colors salla-transform hover:salla-scale-110">
              <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/17407035faa13b157f834c3ecafd6b2709a62c0d0711e8373e23ca0598521262?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
                   alt="TikTok" 
                   class="salla-w-8 salla-h-8" />
            </a>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>