<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div dir="rtl" class="salla-flex salla-flex-col salla-min-h-screen salla-bg-white">
    <!-- Header -->
    <header class="salla-sticky salla-top-0 salla-z-50 salla-bg-white salla-shadow-sm">
      <div class="salla-container salla-mx-auto salla-px-4 salla-py-4">
        <div class="salla-flex salla-items-center salla-justify-between">
          <!-- Logo Section -->
          <div class="salla-flex salla-items-center salla-gap-2">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/076ad4d43ff739e5b7165868b496c5cce1c42209ae846a2e33f5034beb9bd8ef?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
                 alt="Logo" 
                 class="salla-h-8 salla-w-auto" />
          </div>

          <!-- Action Button -->
          <div class="salla-flex salla-items-center">
            <router-link 
              to="/dashboard" 
              class="salla-inline-flex salla-items-center salla-gap-2 salla-bg-primary-600 salla-text-white salla-px-6 salla-py-2.5 salla-rounded-full hover:salla-bg-primary-700 salla-transition-all salla-duration-200 salla-ease-in-out salla-shadow-md hover:salla-shadow-lg salla-transform hover:salla-scale-105 salla-font-medium"
            >
              <span>لنبدأ</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="salla-h-5 salla-w-5 salla-transform salla-rotate-180" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
              </svg>
            </router-link>
          </div>
        </div>
      </div>
    </header>

    <!-- Main Content -->
    <main class="salla-flex-1">
      <!-- Hero Section -->
      <section class="salla-relative salla-py-20 salla-overflow-hidden">
        <img 
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/65397bb1a72f67ed9f8951abf5b1cb80be9bdec5302e67fb542b36e91ec80103?apiKey=d4987b57c1b8435c84b9c73e2e94bde3&" 
          alt="Hero Background" 
          class="salla-absolute salla-inset-0 salla-w-full salla-h-full salla-object-cover" />
        <div class="salla-relative salla-container salla-mx-auto salla-px-4">
          <h1 class="salla-text-5xl salla-font-bold salla-text-center salla-text-gray-900">
            خطط التسعير في "إبداعي"
          </h1>
        </div>
      </section>

      <!-- Description Section -->
      <section class="salla-container salla-mx-auto salla-px-4 salla-py-16">
        <p class="salla-max-w-2xl salla-mx-auto salla-text-lg salla-text-center salla-text-gray-700 salla-leading-relaxed">
          نقدم مجموعة متنوعة من خطط التسعير لتلائم كل احتياجات وميزانيات الأعمال. كل خطة توفر مجموعة من الميزات التي تساعدك على إنشاء ونشر فيديوهات ترويجية تحقق أقصى تأثير.
        </p>
      </section>

      <!-- Can add pricing cards section here if needed -->
    </main>
  </div>
</template>