<!-- eslint-disable vue/no-parsing-error -->
<template>
  <main class="main-content">
    <section class="main-panel">
      <header class="main-panel-header">
        <h1 class="main-panel-title">اختر المنتج</h1>
        <p class="main-panel-description">اختر الشكل، المنتج والتصميم وشاهد عمل الذكاء الاصطناعي</p>
        <div class="main-panel-actions">
          <div class="main-panel-action dropdown-container" @click.stop="toggleProductDropdown">
            <button class="main-panel-action-content">
              <div class="main-panel-action-text">{{ selectedProduct ? products.find(p => p.id === selectedProduct).name : 'اختر المنتج' }}</div>
              <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/299e1bd1919158dc2d97cfa7289aa474e241882179c05d863425da7f7645da37" alt="Choose Product Icon" class="main-panel-action-icon" />
            </button>
            <div v-show="isProductDropdownOpen" ref="productDropdown" class="dropdown-menu product-dropdown">
              <div class="dropdown-search-bar-with-icon">
                <input type="text" v-model="productSearchQuery" @input="searchProducts" @click.stop placeholder="ابحث عن المنتجات" class="dropdown-search-bar">
                <i class="fa fa-search dropdown-search-bar-icon"></i>
              </div>
              <p class="suggested-label">مقترح</p>
              <div v-if="filteredProducts.length === 0" class="dropdown-item">No products found</div>
              <div v-for="product in filteredProducts" :key="product.id" class="dropdown-item" @click="selectProduct(product.id)">
                <img v-if="product.image_url" :src="product.image_url" alt="Product Image" class="product-image" />
                <div v-else class="no-image-placeholder">No Image</div>
                <span class="product-name">{{ product.name }}</span>
</div>
            </div>
          </div>
          <div class="main-panel-action dropdown-container" @click.stop="toggleDesignDropdown">
  <button class="main-panel-action-content">
    <div class="main-panel-action-text">{{ selectedDesign ? designs.find(d => d.id === selectedDesign).name : 'اختر تصميمًا' }}</div>
    <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/118339bd7d03c30019135bb6ae7f16540854e53f55ba05db834569e912b9c393" alt="Select Design Icon" class="main-panel-action-icon" />
  </button>
  <div v-show="isDesignDropdownOpen" ref="designDropdown" class="dropdown-menu design-dropdown">
    <div v-for="design in designs" :key="design.id" class="dropdown-item" @click="selectDesign(design.id)">
      <div class="dropdown-item-content">
        <video v-if="design.preview_url" :src="design.preview_url" class="dropdown-video-preview" muted></video>
        <span>{{ design.name }}</span>
      </div>
    </div>
  </div>
</div>
        </div>
      </header>
      <section class="video-grid">
        <div class="video-item" v-for="video in videos" :key="video.id">
          <div class="video-wrapper">
            <video ref="plyrVideo" :data-video-id="video.id" controls muted>
              <source :src="video.video_url" type="video/mp4">
              المتصفح لا يدعم تشغيل الفيديو.
            </video>
            <div class="video-actions">
  <button class="favorite-btn" @click="toggleFavorite(video.id)">
    <img :src="require('@/assets/icons/heart.png')" alt="Favorite">
  </button>
  <button class="edit-btn" @click="editVideo(video.id)">
    <img :src="require('@/assets/icons/edit.png')" alt="Edit">
  </button>
  <button class="delete-btn" @click="deleteVideo(video.id)">
    <img :src="require('@/assets/icons/trash-can.png')" alt="Delete">
  </button>
  <!-- New download button -->
  <button class="download-btn" @click="downloadVideo(video.video_url)">
    <img :src="require('@/assets/icons/download.png')" alt="Download">
  </button>
</div>
          </div>
        </div>
      </section>
    </section>
  


  <div class="modal" v-if="editVideoId">
    <div class="modal-overlay" ></div>
    <div class="modal-content">
     <!-- In your MainContent template where EditMain is used -->
     <EditMain :video-id="editVideoId" @videoIdUpdated="handleVideoIdUpdate" @close="closeEditPopup" />

    </div>
  </div>
</main>
</template>












<script>
import { API_URL } from '@/config';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import EditMain from './EditMain.vue';
import { useStore } from 'vuex';

export const fetchProducts = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token available. User must be logged in to fetch products.');
    return Promise.reject('No token available.');
  }
  const requestUrl = `${API_URL}/get_products`;
  try {
    const response = await fetch(requestUrl, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log("Fetched products:", data.products);
    return data.products;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
}

export const fetchDesigns = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token available. User must be logged in to fetch designs.');
    return Promise.reject('No token available.');
  }
  const requestUrl = `${API_URL}/api/design-templates`;
  try {
    const response = await fetch(requestUrl, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log("Fetched designs:", data);
    return data;
  } catch (error) {
    console.error('Error fetching designs:', error);
    throw error;
  }
}

export default {
  components: {
    EditMain,
  },
  name: 'MainContent',
  data() {
    return {
      videos: [], // Array to store video data
      players: [],
      products: [], // Array to store the list of products
      designs: [], // Array to store the list of designs
      selectedProduct: null, // Selected product
      productSearchQuery: '',
      filteredProducts: [], // Array for filtered products
      selectedDesign: null, // Selected design
      isProductDropdownOpen: false, // Flag to control the visibility of the product dropdown
      isDesignDropdownOpen: false, // Flag to control the visibility of the design dropdown
      editVideoId: null, // Video ID being edited
      requiredCredits: 0, // Required credits for rendering or downloading the video
    };
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  methods: {
    async checkCredits() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to download videos.');
        return 0;
      }

      try {
        const response = await fetch(`${API_URL}/api/check-credits`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data.credits;
      } catch (error) {
        console.error("Failed to check credits:", error);
        return 0;
      }
    },

    async fetchRequiredCredits() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to view required credits.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/api/required-credits`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.length > 0) {
          this.requiredCredits = data[0].requested_credits;
        } else {
          this.requiredCredits = 0;
        }
      } catch (error) {
        console.error("Failed to fetch required credits:", error);
        this.requiredCredits = 0;
      }
    },
    toggleFavorite(videoId) {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token available. User must be logged in to mark a video as favorite.');
    return;
  }

  const requestUrl = `${API_URL}/api/videos/${videoId}/favorite`;
  fetch(requestUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      console.log("Video marked as favorite successfully:", data);
      // Update the UI or perform any other necessary actions
    })
    .catch(error => {
      console.error('Error marking video as favorite:', error);
    });
},
    async downloadVideo(url) {
      const credits = await this.checkCredits();

      if (credits < this.requiredCredits) {
        this.$store.dispatch('pushNotification', {
          message: 'You do not have enough credits to download this video. Please purchase more credits.',
          type: 'error'
        });
        return;
      }

      // Create a temporary anchor element
      const a = document.createElement('a');
      a.style.display = 'none'; // Hide the anchor element
      a.href = url;

      // Set the download attribute to a default filename or extract from URL
      a.download = url.split('/').pop() || 'downloaded_video.mp4';

      // Append the anchor to the body
      document.body.appendChild(a);

      // Trigger the download
      a.click();

      // Remove the anchor from the body after download
      document.body.removeChild(a);

      // Dispatch a success notification
      this.$store.dispatch('pushNotification', {
        message: 'Your video has been downloaded successfully.',
        type: 'success'
      });
    },

    deleteVideo(videoId) {
      fetch(`${API_URL}/api/videos/${videoId}/delete`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`Failed to delete video: ${response.statusText}`);
          }
          return response.json();
        })
        .then(() => {
          this.videos = this.videos.filter(video => video.id !== videoId);
          alert('Video deleted successfully');
        })
        .catch(error => {
          console.error('Error deleting video:', error);
          alert('Error deleting video');
        });
    },

    handleVideoIdUpdate(newVideoId) {
      this.editVideoId = newVideoId;
    },

    closeEditPopup() {
      this.editVideoId = null;
      this.selectedProduct = null;
      this.selectedDesign = null;
    },

    fetchVideos() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to fetch videos.');
        return;
      }
      const requestUrl = `${API_URL}/get_videos`;
      fetch(requestUrl, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log("Videos fetched successfully:", data);
          this.videos = data;
          this.videos.forEach(video => {
            console.log("Video URL:", video.video_url);
          });
          this.$nextTick(this.initializeVideoPlayers);
        })
        .catch(error => {
          console.error('Error fetching videos:', error);
        });
    },

    initializeVideoPlayers() {
  if (!this.$refs.plyrVideo || !Array.isArray(this.$refs.plyrVideo)) {
    
    return;
  }

  this.$refs.plyrVideo.forEach((videoElement) => {
    const videoId = videoElement.dataset.videoId;
    console.log(`Initializing Plyr for video ID: ${videoId}`);
    const player = new Plyr(videoElement, {
      controls: ['play', 'progress', 'mute', 'volume', 'fullscreen']
    });
    console.log("Plyr instance:", player);
    this.players.push(player);
    console.log(`Plyr initialized for video ID: ${videoId}`);
  });
},

    beforeUnmount() {
      document.removeEventListener('click', this.handleOutsideClick, true);
      this.players.forEach(player => {
        console.log(`Destroying Plyr instance for video ID: ${player.id}`);
        player.destroy();
      });
    },

    async fetchProducts() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to fetch products.');
        return Promise.reject('No token available.');
      }
      const requestUrl = `${API_URL}/get_products`;
      try {
        const response = await fetch(requestUrl, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        this.products = data.products; // All products are stored, perhaps for other uses
        this.filteredProducts = data.products.slice(0, 3); // Only display the first 3 as suggestions
        console.log("Fetched products:", this.products);
        return data.products; // Return the fetched products
      } catch (error) {
        console.error('Error fetching products:', error);
        throw error; // Re-throw the error to propagate it further
      }
    },

    fetchDesigns() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to fetch designs.');
        return Promise.reject('No token available.');
      }
      const requestUrl = `${API_URL}/api/design-templates`;
      return fetch(requestUrl, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          this.designs = data;
          console.log("Fetched designs:", this.designs);
          return data; // Return the fetched designs
        })
        .catch(error => {
          console.error('Error fetching designs:', error);
          throw error; // Re-throw the error to propagate it further
        });
    },

    // Method to handle product selection
    selectProduct(productId) {
  console.log(`VideosMain: selectProduct called with productId: ${productId}`);
  localStorage.removeItem('selectedProductId');
  
  this.selectedProduct = productId;
  this.firstSelection = 'product';
  localStorage.setItem('selectedProductId', productId);
  console.log(`VideosMain: selectedProduct set to ${productId}, stored in localStorage`);
  
  if (!this.selectedDesign) {
    console.log('VideosMain: No design selected, opening design dropdown');
    this.isDesignDropdownOpen = true;
  }
  console.log(`VideosMain: Navigating to Preview page with params: productId: ${productId}, designId: ${this.selectedDesign}`);
  this.$router.push({ name: 'Preview', params: { productId: productId, designId: this.selectedDesign } });
},


    searchProducts() {
      if (!this.productSearchQuery.trim()) {
        this.filteredProducts = this.products.slice(0, 3); // Reset to initial suggestions
        return;
      }
      const query = this.productSearchQuery.trim().toLowerCase();
      this.filteredProducts = this.products.filter(product => {
        return product.name.toLowerCase().includes(query);
      });
    },
    // Method to handle design selection
    selectDesign(designId) {
  console.log(`VideosMain: selectDesign called with designId: ${designId}`);
  localStorage.removeItem('selectedDesignId');
  
  this.selectedDesign = designId;
  this.firstSelection = 'design';
  localStorage.setItem('selectedDesignId', designId);
  console.log(`VideosMain: selectedDesign set to ${designId}, stored in localStorage`);
  
  if (!this.selectedProduct) {
    console.log('VideosMain: No product selected, opening product dropdown');
    this.isProductDropdownOpen = true;
  }
  console.log(`VideosMain: Navigating to Preview page with params: designId: ${designId}, productId: ${this.selectedProduct}`);
  this.$router.push({ name: 'Preview', params: { designId: designId, productId: this.selectedProduct } });
},



    checkForPreview() {
      if (this.selectedProduct && this.selectedDesign) {
        this.$refs.previewPopup.openPopup(this.selectedProduct, this.selectedDesign);
      }
    },

    openPopup(product, design) {
      if (!product || !design) {
        console.error("Selected product or design is null", product, design);
        return; // Avoid proceeding further if data is incomplete
      }
      this.selectedProduct = product;
      this.selectedDesign = design;
      this.showPopup = true;
      this.$nextTick(() => {
        this.initializePreview();
      });
    },

    toggleProductDropdown() {
      console.log("Toggling Product Dropdown");
      this.isProductDropdownOpen = !this.isProductDropdownOpen;
      console.log("Product dropdown is now:", this.isProductDropdownOpen ? "Open" : "Closed");
      if (this.isProductDropdownOpen) {
        document.addEventListener('click', this.handleOutsideClick, true);
      } else {
        document.removeEventListener('click', this.handleOutsideClick, true);
      }
    },

    toggleDesignDropdown() {
      console.log("Toggling Design Dropdown");
      this.isDesignDropdownOpen = !this.isDesignDropdownOpen;
      console.log("Design dropdown is now:", this.isDesignDropdownOpen ? "Open" : "Closed");
      if (this.isDesignDropdownOpen) {
        document.addEventListener('click', this.handleOutsideClick, true);
      } else {
        document.removeEventListener('click', this.handleOutsideClick, true);
      }
    },

    handleOutsideClick(event) {
      if (this.isProductDropdownOpen && !this.$refs.productDropdown.contains(event.target)) {
        this.isProductDropdownOpen = false;
      }
      if (this.isDesignDropdownOpen && !this.$refs.designDropdown.contains(event.target)) {
        this.isDesignDropdownOpen = false;
      }
    },
    async saveInitialEditingState(videoId) {
  const token = localStorage.getItem('token');
  if (!token) {
    alert('No token found. You must be logged in to edit videos.');
    return;
  }

  console.log(`Fetching video details for video ID: ${videoId}`);

  try {
    const response = await fetch(`${API_URL}/api/videos/${videoId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Video details fetched:', data);

    const modifications = data.video.modifications;
  const templateId = data.video.creatomate_template_id;
    console.log(`Modifications: ${JSON.stringify(modifications)}`);
    console.log(`Template ID: ${templateId}`);

    const startEditingResponse = await fetch(`${API_URL}/api/videos/${videoId}/start-editing`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ templateId, modifications }),
    });

    if (!startEditingResponse.ok) {
      throw new Error(`Failed to start editing. HTTP error! Status: ${startEditingResponse.status}`);
    }

    console.log(`Initial editing state saved for video ID: ${videoId}`);
  } catch (error) {
    console.error('Failed to save initial editing state:', error);
  }
},

    editVideo(videoId) {
      console.log(`Edit video with ID: ${videoId}`);
      this.saveInitialEditingState(videoId);
      this.editVideoId = videoId;
      console.log(`Set editVideoId to: ${this.editVideoId}`);
    },
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, true);
  },
  mounted() {
    this.fetchVideos();
    this.fetchProducts();
    this.fetchDesigns();
    this.fetchRequiredCredits(); // Fetch required credits on mount
  }
}
</script>















<style scoped>



*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Flex container for action items */
.main-panel-actions {
  display: flex;
  justify-content: center;
  gap: 40px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 36px;
  margin-top: 20px;
}

/* Dropdown action positioning */
.main-panel-action {
  position: relative;
  display: inline-block;
}

/* Styling the search input bar */
.dropdown-search-bar {
    width: calc(100% - 40px); /* Account for padding and border */
    padding: 10px 20px; /* Increased left/right padding */
    margin-bottom: 10px;
    border: 1px solid #ccc; /* Light border */
    border-radius: 25px; /* Slightly larger rounded corners */
    background-color: #fff; /* White background */
    outline: none; /* Remove default focus outline */
    transition: all 0.3s ease; /* Smooth transition for states */
    font-size: 16px; /* Larger font size */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern font family */
    direction: rtl; /* Set direction to RTL */
    text-align: right; /* Align text to the right */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Placeholder style */
.dropdown-search-bar::placeholder {
    color: #aaa; /* Lighter placeholder text color */
}

/* Search bar focus state */
.dropdown-search-bar:focus {
    box-shadow: 0 0 8px rgba(0, 86, 179, 0.2); /* Blue shadow to match icon color */
    border-color: #007BFF; /* Matching blue border color */
}

/* Container for the search icon within the search bar */
.dropdown-search-bar-with-icon {
    position: relative;
}

/* Search icon styling */
.dropdown-search-bar-icon {
    position: absolute;
    left: 15px; /* Position the icon on the left */
    top: 50%;
    transform: translateY(-50%);
    color: #007BFF; /* Blue color for the icon */
    pointer-events: none;
    font-size: 18px; /* Larger icon size */
}

/* Dropdown menu container */
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0; /* Align the dropdown menu to the left */
    z-index: 1000; /* High z-index to ensure it's above other elements */
    width: 180%; /* Wider dropdown container */
    max-height: 400px; /* Increased max-height for more content */
    overflow-y: auto;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px; /* Slightly larger rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Stronger shadow for depth */
    margin-top: 10px; /* Increased margin-top for better spacing */
    direction: rtl; /* Set direction to RTL */
    text-align: right; /* Align text to the right */
}

/* Suggested label style */
.suggested-label {
    display: block;
    font-size: 18px; /* Larger font size */
    color: #333;
    font-weight: 600;
    margin-bottom: 20px; /* Increased bottom margin */
}

/* Dropdown item styling */
.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 6px;
  direction: rtl;
  text-align: right;
}

.dropdown-item-content {
  display: flex;
  align-items: center;
}

.dropdown-video-preview {
  width: 50px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  object-fit: cover;
  margin-right: 8px; /* Add some spacing between the video and the text */
}
/* Hover state for dropdown items */
.dropdown-item:hover {
    background-color: #f5f5f5; /* Lighter hover background */
}

/* Product image styling */
.product-image {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  margin-left: 10px; /* Adjusts margin for RTL */
  object-fit: cover;
}

.no-image-placeholder {
  width: 50px;
  height: 50px;
  border-radius: 6px;
  margin-left: 10px; /* Adjusts margin for RTL */
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  font-size: 12px;
}

/* Product name styling */
.product-name {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

/* No need to show dropdown on hover as it's controlled by Vue */
.dropdown:hover .dropdown-menu {
    display: none;
}




body { overflow-x: hidden; }
.container {
    width: 100%;
    max-width: 100%;
    border-radius: 20px;
    box-shadow: 2px 47px 69px 0px rgba(149, 149, 149, 0.25);
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
}
.main-content {
  transition: filter 0.3s ease;
    background: white;
    border-radius: 20px;
    top: 11px;
    width: 100%;
    position: relative;
    margin-left: 7px;
}

.sidebar {
    flex: 0 0 250px;
    padding: 20px;
}
.main-panel {
flex: 1;
line-height: normal;
min-height: 100vh;
padding: 20px;
}


.main-panel-header {
border-radius: 250px 0 0 250px;
background: linear-gradient(179deg, rgba(186, 186, 186, 0.06) 1.2%, rgba(0, 152, 253, 0.04) 37.24%);
display: flex;
flex-direction: column;
align-items: center;
font-weight: 400;
padding: 29px 128px;
}



.main-panel-title {
color: #000;
text-align: center;
font: 600 44px/120% "Open Sans", sans-serif;
margin-bottom: 20px;
}

.main-panel-description {
color: #4e4e4e;
text-align: center;
text-transform: capitalize;
margin-bottom: 40px;
max-width: 600px;
font: 25px/40px "Inter", sans-serif;
}





.main-panel-action-content {
border-radius: 72px;
box-shadow: 2px 3px 69px 0px rgba(149, 149, 149, 0.11);
background-color: #fff;
display: flex;
align-items: center;
gap: 20px;
padding: 19px 30px;
transition: background-color 0.3s ease;
}

.main-panel-action-content:hover {
background-color: #f5f5f5;
}

.main-panel-action-text {
margin: 0;
color: #000;
}

.main-panel-action-icon {
width: 48px;
height: 48px;
object-fit: contain;
}



.video-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 20px;
}

.video-wrapper:hover .pause-button {
  display: block; /* Show the pause button only on hover when video is playing */
}


.video-item {
  position: relative;
  overflow: hidden;
}

.video-wrapper {
  position: relative;
  background-color: #000;
}

.video-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.play-button, .pause-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  z-index: 1;
  transition: opacity 0.3s ease;
  display: none; /* Start with hidden buttons, shown conditionally */
}

.play-button:hover, .pause-button:hover {
  opacity: 0.7;
}

.video-wrapper:hover .play-button,
.video-wrapper:hover .pause-button {
  display: block;
  opacity: 1;
}


.video-actions {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.video-actions button {
  background: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
}

.video-actions button:hover, .video-actions button:focus {
  background: #e0e0e0;
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.3);
}

.video-actions button:active {
  transform: translateY(-1px);
  box-shadow: 0 2px 3px rgba(0,0,0,0.2);
}

.video-actions img {
  width: 20px; /* Adjust size as needed */
  height: auto;
  transition: transform 0.3s ease;
}

.video-actions button:hover img {
  transform: scale(1.1);
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(5px);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  z-index: 10000;
  width: 100%;  /* Adjusts to 60% of the parent container's width */
  max-width: 1200px;  /* Ensures it doesn't exceed this width */
  height: 80%;  /* This will fix the height to 80% of the parent container's height */
  max-height: 80%;  /* Ensures it doesn't exceed this height */
  overflow: auto;  /* Allows scrolling within the modal if content exceeds the modal's height */
  position: relative;  /* Ensures the modal is positioned relative to its nearest positioned ancestor */
  margin: auto;  /* Centers the modal horizontally and vertically if used with display: flex in the parent */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);  /* Optional: Adds shadow for better visibility */
}


.main-content {
  transition: filter 0.3s ease;
}

.main-content.blurred {
  filter: blur(5px);
}


/* Ensure the page does not scroll horizontally */
html, body {
  overflow-x: hidden;
}


@media (max-width: 768px) {
  .main-panel-header {
    padding: 15px 30px;
  }

  .main-panel-title {
    font-size: 28px;
  }

  .main-panel-description {
    font-size: 16px;
  }

  .main-panel-actions {
    gap: 15px;
  }

  .video-grid {
    grid-template-columns: 1fr;
    grid-gap: 15px;
    padding: 10px;
  }

  .main-panel-action-content {
    padding: 10px 15px;
  }

  .main-panel-action-icon {
    width: 30px;
    height: 30px;
  }

  .dropdown-menu {
    width: calc(100% - 30px);
  }

  .dropdown-search-bar {
    width: calc(100% - 30px);
  }

  .container {
    padding: 15px;
  }

  .sidebar {
    flex: 0 0 150px;
    padding: 15px;
  }

  .main-content {
    padding: 15px;
  }

  footer {
    padding: 15px;
  }

  .sidebar {
    position: fixed;
    left: -250px;
    top: 0;
    bottom: 0;
    transition: left 0.3s ease;
  }

  .sidebar.open {
    left: 0;
  }

  .sidebar-toggle {
    position: fixed;
    left: 10px;
    top: 10px;
    z-index: 1001;
  }
}


@media (max-width: 480px) {
  .main-panel-header {
    padding: 10px 20px;
    background: white;
  }

  .main-panel-title {
    font-size: 24px;
  }

  .main-panel-description {
    font-size: 14px;
    max-width: 100%;
  }

  .main-panel-actions {
    gap: 10px;
    width: 100%;
  }

  .main-panel-action-content {
    padding: 10px;
    width: 100%;
    justify-content: space-between;
  }

  .main-panel-action-text {
    font-size: 14px;
  }

  .main-panel-action-icon {
    width: 25px;
    height: 25px;
  }

  .video-grid {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding: 5px;
  }

  .dropdown-menu {
    width: 221%;
    margin-left: -66px;
  }

  .dropdown-search-bar {
    width: calc(100% - 20px);
  }

  .dropdown-search-bar-icon{
position: absolute; 
left: 26px; 
top: 46%;
    
  }

  .dropdown-item {
    padding: 8px 10px;
        gap: 13px;
  }

  .dropdown-item img, .dropdown-video-preview {
    width: 40px;
    height: 40px;
  }

  .product-name {
    font-size: 14px;
  }

  .video-actions button {
    width: 30px;
    height: 30px;
  }

  .video-actions img {
    width: 15px;
  }

  .container {
    padding: 10px;
  }

  .sidebar {
    flex: 0 0 100px;
    padding: 10px;
  }

  .main-content {
    padding: 10px;
  }

  footer {
    padding: 10px;
  }

  .sidebar {
    left: -250px;
  }

  .sidebar.open {
    left: 0;
  }

  .sidebar-toggle {
    left: 10px;
    top: 10px;
  }

  .overlay {
    display: none;
  }

  .sidebar.open + .overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
}

</style>