<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="container">
    <aside :class="['sidebar', { 'open': isSidebarOpen }]">
      <div class="sidebar-content">
        <button v-for="item in filteredSidebarItems" :key="item.id" :class="['sidebar-item', { 'active': item.active }]" @click="activateItem(item)" :data-url="item.url">
          <img :src="item.icon" :alt="item.text + ' أيقونة'" class="sidebar-item-icon" />
          <div class="sidebar-item-text">{{ item.text }}</div>
        </button>
        <div class="sidebar-separator"></div>
        <div class="sidebar-credits">
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/98ed187d19fd5ab9e00838f48d8593aaf1c631cbf9a1090db76bde9337628ff0?apiKey=9dd5222737fe46c7a8f356884c88412a&" alt="أيقونة الائتمانات" class="sidebar-credits-icon" />
          <div class="sidebar-credits-text">
            <span id="userCredits" style="font-weight: 600; position: relative; color: rgba(34, 34, 34, 1)">{{ userCredits }}</span>
          </div>
        </div>
      </div>
    </aside>
    <button class="sidebar-toggle" @click="toggleSidebar">
      <div class="icon">
        <span class="middle-bar"></span>
      </div>
    </button>
    <div :class="['overlay', { 'visible': isSidebarOpen }]" @click="toggleSidebar"></div>
  </div>
</template>

<script>
import { API_URL } from '@/config';

export default {
  name: 'MainContent',
  data() {
    return {
      userCredits: 'جاري تحميل الائتمانات...',
      isSallaMerchant: false,
      checkInterval: null,
      sidebarItems: [
        { id: 1, icon: require('@/assets/icons/videos.png'), text: 'فيديوهات', url: '/dashboard', active: false }, 
        { id: 7, icon: require('@/assets/icons/product.png'), text: 'المنتجات', url: '/products', active: false },
        { id: 2, icon: require('@/assets/icons/favourite.png'), text: 'المفضلة', url: '/favourite', active: false },
        { id: 10, icon: require('@/assets/icons/scrape.png'), text: 'جلب المنتجات', url: '/scrape-products', active: false },
        { id: 3, icon: require('@/assets/icons/analytics.png'), text: 'التحليلات', url: '/analytics', active: false },
        { id: 4, icon: require('@/assets/icons/share.png'), text: 'مشاركة', url: '/share', active: false },
        { id: 5, icon: require('@/assets/icons/connect.png'), text: 'ربط مع', url: '/connect', active: false },
        { id: 8, icon: require('@/assets/icons/plans.png'), text: 'الخطط والاشتراكات',url: '/plans', active: false },
        { id: 6, icon: require('@/assets/icons/settings.png'), text: 'الإعدادات', url: '/settings', active: false },
        { id: 9, icon: require('@/assets/icons/support.png'), text: 'الدعم الفني', url: '/support', active: false },
      ],
      visibilitySettings: {},
      isSidebarOpen: false,
    };
  },

  computed: {
    filteredSidebarItems() {
      return this.sidebarItems.filter(item => {
        // First check if it's the plans tab and user is a Salla merchant
        if (item.id === 8 && this.isSallaMerchant) {
          return false;
        }
        // Then apply the admin visibility settings
        return this.visibilitySettings[item.id];
      });
    }
  },

  methods: {
    async checkSallaMerchantStatus() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/check-salla-merchant`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        this.isSallaMerchant = data.is_salla_merchant;
        
        // Store in localStorage for persistence
        localStorage.setItem('isSallaMerchant', data.is_salla_merchant);

        // If they're on plans page and are a Salla merchant, redirect
        if (this.isSallaMerchant && window.location.pathname === '/plans') {
          this.$router.push('/dashboard');
        }
      } catch (error) {
        console.error('Error checking Salla merchant status:', error);
        // Fall back to stored value if available
        const storedStatus = localStorage.getItem('isSallaMerchant');
        if (storedStatus) {
          this.isSallaMerchant = storedStatus === 'true';
        }
      }
    },

    goToReels() {
      this.$router.push({ name: 'LandingPageReel' });
      this.isSidebarOpen = false;
    },

    async activateItem(item) {
      // Always check merchant status before navigation
      await this.checkSallaMerchantStatus();

      // Prevent navigation to plans page if user is a Salla merchant
      if (item.id === 8 && this.isSallaMerchant) {
        console.log('Plans page is not available for Salla merchants');
        return;
      }

      this.sidebarItems.forEach(i => {
        if (i.id === item.id) i.active = true;
        else i.active = false;
      });
      
      this.$router.push(item.url);
      this.isSidebarOpen = false;
    },

    async fetchUserCredits() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to fetch credits.');
        this.userCredits = 'Please log in to view credits.';
        return;
      }

      try {
        const response = await fetch(`${API_URL}/get_user_credits`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        this.userCredits = data.credits;
      } catch (error) {
        console.error('Error fetching user credits:', error);
        this.userCredits = 'Error loading credits';
      }
    },

    async fetchSidebarVisibility() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to fetch sidebar visibility.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/sidebar-visibility`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        this.visibilitySettings = data.reduce((acc, item) => {
          acc[item.tab_id] = item.is_visible;
          return acc;
        }, {});
      } catch (error) {
        console.error('Error fetching sidebar visibility:', error);
      }
    },

    setActiveItem() {
      const currentPath = this.$route.path;
      this.sidebarItems.forEach(item => {
        item.active = item.url === currentPath;
      });
    },

    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },

    // New method to initialize all checks
    async initializeChecks() {
      await this.checkSallaMerchantStatus();
      await this.fetchUserCredits();
      await this.fetchSidebarVisibility();
      this.setActiveItem();
    }
  },

  async created() {
    // Check merchant status as early as possible
    await this.checkSallaMerchantStatus();
  },

  async mounted() {
    await this.initializeChecks();

    // Set up periodic checks every 5 minutes
    this.checkInterval = setInterval(() => {
      this.checkSallaMerchantStatus();
    }, 300000);
  },

  beforeUnmount() {
    // Clean up interval
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  },

  watch: {
    async '$route'(to) {
      this.setActiveItem();
      // Re-check merchant status on every route change
      await this.checkSallaMerchantStatus();
      
      // If trying to access plans page and is Salla merchant, redirect
      if (to.path === '/plans' && this.isSallaMerchant) {
        this.$router.push('/dashboard');
      }
    }
  }
};
</script>

<style scoped>



.create-reels-section {
  background: linear-gradient(135deg, #1e3a8a, #0c1620);
  color: white;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  direction: rtl;
  text-align: right;
}

.create-reels-section::before {
  content: '';
  position: absolute;
  top: -50%;
  right: -50%; /* Changed from left to right */
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 80%);
  transform: rotate(45deg);
  animation: shimmer 3s infinite linear;
}

@keyframes shimmer {
  0% { transform: translateX(50%) rotate(45deg); } /* Changed direction */
  100% { transform: translateX(-50%) rotate(45deg); } /* Changed direction */
}

.create-reels-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.create-reels-section h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.go-now-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.go-now-button::before {
  content: '';
  position: absolute;
  top: 0;
  right: -100%; /* Changed from left to right */
  width: 100%;
  height: 100%;
  background: linear-gradient(
    -120deg, /* Changed angle */
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transition: all 0.6s;
}

.go-now-button:hover::before {
  right: 100%; /* Changed from left to right */
}

.go-now-button:hover {
  background-color: #357abd;
  transform: scale(1.05);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.button-text {
  z-index: 1;
  margin-left: 0.5rem; /* Changed from margin-right to margin-left */
}

.button-icon {
  z-index: 1;
  font-size: 1.2em;
  transition: transform 0.3s ease;
}

.go-now-button:hover .button-icon {
  transform: translateX(-5px); /* Changed direction */
}




/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
  color: #333;
}

/* Container */
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Main Content */
.main-content {
  display: flex;
  flex: 1;
  background-color: #f5f5f5;
}

/* Sidebar */
.sidebar {
  width: 254px;
  margin-right: 1px;
  margin-top: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0 29px 0 0;
  background-color: #fff;
  transition: width 0.3s ease;
}

/* Sidebar Content */
.sidebar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: #222;
  font-weight: 400;
  line-height: 1.5;
  padding: 23px 11px 80px 28px;
}

/* Sidebar Items */
.sidebar-item,
.sidebar-item-inactive {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 19px;
  white-space: nowrap;
  text-align: start;
  font-family: 'Roboto', sans-serif;
  margin: 10px 0;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 8px;
}

.sidebar-item:hover,
.sidebar-item-inactive:hover {
  background-color: #f7fbfd;
  color: #0098fd;
}

.sidebar-item.active,
.sidebar-item-inactive.active {
  color: #0098fd;
  background-color: #f7fbfd;
  border-radius: 20px;
  font-weight: 500;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Sidebar Item Icon */
.sidebar-item-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  color: #0098fd;
  transition: color 0.3s ease;
}

.sidebar-item:hover .sidebar-item-icon,
.sidebar-item-inactive:hover .sidebar-item-icon {
  color: #0098fd;
}

/* Sidebar Item Text */
.sidebar-item-text {
  margin: auto 0;
  transition: color 0.3s ease;
}

/* Sidebar Separator */
.sidebar-separator {
  background-color: #e9eaf3;
  margin-top: 35px;
  width: 100%;
  height: 1px;
}

/* Sidebar Credits */
.sidebar-credits {
  display: flex;
  margin-top: 6px;
  width: 100%;
  padding-top: 34px;
  align-items: start;
  gap: 16px;
  font-weight: 600;
  line-height: 24px;
}

.sidebar-credits-icon {
  width: 26px;
  height: 36px;
  margin-top: -11px;
  margin-left: 15px;
}

.sidebar-credits-text {
  font-family: 'Roboto', sans-serif;
  position: relative;
  top: -4px;
}

/* Sidebar Toggle Button */
.sidebar-toggle {
  display: none;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 101;
  background-color: transparent;
  color: #007bff;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.sidebar-toggle .icon {
  position: relative;
    width: 18px;
    height: 28px;
    transition: transform 0.3s ease;
}

.sidebar-toggle .icon::before,
.sidebar-toggle .icon::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #007bff;
  border-radius: 4px;
  transition: transform 0.3s ease;
}

.sidebar-toggle .icon::before {
  top: 6px;
  animation: top-bar-reverse 0.3s ease forwards;
}

.sidebar-toggle .icon::after {
  bottom: 6px;
  animation: bottom-bar-reverse 0.3s ease forwards;
}

.sidebar-toggle .icon .middle-bar {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #007bff;
  border-radius: 4px;
  transform: translateY(-50%);
  transition: opacity 0.3s ease;
}

.sidebar-toggle.open .icon::before {
  animation: top-bar 0.3s ease forwards;
}

.sidebar-toggle.open .icon::after {
  animation: bottom-bar 0.3s ease forwards;
}

.sidebar-toggle.open .icon .middle-bar {
  opacity: 0;
}

@keyframes top-bar {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(10px) rotate(0);
  }
  100% {
    transform: translateY(10px) rotate(45deg);
  }
}

@keyframes bottom-bar {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-10px) rotate(0);
  }
  100% {
    transform: translateY(-10px) rotate(-45deg);
  }
}

@keyframes top-bar-reverse {
  0% {
    transform: translateY(10px) rotate(45deg);
  }
  50% {
    transform: translateY(10px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

@keyframes bottom-bar-reverse {
  0% {
    transform: translateY(-10px) rotate(-45deg);
  }
  50% {
    transform: translateY(-10px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

.sidebar-toggle:hover .icon::before,
.sidebar-toggle:hover .icon::after,
.sidebar-toggle:hover .icon .middle-bar {
  background-color: #0056b3;
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.overlay.visible {
  opacity: 1;
  pointer-events: auto;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    flex-direction: row;
    position: relative;
  }

  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 250px;
    z-index: 100;
    transform: translateX(-250px);
    transition: transform 0.3s ease;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    overflow-y: auto; /* Enable scrolling */
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling for touch devices */
  }

  .sidebar-separator {
    margin-top: 4px;
  }

  .sidebar-credits {
    margin-top: 6px;
    margin-left: 15px;
  }

  .sidebar.open {
    transform: translateX(0);
    margin-top: 86px;
  }

  .sidebar-content {
    padding: 23px 11px 80px 28px;
  }

  .sidebar-toggle {
    display: flex;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 2000;
    margin-top: 69px;
    margin-left: -19px;
  }

  .main-content {
    padding: 20px;
    width: 100%;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  .overlay.visible {
    opacity: 1;
    pointer-events: auto;
  }
}
/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    flex-direction: row;
    position: relative;
  }

  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 250px;
    z-index: 100;
    transform: translateX(-250px);
    transition: transform 0.3s ease;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    overflow-y: auto; /* Enable scrolling */
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling for touch devices */
  }

  .sidebar-separator {
    margin-top: 4px;
  }

  .sidebar-credits {
    margin-top: 6px;
    margin-left: 15px;
  }

  .sidebar.open {
    transform: translateX(0);
    margin-top: 86px;
  }

  .sidebar-content {
    padding: 23px 11px 80px 28px;
  }

  .sidebar-toggle {
    display: flex;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 2000;
    margin-top: 69px;
    margin-left: -19px;
  }

  .main-content {
    padding: 20px;
    width: 100%;
    background-color: white;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  .overlay.visible {
    opacity: 1;
    pointer-events: auto;
  }
}


/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    flex-direction: row;
    position: relative;
  }

  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 250px;
    z-index: 100;
    transform: translateX(-250px);
    transition: transform 0.3s ease;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    overflow-y: auto; /* Enable scrolling */
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling for touch devices */
  }

  .sidebar-separator {
    margin-top: 4px;
  }

  .sidebar-credits {
    margin-top: 6px;
    margin-left: 15px;
  }

  .sidebar.open {
    transform: translateX(0);
    margin-top: 86px;
  }

  .sidebar-content {
    padding: 23px 11px 80px 28px;
  }

  .sidebar-toggle {
    display: flex;
        position: fixed;
        top: 20px;
        left: 20px;
        z-index: 2000;
        margin-top: 44px;
        margin-left: -33px;
  }

  .main-content {
    padding: 20px;
    width: 100%;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  .overlay.visible {
    opacity: 1;
    pointer-events: auto;
  }
}
</style>

    