<template>
  <div class="salla-flex salla-flex-col salla-gap-8 salla-p-6 salla-bg-gray-50">
    <!-- Stripe Plans Section -->
    <section class="salla-bg-white salla-rounded-lg salla-shadow-md salla-p-6">
      <h2 class="salla-text-2xl salla-font-bold salla-text-gray-800 salla-mb-6">Stripe Plan Products</h2>

      <!-- Plans Table -->
      <div class="salla-overflow-x-auto salla-mb-8">
        <table class="salla-w-full" v-if="plans.length">
          <thead class="salla-bg-gray-50">
            <tr>
              <th class="salla-px-4 salla-py-3 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-600">Name</th>
              <th class="salla-px-4 salla-py-3 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-600">Description</th>
              <th class="salla-px-4 salla-py-3 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-600">Price</th>
              <th class="salla-px-4 salla-py-3 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-600">Credits</th>
              <th class="salla-px-4 salla-py-3 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-600">Interval</th>
              <th class="salla-px-4 salla-py-3 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-600">Actions</th>
            </tr>
          </thead>
          <tbody class="salla-divide-y salla-divide-gray-200">
            <tr v-for="(plan, index) in plans" :key="index" class="salla-hover:salla-bg-gray-50">
              <td class="salla-px-4 salla-py-3 salla-text-sm salla-text-gray-900">{{ plan.name }}</td>
              <td class="salla-px-4 salla-py-3 salla-text-sm salla-text-gray-600">{{ plan.description }}</td>
              <td class="salla-px-4 salla-py-3 salla-text-sm salla-text-gray-900">${{ plan.price }}</td>
              <td class="salla-px-4 salla-py-3 salla-text-sm salla-text-gray-900">{{ plan.credits_amount }}</td>
              <td class="salla-px-4 salla-py-3 salla-text-sm salla-text-gray-900">
                {{ plan.interval }} ({{ plan.interval_count }})
              </td>
              <td class="salla-px-4 salla-py-3 salla-text-sm">
                <button 
                  @click="removePlan(plan.id)"
                  class="salla-bg-red-500 salla-text-white salla-px-3 salla-py-1 salla-rounded-md salla-hover:salla-bg-red-600 salla-transition-colors">
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-else class="salla-text-gray-500 salla-text-center salla-py-4">No plans available</p>
      </div>

      <!-- Add Plan Form -->
      <form @submit.prevent="submitPlanProductForm" class="salla-space-y-6">
        <div class="salla-bg-primary-50 salla-p-4 salla-rounded-lg salla-mb-6">
          <h3 class="salla-text-xl salla-font-semibold salla-text-gray-800 salla-mb-4">Add Stripe Plan Product</h3>
          
          <!-- Stripe IDs Section -->
          <div class="salla-grid salla-grid-cols-1 md:salla-grid-cols-2 salla-gap-6 salla-mb-6">
            <div>
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">
                Stripe Product ID
                <span class="salla-text-xs salla-text-gray-500 salla-block">Starts with prod_XXXXX</span>
              </label>
              <input 
                type="text" 
                v-model="stripeProductId"
                class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                required
              >
            </div>
            <div>
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">
                Stripe Price ID
                <span class="salla-text-xs salla-text-gray-500 salla-block">Starts with price_XXXXX</span>
              </label>
              <input 
                type="text" 
                v-model="stripePlanId"
                class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                required
              >
            </div>
          </div>

          <!-- Basic Information -->
          <div class="salla-grid salla-grid-cols-1 md:salla-grid-cols-2 salla-gap-6">
            <div>
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">Plan Name</label>
              <input 
                type="text" 
                v-model="planName"
                class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                required
              >
            </div>
            <div>
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">Price (USD)</label>
              <input 
                type="number" 
                v-model="price"
                class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                required
              >
            </div>
          </div>

          <!-- Subscription Details -->
          <div class="salla-grid salla-grid-cols-1 md:salla-grid-cols-2 salla-gap-6 salla-mt-6">
            <div>
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">
                Interval
                <span class="salla-text-xs salla-text-gray-500 salla-block">day, week, month, or year</span>
              </label>
              <select 
                v-model="interval"
                class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                required
              >
                <option value="day">Daily</option>
                <option value="week">Weekly</option>
                <option value="month">Monthly</option>
                <option value="year">Yearly</option>
              </select>
            </div>
            <div>
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">
                Interval Count
                <span class="salla-text-xs salla-text-gray-500 salla-block">How many intervals between charges</span>
              </label>
              <input 
                type="number" 
                v-model="intervalCount"
                class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                required
              >
            </div>
          </div>

          <!-- Credits and Description -->
          <div class="salla-grid salla-grid-cols-1 md:salla-grid-cols-2 salla-gap-6 salla-mt-6">
            <div>
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">
                Credits Amount
                <span class="salla-text-xs salla-text-gray-500 salla-block">Number of credits provided</span>
              </label>
              <input 
                type="number" 
                v-model="creditsAmount"
                class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                required
              >
            </div>
            <div>
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">Plan Description</label>
              <textarea 
                v-model="planDescription"
                class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                rows="3"
              ></textarea>
            </div>
          </div>

          <!-- Custom Description and Image -->
          <div class="salla-mt-6">
            <div class="salla-mb-4">
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">Custom Description</label>
              <textarea 
                v-model="customDescription"
                class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                rows="3"
              ></textarea>
            </div>
            <div>
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">Plan Image</label>
              <input 
                type="file" 
                @change="handleImageUpload"
                class="salla-w-full"
              >
            </div>
          </div>
        </div>

        <button 
          type="submit"
          class="salla-w-full salla-bg-primary-600 salla-text-white salla-py-2 salla-px-4 salla-rounded-md salla-hover:salla-bg-primary-700 salla-transition-colors">
          Add Plan
        </button>
      </form>
    </section>

    <!-- One-Time Purchases Section -->
    <section class="salla-bg-white salla-rounded-lg salla-shadow-md salla-p-6">
      <h2 class="salla-text-2xl salla-font-bold salla-text-gray-800 salla-mb-6">One-Time Purchase Products</h2>
      
      <div class="salla-overflow-x-auto salla-mb-8">
        <table class="salla-w-full" v-if="oneTimePurchases.length">
          <thead class="salla-bg-gray-50">
            <tr>
              <th class="salla-px-4 salla-py-3 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-600">Name</th>
              <th class="salla-px-4 salla-py-3 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-600">Description</th>
              <th class="salla-px-4 salla-py-3 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-600">Price</th>
              <th class="salla-px-4 salla-py-3 salla-text-left salla-text-sm salla-font-semibold salla-text-gray-600">Credits</th>
            </tr>
          </thead>
          <tbody class="salla-divide-y salla-divide-gray-200">
            <tr v-for="(product, index) in oneTimePurchases" :key="index" class="salla-hover:salla-bg-gray-50">
              <td class="salla-px-4 salla-py-3 salla-text-sm salla-text-gray-900">{{ product.name }}</td>
              <td class="salla-px-4 salla-py-3 salla-text-sm salla-text-gray-600">{{ product.description }}</td>
              <td class="salla-px-4 salla-py-3 salla-text-sm salla-text-gray-900">${{ product.price }}</td>
              <td class="salla-px-4 salla-py-3 salla-text-sm salla-text-gray-900">{{ product.credits_amount }}</td>
            </tr>
          </tbody>
        </table>
        <p v-else class="salla-text-gray-500 salla-text-center salla-py-4">No one-time purchase products available</p>
      </div>

      <!-- Add One-Time Purchase Form -->
      <form @submit.prevent="submitProductForm" class="salla-space-y-6">
        <div class="salla-bg-primary-50 salla-p-4 salla-rounded-lg">
          <h3 class="salla-text-xl salla-font-semibold salla-text-gray-800 salla-mb-4">Add One-Time Purchase Product</h3>
          
          <div class="salla-grid salla-grid-cols-1 md:salla-grid-cols-2 salla-gap-6">
            <div>
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">Product Name</label>
              <input 
                type="text" 
                v-model="productName"
                class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                required
              >
            </div>
            <div>
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">
                Stripe Product ID
                <span class="salla-text-xs salla-text-gray-500 salla-block">Starts with prod_XXXXX</span>
              </label>
              <input 
                type="text" 
                v-model="stripeProductId"
                class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                required
              >
            </div>
          </div>

          <div class="salla-grid salla-grid-cols-1 md:salla-grid-cols-2 salla-gap-6 salla-mt-6">
            <div>
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">Price (USD)</label>
              <input 
                type="number" 
                v-model="productPrice"
                class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                required
              >
            </div>
            <div>
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">
                Credits Amount
                <span class="salla-text-xs salla-text-gray-500 salla-block">Number of credits provided</span>
              </label>
              <input 
                type="number" 
                v-model="creditsAmount"
                class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                required
              >
            </div>
          </div>

          <div class="salla-mt-6">
            <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">Product Description</label>
            <textarea 
              v-model="productDescription"
              class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
              rows="3"
            ></textarea>
          </div>
        </div>

        <button 
          type="submit"
          class="salla-w-full salla-bg-primary-600 salla-text-white salla-py-2 salla-px-4 salla-rounded-md salla-hover:salla-bg-primary-700 salla-transition-colors">
          Add Product
        </button>
      </form>
    </section>

    <!-- Help Section -->
    <section class="salla-bg-white salla-rounded-lg salla-shadow-md salla-p-6">
      <h2 class="salla-text-2xl salla-font-bold salla-text-gray-800 salla-mb-4">Help & Information</h2>
      
      <div class="salla-space-y-4 salla-text-gray-600">
        <div>
          <h3 class="salla-text-lg salla-font-semibold salla-text-gray-700">Understanding Intervals</h3>
          <p class="salla-mt-1">
            Intervals determine how often a subscription will charge the customer:
            <ul class="salla-list-disc salla-ml-6 salla-mt-2">
              <li>day: Customer is charged daily</li>
              <li>week: Customer is charged weekly</li>
              <li>month: Customer is charged monthly</li>
              <li>year: Customer is charged yearly</li>
            </ul>
          </p>
        </div>

        <div>
          <h3 class="salla-text-lg salla-font-semibold salla-text-gray-700">Interval Count</h3>
          <p class="salla-mt-1">
            Interval count specifies how many intervals should pass between each charge. For example:
            <ul class="salla-list-disc salla-ml-6 salla-mt-2">
              <li>Interval: month, Count: 1 = Monthly billing</li>
              <li>Interval: month, Count: 3 = Quarterly billing</li>
              <li>Interval: month, Count: 6 = Bi-annual billing</li>
            </ul>
          </p>
        </div>

        <div>
          <h3 class="salla-text-lg salla-font-semibold salla-text-gray-700">Stripe IDs Format</h3>
          <p class="salla-mt-1">
            <ul class="salla-list-disc salla-ml-6">
              <li>Product ID: Always starts with <span class="salla-font-mono salla-bg-gray-100 salla-px-1">prod_</span> followed by random characters</li>
              <li>Price ID: Always starts with <span class="salla-font-mono salla-bg-gray-100 salla-px-1">price_</span> followed by random characters</li>
            </ul>
          </p>
        </div>

        <div>
          <h3 class="salla-text-lg salla-font-semibold salla-text-gray-700">Credits</h3>
          <p class="salla-mt-1">
            Credits are the number of units customers receive with their purchase or subscription. Make sure this value accurately reflects your pricing strategy.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
  
  
  <script>
import { API_URL } from '@/config';

export default {
  data() {
    return {
      plans: [],
      oneTimePurchases: [],
      planName: '',
      planDescription: '',
      stripeProductId: '',
      stripePlanId: '',
      price: '',
      creditsAmount: '',
      interval: '',
      intervalCount: 1,
      customDescription: '',
      productName: '',
      productDescription: '',
      productPrice: '',
      planImage: null, // New state for image
    };
  },
  created() {
    this.fetchPlans();
    this.fetchOneTimePurchases();
  },
  methods: {
    async removePlan(planId) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token available. User must be logged in to remove a plan.');
          return;
        }

        const confirmed = window.confirm('Are you sure you want to remove this plan?');
        if (!confirmed) {
          return;
        }

        const response = await fetch(`${API_URL}/admin/stripe_plan_product/${planId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });

        const data = await response.json();
        if (response.ok) {
          alert('Plan removed successfully');
          this.fetchPlans(); // Refresh plans
        } else {
          alert(data.error || 'An error occurred');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred');
      }
    },
  
    handleImageUpload(event) {
      this.planImage = event.target.files[0];
    },
    async fetchPlans() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to fetch plans.');
        return;
      }
      try {
        const response = await fetch(`${API_URL}/stripe_plan_products`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });
        const data = await response.json();
        if (response.ok) {
          this.plans = data;
        } else {
          console.error('Error fetching plans:', data);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async fetchOneTimePurchases() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token available. User must be logged in to fetch one-time purchases.');
        return;
      }
      try {
        const response = await fetch(`${API_URL}/one_time_purchases`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });
        const data = await response.json();
        if (response.ok) {
          this.oneTimePurchases = data;
        } else {
          console.error('Error fetching one-time purchases:', data);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async submitPlanProductForm() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token available. User must be logged in to submit form.');
          return;
        }

        const formData = new FormData();
        formData.append('name', this.planName);
        formData.append('description', this.planDescription);
        formData.append('stripe_product_id', this.stripeProductId);
        formData.append('stripe_plan_id', this.stripePlanId);
        formData.append('price', this.price);
        formData.append('credits_amount', this.creditsAmount);
        formData.append('interval', this.interval);
        formData.append('interval_count', this.intervalCount);
        formData.append('custom_description', this.customDescription);

        if (this.planImage) {
          formData.append('image', this.planImage);
        }

        const response = await fetch(`${API_URL}/admin/stripe_plan_product`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData,
        });

        const data = await response.json();
        if (response.ok) {
          alert('Stripe plan product added successfully');
          this.planName = '';
          this.planDescription = '';
          this.stripeProductId = '';
          this.stripePlanId = '';
          this.price = '';
          this.creditsAmount = '';
          this.interval = '';
          this.intervalCount = 1;
          this.customDescription = '';
          this.planImage = null; // Reset the image
          this.fetchPlans(); // Refresh plans
        } else {
          alert(data.error || 'An error occurred');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred');
      }
    },
    async submitProductForm() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token available. User must be logged in to submit form.');
          return;
        }
        const response = await fetch(`${API_URL}/admin/one_time_purchase`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            name: this.productName,
            description: this.productDescription,
            price: this.productPrice,
            credits_amount: this.creditsAmount,
            stripe_product_id: this.stripeProductId,
          }),
        });
        const data = await response.json();
        if (response.ok) {
          alert('One-time purchase product added successfully');
          this.productName = '';
          this.productDescription = '';
          this.productPrice = '';
          this.creditsAmount = '';
          this.stripeProductId = '';
          this.fetchOneTimePurchases(); // Refresh products
        } else {
          alert(data.error || 'An error occurred');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred');
      }
    },
  },
};
</script>
