<!-- eslint-disable vue/no-parsing-error -->
<template>
  <main class="main-content">
    <section class="content">
      <div class="content-header">
        <h1 class="top-heading">ربط المتجر الخاص بك</h1>
        <h2 class="sub-heading">اختر منصة لربط متجرك واستيراد المنتجات</h2>
        <div class="platforms">
          <div class="platform">
            <div class="platform-card active">
              <div class="platform-icon">
                <img :src="require('../../assets/woo.png')" alt="أيقونة WooCommerce">
              </div>
              <div class="platform-info">
                <p class="platform-name">WooCommerce</p>
                
                <button class="platform-status" @click="isConnectedToWooCommerce ? disconnectWooCommerce() : redirectToWooCommerceAuth()">
                  {{ isConnectedToWooCommerce ? 'قطع الاتصال' : 'اتصال' }}
                </button>
                <button v-if="isConnectedToWooCommerce" class="platform-status" @click="getNewProducts()">تحديث المنتجات</button>
              </div>
            </div>
          </div>

          <div class="platform">
            <div class="platform-card" :class="{ active: isConnectedToSalla }">
              <div class="platform-icon">
                <img :src="require('../../assets/salla.png')" alt="أيقونة Salla">
              </div>
              <div class="platform-info">
                <p class="platform-name">Salla</p>
                
                <button class="platform-status" @click="isConnectedToSalla ? disconnectSalla() : connectSalla()">
                  {{ isConnectedToSalla ? 'قطع الاتصال' : 'اتصال' }}
                </button>
                <button v-if="isConnectedToSalla" class="platform-status" @click="refreshSallaProducts()">تحديث المنتجات</button>
              </div>
            </div>
          </div>

          <div class="platform">
            <div class="platform-card" :class="{ active: isConnectedToZid }">
              <div class="platform-icon">
                <img :src="require('../../assets/zid.png')" alt="أيقونة Zid">
              </div>
              <div class="platform-info">
                <p class="platform-name">Zid</p>
                
                <button class="platform-status" @click="isConnectedToZid ? disconnectZid() : redirectToZidAuth()">
                  {{ isConnectedToZid ? 'قطع الاتصال' : 'اتصال' }}
                </button>
                <button v-if="isConnectedToZid" class="platform-status" @click="refreshZidProducts()">تحديث المنتجات</button>
              </div>
            </div>
          </div>

          <!-- New Shopify platform option -->
          <div class="platform">
            <div class="platform-card">
              <div class="platform-icon">
                <img :src="require('../../assets/shopify.png')" alt="أيقونة Shopify">
              </div>
              <div class="platform-info">
                <p class="platform-name">Shopify</p>
                
                <button class="platform-status">قريبا</button>
              </div>
            </div>
          </div>
          <!-- End of Shopify platform option -->

        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { API_URL } from '@/config';
import AuthService from '@/services/authService';

export default {
  data() {
    return {
      isConnected: false,
      isConnecting: false
    };
  },
  computed: {
    ...mapState([
      'isConnectedToWooCommerce',
      'isConnectedToSalla',
      'isConnectedToZid' // Added Zid connection status
    ])
  },
  methods: {
    ...mapActions([
      'updateSallaConnectionStatus',
      'updateWooCommerceConnectionStatus',
      'updateZidConnectionStatus' // Added Zid connection status update action
    ]),
    async connectSalla() {
      this.isConnecting = true;
      const token = AuthService.getToken();
      if (!token) {
        this.$router.push('/login');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/salla_auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        const responseData = await response.json();
        if (responseData.authorization_url) {
          window.location.href = responseData.authorization_url;
        } else {
          this.$store.dispatch('pushNotification', { message: 'Failed to connect to Salla. Please check your credentials and try again.', type: 'error' });
          this.$router.push('/connect');
        }
      } catch (error) {
        this.$store.dispatch('pushNotification', { message: 'Failed to connect to Salla. Please check your network and try again.', type: 'error' });
        this.$router.push('/connect');
      } finally {
        this.isConnecting = false;
      }
    },
    async handleSallaCallback(code, state) {
      const token = AuthService.getToken();
      if (!token) {
        this.$router.push('/login');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/salla_callback?code=${code}&state=${state}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (data.success) {
          this.updateSallaConnectionStatus(true);
          await this.fetchSallaProducts();
        } else {
          this.$store.dispatch('pushNotification', {
            message: 'Failed to connect to Salla. Please try again.',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Error handling Salla callback:', error);
        this.$store.dispatch('pushNotification', {
          message: 'An error occurred while handling Salla callback.',
          type: 'error'
        });
      } finally {
        this.$router.push('/connect');
      }
    },
    async fetchSallaProducts() {
      const token = AuthService.getToken();
      if (!token) {
        this.$router.push('/login');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/salla/products`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (data.success) {
          this.$store.dispatch('pushNotification', {
            message: 'Products successfully retrieved and stored.',
            type: 'success'
          });
        } else {
          this.$store.dispatch('pushNotification', {
            message: 'Failed to retrieve products. Please try again.',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Error fetching Salla products:', error);
        this.$store.dispatch('pushNotification', {
          message: 'An error occurred while fetching Salla products.',
          type: 'error'
        });
      }
    },
    async checkSallaConnection() {
      try {
        const response = await fetch(`${API_URL}/salla/check_connection`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();

        this.updateSallaConnectionStatus(data.is_connected);
      } catch (error) {
        console.error('Error checking Salla connection:', error);
      }
    },
    async disconnectSalla() {
      try {
        const response = await fetch(`${API_URL}/salla/disconnect`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();

        if (data.success) {
          this.updateSallaConnectionStatus(false);
          this.$store.dispatch('pushNotification', {
            message: 'Disconnected from Salla successfully.',
            type: 'success'
          });
        } else {
          this.$store.dispatch('pushNotification', {
            message: 'Failed to disconnect from Salla. Please try again.',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Error disconnecting from Salla:', error);
        this.$store.dispatch('pushNotification', {
          message: 'An error occurred while disconnecting from Salla.',
          type: 'error'
        });
      }
    },
    async refreshSallaProducts() {
      try {
        this.$store.dispatch('pushNotification', {
          message: 'Refreshing products...',
          type: 'info'
        });

        const response = await fetch(`${API_URL}/salla/refresh`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();

        if (data.success) {
          const newProductsCount = data.newProductsCount || 0;
          this.$store.dispatch('pushNotification', {
            message: `${newProductsCount} new product(s) added successfully.`,
            type: 'success'
          });
        } else {
          this.$store.dispatch('pushNotification', {
            message: 'Failed to refresh products. Please try again.',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Error fetching new products:', error);
        this.$store.dispatch('pushNotification', {
          message: 'An error occurred while refreshing products.',
          type: 'error'
        });
      }
    },
    redirectToWooCommerceAuth() {
      if (!this.$router) {
        console.error('Router instance is not available.');
        return;
      }
      this.$router.push('/woocommerce-auth');
    },
    async disconnectWooCommerce() {
      try {
        const response = await fetch(`${API_URL}/woocommerce/disconnect`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();

        if (data.success) {
          this.updateWooCommerceConnectionStatus(false);
          this.$store.dispatch('pushNotification', {
            message: 'Disconnected from WooCommerce successfully.',
            type: 'success'
          });
        } else {
          this.$store.dispatch('pushNotification', {
            message: 'Failed to disconnect from WooCommerce. Please try again.',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Error disconnecting from WooCommerce:', error);
        this.$store.dispatch('pushNotification', {
          message: 'An error occurred while disconnecting from WooCommerce.',
          type: 'error'
        });
      }
    },
    async getNewProducts() {
      try {
        this.$store.dispatch('pushNotification', {
          message: 'Refreshing products...',
          type: 'info'
        });

        const response = await fetch(`${API_URL}/woocommerce/new_products`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();

        if (data.success) {
          const newProductsCount = data.newProductsCount || 0;
          this.$store.dispatch('pushNotification', {
            message: `${newProductsCount} new product(s) added successfully.`,
            type: 'success'
          });
        } else {
          this.$store.dispatch('pushNotification', {
            message: 'Failed to refresh products. Please try again.',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Error fetching new products:', error);
        this.$store.dispatch('pushNotification', {
          message: 'An error occurred while refreshing products.',
          type: 'error'
        });
      }
    },
    async checkWooCommerceConnection() {
      try {
        const response = await fetch(`${API_URL}/woocommerce/check_connection`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();
        this.updateWooCommerceConnectionStatus(data.is_connected);
      } catch (error) {
        console.error('Error checking WooCommerce connection:', error);
      }
    },

    // Methods for Zid
    async redirectToZidAuth() {
      try {
        const response = await fetch(`${API_URL}/zid_auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();

        if (data.authorization_url) {
          window.location.href = data.authorization_url;
        } else {
          this.$store.dispatch('pushNotification', {
            message: 'Failed to initiate Zid authentication.',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Error initiating Zid authentication:', error);
        this.$store.dispatch('pushNotification', {
          message: 'An error occurred while initiating Zid authentication.',
          type: 'error'
        });
      }
    },
    async checkZidConnection() {
      try {
        const response = await fetch(`${API_URL}/zid/check_connection`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();

        this.updateZidConnectionStatus(data.is_connected);
      } catch (error) {
        console.error('Error checking Zid connection:', error);
      }
    },
    async disconnectZid() {
      try {
        const response = await fetch(`${API_URL}/zid/disconnect`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();

        if (data.success) {
          this.updateZidConnectionStatus(false);
          this.$store.dispatch('pushNotification', {
            message: 'Disconnected from Zid successfully.',
            type: 'success'
          });
        } else {
          this.$store.dispatch('pushNotification', {
            message: 'Failed to disconnect from Zid. Please try again.',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Error disconnecting from Zid:', error);
        this.$store.dispatch('pushNotification', {
          message: 'An error occurred while disconnecting from Zid.',
          type: 'error'
        });
      }
    },
    async refreshZidProducts() {
      try {
        this.$store.dispatch('pushNotification', {
          message: 'Refreshing products...',
          type: 'info'
        });

        const response = await fetch(`${API_URL}/zid/refresh`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const data = await response.json();

        if (data.success) {
          const newProductsCount = data.newProductsCount || 0;
          this.$store.dispatch('pushNotification', {
            message: `${newProductsCount} new product(s) added successfully.`,
            type: 'success'
          });
        } else {
          this.$store.dispatch('pushNotification', {
            message: 'Failed to refresh products. Please try again.',
            type: 'error'
          });
        }
      } catch (error) {
        console.error('Error fetching new products:', error);
        this.$store.dispatch('pushNotification', {
          message: 'An error occurred while refreshing products.',
          type: 'error'
        });
      }
    }
  },
  mounted() {
    this.checkWooCommerceConnection();
    this.checkSallaConnection();
    this.checkZidConnection(); // Check Zid connection on mount

    // Handle Salla callback if code and state are present in the query
    const code = this.$route.query.code;
    const state = this.$route.query.state;
    if (code && state) {
      this.handleSallaCallback(code, state);
    }
  }
};
</script>




<!-- Existing styles -->

  <style scoped>

/* General Styles */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f7f7f7;
  color: #333;
}

/* Top Heading */
.top-heading {
  color: #2c3e50;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

/* Sub Heading */
.sub-heading {
  color: #7f8c8d;
  font-size: 24px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 40px;
  padding-bottom: 15px;
  border-bottom: 2px solid #bdc3c7;
}

/* Platforms */
.platforms {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.platform {
  width: 300px;
  max-width: 100%;
}

/* Platform Card */
.platform-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 25px;
  transition: all 0.3s ease;
}

.platform-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.platform-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin-bottom: 20px;
}

.platform-icon img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.platform-info {
  text-align: center;
}

.platform-name {
  color: #2c3e50;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

/* Platform Status Button */
.platform-status {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #2ecc71;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 5px;
}

.platform-status:hover {
  background-color: #27ae60;
}

.platform-status.connected {
  background-color: #e74c3c;
}

.platform-status.connected:hover {
  background-color: #c0392b;
}

/* Dashboard */
.dashboard {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 30px;
  width: 100%;
}

.main-content {
  width: 100%;
    max-width: 100%;
    margin-top: 14px;
    border-radius: 20px;
    margin-left: 7px;

}

.content {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
}

.content-header {
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.social-links {
  display: flex;
  gap: 20px;
  font-size: 16px;
  color: #7f8c8d;
  font-weight: 500;
  margin-bottom: 20px;
}

.social-button {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  background: none;
  color: #7f8c8d;
  transition: color 0.3s;
}

.social-button:hover {
  color: #2c3e50;
}

.social-button.active {
  color: #2980b9;
  border-bottom: 2px solid #2980b9;
}

.section-title {
  color: #2c3e50;
  letter-spacing: -0.5px;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: center;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .top-heading {
    font-size: 28px;
  }

  .sub-heading {
    font-size: 20px;
  }

  .platform {
    width: 100%;
  }

  .platform-card {
    padding: 20px;
  }

  .section-title {
    font-size: 28px;
  }

  .social-button {
    padding: 6px 12px;
  }
}
  </style>
  
  