<template>
  <div class="salla-min-h-screen salla-bg-gray-50 salla-p-6">
    <!-- Page Header -->
    <div class="salla-mb-8">
      <h1 class="salla-text-3xl salla-font-bold salla-text-gray-900">Design Template Management</h1>
      <p class="salla-mt-2 salla-text-gray-600">Manage your video design templates and their dynamic elements</p>
    </div>

    <div class="salla-grid salla-grid-cols-1 xl:salla-grid-cols-2 salla-gap-6">
      <!-- Add Template Form Section -->
      <section class="salla-bg-white salla-rounded-lg salla-shadow-md salla-p-6">
        <h2 class="salla-text-xl salla-font-semibold salla-text-gray-800 salla-mb-6">Add New Design Template</h2>
        
        <form @submit.prevent="submitDesignTemplate" class="salla-space-y-6">
          <!-- Basic Information -->
          <div class="salla-space-y-4">
            <h3 class="salla-text-sm salla-font-medium salla-text-gray-900 salla-pb-2 salla-border-b">Basic Information</h3>
            
            <div class="salla-grid salla-grid-cols-1 md:salla-grid-cols-2 salla-gap-4">
              <div>
                <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">
                  Creatomate Template ID
                  <span class="salla-text-xs salla-text-gray-500 salla-block">Unique identifier from Creatomate</span>
                </label>
                <input 
                  type="text" 
                  v-model="creatomateTemplateId"
                  class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md focus:salla-ring-primary-500 focus:salla-border-primary-500"
                  required
                >
              </div>
              
              <div>
                <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">
                  Design Name
                  <span class="salla-text-xs salla-text-gray-500 salla-block">Display name for the template</span>
                </label>
                <input 
                  type="text" 
                  v-model="designName"
                  class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md focus:salla-ring-primary-500 focus:salla-border-primary-500"
                  required
                >
              </div>
            </div>

            <div>
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">
                Design Description
                <span class="salla-text-xs salla-text-gray-500 salla-block">Detailed description of the template</span>
              </label>
              <textarea 
                v-model="designDescription"
                rows="3"
                class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md focus:salla-ring-primary-500 focus:salla-border-primary-500"
                required
              ></textarea>
            </div>
          </div>

          <!-- Dynamic Elements Configuration -->
          <div class="salla-space-y-4">
            <div class="salla-flex salla-items-center salla-justify-between">
              <h3 class="salla-text-sm salla-font-medium salla-text-gray-900 salla-pb-2">Dynamic Elements</h3>
              <button 
                type="button"
                @click="showHelp = !showHelp"
                class="salla-text-primary-600 salla-text-sm salla-font-medium hover:salla-text-primary-700"
              >
                Need help with dynamic elements?
              </button>
            </div>

            <div class="salla-grid salla-grid-cols-1 md:salla-grid-cols-2 salla-gap-4">
              <div>
                <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">
                  Number of Images
                  <span class="salla-text-xs salla-text-gray-500 salla-block">Total image placeholders</span>
                </label>
                <input 
                  type="number"
                  v-model.number="numImages"
                  min="1"
                  class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md focus:salla-ring-primary-500 focus:salla-border-primary-500"
                  required
                >
              </div>

              <div>
                <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">
                  Number of Texts
                  <span class="salla-text-xs salla-text-gray-500 salla-block">Total text elements</span>
                </label>
                <input 
                  type="number"
                  v-model.number="numTexts"
                  min="1"
                  class="salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md focus:salla-ring-primary-500 focus:salla-border-primary-500"
                  required
                >
              </div>
            </div>

            <div>
              <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700 salla-mb-1">
                Dynamic Element IDs
                <span class="salla-text-xs salla-text-gray-500 salla-block">JSON mapping of dynamic elements to template IDs</span>
              </label>
              <textarea 
                v-model="dynamicElementIds"
                rows="6"
                class="salla-font-mono salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md focus:salla-ring-primary-500 focus:salla-border-primary-500"
                placeholder='{"template_id": "your_template_id","elements": {"product_name_key": "ID_1"}}'
              ></textarea>
            </div>
          </div>

          <!-- Preview Upload -->
          <div class="salla-space-y-4">
            <h3 class="salla-text-sm salla-font-medium salla-text-gray-900 salla-pb-2">Preview Video</h3>
            
            <div class="salla-flex salla-items-center salla-justify-center salla-w-full">
              <label class="salla-flex salla-flex-col salla-items-center salla-justify-center salla-w-full salla-h-32 salla-border-2 salla-border-gray-300 salla-border-dashed salla-rounded-lg salla-cursor-pointer hover:salla-bg-gray-50">
                <div class="salla-flex salla-flex-col salla-items-center salla-justify-center salla-pt-5 salla-pb-6">
                  <svg class="salla-w-8 salla-h-8 salla-text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"/>
                  </svg>
                  <p class="salla-mt-2 salla-text-sm salla-text-gray-500">
                    <span class="salla-font-medium">Click to upload</span> or drag and drop
                  </p>
                  <p class="salla-text-xs salla-text-gray-500">MP4, WebM or other video formats</p>
                </div>
                <input 
                  type="file" 
                  @change="onVideoPreviewChange" 
                  accept="video/*"
                  class="salla-hidden"
                  required
                >
              </label>
            </div>
          </div>

          <button 
            type="submit"
            :disabled="!videoPreview"
            class="salla-w-full salla-flex salla-justify-center salla-py-2 salla-px-4 salla-border salla-border-transparent salla-rounded-md salla-shadow-sm salla-text-sm salla-font-medium salla-text-white salla-bg-primary-600 hover:salla-bg-primary-700 focus:salla-outline-none focus:salla-ring-2 focus:salla-ring-offset-2 focus:salla-ring-primary-500 disabled:salla-opacity-50 disabled:salla-cursor-not-allowed"
          >
            Add Template
          </button>
        </form>
      </section>

      <!-- Templates List Section -->
      <section class="salla-bg-white salla-rounded-lg salla-shadow-md salla-p-6">
        <h2 class="salla-text-xl salla-font-semibold salla-text-gray-800 salla-mb-6">Existing Templates</h2>
        
        <div class="salla-overflow-x-auto">
          <table class="salla-min-w-full salla-divide-y salla-divide-gray-200">
            <thead class="salla-bg-gray-50">
              <tr>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">Template</th>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-left salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">Creatomate ID</th>
                <th scope="col" class="salla-px-6 salla-py-3 salla-text-right salla-text-xs salla-font-medium salla-text-gray-500 salla-uppercase salla-tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody class="salla-bg-white salla-divide-y salla-divide-gray-200">
              <tr v-for="template in templates" :key="template.id" class="hover:salla-bg-gray-50">
                <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap">
                  <div class="salla-flex salla-items-center">
                    <div class="salla-flex-shrink-0 salla-h-10 salla-w-10">
                      <!-- Template preview thumbnail could go here -->
                      <div class="salla-h-10 salla-w-10 salla-rounded-full salla-bg-gray-200 salla-flex salla-items-center salla-justify-center">
                        <svg class="salla-h-6 salla-w-6 salla-text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                        </svg>
                      </div>
                    </div>
                    <div class="salla-ml-4">
                      <div class="salla-text-sm salla-font-medium salla-text-gray-900">{{ template.name }}</div>
                      <div class="salla-text-sm salla-text-gray-500">ID: {{ template.id }}</div>
                    </div>
                  </div>
                </td>
                <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap">
                  <div class="salla-text-sm salla-text-gray-900">{{ template.creatomate_template_id }}</div>
                </td>
                <td class="salla-px-6 salla-py-4 salla-whitespace-nowrap salla-text-right salla-text-sm salla-font-medium">
                  <button 
                    @click="openEditModal(template)"
                    class="salla-text-primary-600 hover:salla-text-primary-900 salla-mr-4"
                  >
                    Edit
                  </button>
                  <button 
                    @click="deleteDesignTemplate(template.id)"
                    class="salla-text-red-600 hover:salla-text-red-900"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>

    <!-- Help Modal -->
    <div 
      v-if="showHelp"
      class="salla-fixed salla-inset-0 salla-bg-gray-500 salla-bg-opacity-75 salla-transition-opacity salla-z-50"
    >
      <div class="salla-fixed salla-inset-0 salla-overflow-y-auto">
        <div class="salla-flex salla-items-end sm:salla-items-center salla-justify-center salla-min-h-full salla-p-4 salla-text-center sm:salla-p-0">
          <div class="salla-relative salla-bg-white salla-rounded-lg salla-px-4 salla-pt-5 salla-pb-4 salla-text-left salla-overflow-hidden salla-shadow-xl salla-transform salla-transition-all sm:salla-my-8 sm:salla-max-w-2xl sm:salla-w-full sm:salla-p-6">
            <div class="salla-absolute salla-top-0 salla-right-0 salla-pt-4salla-pr-4">
              <button
                @click="showHelp = false"
                class="salla-text-gray-400 hover:salla-text-gray-500"
              >
                <span class="salla-sr-only">Close</span>
                <svg class="salla-h-6 salla-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <!-- Help Content -->
            <div class="salla-mt-2 salla-space-y-4">
              <div>
                <h3 class="salla-text-lg salla-font-medium salla-text-gray-900">Dynamic Elements Guide</h3>
                <p class="salla-text-sm salla-text-gray-500">Learn how to properly configure dynamic elements in your video templates</p>
              </div>

              <div class="salla-bg-gray-50 salla-rounded-lg salla-p-4 salla-space-y-4">
                <div>
                  <h4 class="salla-font-medium salla-text-gray-900">Step 1: Count Your Elements</h4>
                  <p class="salla-text-sm salla-text-gray-600">First, identify the total number of customizable elements in your template:</p>
                  <ul class="salla-mt-2 salla-list-disc salla-list-inside salla-text-sm salla-text-gray-600">
                    <li>Count all image placeholders</li>
                    <li>Count all text elements that will be customizable</li>
                  </ul>
                </div>

                <div>
                  <h4 class="salla-font-medium salla-text-gray-900">Step 2: Configure Element IDs</h4>
                  <p class="salla-text-sm salla-text-gray-600">Format your dynamic elements JSON like this:</p>
                  <pre class="salla-mt-2 salla-bg-gray-800 salla-text-white salla-rounded-md salla-p-4 salla-text-sm salla-overflow-x-auto">
{
  "template_id": "your_template_id",
  "elements": {
    "product_name": "text_element_id_1",
    "product_price": "text_element_id_2",
    "main_image": "image_element_id_1",
    "logo": "image_element_id_2"
  }
}</pre>
                </div>

                <div>
                  <h4 class="salla-font-medium salla-text-gray-900">Step 3: Verify Configuration</h4>
                  <ul class="salla-mt-2 salla-list-disc salla-list-inside salla-text-sm salla-text-gray-600">
                    <li>Ensure the "Number of Images" matches your image elements</li>
                    <li>Ensure the "Number of Texts" matches your text elements</li>
                    <li>Verify all element IDs match your Creatomate template</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Modal -->
    <div 
      v-if="showEditModal"
      class="salla-fixed salla-inset-0 salla-bg-gray-500 salla-bg-opacity-75 salla-transition-opacity salla-z-50"
    >
      <div class="salla-fixed salla-inset-0 salla-overflow-y-auto">
        <div class="salla-flex salla-items-end sm:salla-items-center salla-justify-center salla-min-h-full salla-p-4 salla-text-center sm:salla-p-0">
          <div class="salla-relative salla-bg-white salla-rounded-lg salla-px-4 salla-pt-5 salla-pb-4 salla-text-left salla-overflow-hidden salla-shadow-xl salla-transform salla-transition-all sm:salla-my-8 sm:salla-max-w-2xl sm:salla-w-full sm:salla-p-6">
            <div class="salla-absolute salla-top-0 salla-right-0 salla-pt-4 salla-pr-4">
              <button
                @click="closeEditModal"
                class="salla-text-gray-400 hover:salla-text-gray-500"
              >
                <span class="salla-sr-only">Close</span>
                <svg class="salla-h-6 salla-w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <form @submit.prevent="updateDesignTemplate" class="salla-space-y-6">
              <div>
                <h2 class="salla-text-xl salla-font-semibold salla-text-gray-900">Edit Template</h2>
                <p class="salla-mt-1 salla-text-sm salla-text-gray-600">Update your template's configuration and preview</p>
              </div>

              <!-- Edit Form Fields -->
              <div class="salla-space-y-6">
                <div class="salla-grid salla-grid-cols-1 md:salla-grid-cols-2 salla-gap-4">
                  <div>
                    <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">
                      Template ID
                      <span class="salla-text-xs salla-text-gray-500 salla-block">System identifier</span>
                    </label>
                    <input 
                      type="text"
                      v-model="editTemplateId"
                      disabled
                      class="salla-mt-1 salla-bg-gray-100 salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                    >
                  </div>

                  <div>
                    <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">
                      Creatomate Template ID
                      <span class="salla-text-xs salla-text-gray-500 salla-block">From Creatomate platform</span>
                    </label>
                    <input 
                      type="text"
                      v-model="editCreatomateTemplateId"
                      class="salla-mt-1 salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                      required
                    >
                  </div>
                </div>

                <div class="salla-space-y-4">
                  <div>
                    <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">
                      Design Name
                    </label>
                    <input 
                      type="text"
                      v-model="editDesignName"
                      class="salla-mt-1 salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                      required
                    >
                  </div>

                  <div>
                    <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">
                      Description
                    </label>
                    <textarea 
                      v-model="editDesignDescription"
                      rows="3"
                      class="salla-mt-1 salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                    ></textarea>
                  </div>
                </div>

                <div class="salla-grid salla-grid-cols-1 md:salla-grid-cols-2 salla-gap-4">
                  <div>
                    <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">Number of Images</label>
                    <input 
                      type="number"
                      v-model="editNumImages"
                      min="1"
                      class="salla-mt-1 salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                      required
                    >
                  </div>

                  <div>
                    <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">Number of Texts</label>
                    <input 
                      type="number"
                      v-model="editNumTexts"
                      min="1"
                      class="salla-mt-1 salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                      required
                    >
                  </div>
                </div>

                <div>
                  <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">Dynamic Element IDs</label>
                  <textarea 
                    v-model="editDynamicElementIds"
                    rows="6"
                    class="salla-mt-1 salla-font-mono salla-w-full salla-px-3 salla-py-2 salla-border salla-border-gray-300 salla-rounded-md"
                  ></textarea>
                </div>

                <!-- Video Preview Update -->
                <div>
                  <label class="salla-block salla-text-sm salla-font-medium salla-text-gray-700">Update Preview Video</label>
                  <input 
                    type="file"
                    @change="onEditVideoPreviewChange"
                    accept="video/*"
                    class="salla-mt-1 salla-w-full"
                  >
                </div>
              </div>

              <div class="salla-flex salla-justify-end salla-gap-3">
                <button
                  type="button"
                  @click="closeEditModal"
                  class="salla-px-4 salla-py-2 salla-text-sm salla-font-medium salla-text-gray-700 salla-bg-white salla-border salla-border-gray-300 salla-rounded-md hover:salla-bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  class="salla-px-4 salla-py-2 salla-text-sm salla-font-medium salla-text-white salla-bg-primary-600 salla-border salla-border-transparent salla-rounded-md hover:salla-bg-primary-700"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_URL } from '@/config';

export default {
  data() {
    return {
      creatomateTemplateId: '',
      designName: '',
      designDescription: '',
      videoPreview: null,
      numImages: 1,
      numTexts: 1,
      dynamicElementIds: '',
      templates: [],
      showEditModal: false,
      showHelp: false,  // Added data property for showing help
      editTemplateId: null,
      editCreatomateTemplateId: '',
      editDesignName: '',
      editDesignDescription: '',
      editDesignPreviewUrl: '',
      editNumImages: 1,
      editNumTexts: 1,
      editDynamicElementIds: '',
      editVideoPreview: null,
      editCreatedAt: '',
      editUpdatedAt: ''
    };
  },
  mounted() {
    this.fetchDesignTemplates();
  },
  methods: {
    deleteDesignTemplate(templateId) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to delete templates.');
        return;
      }

      const confirmed = confirm('Are you sure you want to delete this design template?');
      if (!confirmed) {
        return;
      }

      fetch(`${API_URL}/api/design-templates/${templateId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          alert(data.message);
          // Refresh the design templates list
          this.fetchDesignTemplates();
        })
        .catch(error => {
          console.error('Failed to delete design template', error);
          alert('Failed to delete design template');
        });
    },
    onVideoPreviewChange(event) {
      this.videoPreview = event.target.files[0];
    },
    onEditVideoPreviewChange(event) {
      this.editVideoPreview = event.target.files[0];
    },
    submitDesignTemplate() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to submit data.');
        return;
      }

      const formData = new FormData();
      formData.append('creatomate_template_id', this.creatomateTemplateId);
      formData.append('design_name', this.designName);
      formData.append('design_description', this.designDescription);
      formData.append('num_images', this.numImages);
      formData.append('num_texts', this.numTexts);
      formData.append('dynamic_element_ids', this.dynamicElementIds);
      formData.append('video_preview', this.videoPreview);

      fetch(`${API_URL}/api/insert-design-template`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          alert(data.message);
          // Reset form fields
          this.creatomateTemplateId = '';
          this.designName = '';
          this.designDescription = '';
          this.videoPreview = null;
          this.numImages = 1;
          this.dynamicElementIds = '';
          // Refresh the design templates list
          this.fetchDesignTemplates();
        })
        .catch(error => {
          console.error('Failed to submit design template data', error);
          alert('Failed to submit design template data');
        });
    },
    updateDesignTemplate() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to update data.');
        return;
      }

      const formData = new FormData();
      formData.append('creatomate_template_id', this.editCreatomateTemplateId);
      formData.append('design_name', this.editDesignName);
      formData.append('design_description', this.editDesignDescription);
      formData.append('design_preview_url', this.editDesignPreviewUrl);
      formData.append('num_images', this.editNumImages);
      formData.append('num_texts', this.editNumTexts);
      formData.append('dynamic_element_ids', this.editDynamicElementIds);

      if (this.editVideoPreview) {
        formData.append('video_preview', this.editVideoPreview);
      }

      fetch(`${API_URL}/api/design-templates/${this.editTemplateId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          alert(data.message);
          // Reset form fields and close the modal
          this.editTemplateId = null;
          this.editCreatomateTemplateId = '';
          this.editDesignName = '';
          this.editDesignDescription = '';
          this.editDesignPreviewUrl = '';
          this.editNumImages = 1;
          this.editDynamicElementIds = '';
          this.editVideoPreview = null;
          this.showEditModal = false;
          // Refresh the design templates list
          this.fetchDesignTemplates();
        })
        .catch(error => {
          console.error('Failed to update design template', error);
          alert('Failed to update design template');
        });
    },
    fetchDesignTemplates() {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('No token found. You must be logged in to fetch templates.');
        return;
      }

      fetch(`${API_URL}/api/design-templates`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          this.templates = data;
        })
        .catch(error => {
          console.error('Failed to fetch design templates', error);
          alert('Failed to fetch design templates');
        });
    },
    openEditModal(template) {
      this.editTemplateId = template.id;
      this.editCreatomateTemplateId = template.creatomate_template_id;
      this.editDesignName = template.name;
      this.editDesignDescription = template.description;
      this.editDesignPreviewUrl = template.preview_url;
      this.editNumImages = template.num_images;
      this.editNumTexts = template.num_texts;
      this.editDynamicElementIds = template.dynamic_element_ids;
      this.editCreatedAt = template.created_at;
      this.editUpdatedAt = template.updated_at;
      this.editVideoPreview = null;
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
    }
  },
};
</script>
